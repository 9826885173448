<template>
  <a :title="text" @click.stop="onClick">
    <slot>{{ text }}</slot>
  </a>
</template>

<script>
import { toRefs } from "vue";
import { DEFAULT_ACTION_DEBOUNCE } from "@/conf/constants";
import { debounceFunc } from "@/util/uiUtil";

export default {
  name: "ActLink",
  emits: ["click", "action"],
  props: {
    text: { type: String },
    debounce: { type: Number, default: DEFAULT_ACTION_DEBOUNCE },
  },
  setup(props, { emit }) {
    const { debounce } = toRefs(props);
    const onAction = debounceFunc(
      (e) => emit("action", e),
      debounce.value,
      true
    );
    const onClick = (e) => {
      emit("click", e);
      onAction(e);
    };
    return { onClick };
  },
};
</script>

<style scoped></style>
