export default {
  allFieldInfo: {
    width: "l",
    footer: null,
    component: () =>
      import(
        /*  webpackChunkName: "archViewer_dialog" */ "@/mods/front/archDetail/dialog/AllFieldInfo"
      ),
  },
  fileFormat: {
    width: "s",
    okText: "front.actions.download_now",
    component: () =>
      import(
        /*  webpackChunkName: "archViewer_dialog" */ "@/mods/front/archDetail/dialog/FileFormat"
      ),
  },
  excerptInfo: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /*  webpackChunkName: "archViewer_dialog" */ "@/mods/front/archDetail/dialog/ExcerptInfo"
      ),
  },
};
