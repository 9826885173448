export default {
  addOrg: {
    width: "s",
    okText: "actions.save",
    title: "添加单位",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/orgManage/dialog/AddOrg"
      ),
  },
  editParent: {
    width: "s",
    okText: "变更上级",
    title: "变更上级",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/orgManage/dialog/EditParent"
      ),
  },
};
