import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import { loadLanguageAsync } from "@/conf/lang";
import mods from "@/router/mods";
import login from "@/router/login";
import BackNotFound from "@/views/BackNotFound";
import { http } from "@/util/http";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  ...login,
  ...mods,
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: BackNotFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const lang = store.state.language;
  const title = to?.meta?.title ? ` - ${to.meta.title}` : "";
  const pathArr = to.path.split("/");
  const afterLangLoaded = () => {
    if (from.path !== to.path) {
      const systemTitle = store.state.systemConfig?.title;
      document.title = systemTitle + title;
    }
    next();
  };
  if (to.matched.some((r) => r.meta.requireAuth)) {
    if (store.state?.accessToken /*&& Date.now() < store.state?.loginExpire*/) {
      loadLanguageAsync(lang, pathArr[1]).then(afterLangLoaded);
      http.post("common/insert/visits", {});
    } else {
      next({ path: "/login", query: { redirect: to.fullPath } });
    }
  } else {
    loadLanguageAsync(lang, pathArr[1]).then(afterLangLoaded);
  }
});

export default router;
