import { i18n } from "@/conf/lang";

export function useToolAction(useLocale, extraState) {
  const opLabel = (label) => {
    return useLocale.value ? i18n.global.t(label) : label;
  };
  const opTips = (op) => {
    const state = extraState.value;
    if (op.checkHasData && !state.hasData) {
      return state.tipsNoData;
    }
    if (op.checkSelected && !state.selected) {
      return state.tipsNotSelect;
    }
    return op.disabled
      ? op.disabledTips || op.disabledTitle
      : op.tips || op.title;
  };
  const checkDisabled = (op) => {
    const state = extraState.value;
    return op.disabled !== undefined
      ? op.disabled
      : (op.checkHasData && !state.hasData) ||
          (op.checkSelected && !state.selected);
  };
  return { opLabel, opTips, checkDisabled };
}
