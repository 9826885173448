<template>
  <component v-if="iconComp" :is="iconComp" />
  <common-icon v-else :icon="icon" />
</template>

<script>
import { computed, toRefs } from "vue";
import {
  ApartmentOutlined,
  IdcardOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";
import CommonIcon from "@/components/icon/CommonIcon";
const ICONS = {
  dept: ApartmentOutlined,
  id: IdcardOutlined,
  tag: TagOutlined,
  user: UserOutlined,
};
export default {
  name: "OptionIcon",
  components: { CommonIcon },
  props: {
    icon: { type: String },
  },
  setup(props) {
    const { icon } = toRefs(props);
    const iconComp = computed(() => ICONS[icon.value]);
    return { iconComp };
  },
};
</script>

<style scoped></style>
