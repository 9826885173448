import common from "@/mods/common/detailView";
import demo from "@/mods/demo/detailView";
import archive from "@/mods/archive/detailView";
import front from "@/mods/front/detailView";
import setting from "@/mods/setting/detailView";
import system from "@/mods/system/detailView";
import warehouse from "@/mods/warehouse/detailView";
import workbench from "@/mods/workbench/detailView";
import statistic from "@/mods/statistic/detailView";
import user from "@/mods/user/dialog";

export default {
  common,
  demo,
  archive,
  front,
  setting,
  system,
  statistic,
  warehouse,
  workbench,
  user,
};
