<template>
  <div class="status-view" :style="viewStyle" :title="viewInfo.text">
    <colored-tag
      v-if="viewInfo.tag"
      class="status-view-tag"
      :class="{ 'status-view-tag-narrow': narrow }"
      :color="viewInfo.color"
      :outline="isOutline"
    >
      {{ viewInfo.text }}
    </colored-tag>
    <template v-else>
      <div
        v-if="viewInfo.dot"
        class="status-view-dot"
        :class="{ 'status-view-dot-outline': isOutline }"
        :style="viewInfo.dotStyle"
      ></div>
      {{ viewInfo.text }}
    </template>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { i18n } from "@/conf/lang";
import { NAMED_COLORS } from "@/conf/constants";
import ColoredTag from "@/components/common/display/ColoredTag";

export default {
  name: "StatusView",
  components: { ColoredTag },
  props: {
    valueMap: { type: [Object, Function], default: () => ({}) },
    status: { type: [String, Number, Object] },
    outline: { type: Boolean },
    narrow: { type: Boolean },
    useLocale: { type: Boolean },
  },
  setup(props) {
    const { valueMap, status, useLocale, outline } = toRefs(props);
    const viewInfo = computed(() => {
      const valMap = valueMap.value;
      const st = status.value;
      if (typeof valMap === "function") {
        return valMap(st) || {};
      } else {
        const name = valMap?.names?.[st];
        const text = name ? (useLocale.value ? i18n.global.t(name) : name) : st;
        return {
          style: valMap?.styles?.[st],
          color: valMap?.colors?.[st],
          dot: valMap.dot,
          tag: valMap.tag,
          outline: valMap.outline,
          text,
        };
      }
    });
    const viewStyle = computed(() => {
      const val = viewInfo.value;
      const color = val.color;
      const style = val.style ? { ...val.style } : {};
      const realColor = !val.tag ? NAMED_COLORS[color] || color : color;
      if (realColor) style["--status-view-color"] = realColor;
      if (!val.tag && !val.dot && style.color === undefined) {
        style.color = realColor;
      }
      return style;
    });
    const isOutline = computed(() => {
      const val = viewInfo.value;
      return val.outline === undefined ? outline.value : val.outline;
    });
    return { viewInfo, viewStyle, isOutline };
  },
};
</script>

<style scoped>
.status-view {
  display: flex;
  align-items: center;
  --status-view-color: var(--rmx-text-2);
}
.status-view-dot {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
  background: var(--status-view-color);
}
.status-view-dot-outline {
  background: white;
  border: 1px solid var(--status-view-color);
}
.status-view-tag {
  margin: 0;
}
.status-view-tag-narrow {
  padding: 2px;
  line-height: 1;
  font-size: 12px;
}
</style>
