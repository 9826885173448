export default {
  updateTitle: {
    width: "s",
    title: "修改检测说明",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/fourPropertyDesign/dialog/FourDesignDialog"
      ),
  },
  stopCheck: {
    width: "s",
    title: "抽检信息",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/fourPropertyDesign/dialog/FourSpotCheckDialog"
      ),
  },
  manualDetection: {
    width: "xxl",
    title: "人工检测",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/fourPropertyDesign/dialog/FourManualDialog"
      ),
  },
  manualResult: {
    width: "xxl",
    title: "填写人工检测结果",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/fourPropertyDesign/dialog/FourManualResultDialog"
      ),
  },
  add: {
    width: "m",
    title: "四性检测",
    okText: "开始检测",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/fourPropertyDesign/dialog/FourAddDialog"
      ),
  },
  common: {
    width: "m",
    title: "四性检测",
    okText: "开始检测",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/fourPropertyDesign/dialog/FourCommonDialog"
      ),
  },
  fourCommonDetailDialog: {
    width: "xxl",
    title: "四性检测详情",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/fourPropertyDesign/dialog/FourCommonDetailDialog"
      ),
  },
};
