<template>
  <base-front-item
    class="search-result-item"
    :check-able="checkAble"
    :checked="checked"
    :item="item"
    :fields="fields"
    :keywords="markedWords"
    :disabled="disabled"
    @update:checked="$emit('update:checked', $event)"
    @choose="$emit('choose', $event)"
  >
    <template #titlePrefix>
      <colored-tag
        color="info"
        outline
        :disabled="disabled"
        class="search-result-item-prefix"
      >
        {{ levelName }}
      </colored-tag>
      <colored-tag :disabled="disabled" :color="scopeTag.color">
        {{ scopeTag.text }}
      </colored-tag>
    </template>
    <template #titleSuffix>
      <div class="search-result-item-title-suffix">
        <colored-tag
          v-if="securityTag"
          :color="securityTag.color"
          :disabled="disabled"
        >
          {{ securityTag.text }}
        </colored-tag>
        <div v-if="hasFile" class="search-result-item-attach">
          <paper-clip-outlined
            class="search-result-item-icon"
            :class="{
              'search-result-item-icon-disabled': disabled,
            }"
          />
          <div v-if="false" class="search-result-item-attach-text">
            {{ $t("actions.preview_sth", [$t("fields.e_file")]) }}
          </div>
        </div>
        <template v-if="$slots.titleSuffix">
          <slot name="titleSuffix" :arch-item="item"></slot>
        </template>
      </div>
    </template>
    <template #extraHeader>
      <div class="search-result-item-extra-header">
        <act-icon
          v-if="toggleCollection"
          :type="inCollection ? 'starFill' : 'star'"
          check-able
          size="large"
          :checked="inCollection"
          :disabled="item.collectionDisabled || disabled"
          :title="favoriteTitle"
          :async-submit="updateFavorite"
        />
        <act-icon
          v-if="toggleBorrowCart"
          :type="inBorrowCart ? 'shopping-filled' : 'shopping'"
          check-able
          size="large"
          :checked="inBorrowCart"
          :disabled="item.borrowCartDisabled || disabled"
          :title="borrowCartTitle"
          :async-submit="updateBorrowCart"
        />
        <template v-if="$slots.extraHeader">
          <slot name="extraHeader"></slot>
        </template>
      </div>
    </template>
    <template v-if="$slots.middle" #middle>
      <slot name="middle" :arch-item="item"></slot>
    </template>
  </base-front-item>
</template>

<script>
import { ARCH_LEVEL_NAMES } from "@/mods/front/conf/enum";
import { refreshUserCounts } from "@/mods/common/user/userCommonLogic";
import { PaperClipOutlined } from "@ant-design/icons-vue";
import ColoredTag from "@/components/common/display/ColoredTag";
import BaseFrontItem from "@/mods/front/components/BaseFrontItem";
import ActIcon from "@/components/common/action/ActIcon";
import { searchListScopeTag } from "@/mods/front/util/frontUtil";

export default {
  name: "SearchResultItem",
  components: { ActIcon, BaseFrontItem, ColoredTag, PaperClipOutlined },
  emits: ["choose", "showDetail", "fieldValueChange", "update:checked"],
  props: {
    item: { type: Object },
    fields: { type: [Object, Array] },
    markedWords: { type: Array },
    selected: { type: Boolean },
    checked: { type: Boolean },
    checkAble: { type: Boolean },
    disabled: { type: Boolean },
    toggleCollection: { type: Function },
    toggleBorrowCart: { type: Function },
  },
  computed: {
    levelName() {
      const level = ARCH_LEVEL_NAMES[this.item.objectType] || "document";
      return this.$t(`fields.object.${level}`);
    },
    scopeTag() {
      return searchListScopeTag(this.item);
    },
    securityTag() {
      if (this.item.isSecurity) {
        return {
          color: "error",
          text: `★${
            this.item.securityText || this.$t("front.security.confidential")
          }★`,
        };
      }
      return undefined;
    },
    hasFile() {
      return +this.item.filesNum > 0;
    },
    itemStyle() {
      return {};
    },
    inCollection() {
      return !!this.item.collectionId;
    },
    inBorrowCart() {
      return !!this.item.borrowCartId;
    },
    favoriteTitle() {
      const name = this.$t("front.navs.collection");
      return this.inCollection
        ? this.$t("actions.remove_from", [name])
        : this.$t("front.collection.add");
    },
    borrowCartTitle() {
      const name = this.$t("front.navs.borrow_cart");
      return this.inBorrowCart
        ? this.$t("actions.remove_from", [name])
        : this.$t("front.borrow_cart.add");
    },
  },
  methods: {
    updateFavorite({ checked }) {
      const { id, archId, collectionId, objectType, archTypeId } = this.item;
      return this.toggleCollection({
        id,
        archId,
        collectionId,
        objectType,
        archTypeId,
        checked,
      }).then((newId) => {
        this.$emit("fieldValueChange", { field: "collectionId", value: newId });
        refreshUserCounts();
        return Promise.resolve(!!newId);
      });
    },
    updateBorrowCart({ checked }) {
      const { id, archId, borrowCartId, objectType, archTypeId } = this.item;
      return this.toggleBorrowCart({
        id,
        archId,
        borrowCartId,
        objectType,
        archTypeId,
        checked,
      }).then((newId) => {
        this.$emit("fieldValueChange", { field: "borrowCartId", value: newId });
        refreshUserCounts();
        return Promise.resolve(!!newId);
      });
    },
  },
};
</script>

<style scoped>
.search-result-item {
  padding-left: 16px;
  border-bottom: 1px solid var(--rmx-content-split);
}
.search-result-item-prefix {
}
.search-result-item-prefix + .search-result-item-prefix {
  margin: 0 8px 0 0;
}
.search-result-item-title-suffix {
  display: flex;
  gap: 16px;
}
.search-result-item-attach {
  display: flex;
  align-items: center;
  color: var(--rmx-primary-color);
}
.search-result-item-attach-text {
  text-decoration: underline;
  margin-left: 8px;
}
.search-result-item-extra-header {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-right: 16px;
}
.search-result-item-icon-disabled {
  color: var(--rmx-icon-3);
}
</style>
