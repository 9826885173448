export default {
  editDense: {
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/warehouseManage/dialog/EditDenseDialog"
      ),
  },

  editMonitoring: {
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/warehouseManage/dialog/EditMonitoringDialog"
      ),
  },

  chooseArchiveType: {
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/warehouseManage/dialog/ChooseArchiveType"
      ),
  },

  addVirtualWarehouse: {
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/warehouseManage/dialog/AddVirtualWarehouse"
      ),
  },

  virtualWarehouse: {
    okText: "actions.save",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/warehouseManage/dialog/VirtualWarehouse"
      ),
  },

  editShelfInfo: {
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/warehouseManage/dialog/EditShelfInfo"
      ),
  },

  editWarehouse: {
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/warehouseManage/dialog/EditWarehouse"
      ),
  },
};
