<template>
  <a-layout
    theme="light"
    class="main-wrap"
    :class="{ 'main-full-header': fullHeader && !fullPage }"
  >
    <a-layout-header v-if="fullHeader" v-show="!fullPage" class="main-header">
      <back-logo-view full-header />
      <back-layout-header
        full-header
        :nav-info="currentNav"
        :pure-page="purePage"
      />
    </a-layout-header>
    <a-layout theme="dark" class="main-layout">
      <a-layout-sider
        v-show="!fullPage && !purePage"
        class="main-sided-container"
        :width="fullHeader ? '170px' : '150px'"
        collapsible
        :trigger="null"
        v-model:collapsed="collapsed"
      >
        <back-sided-nav
          :full-header="fullHeader"
          :collapsed="collapsed"
          @nav-changed="onNavChanged"
          @auth-changed="onAuthChanged"
          @loaded="onInfoLoaded"
        />
      </a-layout-sider>
      <a-layout-content class="main-container">
        <back-layout-header
          v-if="!fullHeader"
          v-show="!fullPage"
          :nav-info="currentNav"
        />
        <detail-view ref="detailView" class="main-detail-view">
          <template v-if="shouldLoadContent">
            <div v-if="!hasAuth" class="back-layout-holder">
              <error-page-view code="403" />
            </div>
            <div v-else class="back-layout" :style="mainLayoutStyle">
              <template v-if="$slots.default">
                <slot></slot>
              </template>
              <template v-else>
                <router-view></router-view>
              </template>
            </div>
          </template>
          <div v-else class="back-layout-holder">
            <loading-view />
          </div>
        </detail-view>
        <div
          v-if="!fullPage && !purePage"
          class="main-collapse-trigger"
          @click="collapsed = !collapsed"
        >
          <right-outlined v-if="collapsed" />
          <left-outlined v-else />
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { ref, watch, computed, provide } from "vue";
import { useRoute } from "vue-router";
import { useWebStorage } from "@/components/common/shared/storageInternal";
import { BACK_LAYOUT_HOLDER } from "@/components/common/shared/internal";
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";
import BackSidedNav from "@/components/back/BackSidedNav";
import DetailView from "@/components/core/DetailView";
import BackLayoutHeader from "@/components/back/BackLayoutHeader";
import BackLogoView from "@/components/back/BackLogoView";
import ErrorPageView from "@/components/common/display/ErrorPageView";
import LoadingView from "@/components/common/display/LoadingView";

const initNav = (assign = {}) => {
  return {
    contentPadding: undefined,
    title: "",
    description: "",
    showSetting: false,
    showHelp: false,
    func: null,
    ...assign,
  };
};

export default {
  name: "BaseLayout",
  components: {
    LoadingView,
    BackLogoView,
    BackLayoutHeader,
    DetailView,
    BackSidedNav,
    ErrorPageView,
    LeftOutlined,
    RightOutlined,
  },
  props: {
    navs: { type: Array, default: () => [] },
    baseUrl: { type: String, default: "/" },
    indexUrl: { type: String, default: "/" },
  },
  setup() {
    const route = useRoute();
    const detailView = ref(null);
    const currentMeta = ref({});
    const currentNav = ref(initNav());
    const collapsed = ref(false);
    const shouldLoadContent = ref(false);
    const hasAuth = ref(false);
    const fullPage = ref(false);
    const purePage = ref(false);
    const { storage: fullHeader } = useWebStorage("fullHeader", {
      initValue: true,
    });

    const mainLayoutStyle = computed(() => {
      const style = {};
      if (currentNav.value.contentPadding) {
        style["--back-content-padding"] = currentNav.value.contentPadding;
      }
      return style;
    });

    const onNavChanged = (e) => {
      if (e) {
        currentMeta.value = {
          title: e?.label || "",
          description: e?.description,
        };
      } else {
        currentMeta.value = {};
      }
    };
    const onInfoLoaded = (v) => {
      if (!v) detailView.value?.closeAll?.();
      shouldLoadContent.value = v;
    };
    const onAuthChanged = (v) => {
      hasAuth.value = v;
    };
    const handler = {
      setPadding: (v) => {
        currentNav.value.contentPadding = v || undefined;
      },
      setTitle: (v) => {
        currentNav.value.title = v;
      },
      setSubtitle: () => {},
      setActionCallback: (v) => {
        if (typeof v === "function") currentNav.value.func = v;
      },
      setShowSetting: (v) => {
        currentNav.value.showSetting = v;
      },
      setShowHelp: (v) => {
        currentNav.value.showHelp = v;
      },
      setFullPage: (v) => {
        fullPage.value = v;
      },
      setPurePage: (v) => {
        purePage.value = v;
      },
      reset: () => {
        currentNav.value = initNav({
          meta: currentMeta.value,
        });
        fullPage.value = false;
        purePage.value = false;
      },
    };

    watch(
      () => route.path,
      () => {
        detailView.value?.closeAll?.();
      }
    );
    watch(
      () => currentMeta.value,
      (meta) => {
        currentNav.value.meta = meta;
      },
      { immediate: true }
    );
    provide(BACK_LAYOUT_HOLDER, handler);
    return {
      hasAuth,
      shouldLoadContent,
      fullPage,
      purePage,
      detailView,
      currentNav,
      mainLayoutStyle,
      collapsed,
      fullHeader,
      onNavChanged,
      onInfoLoaded,
      onAuthChanged,
    };
  },
};
</script>

<style scoped>
.main-layout {
  min-width: var(--rmx-min-width);
}
.main-sided-container {
  background: var(--rmx-primary-color-8);
}
.main-full-header .main-sided-container {
  background: white;
  box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.05);
  z-index: 6;
}
.main-wrap {
  height: 100vh;
}
.main-header {
  display: flex;
  background: var(--rmx-primary-color-7);
  height: 48px;
  padding: 0 20px;
}
.main-container {
  display: flex;
  flex-direction: column;
  background-color: #f6f7fb;
  height: 100vh;
  overflow: auto;
}
.main-full-header .main-container {
  height: calc(100vh - 48px);
}
.main-collapse-trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 50%;
  margin-top: -32px;
  height: 64px;
  width: 12px;
  background: var(--rmx-primary-color-6);
  opacity: 0.5;
  border-radius: 0 12px 12px 0;
  z-index: 10;
}
.main-collapse-trigger:hover {
  background: var(--rmx-primary-color-4);
}
.main-detail-view {
  height: 100%;
}
.back-layout-holder,
.back-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  --back-content-padding: var(--rmx-pad-m);
}
.back-layout-holder {
  background: white;
  align-items: center;
  justify-content: center;
}
</style>
