// 档案列表右键操作
import { createEnum } from "@/mods/common/enum";

export const ARCH_LIST_CONTEXT_OPERATE = createEnum(
  {
    CUTIN: { CODE: "CUTIN", NAME: "插录", ACTION: "cutin" },
    APPEND: { CODE: "APPEND", NAME: "追加", ACTION: "append" },
    ADJUST_PIECE: { CODE: "ADJUST_PIECE", NAME: "调件", ACTION: "adjustPiece" },
    SAME_CLASSIFY_ORDER: {
      CODE: "SAME_CLASSIFY_ORDER",
      NAME: "同分类调件",
      ACTION: "sameClassifyOrder",
    },
    ADJUST_PIECE_VOL: {
      CODE: "ADJUST_PIECE_VOL",
      NAME: "调卷",
      ACTION: "adjustPiece",
    },
    SAME_CLASSIFY_ORDER_VOL: {
      CODE: "SAME_CLASSIFY_ORDER_VOL",
      NAME: "同分类调卷",
      ACTION: "sameClassifyOrder",
    },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);
