export default {
  externalApproveDetail: {
    title: "", //外部移交审批详情//外部接收审批详情
    component: () =>
      import("@/mods/archive/externalArchive/detailView/ExternalApproveDetail"),
  },
  externalDetail: {
    title: "", //外部移交详情
    component: () =>
      import("@/mods/archive/externalArchive/detailView/ExternalDetail"),
  },
};
