import RouterHolder from "@/components/core/RouterHolder";

export default {
  path: "project",
  component: RouterHolder,
  children: [
    {
      path: "/front/project",
      redirect: "/front/project/detail",
    },
    {
      path: "detail",
      meta: {
        title: "项目详情",
        purePage: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "" */ "@/mods/front/projectPage/ProjectDetail"
        ),
    },
  ],
};
