<template>
  <div class="video-viewer">
    <video class="video-content" :src="url" controls="controls">
      您的浏览器不支持播放视频
    </video>
  </div>
</template>

<script>
import { PROPS_ATTACH_FILE } from "@/components/common/shared/compAttrs";

export default {
  name: "VideoViewer",
  props: { ...PROPS_ATTACH_FILE },
};
</script>

<style scoped>
.video-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--rmx-attach-background);
  padding: 6px;
}
.video-content {
  flex: 1;
  height: 100%;
  max-width: 100%;
  user-select: none;
  outline: none;
}
</style>
