import { http } from "@/util/http";

export function login(username, password) {
  return http.post("user/login", { username, password });
}

/**
 * 更新登录人信息.
 *
 * @returns {Object} 当前登录人相关信息.
 */
export function httpUpdateUserInfo() {
  return http.post("common/user/roles/list", {});
}

export function userMenus() {
  return http.post("common/resource/all/tree", {}).then(({ data }) => {
    return Array.from(data.list).map((item) => {
      item.key = item.code;
      item.label = item.name;
      item.url = item.vueUrl;
      item.description = item.introduction;
      item.icon = item.ico;
      return item;
    });
  });
}
export function loadUserAuthResource() {
  return http.post("common/get/authority", {}).then(({ data }) => {
    return data.haveAuth_ids;
  });
}
// 获取当前登录人提示信息
export function httpGetPageTips(param) {
  return http.post("system/login/security/limit", param);
}

// 校验缓存的账户信息是否有效
export function httpCheckAccountInfo(param) {
  return http.post("system/user/stationrole/effective", JSON.stringify(param));
}

// 登录
export function httpLogin(param, rightCode) {
  return http({
    url: "system/login/login",
    method: "post",
    data: param,
    headers: {
      "Accept-Code": rightCode,
    },
  }).then(({ code, data, message }) => {
    if (code === 0) {
      return { data };
    }
    return Promise.reject({ code, val: message.val });
  });
}

// 获取验证码
export function httpGetCode(param) {
  return http({
    url: "system/login/captchaimage",
    method: "post",
    data: {
      rightCode: param,
    },
    withCredentials: true,
    responseType: "blob",
  });
}

// 校验产品状态
export function httpCheckRegist(param) {
  return http.post("system/login/checkregist", param);
}

// 刷新session
export function httpRefreshSession(param) {
  return http.post("system/login/session", param).then(({ data, code }) => {
    if (code === 0) {
      return data;
    }
    return Promise.reject();
  });
}

// 切换岗位
export function httpSwitchSession({ comId, roleId, positionId }) {
  return http.post("common/user/role/switch", {
    comid: comId,
    roleid: roleId,
    stationid: positionId,
  });
}

// 获取公钥
export function httpGetPublicKey() {
  return http.post("/system/login/sm2/publickey", {});
}

// 返回日志
export function httpBackLog(param) {
  return http.post("/system/login/updaterole", param).then(({ data, code }) => {
    if (code === 0) {
      return data;
    }
    return Promise.reject();
  });
}

// 获取扩展配置内容
export function httpGetExpandConf(param) {
  return http.post("/common/extsetting/load", { identify: param });
}

// 获取修改密码规则
export function httpGetEditPswRule(param = {}) {
  return http.post("/common/user/pwdrules", param);
}

// 修改密码
export function httpEditPsw(param) {
  return http.post("common/user/password/update", param);
}

// 注册
export function httpRegister(data) {
  return http.post("govtnet/registered/insert", data);
}

/**
 *切换岗位
 * @param unitId 单位Id
 * @param jobId 岗位Id
 * @param roleId 角色Id
 * @param attrId 馆室Id
 * @param status 2后台,1前台
 * @returns {Promise}
 */
export function httpSwitchUserRole({ unitId, jobId, roleId, attrId, status }) {
  const bf = status === 2 ? { back: "back" } : { front: "front" };
  return http.post("common/user/role/switch", {
    comid: unitId,
    roleid: roleId,
    stationid: jobId,
    comAttrId: attrId,
    ...bf,
  });
}

// 根据访问策略设置，判断是否直接显示验证码
export function httpCheckUseCode(param) {
  return http
    .post("system/login/captcha/info", param)
    .then(({ code, data }) => {
      if (code === 0) {
        return data;
      }
      return Promise.reject();
    });
}

export function httpLoadSsoUserInfo() {
  return http.post("system/sso/user/info", {}).then(({ code, data }) => {
    if (code === 0) {
      return data;
    }
    return Promise.reject();
  });
}

export function httpEnterChild({ comId, force } = {}) {
  return http.post("setting/fonds/enter/child", { comid: comId, force });
}

export function httpBackToOrigin() {
  return http.post("common/system/org/original");
}

export function httpLogout() {
  return http.post("system/login/logout");
}
