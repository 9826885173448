import demo from "@/mods/demo/router";
import portal from "@/router/portal";
import workbench from "@/mods/workbench/router";
import front from "@/mods/front/router";
import system from "@/mods/system/router";
import archive from "@/mods/archive/router";
import setting from "@/mods/setting/router";
import warehouse from "@/mods/warehouse/router";
import statistic from "@/mods/statistic/router";
import user from "@/mods/user/router";
import portalsso from "@/mods/portalsso/router";
import upgrade from "@/mods/upgrade/router";

export default [
  ...demo,
  ...portal,
  ...workbench,
  ...front,
  ...system,
  ...archive,
  ...setting,
  ...warehouse,
  ...statistic,
  ...user,
  ...portalsso,
  ...upgrade,
];
