<template>
  <component :is="icon"></component>
</template>
<script>
import { computed, toRefs } from "vue";
import { detectFileType } from "@/util/fileUtil";
import {
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  Html5Outlined,
  PlayCircleOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons-vue";

const ICON_MAP = {
  image: FileImageOutlined,
  audio: PlayCircleOutlined,
  video: VideoCameraOutlined,
  html: Html5Outlined,
  excel: FileExcelOutlined,
  word: FileWordOutlined,
  ppt: FilePptOutlined,
  text: FileTextOutlined,
  pdf: FilePdfOutlined,
  ofd: FileTextOutlined,
  compressed: FileZipOutlined,
};

export default {
  name: "FileIcon",
  props: {
    ext: { type: String, default: "" },
  },
  setup(props) {
    const { ext } = toRefs(props);
    const icon = computed(() => {
      const type = detectFileType(ext.value);
      return ICON_MAP[type.type] || FileOutlined;
    });
    return { icon };
  },
};
</script>

<style scoped></style>
