<template>
  <div class="scrollable-view" :style="viewStyle" @wheel.prevent="handleWheel">
    <slot
      v-if="scrollable"
      name="prefix"
      :can-decrease="canDecrease"
      :can-increase="canIncrease"
      :do-decrease="handleDecrease"
      :do-increase="handleIncrease"
    ></slot>
    <div ref="scroll" class="scrollable-view-container">
      <slot></slot>
    </div>
    <slot
      v-if="scrollable"
      name="postfix"
      :can-decrease="canDecrease"
      :can-increase="canIncrease"
      :do-decrease="handleDecrease"
      :do-increase="handleIncrease"
    ></slot>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from "vue";
import { UI_DIRECTION } from "@/conf/constants";
import { debounceFunc } from "@/util/uiUtil";

export default {
  name: "ScrollableView",
  props: {
    direction: {
      type: String,
      default: UI_DIRECTION.horizontal,
      validator: (v) => v in UI_DIRECTION,
    },
    step: { type: Number, default: 16 },
  },
  setup(props, { expose }) {
    const { direction, step } = toRefs(props);
    const scroll = ref(null);
    const scrollable = ref(false);
    const canDecrease = ref(false);
    const canIncrease = ref(false);
    const viewStyle = computed(() => {
      const style = {};
      if (direction.value !== UI_DIRECTION.horizontal) {
        style["flex-direction"] = "column";
      }
      return style;
    });
    const ro = new ResizeObserver(
      debounceFunc((list) => {
        list.forEach((entry) => {
          const target = entry.target;
          if (direction.value === UI_DIRECTION.horizontal) {
            scrollable.value = target.scrollWidth > target.clientWidth;
          } else {
            scrollable.value = target.scrollHeight > target.clientHeight;
          }
          doScroll();
        });
      }, 100)
    );
    const doScroll = (delta) => {
      const el = scroll.value;
      if (direction.value === UI_DIRECTION.horizontal) {
        if (delta !== undefined) el.scrollLeft += delta;
        canDecrease.value = el.scrollLeft > 0;
        canIncrease.value = el.scrollLeft + el.offsetWidth < el.scrollWidth;
      } else {
        if (delta !== undefined) el.scrollTop += delta;
        canDecrease.value = el.scrollTop > 0;
        canIncrease.value = el.scrollTop + el.offsetHeight < el.scrollHeight;
      }
    };
    const handleWheel = (e) => doScroll(e.deltaY || e.deltaX);
    const handleDecrease = () => doScroll(-step.value);
    const handleIncrease = () => doScroll(step.value);
    onMounted(() => {
      ro.observe(scroll.value);
      doScroll(0);
    });
    onBeforeUnmount(() => {
      ro.disconnect();
    });
    expose({});
    return {
      scroll,
      scrollable,
      canDecrease,
      canIncrease,
      viewStyle,
      handleWheel,
      handleDecrease,
      handleIncrease,
    };
  },
};
</script>

<style scoped>
.scrollable-view {
  display: flex;
}
.scrollable-view-container {
  flex: 1;
  overflow: hidden;
}
</style>
