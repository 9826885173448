export default {
  billInfo: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/usingAppraise/dialog/BillInfo"
      ),
  },
};
