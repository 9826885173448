import { Modal, message, notification } from "ant-design-vue";
import { i18n } from "@/conf/lang";

const CONFIRM_FUNC = {
  confirm: Modal.confirm,
  success: Modal.success,
  error: Modal.error,
  warning: Modal.warning,
  warn: Modal.warning,
  info: Modal.info,
};

const MSG_FUNC = {
  info: message.info,
  success: message.success,
  error: message.error,
  warning: message.warning,
  warn: message.warning,
};

const NOTIFY_FUNC = {
  info: notification.info,
  success: notification.success,
  error: notification.error,
  warning: notification.warning,
  warn: notification.warn,
};

export const NOTIFY_POSITION = {
  TOP_LEFT: "topLeft",
  TOP_RIGHT: "topRight",
  BOTTOM_LEFT: "bottomLeft",
  BOTTOM_RIGHT: "bottomRight",
};

function localeExp(keyOrContent) {
  if (typeof keyOrContent === "string") {
    return i18n.global.t(keyOrContent);
  }
  return keyOrContent;
}

export function showConfirm({
  content,
  title = i18n.global.t("hint.tips"),
  width = "480px",
  returnCancel = false,
  centered = false,
  type = "confirm",
  okCancel = true,
  okText = "actions.confirm",
  cancelText = "actions.cancel",
} = {}) {
  const func = CONFIRM_FUNC[type] || CONFIRM_FUNC.confirm;
  return new Promise((resolve) => {
    func({
      class: "rmx-dialog-confirm",
      title,
      content,
      okCancel,
      okButtonProps: {
        class: "",
        size: "large",
      },
      cancelButtonProps: {
        class: "rmx-btn-secondly",
        size: "large",
      },
      onOk() {
        resolve(true);
      },
      onCancel() {
        if (returnCancel) resolve(false);
      },
      width,
      centered,
      okText: localeExp(okText),
      cancelText: localeExp(cancelText),
    });
  });
}

export function showMessage(message, type = "success", duration) {
  const func = MSG_FUNC[type] || MSG_FUNC.success;
  func(localeExp(message), duration).then(() => {});
}

export function showNotification({
  content,
  title,
  type = "info",
  position = NOTIFY_POSITION.TOP_RIGHT,
  duration,
} = {}) {
  const func = NOTIFY_FUNC[type] || NOTIFY_FUNC.info;
  func({
    message: title,
    description: content,
    duration,
    placement: position,
  });
}

export function showLoading(text, duration = 0) {
  const key = `loading_${Date.now()}`;
  const close = message.loading({ content: text, key, duration });
  const finish = (msg, type = "success", duration) => {
    const func = MSG_FUNC[type] || MSG_FUNC.success;
    func({ content: msg, key, duration }).then(() => {});
  };
  return { close, finish };
}
