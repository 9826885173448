<template>
  <div class="empty-block">
    <img-empty-tips v-bind="$attrs">
      <template v-for="(index, name) in $slots" v-slot:[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </img-empty-tips>
  </div>
</template>

<script>
import ImgEmptyTips from "@/components/common/display/ImgEmptyTips.vue";
export default {
  name: "EmptyBlock",
  components: { ImgEmptyTips },
};
</script>

<style scoped>
.empty-block {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
