export default {
  useDetails: {
    width: "s",
    title: "配置利用目的",
    okText: "actions.save",
    component: () =>
      import("@/mods/warehouse/borrowManage/dialog/BorrowDetailsDialog"),
  },
  applicationForm: {
    width: "s",
    title: "申请单信息",
    footer: null,
    component: () =>
      import(
        "@/mods/warehouse/borrowManage/dialog/BorrowApplicationFormDialog"
      ),
  },
  operationDialog: {
    width: "s",
    component: () =>
      import("@/mods/warehouse/borrowManage/dialog/BorrowOperationDialog"),
  },
  importArchDialog: {
    width: "xl",
    component: () =>
      import("@/mods/warehouse/borrowManage/dialog/BorrowImportDialog"),
  },
  printDialog: {
    width: "m",
    title: "选择打印报表",
    component: () =>
      import("@/mods/warehouse/borrowManage/dialog/BorrowPrintDialog"),
  },
  logDialog: {
    width: "m",
    title: "查看日志",
    footer: null,
    component: () =>
      import("@/mods/warehouse/borrowManage/dialog/BorrowLogDialog"),
  },
};
