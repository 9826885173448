export default {
  borrowManageDetail: {
    component: () =>
      import("@/mods/warehouse/borrowManage/detailView/BorrowManageDetail"),
  },
  handleDetail: {
    component: () =>
      import("@/mods/warehouse/borrowManage/detailView/BorrowHandleDetail"),
  },
};
