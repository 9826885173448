import archivesManage from "@/mods/warehouse/detailView/archivesManage";
import entityManage from "@/mods/warehouse/detailView/entityManage";
import borrowManage from "@/mods/warehouse/detailView/borrowManage";
import archivesDisinfect from "@/mods/warehouse/detailView/archivesDisinfect";
import humitureManage from "@/mods/warehouse/detailView/humitureManage";

export default {
  entityManage,
  archivesManage,
  borrowManage,
  archivesDisinfect,
  humitureManage,
  detail: {
    title: "登记",
    component: () =>
      import(
        /* webpackChunkName: "warehouse_detail" */ "@/mods/warehouse/warehouseAssetFittings/WarehouseAssetFittingDetail"
      ),
  },
};
