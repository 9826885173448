import { onBeforeUnmount, ref, onMounted, watch } from "vue";
import { STORAGE_PREFIX } from "@/conf";
import { snakeCase } from "@/util/strUtil";
import { simpleDeepClone } from "@/util/dataUtil";

export function useWebStorage(key, { area = localStorage, initValue } = {}) {
  const storage = ref();
  const sKey = `${STORAGE_PREFIX}${snakeCase(key)}`;
  const iv = initValue !== undefined ? simpleDeepClone(initValue) : undefined;
  const load = () => {
    try {
      storage.value = JSON.parse(area[sKey]);
    } catch (e) {
      if (!storage.value) {
        if (iv !== undefined) {
          storage.value = simpleDeepClone(iv);
        } else {
          storage.value = {};
        }
      }
    }
  };
  load();
  const onStorage = (e) => {
    if (e.storageArea === area && e.key === sKey) {
      load();
    }
  };
  onMounted(() => {
    window.addEventListener("storage", onStorage);
  });
  onBeforeUnmount(() => {
    window.removeEventListener("storage", onStorage);
  });
  watch(
    () => storage.value,
    (v) => {
      const str = JSON.stringify(v);
      if (str !== area[sKey]) area[sKey] = str;
    },
    { deep: true }
  );
  return { storage };
}
