// 档案库
import { createEnum } from "@/mods/common/enum";

export const ARCH_STORAGE = createEnum(
  {
    COLLECT: {
      CODE: 6,
      NAME: "接收库",
      DEF_STATUS: 20,
      ROUTE: "COLLECT",
      RESCODE: "receive",
    },
    ARRANGE: {
      CODE: 2,
      NAME: "整理库",
      DEF_STATUS: 0,
      ROUTE: "ARRANGE",
      RESCODE: "arrange_home",
    },
    ARRANGE_DEPDATA: {
      CODE: 21,
      NAME: "查看部门整理",
      ROUTE: "ARRANGE",
      DEF_STATUS: 0,
      DEF_MANAGE_STATUS: 1,
      REALCODE: 2,
      RESCODE: "view_depdata",
    },
    ARRANGE_PERSONDATA: {
      CODE: 22,
      NAME: "查看个人整理",
      ROUTE: "ARRANGE",
      DEF_STATUS: 0,
      DEF_MANAGE_STATUS: 0,
      REALCODE: 2,
      RESCODE: "view_persondata",
    },
    ARRANGE_UNSAVEDATA: {
      CODE: 23,
      NAME: "查看不归档库",
      ROUTE: "ARRANGE",
      DEF_STATUS: 11,
      REALCODE: 2,
      RESCODE: "view_unsavedata",
    },
    PRESERVE: {
      CODE: 3,
      NAME: "保管库",
      DEF_STATUS: 1,
      ROUTE: "PRESERVE",
      RESCODE: "preserve_home",
    },
    UTILIZ: {
      CODE: 4,
      NAME: "利用库",
      DEF_STATUS: 1,
      ROUTE: "UTILIZ",
      RESCODE: "utiliz",
    },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);
// 档案对象
export const ARCH_OBJTYPE = createEnum(
  {
    UNBOX: { CODE: -1, NAME: "未装盒文件" },
    UNVOL: { CODE: -2, NAME: "散文件" },
    PROJECT: { CODE: 1, NAME: "项目" },
    VOL: { CODE: 2, NAME: "案卷", CHILDOBJTYPE: "INVOL" },
    BOX: { CODE: 4, NAME: "盒", CHILDOBJTYPE: "INBOX" },
    DOC: { CODE: 3, NAME: "文件（全部文件）" },
    INVOL: { CODE: 5, NAME: "卷内文件", PARENTKEY: "volid" },
    INBOX: { CODE: 7, NAME: "盒内文件", PARENTKEY: "boxid" },
    //  -----
    AllDOCWITHBOX: { CODE: 13, NAME: "盒-全部文件" },
    AllDOCWITHVOL: { CODE: 14, NAME: "卷-全部文件" },
    PROJECTDOC: { CODE: 15, NAME: "跨项目文件" },
    PROJECTVOL: { CODE: 16, NAME: "跨项目案卷" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);
// 档案状态
export const ARCH_STATUS = createEnum(
  {
    UNCOLLECTED: { TYPE: 0, NAME: "待整理" },
    COLLECTED: { TYPE: 7, NAME: "已整理" },
    SAVED: { TYPE: 1, NAME: "已归档" },
    WAIT_DESTROY: { TYPE: 4, NAME: "待销毁" },
    ALREADY_DESTROY: { TYPE: 5, NAME: "已销毁" },
    DELETED: { TYPE: 9, NAME: "已删除" },
    SUBMITTING: { TYPE: 8, NAME: "提交中" },
    TRANSFER: { TYPE: 2, NAME: "移交中" },
    APPRAISAL: { TYPE: 3, NAME: "待鉴定" },
    VOLDOCDELETED: { TYPE: 10, NAME: "卷内文件删除" },
    UNSAVED: { TYPE: 11, NAME: "不归档" },
    TOCHECK: { TYPE: 20, NAME: "待检查" },
    CHECKED: { TYPE: 24, NAME: "已检查" },
    TOARRANGE: { TYPE: 28, NAME: "需整理" },
    USING: { TYPE: 30, NAME: "可利用" },
  },
  {
    TYPE: "TYPE",
    NAME: "NAME",
  }
);
