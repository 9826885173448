import {
  computed,
  inject,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  unref,
  watchEffect,
} from "vue";
import { debounceFunc } from "@/util/uiUtil";
import { BLOCK_RECT_PROVIDER } from "@/components/common/shared/internal";

export function useDraggable(handler, target, draggable) {
  let transform = { x: 0, y: 0 };
  const position = ref("");
  const dragging = ref(false);
  const onMouseDown = (e) => {
    dragging.value = true;
    const sX = e.clientX;
    const sY = e.clientY;
    const { x, y } = transform;
    const rect = target.value.getBoundingClientRect();

    const wW = document.documentElement.clientWidth;
    const wH = document.documentElement.clientHeight;

    const minL = -rect.left + x;
    const minT = -rect.top + y;
    const maxL = wW - rect.left - rect.width + x;
    const maxT = wH - rect.top - rect.height + y;

    const onMouseMove = (ev) => {
      const mX = Math.min(Math.max(x + ev.clientX - sX, minL), maxL);
      const mY = Math.min(Math.max(y + ev.clientY - sY, minT), maxT);
      transform = { x: mX, y: mY };
      position.value = `translate(${mX}px, ${mY}px)`;
    };

    const onMouseUp = () => {
      dragging.value = false;
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const onDraggable = () => {
    if (target.value && handler.value) {
      handler.value.addEventListener("mousedown", onMouseDown);
    }
  };

  const offDraggable = () => {
    if (target.value && handler.value) {
      handler.value.removeEventListener("mousedown", onMouseDown);
    }
  };

  onMounted(() => {
    watchEffect(() => {
      if (draggable.value) {
        onDraggable();
      } else {
        offDraggable();
      }
    });
  });

  onBeforeUnmount(() => {
    offDraggable();
  });

  return { position, dragging };
}

export function useFullscreen(target, callback) {
  const fullscreen = ref(false);
  const isFullscreen = computed({
    get() {
      return fullscreen.value;
    },
    set(val) {
      fullscreen.value = !!val;
      callback?.(fullscreen.value);
    },
  });
  const onFullscreenChange = () => {
    isFullscreen.value = !!document.fullscreenElement;
  };
  const toggle = () => {
    if (isFullscreen.value) {
      document.exitFullscreen().then(() => {});
    } else {
      target.value.requestFullscreen().then(() => {});
    }
  };
  onMounted(() => {
    document.addEventListener("fullscreenchange", onFullscreenChange);
  });
  onBeforeUnmount(() => {
    document.removeEventListener("fullscreenchange", onFullscreenChange);
  });
  return { isFullscreen, toggle };
}

export function useBlockSize() {
  const block = inject(BLOCK_RECT_PROVIDER, null);
  const size = computed(() => {
    const s = block?.size?.value || {};
    return {
      height: s.height || 0,
      width: s.width || 0,
    };
  });
  return { size };
}

function getContentSize(target) {
  if (!target) return { height: 0, width: 0 };
  const p = (v) => parseFloat(v || "0");
  const s = window.getComputedStyle(target);
  return {
    height: p(s.height) - p(s.paddingTop) - p(s.paddingBottom),
    width: p(s.width) - p(s.paddingLeft) - p(s.paddingRight),
  };
}

export function useSizeObserver(target) {
  const height = ref(0);
  const width = ref(0);
  const size = computed(() => ({
    height: height.value,
    width: width.value,
  }));
  const el = computed(() => unref(target));
  const ro = new ResizeObserver(
    debounceFunc((list) => {
      list.forEach((entry) => {
        height.value = entry.contentRect.height;
        width.value = entry.contentRect.width;
      });
    }, 100)
  );
  onMounted(() => {
    if (el.value) {
      ro.observe(el.value);
      const tmp = getContentSize(el.value);
      height.value = tmp.height;
      width.value = tmp.width;
    }
  });
  onBeforeUnmount(() => {
    ro.disconnect();
  });
  return { size };
}

export function useSizeProvider(target, provider = "") {
  const { size } = useSizeObserver(target);
  provide(BLOCK_RECT_PROVIDER, { size, provider });
  return { size };
}

export function usePendingState() {
  const state = { timeout: 0 };
  const pending = ref({ start: false, mask: false });
  const clearPendingTimeout = () => {
    if (state.timeout) {
      clearTimeout(state.timeout);
      state.timeout = 0;
    }
  };
  const setPending = (v) => {
    const p = pending.value;
    if (v || v === undefined) {
      p.start = true;
      clearPendingTimeout();
      state.timeout = setTimeout(() => {
        p.mask = true;
        clearPendingTimeout();
      }, 300);
    } else {
      clearPendingTimeout();
      p.mask = false;
      p.start = false;
    }
  };
  const pendingUntil = (p = Promise.resolve()) => {
    setPending(true);
    p.finally(() => setPending(false));
  };
  return { pending, setPending, pendingUntil };
}
