<template>
  <teleport to="body" :disabled="!visible">
    <div v-if="visible" class="overlay-view">
      <div class="overlay-view-body">
        <template v-if="$slots.default">
          <slot></slot>
        </template>
        <template v-else>
          <div class="overlay-view-title">{{ title }}</div>
          <a-progress :percent="progress" />
          <div class="overlay-view-desc">{{ description }}</div>
        </template>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "OverlayView",
  props: {
    visible: { type: Boolean },
    loading: { type: Boolean },
    progress: { type: Number },
    title: { type: String },
    description: { type: String },
  },
};
</script>

<style scoped>
.overlay-view {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000073;
  z-index: 1000;
}
.overlay-view-body {
  padding: 24px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 #00000033;
  height: 140px;
  width: 425px;
}
.overlay-view-title {
  font-weight: bold;
  font-size: 16px;
  color: var(--rmx-text-1);
  line-height: 1.5;
  margin-bottom: 16px;
}
.overlay-view-desc {
  font-size: 14px;
  color: var(--rmx-text-2);
  line-height: 1.5;
  margin-top: 8px;
}
</style>
