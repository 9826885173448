export default {
  ChangeStatisitcsModel: {
    title: "编辑图表",
    okText: "actions.determine",
    cancelText: "actions.cancel",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ "@/mods/workbench/statistics/dialog/ChangeStatisitcsModel"
      ),
  },
};
