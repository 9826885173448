import BaseLayout from "@/components/core/BaseLayout";

export default [
  {
    path: "/statistic",
    name: "Statistic",
    component: BaseLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "annualreport_statistic",
        name: "AnnualReportStatistic",
        meta: {
          title: "年报统计",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/statistic/annualReportStatistic/AnnualReportStatistic"
          ),
      },
      {
        path: "roomstore_statistic",
        name: "RoomStoreStatistic",
        meta: {
          title: "库藏统计",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/statistic/roomStoreStatistic/RoomStoreStatistic"
          ),
      },
      {
        path: "archive_statistic",
        meta: {
          title: "工作统计",
        },
        component: () =>
          import(
            /* webpackChunkName: "statistic" */ "@/mods/statistic/archiveStatistic/ArchiveStatistic"
          ),
      },

      {
        path: "fonds_statistics",
        meta: {
          title: "跨全宗统计",
        },
        component: () =>
          import(
            /* webpackChunkName: "Setting" */ "@/mods/statistic/fondsStatistics/FondsStatistics"
          ),
      },
      {
        path: "using_statistic",
        meta: {
          title: "利用统计",
        },
        component: () =>
          import(
            /* webpackChunkName: "statistic" */ "@/mods/statistic/usingStatistic/UsingStatistic"
          ),
      },
    ],
  },
];
