export default {
  matchCustomFields: {
    title: "自定义扩展配置设置入库字段",
    width: "m",
    okText: "确定",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/onlineReceive/dialog/MatchCustomFields"
      ),
  },
  archiveInfo: {
    width: "l",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/onlineReceive/dialog/ArchiveInfoDialog"
      ),
  },
  docInVolInfoDialog: {
    title: "卷内文件",
    width: "l",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/onlineReceive/dialog/DocInVolInfoDialog"
      ),
  },
  manualStorageDialog: {
    title: "整理后状态设置",
    okText: "开始入库",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/onlineReceive/dialog/ManualStorageDialog"
      ),
  },
  confilctArchiveInfo: {
    title: "重复推送处理",
    okText: "同步所选信息",
    width: "m",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/onlineReceive/dialog/ConflictArchiveInfo"
      ),
  },
};
