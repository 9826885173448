export default {
  addAppraisal: {
    title: "新增鉴定批次",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/AddAppraisal"),
  },
  joinAppraisal: {
    title: "加入鉴定批次",
    destroyOnClose: true,
    width: "m",
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/JoinAppraisal"),
  },
  editAppraisalSituation: {
    title: "编辑鉴定小组成员信息",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/EditAppraisalSituation"),
  },
  addAppraisalUser: {
    title: "添加初审人员",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/AddAppraisalUser"),
  },
  addExpireDocs: {
    title: "获取到期档案",
    width: "xl",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/AddExpireDocs"),
  },
  setAppraisalUser: {
    title: "指定鉴定人员",
    width: "m",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/SetAppraisalUser"),
  },
  batchAppraisalDocs: {
    title: "批量鉴定",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/BatchAppraisalDocs"),
  },
  singleAppraisalDocs: {
    title: "档案鉴定",
    width: "xl",
    okText: "提交",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/SingleAppraisalDocs"),
  },
  appraisalResult: {
    title: "鉴定结果详情",
    width: "l",
    footer: null,
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/AppraisalResult"),
  },
  showAppraResult: {
    title: "鉴定信息",
    width: "m",
    footer: null,
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/ShowAppraResult"),
  },
  setArchiveSenwords: {
    title: "遮盖内容",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/archivalAppraisal/dialog/SetArchiveSenwords"),
  },
  disposalPlanOfAppraisal: {
    title: "处置方案审批",
    width: 1600,
    okText: "提交",
    destroyOnClose: true,
    component: () =>
      import(
        "@/mods/archive/archivalAppraisal/detailView/DisposalPlanOfAppraisal"
      ),
  },
  qualificationPlanOfAppraisal: {
    title: "鉴定计划审批",
    width: 1600,
    okText: "提交",
    destroyOnClose: true,
    component: () =>
      import(
        "@/mods/archive/archivalAppraisal/detailView/QualificationPlanOfAppraisal"
      ),
  },
};
