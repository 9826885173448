export default {
  addProblem: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/archTransfer/dialog/AddProblem"
      ),
  },
  editProblem: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/archTransfer/dialog/EditProblem"
      ),
  },
  archTransferApproval: {
    width: "xxl",
    title: "档案流转审批详情",
    okText: "提交",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/archTransfer/dialog/ArchTransferApproval"
      ),
  },
};
