import { getQueryStr, strHashCode } from "@/util/strUtil";

export function sizeDimension(exp) {
  if (!exp) return {};
  const dims = Array.isArray(exp) ? exp : exp.split(" ");
  const top = dims[0];
  const right = dims[1] || top;
  const bottom = dims[2] || top;
  const left = dims[3] || right;
  return { top, right, bottom, left };
}

export function debounceFunc(func, wait = 500, immediate = false) {
  let timeout;
  return function (...args) {
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    }, wait);
    if (callNow) func.apply(this, args);
  };
}

export function throttleFunc(func, wait) {
  let last, timeout;
  return function (...args) {
    let current = Date.now();
    if (last && current < last + wait) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        last = current;
        func.apply(this, args);
      }, wait);
    } else {
      last = current;
      func.apply(this, args);
    }
  };
}

const SITE_HASH = strHashCode(location.origin);
export function openWindowWith({
  url,
  query = {},
  name = "",
  id = "",
  modal = false,
  feature,
} = {}) {
  const q = getQueryStr(query);
  if (url?.[0] === "/") url = url.substring(1);
  const sign = q ? (url.includes("?") ? "&" : "?") : "";
  const target = !name && !id ? "_blank" : `e_doc_${SITE_HASH}_${name}_${id}`;
  const _feature = feature || (modal ? "location=no" : undefined);
  window.open(`#/${url}${sign}${q}`, target, _feature);
}

export function closeSelfWindow(onFailed = () => {}) {
  if (window.opener) {
    window.open(window.location, "_self").close();
  } else {
    onFailed();
  }
}
