import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import {
  switchRoleInfo,
  useAssignUserInfo,
} from "@/mods/common/user/userCommonLogic";
import { useRoute, useRouter } from "vue-router";
import { httpFrontConfig } from "@/api/front";

const MENUS = {
  home: { label: "front.navs.home", url: "/front/portal" },
  quick: { label: "front.navs.search.quick", url: "/front/search/quick" },
  advanced: {
    label: "front.navs.search.advanced",
    url: "/front/search/advanced",
  },
  card: { label: "front.navs.search.card", url: "/front/search/card" },
  archives: { label: "front.navs.archives", url: "/front/catalog" },
  subjects: {
    label: "front.navs.subjects",
    url: "/front/subject",
    urls: ["/front/subject/list", "/front/subject/detail"],
  },
  announcement: {
    label: "front.navs.announcement",
    url: "/front/announcement",
    urls: ["/front/announcement/list", "/front/announcement/detail"],
    countKey: "announcement",
  },
  collection: {
    label: "front.navs.collection",
    url: "/front/utilization/favorite",
    menuIcon: "star",
  },
  borrow_cart: {
    label: "front.navs.borrow_cart",
    url: "/front/utilization/borrow_cart",
    countKey: "borrowCart",
  },
  excerpt: {
    label: "front.navs.excerpt",
    url: "/front/utilization/excerpt",
    menuIcon: "edit-one",
  },
  using_and_application: {
    label: "front.navs.utilization.u_and_a",
    url: "/front/utilization/online",
    countKey: "usingApplication",
    menuIcon: "book-open",
  },
  application: {
    label: "front.navs.utilization.application",
    url: "/front/utilization/application",
    countKey: "findApplication",
    menuIcon: "doc-search-two",
  },
  pushed: {
    label: "front.navs.utilization.pushed",
    url: "/front/utilization/pushed",
    countKey: "pushed",
    menuIcon: "send-email",
  },
  todo: {
    label: "user.schedule.mine",
    action: "todo",
    countKey: "todo",
    menuIcon: "time",
  },
  message: {
    label: "user.message.mine",
    action: "message",
    countKey: "message",
    menuIcon: "message-one",
  },
};

const FRONT_NOTIFICATION_MENUS = [MENUS.todo, MENUS.message];

const FRONT_MENUS = [MENUS.home, MENUS.quick, MENUS.advanced, MENUS.card];

export function useFrontMenus() {
  const menus = computed(() => FRONT_MENUS);
  return { menus };
}

export function useFrontHeader() {
  const frontConfig = ref();
  const store = useStore();
  const route = useRoute();
  const purePage = computed(() => !!route.meta.purePage);
  const userCounts = computed(() => store.state.userCounts);
  const user = computed(() => store.state.user);
  const utilizationMenus = computed(() => {
    const excerpt = frontConfig.value?.permissions?.excerpt
      ? [MENUS.excerpt]
      : [];
    return [
      ...excerpt
    ];
  });
  const notificationMenus = computed(() => FRONT_NOTIFICATION_MENUS);
  useAssignUserInfo();
  onMounted(() => {
    httpFrontConfig().then((res) => {
      frontConfig.value = res;
    });
  });
  return { user, userCounts, utilizationMenus, notificationMenus, purePage, frontConfig };
}

export function useFrontUnit() {
  const router = useRouter();
  const store = useStore();
  const current = computed(() => store.state?.provisionalInfo?.unitId || "");
  const allOptions = computed(
    () => store.state?.provisionalInfo?.companies || []
  );
  const options = computed(() => {
    const mine = store.state?.provisionalInfo.companyAttrId;
    return {
      mine: allOptions.value.filter((x) => x.value === mine),
      accessible: allOptions.value.filter((x) => x.value !== mine),
    };
  });
  const currentOptions = computed(() => {
    return allOptions.value.find((x) => x.value === current.value);
  });
  const onUnitChange = (e) => {
    switchRoleInfo({ attrId: e, status: 1 }).then(() => {
      router.push("/front").then(() => {});
    });
  };
  return { current, allOptions, options, currentOptions, onUnitChange };
}

export function useFrontLogo() {
  const store = useStore();
  const currentOptions = computed(() => {
    const all = store.state?.provisionalInfo?.companies || [];
    const cur = store.state?.provisionalInfo?.unitId || "";
    return all.find((x) => x.value === cur);
  });
  const logoText = computed(() => currentOptions.value?.text);
  const logoIcon = computed(() => {
    if (currentOptions.value?.type === 2) {
      return "city-one";
    }
    return "museum-one";
  });
  return { logoText, logoIcon };
}
