import { checkUserAuth } from "@/mods/common/user/userCommonLogic";
import { BACK_ROLE_TYPE } from "@/mods/archive/archiveTreasury/enum/index";
export const HIDDEN_RULE = {
  NO_FILE_CIRCULATION: {
    CODE: "NO_FILE_CIRCULATION",
    DESC: "无'数据管理-档案流转'权限隐藏",
    VALIDATE: () => !checkUserAuth("fileCirculation"),
  },
  NO_TRANSFER: {
    CODE: "NO_TRANSFER",
    DESC: "无'档案整理-对内移交'权限隐藏",
    VALIDATE: () => !checkUserAuth("transfer"),
  },
  NO_VIEW_UNSAVEDATA: {
    CODE: "NO_VIEW_UNSAVEDATA",
    DESC: "无'档案整理-整理库-查看不归档库'权限隐藏",
    VALIDATE: () => !checkUserAuth("view_unsavedata"),
  },
  NO_APPRAISAL_RETENTIONPERIOD: {
    CODE: "NO_APPRAISAL_RETENTIONPERIOD",
    DESC: "无'档案保管-保管期限鉴定'权限隐藏",
    VALIDATE: () => !checkUserAuth("appraisal_retentionperiod"),
  },
  NO_APPRAISAL_SECURITY: {
    CODE: "NO_APPRAISAL_SECURITY",
    DESC: "无'档案保管-密级鉴定'权限隐藏",
    VALIDATE: () => !checkUserAuth("appraisal_security"),
  },
  NO_APPRAISAL_QXJB: {
    CODE: "NO_APPRAISAL_QXJB",
    DESC: "无'档案保管-开放鉴定'权限隐藏",
    VALIDATE: () => !checkUserAuth("appraisal_qxjb"),
  },
  COMPANY: {
    CODE: "COMPANY",
    DESC: "公司级角色隐藏",
    VALIDATE: ({ roleType }) => roleType === BACK_ROLE_TYPE.COMPANY.CODE,
  },
  DEPARTMENT: {
    CODE: "DEPARTMENT",
    DESC: "部门级角色隐藏",
    VALIDATE: ({ roleType }) => roleType === BACK_ROLE_TYPE.DEPARTMENT.CODE,
  },
  PERSON: {
    CODE: "PERSON",
    DESC: "个人级角色隐藏",
    VALIDATE: ({ roleType }) => roleType === BACK_ROLE_TYPE.PERSON.CODE,
  },
  NOT_GUAN: {
    CODE: "NOT_GUAN",
    DESC: "非馆单位隐藏",
    VALIDATE: ({ isGuan }) => !isGuan,
  },
  NOT_SHI: {
    CODE: "NOT_SHI",
    DESC: "非室单位隐藏",
    VALIDATE: ({ isShi }) => !isShi,
  },
  NOT_PROJECT: {
    CODE: "NOT_PROJECT",
    DESC: "非项目隐藏",
    VALIDATE: ({ isProject }) => !isProject,
  },
  IS_PROJECT: {
    CODE: "IS_PROJECT",
    DESC: "项目内隐藏",
    VALIDATE: ({ isProject }) => isProject,
  },
  IS_SYNC_TO_USE: {
    CODE: "IS_SYNC_TO_USE",
    DESC: "根据扩展配置是否同步到利用库",
    VALIDATE: ({ isSyncToUse }) => !isSyncToUse,
  },
};
