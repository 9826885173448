<template>
  <div
    ref="viewAttach"
    class="view-attachlist-wrap"
    :class="{ 'in-dialog': isInDialog, 'full-screen': fullScreen }"
  >
    <div class="view-header" v-if="!hideHeader">
      <div class="view-header-left">
        <slot name="viewTitle">
          <div class="file-order" v-if="fileListNum > 1">
            [{{ currIndex + 1 }}/{{ fileListNum }}]
          </div>
          <div class="file-name" :title="currentFile?.title">
            {{ currentFile?.title }}
          </div>
          <div class="file-size">{{ currentFile?.fileLength }}</div>
        </slot>
      </div>
      <div class="view-header-right">
        <act-button
          v-if="currentFile?.actionShow?.copy && !fullScreen"
          :disabled="!currentFile?.actionUse?.copy"
          @click="onCopy"
        >
          复制
        </act-button>
        <act-button
          v-if="currentFile?.actionShow?.edit && !fullScreen"
          :disabled="!currentFile?.actionUse?.edit"
          @click="onEdit"
        >
          摘录
        </act-button>
        <act-button
          v-if="currentFile?.actionShow?.print && !fullScreen"
          :disabled="!currentFile?.actionUse?.print"
          @click="onPrint"
        >
          打印
        </act-button>
        <act-button
          v-if="currentFile?.actionShow?.download && !fullScreen"
          :disabled="!currentFile?.actionUse?.download"
          @click="onDownLoad"
        >
          下载
        </act-button>
        <span class="full-screen-btn" @click="onFullScreen">
          <fullscreen-exit-outlined title="退出全屏" v-if="fullScreen" />
          <fullscreen-outlined title="全屏" v-else />
        </span>
      </div>
    </div>
    <div class="view-content">
      <template v-if="currentFile?.isCanView">
        <file-viewer
          :file="currentFile"
          ref="fileViewer"
          @esc="onEsc"
          @forward="onForward"
          @backward="onBackward"
        />
        <div
          class="go-left"
          :class="cssGoLeft"
          v-if="isViewList"
          @click="onForward"
        >
          <left-circle-outlined />
        </div>
        <div
          class="go-right"
          :class="cssGoRight"
          v-if="isViewList"
          @click="onBackward"
        >
          <right-circle-outlined />
        </div>
      </template>
      <template v-else>
        <div class="have-no-auth">
          <slot name="haveNoAuth">您无权浏览此文件</slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ActButton from "@/components/common/action/ActButton.vue";
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from "@ant-design/icons-vue";
import FileViewer from "@/mods/common/normal/FileViewer";

export default {
  name: "ViewAttachList",
  components: {
    FileViewer,
    ActButton,
    LeftCircleOutlined,
    RightCircleOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
  },
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    firstIndex: {
      type: Number,
      default: 0,
    },
    isInDialog: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
    },
  },
  emits: ["viewChange", "viewEdit", "viewPrint", "viewDownLoad", "viewCopy"],
  data() {
    return {
      currIndex: this.firstIndex,
      fullScreen: false,
    };
  },
  computed: {
    isViewList() {
      return this.fileList.length > 1;
    },
    currentFile() {
      return this.fileList[this.currIndex];
    },
    fileListNum() {
      return this.fileList.length;
    },
    cssGoLeft() {
      return {
        "can-use": this.fileListNum > 0 && this.currIndex > 0,
        "not-use": this.fileListNum > 0 && this.currIndex === 0,
      };
    },
    cssGoRight() {
      return {
        "can-use":
          this.fileListNum > 0 && this.currIndex < this.fileListNum - 1,
        "not-use":
          this.fileListNum > 0 && this.currIndex === this.fileListNum - 1,
      };
    },
  },
  mounted() {
    window.addEventListener("keyup", this.keyupHand);
    this.$refs.viewAttach.addEventListener(
      "fullscreenchange",
      this.fullscreenChangeHand
    );
  },
  beforeUnmount() {
    window.removeEventListener("keyup", this.keyupHand);
    this.$refs.viewAttach.removeEventListener(
      "fullscreenchange",
      this.fullscreenChangeHand
    );
  },
  methods: {
    onDialogShowed({ setFinished, setVisible }) {
      this.setFinished = setFinished;
      this.setVisible = setVisible;
    },
    keyupHand({ keyCode }) {
      keyCode === 37 && this.onForward();
      keyCode === 39 && this.onBackward();
    },
    fullscreenChangeHand() {
      this.fullScreen = !!document.fullscreenElement;
    },
    // 全屏
    onFullScreen() {
      this.fullScreen
        ? document.exitFullscreen().then(() => {})
        : this.$refs.viewAttach.requestFullscreen().then(() => {});
    },
    // 复制
    onCopy() {
      const p = this.$refs.fileViewer.getSelection();
      if (p) {
        p.then((selection) => {
          this.$emit("viewCopy", {
            file: this.currentFile,
            selection,
          });
        });
      }
    },
    // 摘录
    onEdit() {
      const p = this.$refs.fileViewer.getSelection();
      if (p) {
        p.then((selection) => {
          this.$emit("viewEdit", {
            file: this.currentFile,
            selection,
          });
        });
      }
    },
    // 打印
    onPrint() {
      this.$refs.fileViewer.print();
      this.$emit("viewPrint", this.currentFile);
    },
    // 下载
    onDownLoad() {
      this.$refs.fileViewer.download();
      this.$emit("viewDownLoad", this.currentFile);
    },
    // esc
    onEsc() {
      this.setVisible(false);
      this.setFinished();
    },
    // 上一个
    onForward() {
      this.onCurIndexChange("forward");
    },
    // 下一个
    onBackward() {
      this.onCurIndexChange("backward");
    },
    // 切换当前附件
    onCurIndexChange(direction) {
      direction === "forward" && this.currIndex > 0 && this.currIndex--;
      direction === "backward" &&
        this.currIndex < this.fileListNum - 1 &&
        this.currIndex++;
      this.$emit("viewChange", this.currentFile);
    },
  },
};
</script>

<style scoped>
.view-attachlist-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.view-attachlist-wrap.in-dialog {
  height: 696px;
  padding: 24px;
}
.full-screen {
  background: #343843;
  padding: 24px;
}
.view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 24px;
  padding-bottom: 16px;
  font-size: 16px;
}
.full-screen .view-header {
  color: #ffffff;
}
.view-header-left {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  padding-right: 50px;
}
.view-header-right {
  display: flex;
  gap: 10px;
  align-items: center;
}
.full-screen-btn {
  cursor: pointer;
}
.view-content {
  flex: 1;
  position: relative;
  background: var(--rmx-background);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--rmx-text-2);
  overflow: hidden;
}
.have-no-auth {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--rmx-text-2);
}
.go-left,
.go-right {
  position: absolute;
  top: calc(50% - 20px);
  z-index: 10;
  font-size: 28px;
  cursor: pointer;
  color: var(--rmx-icon-2);
}
.go-left:hover,
.go-right:hover {
  color: var(--rmx-icon-1);
}
.go-left {
  left: 24px;
}
.go-right {
  right: 24px;
}
.not-use {
  color: var(--rmx-icon-3);
  pointer-events: none;
}
.file-order,
.file-name,
.file-size {
  padding-right: 10px;
  flex-shrink: 0;
}
.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
}
</style>
