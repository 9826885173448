import archDestructionManage from "@/mods/archive/dialog/archDestructionManage";
import participationAppraisal from "@/mods/archive/dialog/participationAppraisal";
import archivalAppraisal from "@/mods/archive/dialog/archivalAppraisal";
import dataApplication from "@/mods/archive/dialog/dataApplication";
import dataReview from "@/mods/archive/dialog/dataReview";
import archTransfer from "@/mods/archive/dialog/archTransfer";
import fondsInnerTransfer from "@/mods/archive/dialog/fondsInnerTransfer";

import onlineReceive from "@/mods/archive/dialog/onlineReceive";
import externalArchive from "@/mods/archive/dialog/externalArchive";
import migrationArchiveData from "@/mods/archive/dialog/migrationArchiveData";
import usingAppraise from "@/mods/archive/dialog/usingAppraise";
import archList from "@/mods/archive/dialog/archList";
import receiveOfflineArchive from "@/mods/archive/dialog/receiveOfflineArchive";
import outApply from "@/mods/archive/dialog/outApply";
import researchManageArchive from "@/mods/archive/dialog/researchManageArchive";
import quickCheck from "@/mods/archive/dialog/quickCheck";
import topicLibrary from "@/mods/archive/dialog/topicLibrary";
import projectManage from "@/mods/archive/dialog/projectManage";
import collectArchive from "@/mods/archive/dialog/collectArchive";
export default {
  archDestructionManage,
  participationAppraisal,
  archivalAppraisal,
  onlineReceive,
  ...dataApplication,
  ...dataReview,
  archTransfer,
  fondsInnerTransfer,
  externalArchive,
  migrationArchiveData,
  usingAppraise,
  archList,
  quickCheck,
  receiveOfflineArchive,
  outApply,
  researchManageArchive,
  topicLibrary,
  projectManage,
  collectArchive,
};
