<template>
  <div class="list-search-bar">
    <popover-view v-if="isShrink" placement="bottomRight" trigger="click">
      <template #content>
        <a-input-search
          allow-clear
          :loading="loading"
          :placeholder="placeholder || $t('hint.keyword')"
          style="width: 350px"
          v-model:value="innerValue"
          @search="handleSearch"
          @blur="handleBlur"
        >
        </a-input-search>
      </template>
      <slot></slot>
    </popover-view>
    <a-input-search
      v-else
      allow-clear
      :loading="loading"
      :placeholder="placeholder || $t('hint.keyword')"
      :style="inputStyle"
      v-model:value="innerValue"
      @search="handleSearch"
      @blur="handleBlur"
    >
      <template v-if="$slots.searchPrefix" #addonBefore>
        <slot name="searchPrefix"></slot>
      </template>
    </a-input-search>
  </div>
</template>

<script>
import PopoverView from "@/components/common/action/PopoverView";
export default {
  name: "ListSearchBar",
  components: { PopoverView },
  props: {
    loading: { type: Boolean },
    placeholder: { type: String },
    value: { type: String },
    searchOnBlur: { type: Boolean },
    isShrink: { type: Boolean },
  },
  emits: ["search", "update:value"],
  data() {
    return {
      innerValue: "",
    };
  },
  computed: {
    inputStyle() {
      return { width: "280px" };
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val !== undefined) this.innerValue = val;
      },
      immediate: true,
    },
  },
  methods: {
    handleSearch() {
      this.$emit("update:value", this.innerValue);
      this.$emit("search", this.innerValue);
    },
    handleBlur() {
      if (this.searchOnBlur && this.innerValue !== this.value) {
        this.handleSearch();
      }
    },
  },
};
</script>

<style scoped></style>
