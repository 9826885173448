import access from "@/mods/warehouse/dialog/access";
import borrow from "@/mods/warehouse/dialog/borrow";
import entityRegister from "@/mods/warehouse/dialog/entityRegister";
import disinfect from "@/mods/warehouse/dialog/disinfect";
import virtualWareHouse from "@/mods/warehouse/dialog/virtualWareHouse";
import humitureManage from "@/mods/warehouse/dialog/humitureManage";

export default {
  access,
  borrow,
  entityRegister,
  disinfect,
  virtualWareHouse,
  humitureManage,
  addNewIn: {
    width: "s",
    okText: "actions.save",
    cancelText: "actions.cancel",
    component: () =>
      import(
        /* webpackChunkName: "warehouse_dialog" */ "@/mods/warehouse/warehouseAssetFittings/dialog/WarehouseInNewDialog"
      ),
  },
  warehouseList: {
    width: "xl",
    okText: "actions.save",
    cancelText: "actions.cancel",
    component: () =>
      import(
        /* webpackChunkName: "warehouse_dialog" */ "@/mods/warehouse/warehouseAssetFittings/dialog/WarehouseListDialog"
      ),
  },
};
