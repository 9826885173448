<template>
  <a-dropdown overlay-class-name="rmx-nav-menus">
    <template #overlay>
      <menu-view
        :menus="menus"
        :value="value"
        use-locale
        @click="$emit('click', $event)"
      >
        <template #title="{ title, menu }">
          <div class="front-pop-menu-item">
            <div class="front-pop-menu-item-title">
              {{ title }}
            </div>
            <count-tag
              v-if="menu?.countKey"
              :count="userCounts?.[menu?.countKey]"
            />
          </div>
        </template>
        <template #icon="{ menu }">
          <common-icon
            v-if="menu.menuIcon"
            :icon="menu.menuIcon"
            class="front-pop-menu-icon"
          />
        </template>
      </menu-view>
    </template>
    <div class="front-pop-menu-holder">
      <slot></slot>
    </div>
  </a-dropdown>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import MenuView from "@/components/common/action/MenuView";
import CommonIcon from "@/components/icon/CommonIcon";
import CountTag from "@/components/common/display/CountTag";

export default {
  name: "FrontPopMenu",
  emits: ["click"],
  components: { CommonIcon, MenuView, CountTag },
  props: {
    menus: { type: Array },
    value: { type: [String, Number] },
  },
  setup() {
    const store = useStore();
    const userCounts = computed(() => store.state.userCounts || {});
    return { userCounts };
  },
};
</script>

<style scoped>
.front-pop-menu-holder {
  display: flex;
  position: relative;
}
.front-pop-menu-holder:hover {
  color: var(--rmx-primary-color);
}
.front-pop-menu-holder:before {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  background: transparent;
  top: 0;
  width: 100%;
  transition: background 0.3s;
}
.front-pop-menu-holder:hover:before {
  background: var(--rmx-primary-color);
}
.front-pop-menu-icon {
  font-size: 20px;
  color: var(--rmx-icon-1);
}
.front-pop-menu-item {
  display: flex;
  align-items: center;
}
.front-pop-menu-item-title {
  flex: 1;
}
</style>
