import { createEnum } from "@/mods/common/enum/index";

export const ACTION_TYPE = createEnum(
  {
    FAIL: { TYPE: 0, NAME: "不通过并填写意见" },
    PASS: { TYPE: 1, NAME: "通过并填写意见" },
    OTHER: { TYPE: 2, NAME: "其他操作" },
    ASSIST: { TYPE: 3, NAME: "完成协助" },
  },
  {
    TYPE: "TYPE",
    NAME: "NAME",
  }
);

export const ACTION_STATE = createEnum(
  {
    REFORMIST_PROCESS_INSTANCE: {
      CODE: "sg_change",
      NAME: "改派他人审批",
      NEED_PERSON: true,
    },
    ASSIST_PROCESS_INSTANCE: {
      CODE: "sg_transmit_tache",
      NAME: "邀请协助审批",
      NEED_PERSON: true,
    },
    RETURN_PROCESS_INSTANCE: { CODE: "sg_sendback", NAME: "退回上一步审批人" },
    RETURN_TO_APPLICANT: { CODE: "sg_backend", NAME: "退回撰写人" },
    DELETE_PROCESS_INSTANCE: { CODE: "sg_cancel", NAME: "作废" },
    AGREE_AND_FINISH: { CODE: "sg_finish", NAME: "同意并完成" },
    AGREE_AND_NEXT: {
      CODE: "sg_next",
      NAME: "审批通过下一步",
      NEED_PERSON: true,
    },
    FINISH_AND_ASSIST: { CODE: "sg_transback_tache", NAME: "完成协助" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);

//审批结果类型
export const WNODE_PROCESS_INSTANCE_LOG_STATUS = createEnum(
  {
    START_PROCESS_INSTANCE: { TYPE: 1, NAME: "提交审批" },
    NEXT_PROCESS_INSTANCE: { TYPE: 2, NAME: "通过" },
    AGREE_AND_FINISH: { TYPE: 6, NAME: "办结" },
    RETURN_PROCESS_INSTANCE: { TYPE: 3, NAME: "退回" },
    RETURN_TO_APPLICANT: { TYPE: 4, NAME: "退回撰写人" },
    REFORMIST_PROCESS_INSTANCE: { TYPE: 7, NAME: "改派" },
    DELETE_PROCESS_INSTANCE: { TYPE: 5, NAME: "作废" },
    ASSIST_PROCESS_INSTANCE: { TYPE: 8, NAME: "协助" },
    FINISH_AND_ASSIST: { TYPE: 9, NAME: "完成协助" },
    FINISH_AND_SIGN: { TYPE: 10, NAME: "完成会签" },
    BRANCH_APPROVAL: { TYPE: 11, NAME: "通过" }, //分支审批人审批
    LAST_BRANCH_APPROVAL: { TYPE: 12, NAME: "通过" }, //最后一个分支审批人审批
    LAST_BRANCH_APPROVAL_DELETE_PROCESS_INSTANCE: {
      //最后一个分支审批人审批且操作为作废,暂未记录在数据库中，只用在流程日志接口判断逻辑
      TYPE: 13,
      NAME: "作废",
    },
    LAST_BRANCH_APPROVAL_RETURN_PROCESS_INSTANCE: {
      //最后一个分支审批人审批且操作为退回上一环节审批人,暂未记录在数据库中，只用在流程日志接口判断逻辑
      TYPE: 14,
      NAME: "退回",
    },
    LAST_BRANCH_APPROVAL_FINISH: { TYPE: 15, NAME: "办结" }, //最后一个分支审批人审批并且流程完成，办结
  },
  {
    TYPE: "TYPE",
    NAME: "NAME",
  }
);

// 用户角色类型
export const BACK_ROLE_TYPE = createEnum(
  {
    COMPANY: { CODE: 6, NAME: "公司级" },
    DEPARTMENT: { CODE: 5, NAME: "部门级" },
    PERSON: { CODE: 4, NAME: "员工级" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);
