<template>
  <base-layout v-if="isLogin">
    <error-page-view code="404" />
  </base-layout>
  <error-page-view v-else code="404" />
</template>

<script>
import { mapState } from "vuex";
import BaseLayout from "@/components/core/BaseLayout";
import ErrorPageView from "@/components/common/display/ErrorPageView";
export default {
  name: "BackNotFound",
  components: { ErrorPageView, BaseLayout },
  computed: {
    ...mapState(["accessToken", "loginExpire"]),
    isLogin() {
      return this.accessToken;
    },
  },
};
</script>

<style scoped></style>
