export default {
  renewOrDestroyArchDialog: {
    width: "s",
    okText: "actions.save",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/archDestructionManage/dialog/RenewOrDestroyArchDialog"
      ),
  },
  destructionTaskApprovalDialog: {
    width: "1500px",
    title: "销毁任务审批详情",
    okText: "提交",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/archDestructionManage/dialog/DestructionTaskApprovalDialog.vue"
      ),
  },
};
