export default {
  applicationDialog: {
    width: "s",
    title: "数据申请",
    component: () =>
      import("@/mods/archive/dataApplication/dialog/DataApplicationDialog"),
  },
  taskDialog: {
    width: "m",
    title: "关联任务",
    component: () =>
      import("@/mods/archive/dataApplication/dialog/DataTaskDialog"),
  },
};
