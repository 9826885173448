export default {
  researchManageArchiveAddTask: {
    width: "s",
    title: "添加专题",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ResearchManageArchiveAddTask.vue"
      ),
  },
  taskApproval: {
    width: "1600px",
    title: "编研管理任务审核详情",
    okText: "提交",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/TaskApproval.vue"
      ),
  },
  invalidTask: {
    width: "s",
    title: "作废原因",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/InvalidTask.vue"
      ),
  },
  editAuthType: {
    width: "s",
    title: "权限类型",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/EditAuthType.vue"
      ),
  },
  researchOutline: {
    width: "m",
    footer: null,
    title: "编研大纲",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ResearchOutline.vue"
      ),
  },
  researchNode: {
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ResearchNode.vue"
      ),
  },
  resultPreview: {
    width: "l",
    footer: null,
    title: "成果预览",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ResultPreview.vue"
      ),
  },
  archiveApprovalDialog: {
    width: "xxl",
    title: "参考档案申请审批",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ArchiveApprovalDialog.vue"
      ),
  },
  approvalArchiveDetails: {
    width: "xl",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ApprovalArchiveDetails.vue"
      ),
  },
  importFile: {
    width: "s",
    title: "导入文件",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ImportFile"
      ),
  },
  coverImage: {
    width: "s",
    title: "封面图片",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/CoverImage"
      ),
  },
  resultApproval: {
    width: "xxl",
    title: "档案编研成果审批",
    okText: "actions.submit",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ResultApproval"
      ),
  },
  autoImputationSetting: {
    width: "l",
    title: "自动归集设置",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/AutoImputationSetting.vue"
      ),
  },
  batchAuthorization: {
    width: "l",
    title: "档案授权",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/BatchAuthorization.vue"
      ),
  },
  archiveAuthorization: {
    width: "l",
    title: "已授权档案",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ArchiveAuthorization.vue"
      ),
  },
  dataApply: {
    width: "s",
    title: "数据申请",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/DataApply.vue"
      ),
  },
  addAuthUser: {
    width: "s",
    title: "添加授权用户",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/AddAuthUser.vue"
      ),
  },
  returnResearchNode: {
    width: "s",
    title: "退回原因",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ReturnResearchNode.vue"
      ),
  },
  taskAssign: {
    width: "s",
    title: "任务分配",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/TaskAssign.vue"
      ),
  },
  allFieldInfo: {
    width: "l",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/components/dialog/AllFieldInfo.vue"
      ),
  },
  fileFormat: {
    width: "s",
    okText: "立即下载",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/components/dialog/FileFormat.vue"
      ),
  },
  editDescription: {
    width: "s",
    title: "修改说明",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/EditDescription.vue"
      ),
  },
  publishTask: {
    width: "s",
    title: "发布至专题库",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/PublishTask.vue"
      ),
  },
  changeResearchNodePosition: {
    width: "s",
    title: "变更上级",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/researchManageArchive/dialog/ChangeResearchNodePosition.vue"
      ),
  },
};
