<template>
  <overflow-view class="front-nav-menus" item-key="url" :items="menus" enabled>
    <template #item="{ item }">
      <a-dropdown v-if="item.children" overlay-class-name="rmx-nav-menus">
        <template #overlay>
          <menu-view
            :menus="item.children"
            use-locale
            :value="curMenu?.url"
            @click="onMenuClick"
          >
            <template #title="{ title, menu }">
              <div class="front-nav-menu-item">
                <div class="front-nav-menu-item-title">
                  {{ title }}
                </div>
                <count-tag :count="userCounts?.[menu?.countKey]" />
              </div>
            </template>
          </menu-view>
        </template>
        <div
          class="front-nav-menu"
          :class="{ 'front-nav-menu-active': curNav?.url === item.url }"
        >
          {{ item.url === curNav?.url ? $t(curMenu.label) : $t(item.label) }}
          <down-outlined class="front-nav-menu-down" />
        </div>
      </a-dropdown>
      <div
        v-else
        class="front-nav-menu"
        :class="{ 'front-nav-menu-active': curNav?.url === item.url }"
        @click="onNavClick(item)"
      >
        <span>{{ $t(item.label) }}</span>
        <count-tag :count="userCounts?.[item?.countKey]" dot />
      </div>
    </template>
    <template #overflow="{ items }">
      <a-dropdown overlay-class-name="rmx-nav-menus">
        <template #overlay>
          <menu-view
            :menus="items"
            use-locale
            :value="curMenu?.url"
            @click="onMenuClick"
          />
        </template>
        <div class="front-nav-menu front-nav-menu-overflow">
          <ellipsis-icon />
        </div>
      </a-dropdown>
    </template>
  </overflow-view>
</template>

<script>
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useFrontMenus } from "@/components/front/frontNav";
import { DownOutlined } from "@ant-design/icons-vue";
import OverflowView from "@/components/common/display/OverflowView";
import MenuView from "@/components/common/action/MenuView";
import EllipsisIcon from "@/components/icon/EllipsisIcon";
import CountTag from "@/components/common/display/CountTag";
export default {
  name: "FrontNavMenus",
  components: { CountTag, EllipsisIcon, MenuView, OverflowView, DownOutlined },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { menus } = useFrontMenus();
    const flatMenus = computed(() =>
      menus.value.flatMap((x) => {
        if (Array.isArray(x.children)) return [x, ...x.children];
        return x;
      })
    );
    const checkActive = (item, checkChild) => {
      const path = route.path;
      if (path === item.url) {
        return true;
      } else if (Array.isArray(item.urls) && item.urls.includes(path)) {
        return true;
      } else if (checkChild && Array.isArray(item.children)) {
        for (const child of item.children) {
          const r = checkActive(child, true);
          if (r) return true;
        }
      }
      return false;
    };
    const curMenu = computed(() => {
      const menu = flatMenus.value.find((x) => checkActive(x));
      if (menu) return menu;
      return undefined;
    });
    const curNav = computed(() => {
      if (curMenu.value) return menus.value.find((x) => checkActive(x, true));
      return undefined;
    });
    const userCounts = computed(() => store.state.userCounts || {});
    const onMenuClick = ({ action }) => router.push(action);
    const onNavClick = (item) => router.push(item.url);
    return { menus, curMenu, curNav, userCounts, onMenuClick, onNavClick };
  },
};
</script>

<style scoped>
.front-nav-menus {
  height: 64px;
}
.front-nav-menu {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  color: var(--rmx-text-1);
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  white-space: nowrap;
  transition: color 0.3s;
}
.front-nav-menu .front-nav-menu-text {
  color: var(--rmx-text-1);
  font-size: 16px;
  line-height: 1;
}
.front-nav-menu-down {
  margin: 0 0 0 4px;
  font-size: 16px;
}
.front-nav-menu + .front-nav-menu {
  margin-left: 32px;
}
.front-nav-menu-overflow {
  margin-left: 32px;
  font-size: 14px;
}
.front-nav-menu.front-nav-menu-active,
.front-nav-menu:hover {
  color: var(--rmx-primary-color);
}
.front-nav-menu:before {
  content: "";
  display: block;
  position: absolute;
  height: 2px;
  background: transparent;
  top: 0;
  width: 100%;
  transition: background 0.3s;
}
.front-nav-menu.front-nav-menu-active:before,
.front-nav-menu:hover:before {
  background: var(--rmx-primary-color);
}
.front-nav-menu-item {
  display: flex;
  align-items: center;
}
.front-nav-menu-item-title {
  flex: 1;
}
</style>
