import BaseLayout from "@/components/core/BaseLayout";

export default [
  {
    path: "/system",
    name: "System",
    component: BaseLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "information_manage",
        meta: {
          title: "信息发布",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/informationManage/InformationManage"
          ),
      },
      {
        path: "eledoc_manage",
        meta: {
          title: "原文管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/eleDocManage/EleDocManage"
          ),
      },
      {
        path: "log_manage",
        meta: {
          title: "日志管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/logManage/LogManage"
          ),
      },
      {
        path: "index_manage",
        meta: {
          title: "索引管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/indexManage/IndexManage"
          ),
      },
      {
        path: "org_manage",
        meta: {
          title: "单位管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/orgManage/OrgManage"
          ),
      },
      {
        path: "dept_manage",
        meta: {
          title: "组织管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/deptManage/DeptManage"
          ),
      },
      {
        path: "code_define",
        meta: {
          title: "代码定义",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/codeDefine/CodeDefine"
          ),
      },
      {
        path: "security_manage",
        meta: {
          title: "访问策略",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/securityManage/SecurityManage"
          ),
      },
      {
        path: "role_manage",
        meta: {
          title: "角色管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/roleManage/RoleManage"
          ),
      },
      {
        path: "personalarea_manage",
        meta: {
          title: "个人文件区",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/personalFileArea/PersonalFileArea"
          ),
      },
      {
        path: "online_manage",
        meta: {
          title: "在线用户",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/onlineManage/OnlineManage"
          ),
      },
      {
        path: "register_manage",
        meta: {
          title: "注册管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/registerManage/RegisterManage"
          ),
      },
      {
        path: "language_manage",
        meta: {
          title: "国际化",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/languageManage/LanguageManage"
          ),
      },
      {
        path: "resource_manage",
        meta: {
          title: "资源管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/resourceManage/ResourceManage"
          ),
      },
      {
        path: "exconfig_manage",
        meta: {
          title: "扩展配置",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/exConfig/ExConfig"
          ),
      },
      {
        path: "interface_manage",
        meta: {
          title: "接口管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/interfaceManage/InterfaceManage"
          ),
      },
      {
        path: "thesaurus_manage",
        meta: {
          title: "词库管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/thesaurusManage/ThesaurusManage"
          ),
      },
      {
        path: "user_manage",
        meta: {
          title: "人员管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/userManage/UserManage"
          ),
      },
      {
        path: "data_package",
        meta: {
          title: "数据封装",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/dataPackage/DataPackage"
          ),
      },
      {
        path: "help_manage",
        meta: {
          title: "帮助管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/helpManage/HelpManage"
          ),
      },
      {
        path: "help_detail",
        meta: {
          title: "帮助文档",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/helpManage/dialog/HelpDetailView"
          ),
      },
      {
        path: "register_audit",
        meta: {
          title: "用户审核",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/registerAudit/RegisterAudit"
          ),
      },
      {
        path: "management_authorization",
        meta: {
          title: "授权管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/managementAuthorization/ManagementAuthorization"
          ),
      },
      {
        path: "welcome_page",
        meta: {
          title: "欢迎页",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/welcomePage/WelcomePage"
          ),
      },
      {
        path: "four_design",
        meta: {
          title: "检测记录",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/fourPropertyDesign/FourPropertyDesign"
          ),
      },
      {
        path: "backstage_todo",
        meta: {
          title: "我的待办",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/backstageTodo/BackstageTodo"
          ),
      },
      {
        path: "backstage_message",
        meta: {
          title: "我的消息",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/backstageMessage/BackstageMessage"
          ),
      },
      {
        path: "mobile_user_manage",
        meta: {
          title: "移动端用户管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "system" */ "@/mods/system/mobileUserManage/MobileUserManage"
          ),
      },
    ],
  },
];
