<template>
  <a-avatar v-if="user?.avatar" :size="size" :src="user?.avatar"></a-avatar>
  <a-avatar v-else :size="size" :style="avatarStyle">{{ avatarStr }}</a-avatar>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    size: { type: Number, default: 32 },
    user: { type: Object },
    adjustTheme: { type: Boolean, default: true },
    transparent: { type: Boolean },
  },
  computed: {
    avatarStr() {
      const name = this.user?.name || "";
      if (/^[\w\s]+$/.test(name)) {
        const ns = name.split(" ");
        if (ns.length > 1) {
          return (ns[0][0] + ns[1][0]).toUpperCase();
        }
        return name.substring(0, 1).toUpperCase();
      } else {
        return name.substring(0, 1);
      }
    },
    avatarStyle() {
      if (this.adjustTheme) {
        if (this.transparent) {
          return { backgroundColor: "#ffffff44" };
        }
        return {
          backgroundColor: "var(--rmx-primary-color-2)",
          color: "var(--rmx-primary-color)",
        };
      }
      const name = this.user?.name || "";
      let nameCode = 0;
      if (/^[\w\s]+$/.test(name)) {
        name.split(" ").forEach((x) => {
          nameCode += x.charCodeAt(0);
        });
      } else {
        nameCode = name.charCodeAt(0);
      }
      if (nameCode < 200) nameCode *= 200;
      const color = "#" + nameCode.toString(16).substring(1, 4);
      return { backgroundColor: color };
    },
  },
};
</script>

<style scoped></style>
