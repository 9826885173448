<template>
  <a-tooltip
    :title="tips.title"
    :color="tips.color"
    v-model:visible="visible"
    @visible-change="onVisibleChange"
  >
    <div class="copy-button" :style="style" @click.prevent.stop="onClick">
      <slot>
        <check-outlined v-if="state === 1" class="copy-button-icon-success" />
        <close-outlined
          v-else-if="state === 2"
          class="copy-button-icon-error"
        />
        <copy-outlined v-else class="copy-button-icon" />
      </slot>
      <textarea ref="compat" class="copy-button-textarea"></textarea>
    </div>
  </a-tooltip>
</template>

<script>
import { computed, ref, toRefs } from "vue";
import { i18n } from "@/conf/lang";
import {
  CheckOutlined,
  CopyOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";

const TITLE = ["actions.copy", "hint.copied", "hint.copy_failed"];
const COLOR = ["", "green", "red"];

export default {
  name: "CopyButton",
  components: { CopyOutlined, CheckOutlined, CloseOutlined },
  props: {
    text: { type: [String, Number, Object, Function] },
    size: { type: String },
  },
  setup(props) {
    const { text, size } = toRefs(props);
    const state = ref(0);
    const visible = ref(false);
    const compat = ref(null);
    const tips = computed(() => ({
      title: i18n.global.t(TITLE[state.value]),
      color: COLOR[state.value],
    }));
    const style = computed(() => {
      const s = {};
      if (size.value) s["--copy-btn-icon-size"] = size.value;
      return s;
    });
    let timeoutFlag = null;
    const onVisibleChange = (v) => {
      if (v === false && state.value) {
        if (timeoutFlag === null) {
          timeoutFlag = setTimeout(() => {
            state.value = 0;
            timeoutFlag = null;
          }, 2000);
        }
      }
    };
    const onClick = async () => {
      const data =
        typeof text.value === "function" ? await text.value() : text.value;
      const txt = typeof data === "object" ? JSON.stringify(data) : data;
      if (window.isSecureContext) {
        navigator.clipboard.writeText(txt).then(() => (state.value = 1));
      } else if (typeof document.execCommand === "function") {
        const $dom = compat.value;
        if (!$dom) {
          state.value = 2;
          return;
        }
        $dom.style.display = "block";
        $dom.innerText = txt;
        $dom.select();
        document.execCommand("copy");
        $dom.innerText = "";
        $dom.style.display = "";
        state.value = 1;
      } else {
        state.value = 2;
      }
    };
    return { tips, state, visible, compat, style, onVisibleChange, onClick };
  },
};
</script>

<style scoped>
.copy-button {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  --copy-btn-icon-size: 14px;
}
.copy-button:not(:first-child) {
  margin-left: 8px;
}
.copy-button-icon {
  font-size: var(--copy-btn-icon-size);
  color: var(--rmx-icon-3);
}
.copy-button-icon:hover {
  color: var(--rmx-primary-color);
}
.copy-button-icon-success {
  font-size: var(--copy-btn-icon-size);
  color: var(--rmx-success-color);
}
.copy-button-icon-error {
  font-size: var(--copy-btn-icon-size);
  color: var(--rmx-error-color);
}
.copy-button-textarea {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  appearance: none;
  border: none;
  outline: none;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
</style>
