<script>
import { defineComponent, toRefs } from "vue";
import { i18n } from "@/conf/lang";
import { snakeCase } from "@/util/strUtil";
import {
  PROPS_INPUT_ITEM_ALL,
  PROPS_UPLOAD_PARAMS,
  PROPS_UPLOADER,
} from "@/components/common/shared/compAttrs";
import {
  FORM_ITEM_BOOLEAN,
  FORM_ITEM_NUMBER,
  FORM_ITEM_WIDTH,
} from "@/conf/constants";
import {
  Row,
  Col,
  FormItem,
  FormItemRest,
  Button,
  Alert,
} from "ant-design-vue";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons-vue";
import FormInput from "@/components/common/form/FormInput";
import CloseableField from "@/components/common/form/CloseableField";
import TitledGroup from "@/components/common/display/TitledGroup";
import MetaDescriptions from "@/components/common/display/MetaDescriptions";
import PhotoUploader from "@/components/common/form/PhotoUploader";
import BaseTree from "@/components/common/list/BaseTree";
import TipsIcon from "@/components/common/display/TipsIcon";
import ActIcon from "@/components/common/action/ActIcon";
import CopyButton from "@/components/common/action/CopyButton";
import ActButton from "@/components/common/action/ActButton";
import FileUploader from "@/components/common/form/FileUploader";
import RichTextEditor from "@/components/common/form/RichTextEditor";
import IconPicker from "@/components/common/form/IconPicker";
import OptionCheckbox from "@/components/common/form/OptionCheckbox";

const defValue = (field) => {
  if (field.range) {
    return { begin: "", end: "" };
  }
  if (FORM_ITEM_BOOLEAN.includes(field.type)) {
    if (field.compat) return 0;
    return false;
  }
  if (FORM_ITEM_NUMBER.includes(field.type)) return 0;
  return "";
};
const pickProps = (props, valid) => {
  const val = {};
  valid.forEach((a) => (val[a] = props[a]));
  return val;
};

const INPUT_ATTRS = Object.keys(PROPS_INPUT_ITEM_ALL);
const UPLOAD_ATTRS = Object.keys(PROPS_UPLOADER);
const RICH_TEXT_ATTRS = [...INPUT_ATTRS, ...Object.keys(PROPS_UPLOAD_PARAMS)];

const FormRow = (props) =>
  props.enabled ? (
    <Row gutter={24} class={props.split ? "rmx-row-with-split" : undefined}>
      {props.content}
    </Row>
  ) : (
    <>{props.content}</>
  );

const FormCol = (props) =>
  props.useGrid ? (
    <Col span={props.span || 24}>{props.content}</Col>
  ) : (
    <>{props.content}</>
  );

const AddButton = (props) => (
  <Button
    type="dashed"
    htmlType="button"
    block
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <PlusOutlined />
    {i18n.global.t("actions.add")}
  </Button>
);
const MinusIcon = (props) => {
  const disabled = !!props.disabled;
  return (
    <MinusCircleOutlined
      class={["rmx-form-icon-remove"]}
      {...(disabled ? { disabled } : {})}
      title={i18n.global.t("actions.remove")}
      onClick={() => {
        if (!disabled) props?.onAction?.();
      }}
    />
  );
};

export default defineComponent({
  name: "FormGroup",
  emits: ["deleteField", "action", "change"],
  props: {
    value: { type: Object, default: () => ({}) },
    fields: { type: Array, default: () => [] },
    validators: { type: Object, default: () => ({}) },
    size: { type: String },
    minLength: { type: Number, default: 0 },
    withWrap: { type: Boolean, default: true },
    hasPlaceholder: { type: Boolean, default: false },
    autoPlaceholder: { type: Boolean, default: false },
    deleteEmpty: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    keepRemovedField: { type: Boolean, default: false },
    defaultSpan: { type: Number, default: 6 },
    defaultWidth: { type: String },
    useGrid: { type: Boolean, default: true },
    useLocale: { type: Boolean },
  },
  setup(props, { emit, slots, expose }) {
    const {
      value,
      fields,
      validators,
      size,
      withWrap,
      hasPlaceholder,
      autoPlaceholder,
      deleteEmpty,
      minLength,
      disabled,
      keepRemovedField,
      defaultSpan,
      defaultWidth,
      useGrid,
      useLocale,
    } = toRefs(props);
    const templateMap = new Map();
    const removeField = (x) => {
      if (minLength.value > 0 && fields.value.length <= minLength.value) {
        return;
      }
      let index = fields.value.indexOf(x);
      if (index !== -1) {
        const [field] = fields.value.splice(index, 1);
        if (!keepRemovedField.value && field) {
          delete value.value[field.column];
        }
        emit("deleteField", x);
      }
    };
    const getHint = (type, label) => {
      if (type === "select" || type === "tree_select") {
        return label
          ? i18n.global.t("hint.select_what", [label])
          : i18n.global.t("hint.select");
      } else if (
        type === "radio_group" ||
        type === "radio_buttons" ||
        type === "checkbox_group"
      ) {
        return i18n.global.t("hint.no_data");
      }
      return label
        ? i18n.global.t("hint.input_what", [label])
        : i18n.global.t("hint.input");
    };
    const FormInputItem = (childProps) => {
      const validProps = pickProps(childProps.field, INPUT_ATTRS);
      return (
        <FormInput
          {...validProps}
          name={childProps.name}
          type={childProps.type}
          options={childProps.field.from}
          disabled={childProps.disabled}
          placeholder={childProps.placeholder}
          size={size.value}
          width={childProps.width ?? "block"}
          value={childProps.value}
          onChange={childProps.onChange}
        />
      );
    };
    const FormOp = (childProps) => {
      const op = childProps.op;
      return (
        <div className={"rmx-form-item-operation"}>
          <ActButton
            type={op.type}
            narrow={op.type === "link"}
            onClick={() =>
              emit("action", { action: op.action || childProps.act })
            }
          >
            {op.label}
          </ActButton>
        </div>
      );
    };
    const generateField = (field, parentVal, nameArr, setVal) => {
      const column = field.column;
      const nameKey =
        Array.isArray(nameArr) && nameArr.length > 0
          ? nameArr.join("_")
          : column;
      const isDisabled = !!field.disabled || disabled.value;
      if (parentVal[column] === undefined && field.initialValue !== undefined) {
        parentVal[column] = field.initialValue;
      }
      const type = snakeCase(field.type);
      if (type === "upload" || type === "image_upload") {
        if (!parentVal[column]) parentVal[column] = [];
        const Uploader = type === "image_upload" ? PhotoUploader : FileUploader;
        const validProps = pickProps(field, UPLOAD_ATTRS);
        return (
          <Uploader
            {...validProps}
            style={{ width: "100%" }}
            v-model={[parentVal[column], "value"]}
            onChange={(e) => {
              emit("change", { column, value: e, path: nameArr });
            }}
          />
        );
      } else if (type === "rich_text") {
        if (!parentVal[column]) parentVal[column] = "";
        const validProps = pickProps(field, RICH_TEXT_ATTRS);
        return (
          <RichTextEditor
            {...validProps}
            style={{ width: "100%" }}
            v-model={[parentVal[column], "value"]}
            onChange={(e) => {
              emit("change", { column, value: e, path: nameArr });
            }}
          />
        );
      } else if (type === "icon_picker") {
        if (!parentVal[column]) parentVal[column] = "";
        const validProps = pickProps(field, INPUT_ATTRS);
        return (
          <IconPicker
            {...validProps}
            v-model={[parentVal[column], "value"]}
            onChange={(e) => {
              emit("change", { column, value: e, path: nameArr });
            }}
          />
        );
      } else if (type === "tree") {
        const options = Array.isArray(field.from)
          ? field.from.map((x) => ({
              title: x.title || x.text || x.label,
              value: x.value,
              key: x.key || x.id || x.value,
              pId: x.pId,
              disabled: !!x.disabled,
            }))
          : [];
        return (
          <BaseTree
            checkAble={true}
            checkStrictly={field.checkStrictly}
            list={options}
            height={field.height}
            disabled={isDisabled}
            bordered
            style={{ width: "100%" }}
            v-model={[parentVal[column], "value"]}
            onChange={(e) =>
              emit("change", { column, value: e, path: nameArr })
            }
          />
        );
      } else if (type === "inputs") {
        if (!parentVal[column]) {
          parentVal[column] = [];
        }
        const length = parentVal[column].length;
        if (field.min && parentVal[column].length < field.min) {
          const remain = field.min - length;
          for (let i = 0; i < remain; i++) {
            parentVal[column].push(defValue(field));
          }
        }
        const childType = snakeCase(field.children.type);
        const placeholder =
          field.placeholder ??
          (!isDisabled && hasPlaceholder.value
            ? getHint(type, autoPlaceholder.value ? field.label : "")
            : "");
        const noAddable = field.max ? length >= field.max : false;
        const noRemovable = field.min ? length <= field.min : false;
        const emitChange = () => {
          emit("change", { column, value: parentVal[column], path: nameArr });
        };
        return (
          <div className={"rmx-form-inputs"}>
            <FormItemRest>
              {parentVal[column].map((v, i) => (
                <div className={"rmx-form-inputs-item"}>
                  <FormInputItem
                    field={field.children}
                    value={v}
                    name={`${nameKey}_${i}`}
                    type={childType}
                    disabled={isDisabled}
                    placeholder={placeholder}
                    onChange={(e) => {
                      parentVal[column][i] = e;
                      emitChange();
                    }}
                  />
                  <MinusIcon
                    disabled={noRemovable}
                    onAction={() => {
                      parentVal[column].splice(i, 1);
                      emitChange();
                    }}
                  />
                </div>
              ))}
              <AddButton
                disabled={noAddable}
                onClick={() => {
                  parentVal[column].push(defValue(field.children));
                  emitChange();
                }}
              />
            </FormItemRest>
          </div>
        );
      } else if (type === "complex") {
        let usedValue = parentVal;
        if (column) {
          if (!parentVal[column]) parentVal[column] = {};
          usedValue = parentVal[column];
        }
        const children = field.use
          ? templateMap.get(field.use)
          : field.children;
        return (
          <div className={"rmx-form-complex"}>
            <FormItemRest>
              {children.map((fi, fii) => {
                if (typeof fi !== "object") {
                  return (
                    <div key={fii} className={"rmx-form-complex-item"}>
                      {fi}
                    </div>
                  );
                }
                const placeholder =
                  field.placeholder ??
                  (!isDisabled && hasPlaceholder.value
                    ? getHint(type, autoPlaceholder.value ? field.label : "")
                    : "");
                const cType = snakeCase(fi.type);
                return (
                  <div
                    key={`ci_${fi.column}`}
                    className={"rmx-form-complex-item"}
                  >
                    <FormInputItem
                      field={fi}
                      value={usedValue[fi.column]}
                      name={`${nameKey}_${fi.column}`}
                      type={cType}
                      disabled={isDisabled || fi.disabled}
                      placeholder={placeholder}
                      width={fi.width ?? ""}
                      onChange={(e) => {
                        usedValue[fi.column] = e;
                        emit("change", { column: fi.column, value: e });
                      }}
                    />
                  </div>
                );
              })}
            </FormItemRest>
          </div>
        );
      } else if (type === "slot") {
        const custom = (
          <FormItemRest>
            {slots.custom?.({
              itemName: nameKey,
              field,
              itemValue: parentVal[column],
              disabled: isDisabled,
              setValue: (val) => setVal(column, val),
            })}
          </FormItemRest>
        );
        return <div className={"rmx-form-custom-item"}>{custom}</div>;
      } else {
        const placeholder =
          field.placeholder ??
          (!isDisabled && hasPlaceholder.value
            ? getHint(type, autoPlaceholder.value ? field.label : "")
            : "");
        return (
          <FormInputItem
            field={field}
            value={parentVal[column]}
            name={nameKey}
            type={type}
            disabled={isDisabled}
            placeholder={placeholder}
            onChange={(e) => {
              setVal(column, e);
              emit("change", { column, value: e, path: nameArr });
            }}
          />
        );
      }
    };
    const makeRules = (x) => {
      const v = x.validator;
      if (!v) return [];
      const arr = !Array.isArray(v) ? [v] : v;
      return arr.flatMap((it) => {
        const func = typeof it === "function" ? it : validators.value[it];
        if (!it) return undefined;
        return { validator: async (rule, value) => func(x, value, rule) };
      });
    };
    const renderGroup = (list, groupVal, useGrid, defWidth, nameKeys = []) => {
      const setVal = (column, val) => {
        if (deleteEmpty.value) {
          if (val || val === false) {
            groupVal[column] = val;
          } else {
            delete groupVal[column];
          }
        } else {
          groupVal[column] = val;
        }
      };
      return list.map((x, fieldIndex) => {
        const width = useGrid ? undefined : x.width || defWidth;
        const widthVal = FORM_ITEM_WIDTH[width] || width;
        const style = { width: widthVal ? widthVal : undefined };
        const span = x.span ?? defaultSpan.value;
        const name = nameKeys.length > 0 ? [...nameKeys, x.column] : x.column;
        const showClose = fields.value.length > minLength.value;
        const labelText =
          useLocale.value && x.label ? i18n.global.t(x.label) : x.label;
        const rules = makeRules(x, labelText);
        const domKey = `dk_${nameKeys.join("_")}_${
          x.key || x.column || fieldIndex
        }`;
        if (x.required) {
          rules.unshift({
            required: true,
            message: i18n.global.t("hint.required_what", [labelText]),
          });
        }
        if (x.notEmpty) {
          rules.unshift({
            whitespace: true,
            message: i18n.global.t("hint.not_empty", [labelText]),
          });
        }
        if (x.type === "template") {
          templateMap.set(x.column, x.children);
          return undefined;
        } else if (x.type === "group" || x.type === "object") {
          let usedValue = groupVal;
          const groupNameKeys = [...nameKeys];
          if (x.column) {
            if (!groupVal[x.column]) groupVal[x.column] = {};
            usedValue = groupVal[x.column];
            groupNameKeys.push(x.column);
          }
          const children = x.use ? templateMap.get(x.use) : x.children;
          const style = {
            width: defWidth ? FORM_ITEM_WIDTH[defWidth] || defWidth : undefined,
          };
          const childGrid = x.grid === undefined ? useGrid : !!x.grid;
          const groupContent = (
            <FormRow
              enabled={childGrid}
              split={!!x.split}
              content={renderGroup(
                children,
                usedValue,
                childGrid,
                width,
                groupNameKeys
              )}
            />
          );
          let wrap;
          if (x.type === "object") {
            const objectClass = x.bordered
              ? "rmx-form-object-bordered"
              : "rmx-form-object";
            wrap = (
              <div className={objectClass} style={style}>
                {groupContent}
              </div>
            );
          } else {
            wrap = (
              <TitledGroup
                title={labelText}
                bordered={x.bordered}
                style={style}
              >
                {groupContent}
              </TitledGroup>
            );
          }
          return (
            <FormCol
              key={domKey}
              content={wrap}
              span={span}
              useGrid={useGrid}
            />
          );
        } else if (x.type === "tips") {
          const func = x.func;
          const conf =
            typeof func === "function"
              ? Object.assign({}, x, func(groupVal))
              : x;
          return (
            <FormCol
              key={domKey}
              useGrid={useGrid}
              content={
                <Alert
                  type={conf.tipsType}
                  message={conf.message}
                  description={conf.desc}
                  showIcon={x.icon}
                  closable={x.closable}
                  class={["rmx-form-tips"]}
                  style={style}
                />
              }
            />
          );
        } else if (x.type === "meta") {
          const metaValue = groupVal[x.column] ?? groupVal;
          const bordered = x.bordered === undefined ? true : x.bordered;
          return (
            <FormCol
              key={domKey}
              useGrid={useGrid}
              content={
                <MetaDescriptions
                  style={style}
                  bordered={bordered}
                  table={x.table}
                  direction={x.direction}
                  value={metaValue}
                  fields={x.children}
                  columns={x.columns}
                  class={["rmx-form-meta"]}
                  useLocale={useLocale.value}
                />
              }
            />
          );
        } else if (x.type === "array") {
          if (!groupVal[x.column]) {
            groupVal[x.column] = [];
          }
          const children = x.use ? templateMap.get(x.use) : x.children;
          const emitChange = () =>
            emit("change", {
              column: x.column,
              value: groupVal[x.column],
              path: name,
            });
          const arrayContent = (
            <>
              {groupVal[x.column].map((item, index) => (
                <CloseableField
                  showClose
                  onClose={() => {
                    groupVal[x.column].splice(index, 1);
                    emitChange();
                  }}
                >
                  <div className={"rmx-form-group-wrap"}>
                    <FormRow
                      enabled={x.grid}
                      content={renderGroup(children, item, !!x.grid, width, [
                        ...nameKeys,
                        x.column,
                        index,
                      ])}
                    />
                  </div>
                </CloseableField>
              ))}
              <AddButton
                onClick={() => {
                  groupVal[x.column].push({});
                  emitChange();
                }}
              />
            </>
          );
          return (
            <TitledGroup key={domKey} title={labelText}>
              {arrayContent}
            </TitledGroup>
          );
        } else if (x.type === "option_checkbox") {
          return (
            <FormCol
              key={domKey}
              useGrid={useGrid}
              content={
                <OptionCheckbox
                  name={domKey}
                  disabled={!!x.disabled || disabled.value}
                  readonly={!!x.readonly}
                  compat={x.compat}
                  text={x.text}
                  label={x.label}
                  description={x.description}
                  tips={x.tips}
                  v-model={[groupVal[x.column], "value"]}
                  onChange={(e) => {
                    emit("change", {
                      column: x.column,
                      value: e,
                      path: name,
                    });
                  }}
                />
              }
            />
          );
        }
        const field = generateField(x, groupVal, name, setVal);
        const wrapStyle = { width: widthVal };
        const content = x.operation ? (
          <div className={"rmx-form-content-wrap"} style={wrapStyle}>
            {field}
            <FormOp op={x.operation} act={x.column} />
          </div>
        ) : widthVal ? (
          <div className={"rmx-form-content-wrap"} style={wrapStyle}>
            {field}
          </div>
        ) : (
          field
        );
        const labelAttr =
          x.tips || x.description || x.labelAction || x.copyable ? (
            <div className="rmx-form-label">
              <span>{labelText}</span>
              {x.description ? (
                <span className="rmx-form-label-desc">{x.description}</span>
              ) : undefined}
              {x.tips ? <TipsIcon tips={x.tips} /> : undefined}
              {x.labelAction ? (
                <ActIcon
                  type={x.labelAction.action}
                  color={"var(--rmx-icon-3)"}
                  size={"s"}
                  style={{ marginLeft: "8px" }}
                  title={x.labelAction.label}
                />
              ) : undefined}
              {x.copyable ? (
                <CopyButton text={groupVal[x.column]} />
              ) : undefined}
            </div>
          ) : (
            labelText
          );
        return (
          <FormCol
            key={domKey}
            span={span}
            useGrid={useGrid}
            content={
              <FormItem
                label={labelAttr}
                name={name}
                required={!!x.required}
                rules={rules}
              >
                {x.closable ? (
                  <CloseableField
                    showClose={showClose}
                    onClose={() => removeField(x)}
                  >
                    {content}
                  </CloseableField>
                ) : (
                  content
                )}
              </FormItem>
            }
          />
        );
      });
    };
    expose({});
    return () => {
      return (
        <FormRow
          enabled={withWrap.value}
          content={renderGroup(
            fields.value,
            value.value,
            useGrid.value,
            defaultWidth.value
          )}
        />
      );
    };
  },
});
</script>
