export default {
  editRegisterInfo: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/registerManage/dialog/EditRegisterInfo"
      ),
  },
};
