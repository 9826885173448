<template>
  <a-dropdown
    v-if="menus.length > 0"
    :trigger="trigger"
    :disabled="disabled"
    destroy-popup-on-hide
    v-model:visible="menuVisible"
  >
    <slot>
      <div class="dropdown-menus" @click.stop>
        <ellipsis-icon />
      </div>
    </slot>
    <template #overlay>
      <menu-view
        :menus="menus"
        :extra-state="extraState"
        mode="vertical"
        @click="onClick"
      ></menu-view>
    </template>
  </a-dropdown>
</template>

<script>
import { computed, toRefs } from "vue";
import { useCachedProps } from "@/components/common/shared/compInternal";
import MenuView from "@/components/common/action/MenuView";
import EllipsisIcon from "@/components/icon/EllipsisIcon";

export default {
  name: "DropdownMenus",
  components: { EllipsisIcon, MenuView },
  props: {
    operations: { type: [Array, Function] },
    param: { type: Object },
    disabled: { type: Boolean },
    extraState: { type: Object },
    trigger: { type: Array, default: () => ["hover"] },
    visible: { type: Boolean },
  },
  emits: ["action", "update:visible"],
  setup(props, { emit }) {
    const propRefs = toRefs(props);
    const { operations, param } = propRefs;
    const { makeValProp } = useCachedProps(propRefs, { emit });
    const menuVisible = makeValProp("visible", false, true);
    const menus = computed(() => {
      if (typeof operations.value === "function") {
        return operations.value(param.value) || [];
      }
      return operations.value || [];
    });
    const onClick = ({ action }) => {
      menuVisible.value = false;
      emit("action", { action, item: param.value });
    };
    return { menus, menuVisible, onClick };
  },
};
</script>

<style scoped></style>
