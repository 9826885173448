import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { generateTree, simpleDeepClone } from "@/util/dataUtil";
import { httpBackToOrigin, httpEnterChild, userMenus } from "@/api/user";
import {
  refreshGlobalUserInfo,
  syncUserInnerState,
  useAssignUserInfo,
  userAuthList,
} from "@/mods/common/user/userCommonLogic";
import { httpInitComTree } from "@/api/common";
import { showConfirm } from "@/util/dialog";
import { useRoute } from "vue-router";

export function loadUserMenus() {
  return new Promise((resolve) => {
    Promise.all([userMenus(), userAuthList(true)]).then(([resources, auth]) => {
      const menus = resources.filter((item) => auth.includes(item.code));
      const all = resources.map((x) => x.url).filter((x) => x);
      const valid = menus.map((x) => x.url).filter((x) => x);
      const tree = generateTree(menus, { key: "id", pId: "fatherid" });
      tree.forEach((g) => {
        if (Array.isArray(g.children)) {
          g.children.forEach((x) => {
            if (x.children) delete x.children;
          });
        }
      });
      resolve({ all, valid, tree });
    });
  });
}

const backNavState = { menus: [] };

export function useBackMenus() {
  const store = useStore();
  const route = useRoute();
  const all = ref([]);
  const valid = ref([]);
  const menus = ref([]);
  const menuLoaded = ref(false);
  const loaded = computed(() => {
    return menuLoaded.value && store.state.modAvailable;
  });
  const hasAuth = computed(
    () => valid.value.includes(route.path) || !all.value.includes(route.path)
  );
  const loadMenu = () => {
    menuLoaded.value = false;
    if (store.state.loginType === "back") {
      loadUserMenus().then((e) => {
        all.value = e.all;
        valid.value = e.valid;
        menus.value = e.tree;
        backNavState.menus = simpleDeepClone(menus.value);
        menuLoaded.value = true;
      });
    } else if (store.state.loginType === "front") {
      router.push("/front/").then(() => {});
    }
  };
  onMounted(() => {
    loadMenu();
  });
  watch(
    () => store.state.user,
    () => {
      loadMenu();
    }
  );
  useAssignUserInfo();
  return { menus, loaded, hasAuth };
}

export function useCurrentBackMenus() {
  return { menus: backNavState.menus };
}

export function useBackUnit() {
  const store = useStore();
  const info = computed(() => store.state.provisionalInfo);
  const popupVisible = ref(false);
  const switchable = computed(() => info.value?.isInSelfUnit);
  const canEnterChild = computed(() => {
    const i = info.value || {};
    if (!i.canEnterChild) {
      return false;
    }
    return (i.isInSelfUnit && i.isRoom) || !i.isInSelfUnit;
  });
  const selection = ref([]);
  const expanded = ref([]);
  const _filter = ref("");
  const searchValue = ref("");
  const companyList = ref([]);
  const companyTree = ref([]);
  const tree = computed(() => {
    if (_filter.value) {
      return companyList.value.filter((x) => x.title.includes(_filter.value));
    }
    return companyTree.value;
  });
  const current = computed(() => {
    return { title: info.value.unitName };
  });
  const afterUnitChange = () =>
    refreshGlobalUserInfo().then(() => {
      syncUserInnerState(true);
      router.push("/portal").then(() => {});
    });
  const onSelect = () => {
    popupVisible.value = false;
    httpEnterChild({ comId: selection.value[0] }).then((res) => {
      if (res.code > 0) {
        showConfirm({
          content: "所选单位暂无全宗信息，是否进入",
          returnCancel: true,
        }).then((ok) => {
          if (ok) {
            httpEnterChild({ comId: selection.value[0], force: true }).then(
              () => {
                return afterUnitChange();
              }
            );
          }
        });
      } else {
        return afterUnitChange();
      }
    });
  };
  const onBack = () => {
    popupVisible.value = false;
    httpBackToOrigin().then(() => {
      return afterUnitChange();
    });
  };
  const onSearch = () => {
    _filter.value = searchValue.value;
    selection.value = [];
  };
  watch(
    () => info.value,
    (it) => {
      if (it.userId) {
        httpInitComTree({}).then((res) => {
          companyList.value = simpleDeepClone(res);
          companyTree.value = generateTree(res, { addMark: true });
        });
      }
    },
    { immediate: true }
  );
  watch(
    () => popupVisible.value,
    (v) => {
      if (v) {
        searchValue.value = "";
        onSearch();
        if (expanded.value.length === 0) {
          expanded.value = [tree.value[0].id];
        }
      }
    }
  );
  return {
    popupVisible,
    canEnterChild,
    switchable,
    tree,
    current,
    selection,
    expanded,
    searchValue,
    onSelect,
    onSearch,
    onBack,
  };
}
