export default {
  addDept: {
    width: "s",
    okText: "actions.save",
    title: "添加组织",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/deptManage/dialog/AddDept"
      ),
  },
  changeParent: {
    width: "s",
    okText: "变更上级",
    title: "变更上级",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/deptManage/dialog/ChangeParent"
      ),
  },
};
