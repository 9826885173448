export default {
  // 添加扩展配置弹窗
  addOrUpdateExConfig: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/exConfig/dialog/AddOrEditExConfig"
      ),
  },
  // 设置对象属性弹窗
  setObjectProperties: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/exConfig/dialog/SetObjectProperties"
      ),
  },
  // 添加对象属性弹窗
  addAttributes: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/exConfig/dialog/AddAttributes"
      ),
  },
  // 变更上级弹窗
  changeSup: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/exConfig/dialog/ChangeSup"
      ),
  },
};
