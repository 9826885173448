<template>
  <div
    ref="listWrapRef"
    class="base-list"
    :class="{
      'base-list-with-header': showHeader,
      'base-list-with-pager': showPager,
      'base-list-split-through': splitThrough,
    }"
    :style="baseListStyle"
  >
    <div v-if="showHeader" class="base-list-header">
      <div v-if="showHeaderLeft" class="base-list-header-left">
        <div v-if="showSelectionAll" class="base-list-selection">
          <div class="base-list-select-all">
            <a-checkbox :checked="isSelectAll" @update:checked="handleCheckAll">
              {{ $t("actions.select_all") }}
            </a-checkbox>
          </div>
          <div class="base-list-total">
            <a-tooltip>
              <template #title>
                {{ $t("hint.selected_count", [selectedIds.length]) }}
              </template>
              <count-tag brace :count="selectedIds.length"></count-tag>
            </a-tooltip>
          </div>
        </div>
        <grouped-toolbar
          v-if="showOperation"
          class="base-list-toolbar"
          :operations="operations"
          :extra-state="extraState"
          :default-type="operationConfig.defaultType"
          :narrow="operationConfig.narrow"
          shrink-enabled
          @action="onToolbarAction"
        />
        <template v-if="$slots.extraToolbar">
          <slot name="extraToolbar"></slot>
        </template>
      </div>
      <div v-if="showHeaderRight" class="base-list-header-right">
        <filter-view
          v-if="showFilter"
          class="base-list-filter"
          :fields="filterFields"
          v-model:value="displayFilterValue"
          @filter="onFilter"
        />
        <list-search-bar
          v-if="showSearch"
          class="base-list-search"
          :search-on-blur="searchOnBlur"
          :placeholder="searchPlaceholder"
          v-model:value="displaySearchValue"
          @search="onSearch"
        >
          <template v-if="$slots.searchPrefix" #searchPrefix>
            <slot name="searchPrefix"></slot>
          </template>
        </list-search-bar>
        <icons-toolbar
          v-if="showToolIcons"
          :operations="toolIcons"
          :checked="toolIconChecked"
          @action="onIconAction"
        />
      </div>
    </div>
    <div v-if="displayLoading" class="base-list-placeholder">
      <loading-view />
    </div>
    <template v-else-if="displayList.length > 0">
      <scroller-view
        class="base-list-scroll"
        :scroll-top="scrollTop"
        :scroll-left="scrollLeft"
        @view-scroll="$emit('listScroll', $event)"
      >
        <template v-if="$slots.header">
          <div class="base-list-scroll-header">
            <slot
              name="header"
              :select-all="isSelectAll"
              :set-select-all="(v) => handleCheckAll(v)"
            ></slot>
          </div>
        </template>
        <template v-for="(item, index) in displayList" :key="item.id">
          <slot
            name="listItem"
            :item="item"
            :index="countable ? indexCounter(index) : undefined"
            :check-able="isCheckAble(item)"
            :checked="selectedIdState.get(item.id)"
            :set-checked="(checked) => handleCheck(checked, item)"
            :set-field-value="(field, val) => onSetFieldValue(item, field, val)"
          ></slot>
        </template>
      </scroller-view>
      <div v-if="showPager" class="base-list-pager">
        <a-pagination
          :current="displayPage"
          :page-size="displaySize"
          :total="displayTotal"
          :show-total="(x) => $t('hint.total_count', [x])"
          :page-size-options="displaySizeOptions"
          show-size-changer
          @change="onPageChange"
        />
      </div>
    </template>
    <div v-else class="base-list-placeholder">
      <template v-if="!realLoading">
        <slot name="empty">
          <img-empty-tips
            :type="emptyIcon"
            :description="emptyText"
            :size="emptySize"
          ></img-empty-tips>
        </slot>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, computed, watch } from "vue";
import { i18n } from "@/conf/lang";
import { sizeDimension } from "@/util/uiUtil";
import { useAbstractList } from "@/components/common/shared/listInternal";
import { useBlockSize } from "@/components/common/shared/uiInternal";
import {
  EMITS_ABSTRACT_LIST,
  PROPS_ABSTRACT_LIST,
} from "@/components/common/shared/compAttrs";
import GroupedToolbar from "@/components/common/action/GroupedToolbar";
import ImgEmptyTips from "@/components/common/display/ImgEmptyTips";
import ScrollerView from "@/components/common/display/ScrollerView";
import ListSearchBar from "@/components/common/form/ListSearchBar";
import FilterView from "@/components/common/form/FilterView";
import CountTag from "@/components/common/display/CountTag";
import IconsToolbar from "@/components/common/action/IconsToolbar";
import LoadingView from "@/components/common/display/LoadingView";

export default {
  name: "BaseList",
  components: {
    LoadingView,
    IconsToolbar,
    CountTag,
    FilterView,
    ListSearchBar,
    ScrollerView,
    ImgEmptyTips,
    GroupedToolbar,
  },
  emits: ["listScroll", ...EMITS_ABSTRACT_LIST],
  props: {
    ...PROPS_ABSTRACT_LIST,
    hideSelectAll: { type: Boolean },
    scrollTop: { type: Number },
    scrollLeft: { type: Number },
    listPadding: { type: [String, Array] },
    splitThrough: { type: Boolean },
    headerHeight: { type: [String, Number] },
    headerGap: { type: [String, Number] },
    countable: { type: Boolean },
  },
  setup(props, { expose, slots }) {
    const propRefs = toRefs(props);
    const {
      resultList,
      crossPage,
      showOperation,
      showSelection,
      showSearch,
      showFilter,
      showToolIcons,
      listPadding,
      headerGap,
      hideSelectAll,
      selectType,
      selectable,
      height,
      attachBlock,
    } = propRefs;
    const {
      realLoading,
      hasData,
      displayList,
      displaySearchValue,
      displayFilterValue,
      isSelected,
      isSelectAll,
      selectedIds,
      selectedIdState,
      displayLoading,
      displayPage,
      displaySize,
      displayTotal,
      displaySizeOptions,
      onToolbarAction,
      onIconAction,
      onPageChange,
      onSearch,
      onFilter,
      onSetFieldValue,
      load,
      refresh,
      clear,
      clearFilter,
      getSelectionList,
      getDisplayList,
      updateSelectedIds,
    } = useAbstractList(propRefs, undefined, {
      afterLoaded: () => {
        const $el = listWrapRef.value;
        if (!$el) return;
        const body = $el.querySelector(".base-list-scroll");
        if (!body) return;
        body.scrollTop = 0;
        body.scrollLeft = 0;
      },
    });
    const listWrapRef = ref(null);
    const { size: blockSize } = useBlockSize();

    const showSelectionAll = computed(
      () =>
        showSelection.value &&
        !hideSelectAll.value &&
        selectType.value !== "radio"
    );
    const showHeaderLeft = computed(
      () =>
        showOperation.value || showSelectionAll.value || !!slots.extraToolbar
    );
    const showHeaderRight = computed(
      () => showSearch.value || showFilter.value || showToolIcons.value
    );

    const showHeader = computed(
      () => showHeaderLeft.value || showHeaderRight.value
    );

    const extraState = computed(() => ({
      hasData: hasData.value,
      selected: isSelected.value,
      tipsNoData: i18n.global.t("hint.list_no_data"),
      tipsNotSelect: i18n.global.t("hint.list_not_select"),
    }));

    const handleCheck = (e, item) => {
      if (isCheckAble(item)) {
        e ? updateSelectedIds([item.id]) : updateSelectedIds([], [item.id]);
      }
    };
    const handleCheckAll = (e) => {
      updateSelectedIds(e ? "all" : "none");
    };

    const isCheckAble = (item) => {
      if (!showSelection.value) return false;
      if (selectable.value) return selectable.value(item);
      return true;
    };

    const indexCounter = (index) => {
      const start = (+displayPage.value - 1) * +displaySize.value;
      return start + 1 + +index;
    };

    const baseListStyle = computed(() => {
      const styles = {};
      if (listPadding.value) {
        const padding = sizeDimension(listPadding.value);
        styles["--base-list-pad-left"] = padding.left;
        styles["--base-list-pad-right"] = padding.right;
        styles["--base-list-pad-top"] = padding.top;
        styles["--base-list-pad-bottom"] = padding.bottom;
      }
      if (headerGap.value) {
        styles["--base-list-header-gap"] = headerGap.value;
      }
      if (height.value) {
        styles["height"] = height.value;
      } else if (attachBlock.value) {
        styles["height"] = blockSize.value.height + "px";
      }
      return styles;
    });

    watch(
      () => resultList.value,
      (val) => {
        if (Array.isArray(val) && !crossPage.value) {
          selectedIds.value = [];
        }
      }
    );

    expose({
      load,
      refresh,
      clear,
      clearFilter,
      getSelectionList,
      getDisplayList,
    });

    return {
      realLoading,
      listWrapRef,
      displaySearchValue,
      displayFilterValue,
      displayList,
      selectedIds,
      selectedIdState,
      isSelectAll,
      displayPage,
      displaySize,
      displayLoading,
      displayTotal,
      showSelectionAll,
      showHeader,
      showHeaderLeft,
      showHeaderRight,
      displaySizeOptions,
      baseListStyle,
      extraState,
      isCheckAble,
      indexCounter,
      handleCheck,
      handleCheckAll,
      onSetFieldValue,
      onPageChange,
      onSearch,
      onFilter,
      onToolbarAction,
      onIconAction,
    };
  },
};
</script>

<style scoped>
.base-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 1px;
  --base-list-pad-left: 0px;
  --base-list-pad-right: 0px;
  --base-list-pad-top: 0px;
  --base-list-pad-bottom: 0px;
  --base-list-header-gap: 0px;
  padding: 0 0 var(--base-list-pad-bottom);
  --base-list-header-height: 0px;
  --base-list-header-margin: 0 0 var(--base-list-header-gap);
  --base-list-header-padding: var(--base-list-pad-top) 0 16px;
  --base-list-pager-height: 0px;
  --base-list-suffix-pad: 0px;
}
.base-list-with-pager {
  --base-list-pager-height: 48px;
}
.base-list-with-header {
  --base-list-header-height: 49px;
}
.base-list-split-through {
  --base-list-header-margin: 0 0 var(--base-list-header-gap);
  --base-list-header-padding: var(--base-list-pad-top)
    var(--base-list-pad-right) 16px var(--base-list-pad-left);
}
.base-list.base-list-with-header {
  padding-top: 0;
}
.base-list-header {
  border-bottom: 1px solid var(--rmx-split);
  display: flex;
  margin: var(--base-list-header-margin);
  padding: var(--base-list-header-padding);
  align-items: center;
}
.base-list-select-all {
  display: block;
  white-space: nowrap;
}
.base-list-total {
  display: flex;
  align-items: center;
  min-width: 45px;
  line-height: 1;
}
.base-list-header-left {
  display: flex;
  flex: 1;
  align-items: center;
  width: 1px;
}
.base-list-header-right {
  display: flex;
  align-items: center;
  gap: 8px;
}
.base-list-selection {
  padding: 0 16px;
  display: flex;
}
.base-list-toolbar {
  flex: 1;
  height: 32px;
  width: 1px;
}
.base-list-scroll {
  overflow-y: auto;
  padding-left: var(--base-list-pad-left);
  margin-right: var(--base-list-pad-right);
  height: calc(
    100% - var(--base-list-header-height) - var(--base-list-header-gap) -
      var(--base-list-pager-height) - var(--base-list-suffix-pad)
  );
}
.base-list-scroll-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
.base-list-pager {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 8px;
}
.base-list-placeholder {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.base-list-loading {
  font-size: 64px;
  color: var(--rmx-primary-color);
}
:deep(.ant-pagination-total-text) {
  color: var(--rmx-text-2);
  font-size: 14px;
  margin-right: 16px;
}
</style>
