export default {
  appraisalArchive: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/participationAppraisal/dialog/AppraisalArchive"
      ),
  },
  sensitiveWord: {
    title: "遮盖内容",
    width: "s",
    okText: "actions.confirm",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/participationAppraisal/dialog/SensitiveWord"
      ),
  },
};
