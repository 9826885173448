/*
 * @Author: HuaZhiQiang
 * @Date: 2022-09-27 09:20:40
 * @LastEditors: HuaZhiQiang
 * @LastEditTime: 2022-09-27 16:11:16
 * @Description: file content
 */
import manageProcessFlow from "@/mods/setting/dialog/manageProcessFlow";
import fondsManage from "@/mods/setting/dialog/fondsManage";
import catalogConfig from "@/mods/setting/dialog/catalogConfig";
import archiveTypeInfoDialog from "@/mods/setting/dialog/archive/archiveTypeInfoDialog";
import archiveTypeClassify from "@/mods/setting/dialog/archive/archiveTypeClassify";
import archiveTypeRelation from "@/mods/setting/dialog/archive/archiveTypeRelation";
import archiveTypeMetaCor from "@/mods/setting/dialog/archive/archiveTypeMetaCor";
import archiveSpecialAccessRights from "@/mods/setting/dialog/archive/archiveSpecialAccessRights";
import archiveAttachType from "@/mods/setting/dialog/archive/archiveAttachType";

import archiveTypeRule from "@/mods/setting/dialog/archive/archiveTypeRule";
import archiveTypeMeta from "@/mods/setting/dialog/archive/archiveTypeMeta";
import reportManage from "@/mods/setting/dialog/reportManage";
import warehouseManage from "@/mods/setting/dialog/warehouseManage";
import archiveTypeOrder from "@/mods/setting/dialog/archive/archiveTypeOrder";
import detectionScheme from "@/mods/setting/dialog/detectionScheme";
export default {
  manageProcessFlow,
  fondsManage,
  catalogConfig,
  archiveTypeInfoDialog,
  archiveTypeClassify,
  archiveTypeRelation,
  archiveTypeMetaCor,
  archiveSpecialAccessRights,
  archiveAttachType,
  archiveTypeRule,
  archiveTypeMeta,
  reportManage,
  warehouseManage,
  archiveTypeOrder,
  detectionScheme,
};
