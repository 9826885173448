export default {
  schemeDialog: {
    width: "m",
    okText: "保存",
    cancelText: "取消",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/detectionScheme/detail/DetectionSchemeDetail"
      ),
  },
  chooseDataDialog: {
    width: "s",
    okText: "保存",
    cancelText: "取消",
    title: "选择元数据",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/detectionScheme/dialog/DetectionChooseDialog"
      ),
  },
  customDialog: {
    width: "s",
    okText: "保存",
    cancelText: "取消",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/detectionScheme/dialog/DetectionCustomDialog"
      ),
  },
  updateTitleDialog: {
    width: "s",
    okText: "保存",
    cancelText: "取消",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/detectionScheme/dialog/DetectionUpdateTitleDialog"
      ),
  },
  fourDetailDialog: {
    width: "xxl",
    title: "检测方案详情",
    okCancel: false,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/detectionScheme/dialog/DetectionDetailDialog"
      ),
  },
};
