import { getCurrentInstance } from "vue";
import { i18n } from "@/conf/lang";
import { showConfirm, showMessage, showNotification } from "@/util/dialog";

export const useIframeBridge = (iframe, bridge, context) => {
  if (!context) context = getCurrentInstance();
  const { emit } = context;
  const sendMessage = (data) => {
    const win = iframe.value.contentWindow;
    win.postMessage(JSON.parse(JSON.stringify(data)), "*");
  };
  const onMessage = (e) => {
    const sameOrigin = e.origin === window.origin;
    const data = e.data;
    if (!bridge.value) {
      emit("message", { data, sameOrigin });
      return;
    }
    const { action, eventId, ...remain } = data;
    if (action === "message") {
      showMessage(remain.message, remain.type);
    } else if (action === "notify") {
      showNotification({ ...remain });
    } else if (action === "confirm") {
      showConfirm({
        ...remain,
        okCancel: true,
        returnCancel: true,
      }).then((result) => {
        sendMessage({
          isBridge: true,
          action: "confirmCallback",
          result,
          eventId,
        });
      });
    } else if (action === "alert") {
      showConfirm({
        ...remain,
        okCancel: false,
        okText: i18n.global.t("actions.got_it"),
      }).then(() => {});
    } else if (action === "callFunc") {
      emit("called", {
        name: remain.name,
        params: remain.params,
        sameOrigin,
        setResult: (result) => {
          sendMessage({
            isBridge: true,
            action: "callFuncCallback",
            result,
            eventId,
          });
        },
      });
    } else {
      emit("message", { data, sameOrigin });
    }
  };
  return { sendMessage, onMessage };
};
