export default {
  newReport: {
    width: "s",
    okText: "保存",
    cancelText: "取消",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/reportManage/dialog/ReportNewDialog"
      ),
  },
  importReport: {
    width: "xl",
    title: "选择模板",
    okText: "导入选中模板",
    cancelText: "取消",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/reportManage/dialog/ReportImportDialog"
      ),
  },
  attachReport: {
    width: "s",
    title: "导入外部数据",
    okText: false,
    cancelText: false,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/reportManage/dialog/ReportAttachDialog"
      ),
  },
  distributeReport: {
    width: "s",
    title: "选择全宗",
    okText: "确定选择",
    cancelText: "取消选择",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/reportManage/dialog/ReportDistributeDialog"
      ),
  },
};
