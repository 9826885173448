import { createEnum } from "@/mods/common/enum";

// 目录树节点类型

export const CATALOG_TREE_NODE_TYPE = createEnum(
  {
    CATALOG: { CODE: 1, NAME: "目录节点" },
    FOND: { CODE: -2, NAME: "全宗节点" },
    KIND: { CODE: 2, NAME: "门类节点" },
    OBJECT: { CODE: 3, NAME: "对象节点" },
    DATA: { CODE: 4, NAME: "数据节点" },
    GROUP: { CODE: 5, NAME: "分组节点" },
    UNDERGROUP: { CODE: -1, NAME: "分组下的归类节点" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);
