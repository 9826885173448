<template>
  <div
    ref="holder"
    class="attach-view"
    :class="{
      'attach-view-fullscreen': isFullscreen,
      'attach-view-with-header': showHeader,
      'attach-view-dark': theme === 'dark',
      'attach-view-light': theme === 'light',
    }"
  >
    <template v-if="file && Object.keys(file).length > 0">
      <div v-if="showHeader" class="attach-view-header">
        <div class="attach-view-title">
          {{ fileTitle }}
        </div>
        <div class="attach-view-tool">
          <div v-if="!isFullscreen && !showMessage" class="attach-view-tools">
            <template v-if="tools?.copy">
              <act-button
                v-if="customEvents?.download"
                type="text"
                :disabled="!permissions?.copy"
                :debounce="actionDebounce"
                @action="onCopy"
              >
                <template #icon><copy-outlined /></template>
                {{ $t("actions.copy") }}
              </act-button>
              <copy-button v-else :text="getText">
                <act-button
                  type="text"
                  :disabled="!permissions?.copy"
                  :debounce="actionDebounce"
                >
                  <template #icon><copy-outlined /></template>
                  {{ $t("actions.copy") }}
                </act-button>
              </copy-button>
            </template>
            <act-button
              v-if="tools?.excerpt"
              type="text"
              :disabled="!permissions?.excerpt"
              :debounce="actionDebounce"
              @action="onExcerpt"
            >
              <template #icon><form-outlined /></template>
              {{ $t("actions.excerpt") }}
            </act-button>
            <act-button
              v-if="tools?.download"
              type="text"
              :disabled="!permissions?.download"
              :debounce="actionDebounce"
              @action="onDownload"
            >
              <template #icon><download-outlined /></template>
              {{ $t("actions.download") }}
            </act-button>
            <act-button
              v-if="tools?.print"
              type="text"
              :disabled="!permissions?.print"
              :debounce="actionDebounce"
              @action="onPrint"
            >
              <template #icon><printer-outlined /></template>
              {{ $t("actions.print") }}
            </act-button>
          </div>
          <div class="attach-view-op" :title="fullscreenTitle" @click="toggle">
            <fullscreen-exit-outlined v-if="isFullscreen" />
            <fullscreen-outlined v-else />
          </div>
        </div>
      </div>
      <div
        v-if="showMessage"
        class="attach-view-holder-hint"
        :class="{ 'attach-view-light-force': !!$slots.message }"
      >
        <template v-if="$slots.message">
          <slot name="message"></slot>
        </template>
        <template v-else>
          {{ message }}
        </template>
      </div>
      <component
        v-else-if="comp"
        :is="comp"
        ref="viewerRef"
        :url="fileUrl"
        class="attach-view-holder"
        :title="fileTitle"
      ></component>
      <div
        v-else
        class="attach-view-holder-hint"
        :class="{ 'attach-view-light-force': !!$slots.noSupport }"
      >
        <template v-if="$slots.noSupport">
          <slot name="noSupport"></slot>
        </template>
        <template v-else>
          {{ noSupportText || $t("hint.file.type_not_support") }}
        </template>
      </div>
    </template>
    <img-empty-tips type="no_file" v-else />
  </div>
</template>

<script>
import { computed, ref, toRefs, unref } from "vue";
import { useFullscreen } from "@/components/common/shared/uiInternal";
import { DEFAULT_ACTION_DEBOUNCE, HTTP_METHODS } from "@/conf/constants";
import { i18n } from "@/conf/lang";
import { detectFileType, getFileUrl, httpDownload } from "@/util/fileUtil";
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  FormOutlined,
  DownloadOutlined,
  PrinterOutlined,
  CopyOutlined,
} from "@ant-design/icons-vue";
import AudioViewer from "@/components/common/file/AudioViewer";
import ImgEmptyTips from "@/components/common/display/ImgEmptyTips";
import PdfViewer from "@/components/common/file/PdfViewer";
import PictureViewer from "@/components/common/file/PictureViewer";
import VideoViewer from "@/components/common/file/VideoViewer";
import ActButton from "@/components/common/action/ActButton";
import CopyButton from "@/components/common/action/CopyButton";
import OfdViewer from "@/components/common/file/OfdViewer";

const VIEWER_MAP = {
  image: PictureViewer,
  audio: AudioViewer,
  video: VideoViewer,
  formatted: PdfViewer,
  pdf: PdfViewer,
  ofd: OfdViewer,
};

const THEME = { dark: "dark", light: "light" };

const $t = (k, a) => i18n.global.t(k, a);

export default {
  name: "AttachView",
  props: {
    file: { type: Object },
    viewer: { type: [String, Function] },
    showHeader: { type: Boolean, default: true },
    showMessage: { type: Boolean },
    message: { type: String },
    theme: { type: String, default: "dark", validator: (v) => v in THEME },
    noSupportText: { type: String },
    tools: { type: Object, default: () => ({}) },
    permissions: { type: Object, default: () => ({}) },
    customEvents: { type: Object, default: () => ({}) },
  },
  emits: ["excerpt", "download", "print", "copy"],
  components: {
    CopyButton,
    ActButton,
    AudioViewer,
    ImgEmptyTips,
    PdfViewer,
    PictureViewer,
    VideoViewer,
    FullscreenOutlined,
    FullscreenExitOutlined,
    FormOutlined,
    DownloadOutlined,
    PrinterOutlined,
    CopyOutlined,
  },
  setup(props, { emit, expose }) {
    const { file, viewer, customEvents } = toRefs(props);
    const holder = ref(null);
    const viewerRef = ref(null);
    const actionDebounce = DEFAULT_ACTION_DEBOUNCE;
    const { toggle, isFullscreen } = useFullscreen(holder);
    const fullscreenTitle = computed(() => {
      return isFullscreen.value
        ? $t("actions.exit_fullscreen")
        : $t("actions.fullscreen");
    });
    const fileTitle = computed(() => {
      const f = unref(file);
      const untitled = $t("hint.untitled");
      return `${f.name || untitled}.${f.ext}`;
    });
    const fileUrl = computed(() => {
      const f = unref(file);
      return getFileUrl(f.previewUrl || f.url, {});
    });
    const fileType = computed(() => detectFileType(file.value.ext));
    const currentViewer = computed(() => {
      const v = viewer.value;
      if (typeof v === "function") {
        return v({ ...fileType.value });
      }
      return v || fileType.value.group;
    });
    const comp = computed(() => VIEWER_MAP[currentViewer.value]);
    const canGetText = computed(() =>
      ["pdf", "formatted"].includes(currentViewer.value)
    );
    const onExcerpt = () => {
      if (canGetText.value) {
        viewerRef.value?.getSelection?.().then((text) => {
          emit("excerpt", text);
        });
      }
    };
    const getText = () => {
      if (canGetText.value) {
        return viewerRef.value?.getSelection?.();
      } else {
        return "";
      }
    };
    const onCopy = () => {
      if (canGetText.value) {
        viewerRef.value?.getSelection?.().then((text) => {
          emit("copy", text);
        });
      }
    };
    const onDownload = () => {
      const f = file.value;
      if (customEvents.value?.download === true) {
        emit("download", f);
      } else {
        httpDownload(
          f.downloadUrl || f.url,
          {},
          fileTitle.value,
          HTTP_METHODS.GET
        );
      }
    };
    const onPrint = () => {
      const f = file.value;
      if (customEvents.value?.print === true) {
        emit("print", f);
      } else {
        const url = getFileUrl(f.printUrl || f.url, {});
        if (viewerRef.value?.doPrint) {
          viewerRef.value?.doPrint();
        } else {
          window.open(url, "_blank");
        }
      }
    };
    const doPrint = () => {
      viewerRef.value?.doPrint?.();
    };
    expose({ doPrint, getText });
    return {
      holder,
      comp,
      viewerRef,
      isFullscreen,
      fileUrl,
      fileTitle,
      fullscreenTitle,
      actionDebounce,
      toggle,
      getText,
      onCopy,
      onExcerpt,
      onDownload,
      onPrint,
    };
  },
};
</script>

<style scoped>
.attach-view {
  display: flex;
  flex-direction: column;
  padding: 0;
  background: white;
}
.attach-view-dark {
  --rmx-attach-background: #343843;
  --rmx-attach-font: white;
}
.attach-view-light-force,
.attach-view-light {
  --rmx-attach-background: var(--rmx-content-color);
  --rmx-attach-font: var(--rmx-text-2);
}
.attach-view.attach-view-with-header {
  padding: 16px 24px 24px;
}
.attach-view-fullscreen {
  background: var(--rmx-attach-background);
}
.attach-view-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  height: 32px;
}
.attach-view-title {
  font-size: 16px;
  color: var(--rmx-text-1);
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.attach-view-fullscreen .attach-view-title {
  color: white;
}
.attach-view-light.attach-view-fullscreen .attach-view-title {
  color: var(--rmx-text-1);
}
.attach-view-tool {
  display: flex;
  align-items: center;
  gap: 16px;
}
.attach-view-op {
  color: var(--rmx-icon-1);
  cursor: pointer;
  font-size: 16px;
}
.attach-view-fullscreen .attach-view-op {
  color: white;
}
.attach-view-light.attach-view-fullscreen .attach-view-op {
  color: var(--rmx-icon-1);
}
.attach-view-holder {
  flex: 1;
  max-height: 100%;
}
.attach-view-with-header .attach-view-holder {
  max-height: calc(100% - 40px);
}
.attach-view-with-header.attach-view-fullscreen .attach-view-holder {
  max-height: calc(100vh - 80px);
}
.attach-view-holder-hint {
  display: flex;
  flex: 1;
  background: var(--rmx-attach-background);
  color: var(--rmx-attach-font);
  font-size: 16px;
  justify-content: center;
  align-items: center;
}
</style>
