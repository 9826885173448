import { computed, unref } from "vue";
import { NAMED_COLORS, UI_DIRECTION } from "@/conf/constants";
import { themeColors } from "@/util/colorUtil";

const TAG_PREFIX = "--rmx-tag-";
export function useTagStyle(color, outline, fill, closable) {
  return computed(() => {
    let c = color.value;
    if (c && c !== "default") {
      if (NAMED_COLORS[c]) c = NAMED_COLORS[c];
      const cs = themeColors(c);
      const colors = { "--rmx-tag-color-main": cs[5] };
      if (!outline.value && !fill.value) {
        colors[`${TAG_PREFIX}border`] = cs[2];
        colors[`${TAG_PREFIX}background`] = cs[0];
      }
      if (outline.value && closable.value) {
        colors[`${TAG_PREFIX}color-4`] = cs[4];
        colors[`${TAG_PREFIX}color-6`] = cs[6];
      }
      return colors;
    }
    return {};
  });
}

const SV_PREFIX = "--space-view-";
export function useSpaceViewStyle(props) {
  return computed(() => {
    const s = {};
    const cp = unref(props.contentPadding);
    if (cp) s[`${SV_PREFIX}padding`] = cp;
    const gap = unref(props.gap);
    if (gap) s[`${SV_PREFIX}gap`] = gap;
    const column = unref(props.column);
    const row = unref(props.row);
    if (unref(props.grid)) {
      const cw = unref(props.columnWidths) || [];
      if (cw.length > 0) {
        const arr = [];
        const len = column || cw.length;
        const ml = Math.min(len, cw.length);
        for (let i = 0; i < len; i++) {
          const it = cw[i % ml];
          if (typeof it === "number") {
            arr.push(`${it}fr`);
          } else {
            arr.push(it);
          }
        }
        s[`${SV_PREFIX}column-temp`] = arr.join(" ");
      } else {
        if (column) s[`${SV_PREFIX}column`] = column;
        if (unref(props.equalParts)) s[`${SV_PREFIX}part-size`] = "1fr";
      }
      if (row) s[`${SV_PREFIX}row`] = row;
    } else {
      const direction = unref(props.direction);
      if (direction === UI_DIRECTION.vertical) {
        s[`${SV_PREFIX}direction`] = "column";
      } else if (direction === UI_DIRECTION.horizontal) {
        s[`${SV_PREFIX}direction`] = "row";
      }
    }
    return s;
  });
}
