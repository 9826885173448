<template>
  <div class="error-page-view-container">
    <div class="error-page-view-content">
      <div class="error-page-view-detail">
        <div class="error-page-view-title">{{ code }}</div>
        <div class="error-page-view-subtitle">
          {{ $t(`errors.${code}.title`) }}
        </div>
        <div class="error-page-view-desc">{{ $t(`errors.${code}.desc`) }}</div>
        <div class="error-page-view-action">
          <a-button type="primary" class="rmx-btn-large" @click="goIndex">
            {{ $t("actions.back_to_homepage") }}
          </a-button>
          <a-button class="rmx-btn-large rmx-btn-secondly" @click="goPrev">
            {{ $t("actions.back_to_last") }}
          </a-button>
        </div>
      </div>
      <img class="error-page-view-img" :src="img" :alt="code" />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { computed, toRefs } from "vue";

const IMG = {
  404: require("@/assets/common/404.png"),
  403: require("@/assets/common/403.png"),
};

export default {
  name: "ErrorPageView",
  props: {
    code: { type: String },
  },
  setup(props) {
    const { code } = toRefs(props);
    const router = useRouter();
    const img = computed(() => IMG[code.value]);
    const goIndex = () => {
      router.push("/");
    };
    const goPrev = () => {
      router.back();
    };
    return { img, goIndex, goPrev };
  },
};
</script>

<style scoped>
.error-page-view-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: #fff;
}
.error-page-view-content {
  display: flex;
  justify-content: space-between;
  width: 1100px;
  height: 600px;
  margin-top: 120px;
}
.error-page-view-detail {
  padding-top: 140px;
}
.error-page-view-title {
  font-weight: bold;
  font-size: 60px;
  color: var(--rmx-primary-color);
  line-height: 1;
  margin-bottom: 40px;
}
.error-page-view-subtitle {
  font-size: 32px;
  color: var(--rmx-text-1);
  line-height: 1.3;
  margin-bottom: 24px;
}
.error-page-view-desc {
  font-size: 18px;
  color: var(--rmx-text-2);
  line-height: 1.3;
  margin-bottom: 40px;
}
.error-page-view-action {
  display: flex;
  gap: 16px;
}
.error-page-view-img {
  width: 600px;
  height: 600px;
}
</style>
