export default {
  autoImputationSetting: {
    width: "l",
    title: "自动归集设置",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/topicLibrary/dialog/AutoImputationSetting.vue"
      ),
  },
  subjectDirectoryNode: {
    width: "s",
    title: "",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/topicLibrary/dialog/SubjectDirectoryNode.vue"
      ),
  },
  topicOutlineNode: {
    width: "s",
    title: "",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/topicLibrary/dialog/TopicOutlineNode.vue"
      ),
  },
  selectTopicDirectory: {
    width: "s",
    title: "变更上级",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/topicLibrary/dialog/SelectTopicDirectory.vue"
      ),
  },
  selectTopicOutlineNode: {
    width: "s",
    title: "变更上级",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/topicLibrary/dialog/SelectTopicOutlineNode.vue"
      ),
  },
  subjectDirectoryInfo: {
    width: "s",
    title: "添加专题",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/topicLibrary/dialog/SubjectDirectoryInfo.vue"
      ),
  },
  editAuthType: {
    width: "s",
    title: "权限类型",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/topicLibrary/dialog/EditAuthType"
      ),
  },
};
