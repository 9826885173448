<template>
  <a-tag
    class="colored-tag"
    :class="{
      'colored-tag-default': !useOuterStyle,
      'colored-tag-fill': fill,
      'colored-tag-outline': outline,
      'colored-tag-round': round,
      'colored-tag-rect': rect,
      'colored-tag-disabled': disabled,
    }"
    :style="[$attrs.style, tagStyle]"
    :closable="closable"
  >
    <template v-if="$slots.icon" #icon>
      <slot name="icon"></slot>
    </template>
    <slot></slot>
  </a-tag>
</template>

<script>
import { toRefs } from "vue";
import { useTagStyle } from "@/components/common/shared/styleInternal";

export default {
  name: "ColoredTag",
  props: {
    color: { type: String },
    outline: { type: Boolean },
    fill: { type: Boolean },
    round: { type: Boolean },
    rect: { type: Boolean },
    closable: { type: Boolean },
    disabled: { type: Boolean },
    useOuterStyle: { type: Boolean },
  },
  setup(props) {
    const { color, outline, fill, closable } = toRefs(props);
    const tagStyle = useTagStyle(color, outline, fill, closable);
    return { tagStyle };
  },
};
</script>

<style scoped>
.colored-tag {
  border-color: var(--rmx-tag-border);
  background: var(--rmx-tag-background);
  color: var(--rmx-tag-color-main);
}
.colored-tag.colored-tag-disabled {
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #c7c7c7;
  color: #b0b0b0;
}
.colored-tag-default {
  --rmx-tag-color-main: var(--rmx-text-2);
  --rmx-tag-border: #d9d9d9;
  --rmx-tag-color-4: var(--rmx-text-2);
  --rmx-tag-color-6: var(--rmx-text-3);
  --rmx-tag-background: #fafafa;
}
.colored-tag-outline {
  border-color: var(--rmx-tag-color-main);
  background: white;
}
.colored-tag-outline.colored-tag-disabled {
  border-color: #b0b0b0;
  background: white;
  color: #b0b0b0;
}
.colored-tag-fill {
  color: white;
  border-color: var(--rmx-tag-color-main);
  background: var(--rmx-tag-color-main);
}
.colored-tag-fill.colored-tag-disabled {
  color: white;
  border-color: #b0b0b0;
  background: #b0b0b0;
}
.colored-tag-round {
  border-radius: 16px;
  padding: 0 10px;
}
.colored-tag-rect {
  border-radius: 0;
}
.colored-tag-fill :deep(.ant-tag-close-icon) {
  color: rgba(255, 255, 255, 0.85);
}
.colored-tag-fill :deep(.ant-tag-close-icon:hover) {
  color: rgba(255, 255, 255, 0.45);
}
.colored-tag-outline :deep(.ant-tag-close-icon) {
  color: var(--rmx-tag-color-4);
}
.colored-tag-outline :deep(.ant-tag-close-icon:hover) {
  color: var(--rmx-tag-color-6);
}
</style>
