import annualReportStatistic from "@/mods/statistic/dialog/annualReportStatistic";
import roomStoreStatistic from "@/mods/statistic/dialog/roomStoreStatistic";
import archiveStatistic from "@/mods/statistic/dialog/archiveStatistic";
import usingStatistic from "@/mods/statistic/dialog/usingStatistic";
export default {
  annualReportStatistic,
  roomStoreStatistic,
  archiveStatistic,
  usingStatistic,
};
