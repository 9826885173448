<template>
  <div class="filter-view">
    <popover-view v-if="isShrink" placement="bottomRight" trigger="click">
      <template #content>
        <field-render
          class="filter-view-popup-form"
          :fields="fields"
          v-model:value="realValue"
          use-grid
          :default-span="12"
          auto-placeholder
        />
      </template>
      <slot></slot>
    </popover-view>
    <template v-else>
      <template v-for="field in realFields" :key="field.column">
        <form-input
          v-bind="field"
          v-model:value="realValue[field.column]"
          @change="onChange($event, field)"
        ></form-input>
      </template>
    </template>
  </div>
</template>

<script>
import { computed, toRefs, watch } from "vue";
import { useCachedProps } from "@/components/common/shared/compInternal";
import { throttleFunc } from "@/util/uiUtil";
import FormInput from "@/components/common/form/FormInput";
import PopoverView from "@/components/common/action/PopoverView";
import FieldRender from "@/components/common/form/FieldRender";

let counter = 0;

const VALID_FIELD = [
  "type",
  "compat",
  "disabled",
  "readonly",
  "size",
  "mode",
  "range",
  "maxlength",
  "input",
  "text",
  "raw",
  "optionsIcon",
  "multiple",
  "checkStrictly",
  "mix",
  "max",
  "step",
];

export default {
  name: "FilterView",
  components: { FieldRender, PopoverView, FormInput },
  props: {
    name: { type: String },
    fields: { type: Array, default: () => [] },
    size: { type: String },
    value: { type: Object },
    isShrink: { type: Boolean },
  },
  emits: ["filter"],
  setup(props, { emit, expose }) {
    const propRefs = toRefs(props);
    const { name, fields, value } = propRefs;
    const { makeRefProp } = useCachedProps(propRefs, { emit });

    const cachedName = `fv_${++counter}`;
    const baseName = computed(() => name.value || cachedName);
    const realFields = computed(() =>
      fields.value.map((x) => {
        const width = x.type === "select" ? x.width || "150px" : x.width;
        const f = {
          column: x.column,
          name: `${baseName.value}_${x.column}`,
          placeholder: x.label,
          clearable: x.clearable === undefined ? true : x.clearable,
          width,
          options: x.from,
          maxCount: -1,
        };
        VALID_FIELD.forEach((n) => {
          f[n] = x[n];
        });
        return f;
      })
    );
    const realValue = makeRefProp("value", {});

    const onChange = throttleFunc((value, field) => {
      emit("filter", {
        changed: { column: field.column, value },
        value: realValue.value,
      });
    }, 1000);

    const init = () => {
      fields.value.forEach((field) => {
        if (
          realValue.value[field.column] === undefined &&
          field.initialValue !== undefined
        ) {
          realValue.value[field.column] = field.initialValue;
        }
      });
    };
    const reset = () => {
      realValue.value = {};
      init();
    };
    watch(
      () => value.value,
      () => {
        init();
      },
      { immediate: true }
    );

    expose({ reset });
    return { baseName, realFields, realValue, onChange };
  },
};
</script>

<style scoped>
.filter-view {
  display: flex;
  align-items: center;
  gap: 8px;
}
.filter-view-popup-form {
  width: 500px;
}
</style>
