export default {
  searchDetail: {
    title: "检索结果",
    width: 1600,
    footer: false,
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ "@/mods/workbench/personal/dialog/SearchDetail"
      ),
  },
  downLoadAttach: {
    title: "下载附件",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ "@/mods/workbench/personal/dialog/EleAttachDownLoad"
      ),
  },
  editAttach: {
    title: "编辑附件信息",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ "@/mods/workbench/personal/dialog/EleAttachEdit"
      ),
  },
  showAttachMetaData: {
    title: "元数据",
    destroyOnClose: true,
    width: "l",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "workbench_dialog" */ "@/mods/common/eleFile/EleFileMetaData"
      ),
  },
  editModels: {
    title: "编辑工作台",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ "@/mods/workbench/personal/dialog/EditModels"
      ),
  },
  editShortCuts: {
    title: "编辑快捷方式",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ "@/mods/workbench/personal/dialog/EditCuts"
      ),
  },
  moreNotificationList: {
    title: "栏目列表",
    width: "xl",
    footer: false,
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ "@/mods/workbench/personal/dialog/MoreNotificationList"
      ),
  },
  notificationDetail: {
    title: "信息详情",
    width: "xl",
    footer: false,
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ "@/mods/workbench/personal/dialog/NotificationDetail"
      ),
  },
  editNumber: {
    title: "请输入近期整理的排名位数",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ "@/mods/workbench/personal/dialog/EditCarNumber"
      ),
  },
};
