export default {
  editMetaSchemeDialog: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/relation/EditMetaSchemeDialog"
      ),
  },
};
