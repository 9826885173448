export default {
  addApplyOrder: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "dialog" */ "@/mods/front/myUtilization/dialog/ApplyOrder"
      ),
  },
};
