import { i18n } from "@/conf/lang";
const $t = (c, arr) => i18n.global.t(c, arr);

export default {
  choose: {
    width: "s",
    title: $t("front.actions.more_options"),
    component: () =>
      import(
        /* webpackChunkName: "portal_dialog" */ "@/mods/front/portal/MoreOptionsDialog"
      ),
  },
};
