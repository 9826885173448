<template>
  <global-provider>
    <router-view />
  </global-provider>
</template>

<style></style>
<script>
import GlobalProvider from "@/components/core/GlobalProvider";
export default {
  components: { GlobalProvider },
};
</script>
