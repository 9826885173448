export default {
  addFonds: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/fondsManage/dialog/AddFonds"
      ),
  },
  manageFillDepDialog: {
    width: "860px",
    okText: "actions.save",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/fondsManage/dialog/ManageFillDepDialog"
      ),
  },
  importFondsDialog: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/fondsManage/dialog/ImportFondsDialog"
      ),
  },
  chooseFondsDialog: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/fondsManage/dialog/ChooseFondsDialog"
      ),
  },

  toMigration: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/fondsManage/dialog/ToMigration"
      ),
  },
  chooseDept: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/fondsManage/dialog/ChooseDept"
      ),
  },

  addFillDep: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/fondsManage/dialog/AddFillDep"
      ),
  },

  initFillDep: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/fondsManage/dialog/InitFillDep"
      ),
  },

  fondsSort: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/fondsManage/dialog/FondsSort"
      ),
  },
};
