let tmpUrl = process.env.VUE_APP_API_URL || window.__SETTING__.API_URL;
if (!tmpUrl.startsWith("http://") && !tmpUrl.startsWith("https://")) {
  if (tmpUrl[0] !== "/") {
    tmpUrl = `/${tmpUrl}`;
  }
  let location = window.location;
  tmpUrl = `${location.origin}${tmpUrl}`;
}
let tmpWsUrl =
  window.__SETTING__.WS_URL ||
  tmpUrl.replace("http://", "ws://").replace("https://", "wss://") + "ws";
let tmpStaticUrl = window.__SETTING__.STATIC_URL || "./";
export const BASE_URL = tmpUrl;
export const BASE_WS_URL = tmpWsUrl;
export const BASE_STATIC_URL = tmpStaticUrl;
export const RSA_PUBLIC_KEY = window.__SETTING__.RSA_PUBLIC_KEY;
export const STORAGE_PREFIX = window.__SETTING__.STORAGE_PREFIX || "e_doc_";
export const IS_IN_DEBUG = process.env.NODE_ENV === "development";
export const DEFAULT_TITLE = "档案管理系统";
export const SUPPORT_LANGUAGE_LIST = Array.isArray(window.__SETTING__.LANGUAGES)
  ? window.__SETTING__.LANGUAGES
  : [{ label: "简体中文", value: "zh-cn" }];
export const ADMIN_USER_NAME = window.__SETTING__.ADMIN_USER_NAME;
export const FILE_CHUNK_SIZE = +window.__SETTING__.CHUNK_SIZE || 5242880;
export const OFD_URL = window.__SETTING__.OFD_URL;
export const OFD_PARAM = window.__SETTING__.OFD_PARAM;
export const DEBUG = window.__SETTING__.DEBUG;
export const ARCHIVE_OPERATION_FLAG = window.__SETTING__.STORAGE.EDIT_AUTH;
