/**
 * 创建枚举类
 * @param definition
 * @example
 */
function createEnum(
  definition,
  field = {
    TYPE: "TYPE",
    NAME: "NAME",
    STATUS: "STATUS",
  }
) {
  const typeToValueMap = {};
  const typeToKeyMap = {};
  const typeToNameMap = {};
  const typeToStatusMap = {};
  // const { TYPE, NAME } = field;
  for (const key of Object.keys(definition)) {
    const TYPE = definition[key][field.TYPE];
    const NAME = definition[key][field.NAME];
    const STATUS = definition[key][field.STATUS];
    typeToKeyMap[TYPE] = key;
    typeToNameMap[TYPE] = NAME;
    typeToStatusMap[TYPE] = STATUS;
    typeToValueMap[key] = definition[key];
  }
  const enumerate = {};
  Object.assign(
    enumerate,
    {
      getKeyFromValue: (value) => typeToKeyMap[value] || "",
      getNameFromValue: (value) => typeToNameMap[value] || "",
      getStatusFromValue: (value) => typeToStatusMap[value] || "",
    },
    typeToValueMap
  );
  Object.defineProperty(enumerate, "getKeyFromValue", {
    enumerable: false,
    writable: false,
  });
  Object.defineProperty(enumerate, "getNameFromValue", {
    enumerable: false,
    writable: false,
  });
  Object.defineProperty(enumerate, "getStatusFromValue", {
    enumerable: false,
    writable: false,
  });
  return enumerate;
}

// 编研管理标识
export const RESEARCH_MANAGE_FLAG = "subject";
// 编研数据管理权限
export const RESEARCH_DATA_MANAGE_AUTH = "subjectmanage";

// 资源标识
export const RESOURCE_FLAG = createEnum({
  PLAN_READY: { TYPE: "planReady", NAME: "计划准备", STATUS: "1" },
  PLAN_APPROVAL: { TYPE: "planApproval", NAME: "计划审批", STATUS: "3" },
  RESEARCH_USURP: { TYPE: "researchUsurp", NAME: "编研总纂", STATUS: "4" },
  JOIN_RESEARCH: { TYPE: "joinResearch", NAME: "参与编研", STATUS: "" },
  ARCHIVE_APPROVAL: {
    TYPE: "archiveApproval",
    NAME: "参考档案审核",
    STATUS: "",
  },
  RESULT_APPROVAL: { TYPE: "resultApproval", NAME: "成果审批", STATUS: "5" },
  RESULT_PUBLISH: { TYPE: "resultPublish", NAME: "已发布", STATUS: "6" },
  INVALID: { TYPE: "invalid", NAME: "已作废", STATUS: "8" },
});

// 权限对应关系
export const AUTH_RELATION = Object.freeze({
  PLAN_MANAGE: [
    RESOURCE_FLAG.PLAN_READY.TYPE,
    RESOURCE_FLAG.PLAN_APPROVAL.TYPE,
  ],
  ARCHIVE_RESEARCH: [
    RESOURCE_FLAG.RESEARCH_USURP.TYPE,
    RESOURCE_FLAG.JOIN_RESEARCH.TYPE,
    RESOURCE_FLAG.ARCHIVE_APPROVAL.TYPE,
  ],
  RESULT_MANAGE: [
    RESOURCE_FLAG.RESULT_APPROVAL.TYPE,
    RESOURCE_FLAG.RESULT_PUBLISH.TYPE,
  ],
  OTHER: [RESOURCE_FLAG.INVALID.TYPE],
});

// 任务状态
export const TASK_STATUS = createEnum({
  DRAFT: { TYPE: 1, NAME: "草稿" },
  TO_EDIT: { TYPE: 2, NAME: "待修改" },
  TASK_APPROVAL: { TYPE: 3, NAME: "任务审批" },
  RESEARCH_PROCESS: { TYPE: 4, NAME: "编研中" },
  RESULT_APPROVAL: { TYPE: 5, NAME: "成果审批" },
  NOT_PUBLISHED: { TYPE: 6, NAME: "未发布" },
  PUBLISHED: { TYPE: 7, NAME: "已发布" },
  INVALID: { TYPE: 8, NAME: "已作废" },
});

// 编研类型
export const RESEARCH_TYPE = Object.freeze([
  { value: 1, label: "专题汇编" },
  { value: 2, label: "编研撰写" },
]);
// 专题汇编
export const TOPIC_COMPILATION = 1;
// 编研撰写
export const RESEARCH_WRITING = 2;
// 编研节点查询条件
export const RESEARCH_NODE_CONDITION = Object.freeze([
  { value: 1, label: "待下发" },
  { value: 2, label: "进行中" },
  { value: 3, label: "已完成" },
]);

// 编研节点状态
export const RESEARCH_NODE_STATUS = createEnum({
  UNSENT: { TYPE: 1, NAME: "待下发" },
  WAITING: { TYPE: 2, NAME: "编研中" },
  FINISH: { TYPE: 3, NAME: "已完成" },
  EDIT: { TYPE: 4, NAME: "待修改" },
  REMOVE: { TYPE: 5, NAME: "已作废" },
});

export const SHI_AUTH = Object.freeze([
  { value: "GK", label: "公开" },
  { value: "SK", label: "受控" },
]);

export const ARCHIVE_TYPE = Object.freeze({
  1: "项目",
  2: "案卷",
  3: "文件",
  4: "盒",
});

export const RESULT_UPLOAD_TYPE = 3; // 成果上传
export const ONLINE_WRITING_TYPE = 2; // 编研撰写
export const AUTO_COMPILATION_FLAG = 1; // 自动归集
export const MANUAL_COMPILATION_FLAG = 4; // 手动归集

export const OBJECT_TYPE_TRANSLATE = createEnum({
  PROJECT: { TYPE: 1, NAME: "项目", STATUS: 1 },
  VOLUME: { TYPE: 2, NAME: "案卷", STATUS: 2 },
  DOCUMENT: { TYPE: 3, NAME: "文件", STATUS: 3 },
  BOX: { TYPE: 4, NAME: "盒", STATUS: 4 },
  UNBOXDOC: { TYPE: -1, NAME: "未装盒文件", STATUS: 3 },
  DISPERSEDOC: { TYPE: -2, NAME: "散文件", STATUS: 3 },
  OUTPROJECTDOC: { TYPE: -3, NAME: "项目外散文件", STATUS: 3 },
  VOLDOC: { TYPE: -4, NAME: "卷内文件删除", STATUS: 3 },
  NOMALVOLDOC: { TYPE: 5, NAME: "卷内文件", STATUS: 3 },
  SINGLEDOC: { TYPE: 6, NAME: "单文件", STATUS: 3 },
  NOMALBOXDOC: { TYPE: 7, NAME: "盒内文件", STATUS: 3 },
});
