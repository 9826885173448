<template>
  <a-modal
    class="rmx-dialog"
    :footer="displayFooter"
    :width="displayWidth"
    :ok-button-props="okBtnProp"
    :cancel-button-props="cancelBtnProp"
    :body-style="{ padding: bodyPadding }"
    :destroy-on-close="destroyOnClose"
    :ok-text="displayOkText"
    :cancel-text="displayCancelText"
    :confirm-loading="loading"
    :mask-closable="false"
    v-model:visible="displayVisible"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <template #modalRender="{ originVNode }">
      <div ref="dialogWrap" class="rmx-dialog-wrap" :style="wrapStyle">
        <component :is="originVNode" />
        <div
          v-if="pending.start"
          class="rmx-dialog-pending"
          :class="{ 'rmx-dialog-pending-mask': pending.mask }"
        >
          <loading-view v-if="pending.mask" />
        </div>
      </div>
    </template>
    <template v-if="hasTitle" #title>
      <div ref="dialogHeader" class="rmx-dialog-drag">
        <slot name="title">
          {{ displayTitle }}
          <div
            v-if="displaySubtitle || $slots.subtitle"
            class="rmx-dialog-subtitle"
          >
            <slot name="subtitle">{{ displaySubtitle }}</slot>
          </div>
        </slot>
      </div>
    </template>
    <template #footer>
      <template v-if="$slots.footer"><slot name="footer"></slot></template>
      <template v-else>
        <div :id="footerId" class="model-footer-extra">
          <slot name="extraFooter"></slot>
        </div>
        <template v-if="showButton">
          <a-button v-bind="cancelBtnProp" @click="onCancelClick">
            {{ displayCancelText || $t("actions.cancel") }}
          </a-button>
          <a-button
            v-bind="okBtnProp"
            type="primary"
            :loading="loading"
            @click="onSubmitClick"
          >
            {{ displayOkText || $t("actions.confirm") }}
          </a-button>
        </template>
      </template>
    </template>
    <slot v-if="$slots.default" :dragging="dragging"></slot>
    <suspense v-else-if="dialog" @resolve="handleResolve">
      <component
        :is="componentDef"
        :key="dialogKey"
        v-bind="$attrs"
        ref="contentRef"
      ></component>
    </suspense>
  </a-modal>
</template>

<script>
import { computed, nextTick, provide, reactive, ref, toRefs, watch } from "vue";
import dialogConf from "@/conf/dialog";
import { i18n } from "@/conf/lang";
import { ASYNC_DIALOG } from "@/conf/symbols";
import { DIALOG_FOOTER_PROVIDER } from "@/components/common/shared/internal";
import { DEFAULT_ACTION_DEBOUNCE, DIALOG_WIDTH } from "@/conf/constants";
import {
  usePendingState,
  useDraggable,
} from "@/components/common/shared/uiInternal";
import { useAsyncCompDef } from "@/components/common/shared/asyncCompInternal";
import { debounceFunc } from "@/util/uiUtil";
import LoadingView from "@/components/common/display/LoadingView";

const cached = {};
const BTN_PROPS = {
  ok: {},
  cancel: {},
};
let dialogCount = 0;
export default {
  name: "AsyncDialog",
  components: { LoadingView },
  props: {
    dialog: { type: [String, Function], default: "" },
    name: { type: String, default: "" },
    draggable: { type: Boolean, default: true },
    title: { type: [String, Function] },
    subtitle: { type: [String, Function] },
    footer: { type: [String, Function] },
    okText: { type: [String, Function] },
    cancelText: { type: [String, Function] },
    visible: { type: Boolean, default: false },
    width: { type: String },
    destroyOnClose: { type: Boolean, default: false },
  },
  emits: ["dialogFinished", "dialogCanceled", "update:visible"],
  setup(props, { emit, slots, expose }) {
    const {
      dialog,
      name,
      visible,
      title,
      subtitle,
      footer,
      width,
      okText,
      cancelText,
      draggable,
      destroyOnClose,
    } = toRefs(props);
    let componentDef, curConfig;
    const dialogHeader = ref(null);
    const dialogWrap = ref(null);
    const { position, dragging } = useDraggable(
      dialogHeader,
      dialogWrap,
      draggable
    );
    const { pending, setPending, pendingUntil } = usePendingState();
    const wrapStyle = computed(() => {
      return { transform: position.value };
    });
    if (slots.default) {
      curConfig = null;
    } else {
      const comp = useAsyncCompDef(dialog.value, cached, dialogConf);
      curConfig = comp.config;
      componentDef = comp.componentDef;
    }
    const refProps = { title, subtitle, footer, width, okText, cancelText };
    const dialogKey = name.value || dialog.value;
    const cachedData = reactive({});
    const btnProps = reactive({ ok: {}, cancel: {} });
    const footerId = "async_dialog_footer_" + ++dialogCount;
    const showButton = ref(true);
    let submitFunc = null;
    const generateCachedProp = (name) =>
      computed(() => {
        if (cachedData[name]) return cachedData[name];
        return refProps[name].value || curConfig?.[name];
      });
    const generateLocaleCachedProp = (name) =>
      computed(() => {
        if (cachedData[name]) return cachedData[name];
        if (refProps[name].value) return refProps[name].value;
        const value = curConfig?.[name];
        if (typeof value === "string") {
          return i18n.global.t(value);
        }
        return value;
      });
    const generateBtnProp = (name) =>
      computed(() => ({ ...btnProps[name], ...BTN_PROPS[name] }));
    const displayTitle = generateLocaleCachedProp("title");
    const displaySubtitle = generateLocaleCachedProp("subtitle");
    const widthValue = generateCachedProp("width");
    const displayWidth = computed(() => {
      const w = widthValue.value;
      return DIALOG_WIDTH[w] ?? w;
    });
    const displayOkText = generateLocaleCachedProp("okText");
    const displayCancelText = generateLocaleCachedProp("cancelText");
    const displayVisible = computed({
      get() {
        return visible.value;
      },
      set(newVal) {
        emit("update:visible", newVal);
      },
    });
    const bodyPadding = ref("0");
    const displayFooter = computed(() => {
      if (cachedData.footer !== undefined) {
        return cachedData.footer;
      }
      return footer.value !== undefined ? footer.value : curConfig?.footer;
    });
    const hasTitle = computed(
      () => !!(displayTitle || slots.title || slots.subtitle || displaySubtitle)
    );
    const loading = ref(false);
    const okBtnProp = generateBtnProp("ok");
    const cancelBtnProp = generateBtnProp("cancel");
    const handler = {
      setLoading: (v) => (loading.value = v === undefined ? true : v),
      setPending: (v) => setPending(v),
      pendingUntil,
      setFinished: (data) => emit("dialogFinished", data),
      close: () => {
        displayVisible.value = false;
        emit("dialogCanceled");
      },
      setVisible: (v) => (displayVisible.value = v),
      setTitle: (title) => (cachedData.title = title),
      setSubtitle: (title) => (cachedData.subtitle = title),
      setFooter: (footer) => (cachedData.footer = footer),
      setOkText: (text) => (cachedData.okText = text),
      setCancelText: (text) => (cachedData.cancelText = text),
      setOkProp: (prop, val) => {
        if (val === undefined) delete btnProps.ok[prop];
        else btnProps.ok[prop] = val;
      },
      setCancelProp: (prop, val) => {
        if (val === undefined) delete btnProps.cancel[prop];
        else btnProps.cancel[prop] = val;
      },
      setSubmitCallback: (callback) => {
        submitFunc = callback;
      },
      setPadding: (v) => {
        if (v === true) {
          bodyPadding.value = "";
        } else if (v === false) {
          bodyPadding.value = "0";
        } else {
          bodyPadding.value = v;
        }
      },
    };
    const footerHandler = {
      id: footerId,
      setButtonVisible: (v) => {
        showButton.value = v;
      },
    };
    const contentRef = ref(null);
    const handleResolve = () => {
      nextTick(() => {
        contentRef.value?.onDialogShowed?.(handler);
      });
    };
    const handleSubmit = () => {
      submitFunc?.(handler);
      contentRef.value?.onDialogSubmit?.(handler);
    };
    const handleCancel = () => {
      const ret = contentRef.value?.onDialogCancel?.(handler);
      if (ret === true) {
        displayVisible.value = true;
        return;
      }
      emit("dialogCanceled");
    };
    const onSubmitClick = debounceFunc(
      () => handleSubmit(),
      DEFAULT_ACTION_DEBOUNCE,
      true
    );
    const onCancelClick = debounceFunc(
      () => {
        displayVisible.value = false;
        handleCancel();
      },
      DEFAULT_ACTION_DEBOUNCE,
      true
    );
    watch(
      () => visible.value,
      (v) => {
        if (v && !destroyOnClose.value) {
          nextTick(() => {
            contentRef.value?.onDialogShowed?.(handler);
          });
        }
      }
    );
    if (!slots.default) {
      provide(ASYNC_DIALOG, handler);
      provide(DIALOG_FOOTER_PROVIDER, footerHandler);
    }
    expose({ handler });
    return {
      dialogWrap,
      dialogHeader,
      showButton,
      footerId,
      componentDef,
      dialogKey,
      displayVisible,
      displayTitle,
      displaySubtitle,
      displayFooter,
      hasTitle,
      displayWidth,
      loading,
      pending,
      displayOkText,
      displayCancelText,
      handler,
      contentRef,
      okBtnProp,
      cancelBtnProp,
      wrapStyle,
      dragging,
      bodyPadding,
      handleResolve,
      handleSubmit,
      onSubmitClick,
      handleCancel,
      onCancelClick,
    };
  },
};
</script>

<style scoped></style>
