export default {
  receiveOfflinePackageImport: {
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/ReceiveOfflinePackageImport.vue"
      ),
  },
  receiveOfflinePackageSolutionImport: {
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/ReceiveOfflinePackageSolutionImport.vue"
      ),
  },
  receiveOfflinePackageSolutionHandler: {
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/ReceiveOfflinePackageSolutionHandler.vue"
      ),
  },
  receiveOfflineArticleSolutionHandler: {
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/ReceiveOfflineArticleSolutionHandler.vue"
      ),
  },
  packageSolutionMetadataRuleHandler: {
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/PackageSolutionMetadataRuleHandler.vue"
      ),
  },
  packageSolutionElectronRuleHandler: {
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/PackageSolutionElectronRuleHandler.vue"
      ),
  },
  receiveOfflineArticleImport: {
    okText: "创建批次",
    cancelText: "关闭",
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/ReceiveOfflineArticleImport.vue"
      ),
  },
  receiveOfflineCatchInfo: {
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/ReceiveOfflineCatchInfo.vue"
      ),
  },
  batchDetailArchiveIntoLib: {
    width: "l",
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/BatchDetailArchiveIntoLib.vue"
      ),
  },
  archiveIntoLibFailDetail: {
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/ArchiveIntoLibFailDetail.vue"
      ),
  },
  editMiddleTableData: {
    width: "m",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/EditMiddleTableData.vue"
      ),
  },
  batchDetailHandleConflict: {
    width: "m",
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/BatchDetailHandleConflict.vue"
      ),
  },
  batchDetailReasonDetail: {
    width: "s",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/BatchDetailReasonDetail.vue"
      ),
  },
  batchDetailMarkReturn: {
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "archive_ReceiveOfflineArchive" */ "@/mods/archive/receiveOfflineArchive/dialog/BatchDetailMarkReturn.vue"
      ),
  },
  batchEleFile: {
    title: "批量挂接电子文件",
    width: "xl",
    destroyOnClose: true,
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/receiveOfflineArchive/dialog/BatchEleFile"
      ),
  },
  reUploadFile: {
    title: "重新上传",
    width: "s",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/receiveOfflineArchive/dialog/ReceiveOfflineReUploadFile"
      ),
  },
};
