export default [
  {
    path: "/upgrade",
    name: "Upgrade",
    meta: {
      requireAuth: false,
    },
    children: [
      {
        path: "system",
        meta: {
          title: "系统升级",
        },
        component: () =>
          import(
            /* webpackChunkName: "upgrade" */ "@/mods/upgrade/systemUpgrade/SystemUpgrade.vue"
          ),
      },
    ],
  },
];
