import { createI18n } from "vue-i18n";
import { SUPPORT_LANGUAGE_LIST } from "@/conf/index";

export const DEFAULT_LANGUAGE = "zh-cn";
export const SUPPORT_LANGUAGES = SUPPORT_LANGUAGE_LIST.map((x) => x.value);
export const LANGUAGE_MODS = Object.freeze(["demo", "front"]);

export const i18n = createI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});

const loadedLanguageMods = [];
export const DEBUG_LANG_KEY = "debug";

function setLanguage(lang) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = lang;
  } else {
    i18n.locale = lang;
  }
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

function langKey(lang) {
  let key = lang;
  if (!SUPPORT_LANGUAGES.includes(lang)) {
    const arr = lang.split("-");
    if (SUPPORT_LANGUAGES.includes(arr[0])) {
      key = arr[0];
    } else if (lang === DEBUG_LANG_KEY) {
      key = DEBUG_LANG_KEY;
    } else {
      key = DEFAULT_LANGUAGE;
    }
  }
  return key;
}

function loadOrFallback(lang, sub = "") {
  return new Promise((resolve) => {
    if (lang === DEBUG_LANG_KEY) {
      resolve({});
      return;
    }
    const target = sub ? `${lang}/${sub}` : `${lang}/index`;
    fetch(`./lang/${target}.json`)
      .then((r) => r.json())
      .then((list) => {
        resolve({ list });
      })
      .catch(() => {
        const fallback = sub
          ? `${DEFAULT_LANGUAGE}/${sub}`
          : `${DEFAULT_LANGUAGE}/index`;
        fetch(`./lang/${fallback}.json`)
          .then((r) => r.json())
          .then((list) => {
            resolve({ list, real: DEFAULT_LANGUAGE });
          })
          .catch(() => {
            resolve({ real: DEFAULT_LANGUAGE });
          });
      });
  });
}

function loadAndSet(lang, mod, lazy) {
  const langMod = mod ? `${lang}/${mod}` : lang;
  if (loadedLanguageMods.includes(langMod)) {
    if (i18n.locale !== lang && !lazy) setLanguage(lang);
    return Promise.resolve();
  }
  return new Promise((resolve) => {
    loadOrFallback(lang, mod).then(({ list, real }) => {
      loadedLanguageMods.push(langMod);
      if (real) {
        loadedLanguageMods.push(real);
        if (mod) {
          if (list) {
            const val = list[mod] ? list : { [mod]: list };
            i18n.global.mergeLocaleMessage(real, val);
          }
        } else {
          i18n.global.setLocaleMessage(lang, {});
          i18n.global.setLocaleMessage(real, list);
        }
      } else {
        if (mod) {
          if (list) {
            const val = list[mod] ? list : { [mod]: list };
            i18n.global.mergeLocaleMessage(lang, val);
          }
        } else {
          i18n.global.setLocaleMessage(lang, list);
        }
      }
      if (!lazy) setLanguage(lang);
      resolve();
    });
  });
}

export function loadLanguageAsync(lang, mod) {
  const loadingLang = langKey(lang);
  const module = LANGUAGE_MODS.includes(mod) ? mod : "";
  if (!module) {
    return loadAndSet(loadingLang, "", true).then(() =>
      loadAndSet(loadingLang, "front")
    );
  }
  if (!loadedLanguageMods.includes(loadingLang)) {
    return loadAndSet(loadingLang, "", true)
      .then(() => loadAndSet(loadingLang, "front", true))
      .then(() => loadAndSet(loadingLang, mod));
  } else {
    return loadAndSet(lang, mod);
  }
}
