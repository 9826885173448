export default {
  codeDefineDictEdit: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/codeDefine/dialog/CodeDefineDictEdit"
      ),
  },
  codeDefineDetailEdit: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/codeDefine/dialog/CodeDefineDetailEdit"
      ),
  },
  codeDefineAttribute: {
    width: "m",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/codeDefine/dialog/CodeDefineAttribute"
      ),
  },
  codeDefineAttributeEdit: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/codeDefine/dialog/CodeDefineAttributeEdit"
      ),
  },
  codeDefineSeries: {
    width: "m",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/codeDefine/dialog/CodeDefineSeries"
      ),
  },
  codeDefineSeriesEdit: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/codeDefine/dialog/CodeDefineSeriesEdit"
      ),
  },
};
