<template>
  <div
    class="front-unit-chooser-option"
    :class="{
      'front-unit-chooser-option-show': show,
      'front-unit-chooser-option-current': isCurrent,
    }"
    :title="option.text"
  >
    <div class="front-unit-chooser-option-icon">
      <common-icon v-if="option.type === 2" icon="city-one" />
      <common-icon v-else icon="museum-one" />
    </div>
    <div class="front-unit-chooser-option-text">{{ option.text }}</div>
  </div>
</template>

<script>
import CommonIcon from "@/components/icon/CommonIcon";
export default {
  name: "FrontUnitChooserOption",
  components: { CommonIcon },
  props: {
    option: { type: Object, default: () => ({}) },
    isMine: { type: Boolean },
    show: { type: Boolean },
    isCurrent: { type: Boolean },
  },
};
</script>

<style scoped>
.front-unit-chooser-option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 12px;
  overflow: hidden;
  font-size: 14px;
  --option-icon-size: 16px;
  --option-color: var(--rmx-text-1);
  --option-icon-color: var(--rmx-icon-1);
}
.front-unit-chooser-option-show {
  font-size: 16px;
  --option-icon-size: 18px;
}
.front-unit-chooser-option-current {
  --option-color: var(--rmx-primary-color);
  --option-icon-color: var(--rmx-primary-color);
  background: var(--rmx-primary-color-1);
}
.front-unit-chooser-option-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--option-icon-color);
  font-size: var(--option-icon-size);
  line-height: 1;
}
.front-unit-chooser-option-text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--option-color);
  line-height: 1.5;
}
.front-unit-chooser-option-show .front-unit-chooser-option-text {
  font-weight: bold;
}
</style>
