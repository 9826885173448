import {
  computed,
  getCurrentInstance,
  inject,
  onMounted,
  provide,
  ref,
} from "vue";
import { useCachedProps } from "@/components/common/shared/compInternal";
import { ASYNC_DIALOG } from "@/conf/symbols";
import { debounceFunc } from "@/util/uiUtil";
import { FORM_WIDTH } from "@/conf/constants";

const FORM_GLOBAL = { index: 0 };

export function useAbstractForm(propRefs, context) {
  if (!context) context = getCurrentInstance();
  const { emit } = context;
  const { makeRefProp, makeValProp } = useCachedProps(propRefs, { emit });
  const {
    asyncSubmit,
    attachDialog,
    actionDebounce,
    maxWidth,
    defaultSpan,
    layout,
    showAction,
  } = propRefs;

  const formRef = ref(null);
  const dialog = attachDialog.value ? inject(ASYNC_DIALOG, null) : null;

  const cachedName = `form_render_${++FORM_GLOBAL.index}`;
  const formName = computed(() => name.value || cachedName);
  const formData = makeRefProp("value", {});
  const formLoading = makeValProp("loading", false);

  const formStyle = computed(() => {
    const style = {};
    if (maxWidth.value) {
      style.maxWidth = FORM_WIDTH[maxWidth.value] || maxWidth.value;
    }
    return style;
  });
  const formDefaultSpan = computed(() =>
    defaultSpan.value ? defaultSpan.value : layout.value === "vertical" ? 24 : 6
  );
  const labelStyle = computed(() =>
    layout.value === "vertical" ? {} : { width: "126px", marginRight: "6px" }
  );
  const formShowAction = computed(() => {
    if (dialog !== null) return false;
    return showAction.value;
  });

  const setLoading = (v) => {
    formLoading.value = v;
    if (dialog) dialog.setLoading(v);
  };
  const asyncFinished = (data) => {
    if (dialog !== null) {
      const ad = attachDialog.value;
      dialog.setFinished(data);
      if (ad === true || ad?.autoClose) {
        dialog.setVisible(false);
      }
    }
  };

  const processSubmit = (callback = () => {}) => {
    const f = asyncSubmit.value;
    if (typeof f === "function") {
      setLoading(true);
      f(formData.value)
        .then((data) => {
          callback(data);
          emit("finished", data);
          setLoading(false);
          asyncFinished(data);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      callback(formData.value);
    }
    emit("submit", formData.value);
  };

  const doSubmit = (withReject) => {
    return new Promise((resolve, reject) => {
      formRef.value
        .validateFields()
        .then(() => {
          processSubmit(resolve);
        })
        .catch((err) => {
          if (withReject) {
            reject(err);
          }
          emit("validateFailed", err);
        });
    });
  };
  const doValidate = () => {
    return formRef.value?.validateFields();
  };
  const doReset = () => {
    formData.value = {};
    formRef.value?.resetFields();
  };
  const doClearValidation = (param) => {
    formRef.value.clearValidate(param);
  };

  const onSubmit = debounceFunc(
    () => processSubmit(),
    actionDebounce.value,
    true
  );
  const onAction = (act) => {
    emit("action", { action: act.action });
  };

  onMounted(() => {
    if (dialog !== null) {
      const ad = attachDialog.value;
      dialog.setPadding(ad?.padding ?? true);
      dialog.setSubmitCallback(() => {
        return doSubmit();
      });
    }
  });

  provide(ASYNC_DIALOG, null);

  return {
    formRef,
    formName,
    formData,
    formLoading,
    formStyle,
    formDefaultSpan,
    labelStyle,
    formShowAction,
    doSubmit,
    doValidate,
    doReset,
    doClearValidation,
    onSubmit,
    onAction,
  };
}
