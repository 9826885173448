import RouterHolder from "@/components/core/RouterHolder";

export default {
  path: "message",
  component: RouterHolder,
  children: [
    {
      path: "/front/message",
      redirect: "/front/message/list",
    },
    {
      path: "list",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/messageList/MessageList"
        ),
    },
  ],
};
