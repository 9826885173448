export default {
  archiveTypeUpdateName: {
    width: "s",
    title: "修改门类名称",
    okText: "确认修改",
    cancelText: "我再想想",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/ArchiveTypeUpdateName"
      ),
  },
  archiveTypeInsert: {
    width: "m",
    title: "添加门类",
    okText: "保存",
    cancelText: "取消",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/ArchiveTypeInsert"
      ),
  },
};
