import RouterHolder from "@/components/core/RouterHolder";

export default {
  path: "archives",
  component: RouterHolder,
  children: [
    {
      path: "/front/archives",
      redirect: "/front/archives/arch_detail",
    },
    {
      path: "arch_detail",
      meta: {
        title: "档案详情",
        purePage: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/archDetail/ArchViewer"
        ),
    },
  ],
};
