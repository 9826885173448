<template>
  <div class="front-sub-layout rmx-scrollbar">
    <div v-if="$slots.header" class="front-sub-layout-header rmx-card">
      <slot name="header"></slot>
    </div>
    <div class="front-sub-layout-main">
      <div
        v-if="$slots.left"
        class="front-sub-layout-left rmx-card rmx-scrollbar"
      >
        <slot name="left"></slot>
      </div>
      <div ref="bodyRef" class="front-sub-layout-body">
        <slot></slot>
      </div>
      <div v-if="$slots.right" class="front-sub-layout-right">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useSizeProvider } from "@/components/common/shared/uiInternal";

export default {
  name: "FrontSubLayout",
  props: {
    leftWidth: { type: [Number, String] },
    rightWidth: { type: [Number, String] },
  },
  setup() {
    const bodyRef = ref(null);
    useSizeProvider(bodyRef, "FrontSubLayout");
    return { bodyRef };
  },
};
</script>

<style scoped>
.front-sub-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--rmx-page-gap);
  padding: var(--rmx-page-gap);
  overflow: auto;
  --front-sub-left-width: 240px;
  --front-sub-right-width: 240px;
}
.front-sub-layout-header {
  padding: var(--rmx-pad-m);
}
.front-sub-layout-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: var(--rmx-page-gap);
  flex: 1;
  height: 100%;
}
.front-sub-layout-left {
  width: var(--front-sub-left-width);
  height: 100%;
  padding: var(--rmx-pad-m);
  overflow: auto;
}
.front-sub-layout-right {
  width: var(--front-sub-right-width);
}
.front-sub-layout-body {
  flex: 1;
  height: 100%;
}
</style>
