<template>
  <div class="file-uploader" :class="{ 'file-upload-hidden': hide }">
    <div class="clearfix" v-if="!uploadDisabled">
      <a-upload
        class="file-uploader-input"
        :accept="uploadAccept"
        :headers="uploadHeader"
        :action="uploadAct"
        :custom-request="uploadRequest"
        :data="uploadBody"
        :disabled="uploadDisabled || !uploadAble"
        :show-upload-list="false"
        :directory="directory"
        :max-count="max"
        :multiple="multiple"
        v-model:file-list="rawList"
        :before-upload="beforeUpload"
        @change="onUploaderChange"
      >
        <a-button v-if="!hide" :disabled="!uploadAble">
          <upload-outlined></upload-outlined>
          <slot>{{ $t("actions.upload") }}</slot>
        </a-button>
        <div v-else ref="elTrigger"></div>
      </a-upload>
    </div>
    <file-gallery
      v-if="!hide"
      :list="displayList"
      :removable="removable"
      :downloadable="downloadable"
      :previewable="showPreview"
      @remove="onRemove($event)"
    />
    <overlay-view
      v-if="hide"
      :title="$t('actions.upload')"
      :description="uploadProgressText"
      :visible="uploading"
      :progress="uploadProgress"
    />
  </div>
</template>

<script>
import { ref, toRefs } from "vue";
import {
  useUploader,
  useUploadOverlay,
} from "@/components/common/shared/fileCompInternal";
import {
  EMITS_UPLOADER,
  PROPS_UPLOADER,
} from "@/components/common/shared/compAttrs";
import { UploadOutlined } from "@ant-design/icons-vue";
import FileGallery from "@/components/common/display/FileGallery";
import OverlayView from "@/components/common/display/OverlayView";

export default {
  name: "FileUploader",
  components: { OverlayView, FileGallery, UploadOutlined },
  props: {
    ...PROPS_UPLOADER,
    hide: { type: Boolean },
  },
  emits: EMITS_UPLOADER,
  setup(props, { emit, expose }) {
    const propRefs = toRefs(props);
    const {
      uploadAccept,
      uploadAct,
      uploadRequest,
      uploadHeader,
      uploadBody,
      uploadDisabled,
      rawList,
      displayList,
      uploadAble,
      downloadable,
      removable,
      beforeUpload,
      onUploaderChange,
      onRemove,
    } = useUploader("file", propRefs, { emit });
    const { uploading, uploadProgress, uploadProgressText } =
      useUploadOverlay(displayList);
    const elTrigger = ref(null);
    const choose = () => {
      elTrigger?.value?.click?.();
    };
    expose({ choose });
    return {
      elTrigger,
      uploadAccept,
      uploadAct,
      uploadRequest,
      uploadHeader,
      uploadBody,
      uploadDisabled,
      uploadAble,
      downloadable,
      removable,
      rawList,
      displayList,
      uploading,
      uploadProgress,
      uploadProgressText,
      beforeUpload,
      onUploaderChange,
      onRemove,
    };
  },
};
</script>

<style scoped>
.file-upload-hidden {
  display: none;
}
</style>
