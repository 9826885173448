<template>
  <div
    class="base-front-item"
    :class="{
      'base-front-item-check-able': checkAble,
      'base-front-item-has-middle': !!$slots.middle,
      'base-front-item-selected': selected,
      'base-front-item-checked': checked,
      'base-front-item-disabled': disabled,
    }"
  >
    <div v-if="checkAble" class="base-front-item-check">
      <form-input
        type="checkbox"
        :disabled="disabled"
        :value="checked"
        @change="$emit('update:checked', $event)"
      />
    </div>
    <div class="base-front-item-header">
      <div class="base-front-item-header-left">
        <template v-if="$slots.titlePrefix">
          <slot name="titlePrefix" :item="item"></slot>
        </template>
        <div
          v-if="!item.title"
          class="base-front-item-title"
          @click.stop="onTitleClick"
        >
          --
        </div>
        <marked-text
          v-else-if="useMark"
          class="base-front-item-title"
          :text="item.title"
          :title="item.title"
          :marked-words="keywords"
          marked-color="#f5222d"
          ignore-case
          @click.stop="onTitleClick"
        ></marked-text>
        <div
          v-else
          class="base-front-item-title"
          :title="item.title"
          @click.stop="onTitleClick"
        >
          {{ item.title }}
        </div>
        <template v-if="$slots.titleSuffix">
          <slot name="titleSuffix"></slot>
        </template>
      </div>
      <div v-if="$slots.extraHeader" class="base-front-item-header-right">
        <slot name="extraHeader"></slot>
      </div>
    </div>
    <div v-if="$slots.middle" class="base-front-item-middle">
      <slot name="middle"></slot>
    </div>
    <space-view
      class="base-front-item-metas"
      content-padding="0"
      direction="horizontal"
      gap="24px"
      :equal-parts="equalMetaWidth"
    >
      <div
        v-for="f in fields"
        :key="f.field"
        class="base-front-item-meta"
        :title="metaStr(f)"
      >
        {{ metaStr(f) }}
      </div>
    </space-view>
  </div>
</template>

<script>
import SpaceView from "@/components/common/display/SpaceView";
import MarkedText from "@/components/common/display/MarkedText";
import FormInput from "@/components/common/form/FormInput";
import { computed, toRefs } from "vue";
export default {
  name: "BaseFrontItem",
  components: { FormInput, MarkedText, SpaceView },
  props: {
    item: { type: Object },
    fields: { type: Array },
    selected: { type: Boolean },
    checked: { type: Boolean },
    checkAble: { type: Boolean },
    disabled: { type: Boolean },
    keywords: { type: Array },
    equalMetaWidth: { type: Boolean },
  },
  emits: ["choose"],
  setup(props, { emit }) {
    const { item, keywords, disabled } = toRefs(props);
    const onTitleClick = () => {
      if (!disabled.value) emit("choose", {});
    };
    const useMark = computed(() => !!keywords.value?.length);
    const metaStr = (f) => {
      const v = item.value?.[f.field];
      const s = typeof v === "number" ? v || 0 : v || "--";
      return `${f.name} : ${s}`;
    };
    return { useMark, metaStr, onTitleClick };
  },
};
</script>

<style scoped>
.base-front-item {
  display: grid;
  grid-template-areas: "header" "metas";
  grid-template-columns: auto;
  gap: 16px;
}
.base-front-item:not(.base-front-item-disabled):hover {
  background: #f6f7fb;
}
.base-front-item-selected,
.base-front-item-checked {
  background: #f6f7fb;
}
.base-front-item-has-middle {
  grid-template-areas: "header" "middle" "metas";
  grid-template-columns: auto;
}
.base-front-item-check-able {
  grid-template-areas: "check header" ". metas";
  grid-template-columns: 16px 1fr;
}
.base-front-item-has-middle.base-front-item-check-able {
  grid-template-areas: "check header" ". middle" ". metas";
  grid-template-columns: 16px 1fr;
}
.base-front-item-check {
  grid-area: check;
  display: flex;
  align-items: flex-end;
}
.base-front-item-header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.base-front-item-header-left {
  display: flex;
  align-items: center;
}
.base-front-item-title {
  font-weight: bold;
  font-size: 18px;
  color: var(--rmx-text-1);
  line-height: 22px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
  max-width: var(--rmx-front-item-title-width, calc(100vw - 760px));
}
.base-front-item-checked .base-front-item-title,
.base-front-item-title:hover {
  color: var(--rmx-primary-color);
}
.base-front-item-disabled .base-front-item-title {
  cursor: not-allowed;
  color: var(--rmx-text-3);
}
.base-front-item-header-right {
  display: flex;
  align-items: center;
  justify-self: flex-end;
}
.base-front-item-middle {
  grid-area: middle;
}
.base-front-item-metas {
  grid-area: metas;
  margin-bottom: 20px;
}
.base-front-item-meta {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--rmx-text-3);
}
</style>
