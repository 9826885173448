import informationManage from "@/mods/system/dialog/informationManage";
import roleManage from "@/mods/system/dialog/roleManage";
import orgManage from "@/mods/system/dialog/orgManage";
import deptManage from "@/mods/system/dialog/deptManage";
import codeDefine from "@/mods/system/dialog/codeDefine";
import registerManage from "@/mods/system/dialog/registerManage";
import securityManage from "@/mods/system/dialog/securityManage";
import personalFileArea from "@/mods/system/dialog/personalFileArea";
import languageManage from "@/mods/system/dialog/languageManage";
import resourceManage from "@/mods/system/dialog/resourceManage";
import exConfig from "@/mods/system/dialog/exConfig";
import thesaurusManage from "@/mods/system/dialog/thesaurusManage";
import interfaceManage from "@/mods/system/dialog/interfaceManage";
import userManage from "@/mods/system/dialog/userManage";
import dataPackage from "@/mods/system/dialog/dataPackage";
import helpManage from "@/mods/system/dialog/helpManage";
import managementAuthorization from "@/mods/system/dialog/managementAuthorization";
import registerAudit from "@/mods/system/dialog/registerAudit";
import fourDesign from "@/mods/system/dialog/fourDesign";

export default {
  informationManage,
  orgManage,
  deptManage,
  codeDefine,
  registerManage,
  securityManage,
  roleManage,
  personalFileArea,
  languageManage,
  resourceManage,
  exConfig,
  thesaurusManage,
  interfaceManage,
  userManage,
  dataPackage,
  helpManage,
  managementAuthorization,
  registerAudit,
  fourDesign,
};
