<template>
  <photo-gallery
    :list="displayList"
    :removable="removable"
    :downloadable="downloadable"
    @remove="onRemove($event)"
  >
    <div class="uploader-area clearfix" v-if="!uploadDisabled">
      <a-upload
        class="photo-uploader-input"
        :accept="uploadAccept"
        :headers="uploadHeader"
        :action="uploadAct"
        :custom-request="uploadRequest"
        :data="uploadBody"
        :disabled="uploadDisabled || !uploadAble"
        :show-upload-list="false"
        :directory="directory"
        :max-count="max"
        :multiple="multiple"
        v-model:file-list="rawList"
        :before-upload="beforeUpload"
        @change="onUploaderChange"
      >
        <div
          class="photo-uploader-btn"
          role="button"
          :class="{ 'photo-uploader-btn-disabled': !uploadAble }"
        >
          <plus-outlined class="photo-uploader-icon" />
          <div class="photo-uploader-text">
            {{ $t("actions.upload_images") }}
          </div>
        </div>
      </a-upload>
    </div>
  </photo-gallery>
</template>

<script>
import { toRefs } from "vue";
import {
  EMITS_UPLOADER,
  PROPS_UPLOADER,
} from "@/components/common/shared/compAttrs";
import { useUploader } from "@/components/common/shared/fileCompInternal";
import { PlusOutlined } from "@ant-design/icons-vue";
import PhotoGallery from "@/components/common/display/PhotoGallery";

export default {
  name: "PhotoUploader",
  components: {
    PhotoGallery,
    PlusOutlined,
  },
  props: {
    ...PROPS_UPLOADER,
  },
  emits: EMITS_UPLOADER,
  setup(props, { emit }) {
    const propRefs = toRefs(props);
    const {
      uploadAccept,
      uploadAct,
      uploadRequest,
      uploadHeader,
      uploadBody,
      uploadDisabled,
      rawList,
      displayList,
      uploadAble,
      downloadable,
      removable,
      beforeUpload,
      onUploaderChange,
      onRemove,
    } = useUploader("image", propRefs, { emit });
    return {
      uploadAccept,
      uploadAct,
      uploadRequest,
      uploadHeader,
      uploadBody,
      uploadDisabled,
      uploadAble,
      downloadable,
      removable,
      rawList,
      displayList,
      beforeUpload,
      onUploaderChange,
      onRemove,
    };
  },
};
</script>

<style scoped>
.photo-uploader-input {
  margin-bottom: -8px;
}
.photo-uploader-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--rmx-background);
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  border-radius: var(--rmx-border-radius);
  cursor: pointer;
  transition: border-color 0.3s;
}
.photo-uploader-btn:hover {
  border-color: var(--rmx-primary-color);
  color: var(--rmx-primary-color);
}
.photo-uploader-btn.photo-uploader-btn-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.photo-uploader-icon {
  font-size: 22px;
  color: var(--rmx-icon-2);
}
.photo-uploader-btn.photo-uploader-btn-disabled .photo-uploader-icon {
  color: var(--rmx-icon-3);
}
.photo-uploader-text {
  font-size: 14px;
  color: var(--rmx-text-2);
  line-height: 1;
  margin-top: 8px;
  padding: 0 4px;
  text-align: center;
}
.photo-uploader-btn.photo-uploader-btn-disabled .photo-uploader-text {
  color: var(--rmx-text-3);
}
</style>
