<template>
  <base-icon
    :icon="icon"
    :weight="weight"
    :line-cap="lineCap"
    :line-join="lineJoin"
    empty-holder="menu"
  >
    <template v-if="icon === 'abnormal'">
      <path
        d="M40 16.398V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h10"
      ></path>
      <path d="M16 14h13m-13 7h5"></path>
      <circle transform="rotate(90 34 34)" r="10" cy="34" cx="34"></circle>
      <path d="M34 36v3"></path>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="2"
        cy="30"
        cx="34"
      ></circle>
    </template>
    <template v-else-if="icon === 'ad-product'">
      <path d="M44 14 24 4 4 14v20l20 10 20-10V14Z"></path>
      <path d="m4 14 20 10m0 20V24m20-10L24 24M34 9 14 19"></path>
    </template>
    <template v-else-if="icon === 'address-book'">
      <path d="M8 36v8h32V4H8v8M6 30h4m-4-6h4m-4-6h4"></path>
      <circle r="4" cy="17" cx="24"></circle>
      <path d="M32 35a8 8 0 1 0-16 0"></path>
    </template>
    <template v-else-if="icon === 'afferent-three'">
      <path
        d="M19 6H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V29"
      ></path>
      <path d="m18 17 4 12 12-4"></path>
      <path d="M22 29c5-15 8-18 18-22"></path>
    </template>
    <template v-else-if="icon === 'agreement'">
      <rect rx="2" height="40" width="32" y="4" x="8"></rect>
      <path d="M16 4h9v16l-4.5-4-4.5 4V4Z"></path>
      <path d="M16 28h10m-10 6h16"></path>
    </template>
    <template v-else-if="icon === 'all-application'">
      <path
        d="M18 6H8a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Zm0 22H8a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2ZM40 6H30a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Zm0 22H30a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2Z"
      ></path>
    </template>
    <template v-else-if="icon === 'analysis'">
      <path d="M44 5H4v12h40V5Z"></path>
      <path d="m4 41.03 12.176-12.3 6.579 6.3L30.798 27l4.48 4.368"></path>
      <path d="M44 16.172v26m-40-26v14M13.015 43H44M17 11h21m-28-.003h1"></path>
    </template>
    <template v-else-if="icon === 'audit'">
      <path
        d="m8 36 .005-7.957a1 1 0 0 1 1-1h10.002c.922 0 .917-.818.917-2.764 0-1.946-4.902-3.585-4.902-10.426S20.1 5 24.32 5s8.817 2.012 8.817 8.853c0 6.841-4.876 7.929-4.876 10.426s0 2.764.78 2.764h9.96a1 1 0 0 1 1 1V36H8Z"
      ></path>
      <path d="M8 42h32"></path>
    </template>
    <template v-else-if="icon === 'baby-feet'">
      <path
        d="M15 20.612c-1.424 6.15 6.493 7.715 4.624 12.048-1.87 4.332-6.055 3.466-5.588 7.798.468 4.333 6.006 4.394 11.048 1.784 10.083-5.221 12.187-16.625 7.624-21.63-5.608-6.15-16.284-6.15-17.708 0Z"
      ></path>
      <ellipse
        fill="currentColor"
        stroke-width="0"
        transform="rotate(40 34.535 13.535)"
        ry="3"
        rx="2"
        cy="13.535"
        cx="34.535"
      ></ellipse>
      <ellipse
        fill="currentColor"
        stroke-width="0"
        transform="rotate(25 29.38 10.603)"
        ry="3"
        rx="2"
        cy="10.603"
        cx="29.381"
      ></ellipse>
      <ellipse
        fill="currentColor"
        stroke-width="0"
        transform="rotate(6 23.38 9.603)"
        ry="3"
        rx="2"
        cy="9.603"
        cx="23.381"
      ></ellipse>
      <ellipse
        transform="rotate(-20 14 8)"
        ry="4"
        rx="3"
        cy="8"
        cx="14"
      ></ellipse>
      <ellipse
        fill="currentColor"
        stroke-width="0"
        transform="rotate(50 38.535 17.536)"
        ry="3"
        rx="2"
        cy="17.536"
        cx="38.535"
      ></ellipse>
    </template>
    <template v-else-if="icon === 'bill'">
      <path
        d="M10 6a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v38l-7-5-7 5-7-5-7 5V6Zm8 16h12m-12 8h12M18 14h12"
      ></path>
    </template>
    <template v-else-if="icon === 'bloom'">
      <path
        d="m6 32 12 10m24-10L30 42m-6-10v12m-7-33 7-7 7 7 7-1s1 4.239 1 7c0 10-8.5 15-15 15S9 27 9 17c0-2.761 1-7 1-7l7 1Z"
      ></path>
    </template>
    <template v-else-if="icon === 'boiler'">
      <rect rx="2" height="8" width="38" y="6" x="5"></rect>
      <path d="M8 14v26a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V14"></path>
      <path
        d="M31 29.067C31 32.896 27.866 36 24 36s-7-3.104-7-6.933c0-3.83 2.692-5.334 3.77-9.067 4.307 1.867 4.307 7.467 4.307 7.467s1.077-3.2 4.308-4C29.654 26.4 31 27.432 31 29.067Z"
      ></path>
    </template>
    <template v-else-if="icon === 'book-one'">
      <path d="M7 37V11a6 6 0 0 1 6-6h22v26H13c-3.3 0-6 2.684-6 6Z"></path>
      <path d="M35 31H13a6 6 0 0 0 0 12h28V7M14 37h20"></path>
    </template>
    <template v-else-if="icon === 'book-open'">
      <path
        d="M5 7h11a8 8 0 0 1 8 8v27a6 6 0 0 0-6-6H5V7Zm38 0H32a8 8 0 0 0-8 8v27a6 6 0 0 1 6-6h13V7Z"
      ></path>
    </template>
    <template v-else-if="icon === 'bookmark'">
      <path
        d="M10 44a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H10Z"
      ></path>
      <path d="M21 22V4h12v18l-6-6.273L21 22Z" clip-rule="evenodd"></path>
      <path d="M10 4h28"></path>
    </template>
    <template v-else-if="icon === 'bookshelf'">
      <path
        d="M5 6h34s4 2 4 7-4 7-4 7H5s4-2 4-7-4-7-4-7Zm38 22H9s-4 2-4 7 4 7 4 7h34s-4-2-4-7 4-7 4-7Z"
      ></path>
    </template>
    <template v-else-if="icon === 'box'">
      <rect rx="2" height="30" width="36" y="12" x="6"></rect>
      <path d="M17.95 24.008h12M6 13l7-8h22l7 8"></path>
    </template>
    <template v-else-if="icon === 'branch-one'">
      <path
        d="M24 33V15M10 9h28v6H10zM8 32l6-7h19.974L40 32M4 33h8v8H4zm16 0h8v8h-8zm16 0h8v8h-8z"
      ></path>
    </template>
    <template v-else-if="icon === 'building-one'">
      <path d="M11 14 25 4v40H11V14Z" clip-rule="evenodd"></path>
      <path d="m25 13 14 10v21M4 44h40"></path>
    </template>
    <template v-else-if="icon === 'building-three'">
      <path d="m24 8 20 13v23H4V21L24 8Z" clip-rule="evenodd"></path>
      <path d="M20 44V23l-8 5v16m16 0V23l8 5v16m5 0H8"></path>
    </template>
    <template v-else-if="icon === 'camera-one'">
      <circle r="14" cy="19" cx="24"></circle>
      <circle r="6" cy="19" cx="24"></circle>
      <path d="m17 31-6 12h26l-6-12"></path>
    </template>
    <template v-else-if="icon === 'category-management'">
      <rect rx="4" height="14" width="36" y="28" x="6"></rect>
      <path d="M20 7H10a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h10"></path>
      <circle r="8" cy="14" cx="34"></circle>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="3"
        cy="14"
        cx="34"
      ></circle>
    </template>
    <template v-else-if="icon === 'certificate'">
      <path
        d="M26 36H6a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2h-8M12 14h24m-24 7h6m-6 7h4"
      ></path>
      <path d="M30 33a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"></path>
      <path
        d="m30 40 4 2V31.472S32.86 33 30 33c-2.86 0-4-1.5-4-1.5V42l4-2Z"
      ></path>
    </template>
    <template v-else-if="icon === 'chart-graph'">
      <path d="M17 6h14v9H17zM6 33h14v9H6zm22 0h14v9H28z"></path>
      <path d="M24 16v8m-11 9v-9h22v9"></path>
    </template>
    <template v-else-if="icon === 'chart-histogram'">
      <path d="M6 6v36h36M14 30v4m8-12v12m8-28v28m8-20v20"></path>
    </template>
    <template v-else-if="icon === 'chart-line'">
      <path d="M6 6v36h36"></path>
      <path d="m14 34 8-16 10 9L42 6"></path>
    </template>
    <template v-else-if="icon === 'chart-line-area'">
      <path d="M6 6v36h36"></path>
      <path d="m14 34 8-16 10 9L42 6v28H14Z"></path>
    </template>
    <template v-else-if="icon === 'chart-pie-one'">
      <path
        d="M5 24c0 10.493 8.507 19 19 19V26a2 2 0 0 1 2-2h17c0-10.493-8.507-19-19-19S5 13.507 5 24Z"
      ></path>
      <path d="M30 30h12v12H30z"></path>
    </template>
    <template v-else-if="icon === 'checklist'">
      <path
        d="m34 10 8 8m0-8-8 8m10 12-7 8-4-4m-7-24H4v8h22v-8Zm0 20H4v8h22v-8Z"
      ></path>
    </template>
    <template v-else-if="icon === 'circle-three'">
      <path
        d="M32.904 13.927A10 10 0 0 1 24 19.37a10 10 0 0 1-8.904-5.443A14.979 14.979 0 0 0 9 26c0 .818.065 1.62.191 2.402.267-.02.537-.032.809-.032 5.523 0 10 4.477 10 10 0 .697-.071 1.376-.207 2.032 1.335.39 2.747.598 4.207.598s2.872-.209 4.207-.598A10.038 10.038 0 0 1 28 38.37c0-5.523 4.477-10 10-10 .272 0 .542.011.809.032A15.11 15.11 0 0 0 39 26c0-4.95-2.398-9.34-6.096-12.073Z"
      ></path>
      <path
        d="M24 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM9 43a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm30 0a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        clip-rule="evenodd"
      ></path>
    </template>
    <template v-else-if="icon === 'city'">
      <path d="M4 42h40"></path>
      <rect rx="2" height="16" width="8" y="26" x="8"></rect>
      <path d="M12 34h1"></path>
      <rect rx="2" height="38" width="24" y="4" x="16"></rect>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M22 10h4v4h-4zm8 0h4v4h-4zm-8 7h4v4h-4zm8 0h4v4h-4zm0 7h4v4h-4zm0 7h4v4h-4z"
      ></path>
    </template>
    <template v-else-if="icon === 'city-one'">
      <path d="M4 42h40"></path>
      <rect rx="2" height="20" width="12" y="22" x="8"></rect>
      <rect rx="2" height="38" width="20" y="4" x="20"></rect>
      <path d="M28 32.008h4m-20 0h4m12-9h4m-4-9h4"></path>
    </template>
    <template v-else-if="icon === 'clear'">
      <path d="M20 5.914h8v8h15v8H5v-8h15v-8Z" clip-rule="evenodd"></path>
      <path d="M8 40h32V22H8v18Z"></path>
      <path d="M16 39.898v-5.984m8 5.984v-6m8 6v-5.984M12 40h24"></path>
    </template>
    <template v-else-if="icon === 'clue'">
      <path
        d="M37 16a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM11 42a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      ></path>
      <path
        d="M37 16v19.504A6.496 6.496 0 0 1 30.504 42v0a6.496 6.496 0 0 1-6.496-6.496v-23A6.504 6.504 0 0 0 17.504 6v0A6.504 6.504 0 0 0 11 12.504V32"
      ></path>
    </template>
    <template v-else-if="icon === 'connection-arrow'">
      <path
        d="M40.99 8.027H12.182C9.455 8.027 4 9.55 4 15.967 4 22.384 9.455 24 12.182 24h23.812C38.72 24 44 25.568 44 31.985c0 6.416-5.279 8.018-8.006 8.018H6.065"
      ></path>
      <path
        d="m8.046 35.993-3.979 4.066L8.046 44M38.043 3.954 42.02 8.02l-3.978 3.941"
      ></path>
    </template>
    <template v-else-if="icon === 'connection-box'">
      <path
        d="M43 4H5c-.552 0-1 .488-1 1.09v9.82c0 .602.448 1.09 1 1.09h38c.552 0 1-.488 1-1.09V5.09C44 4.489 43.552 4 43 4Zm0 28H5c-.552 0-1 .488-1 1.09v9.82c0 .602.448 1.09 1 1.09h38c.552 0 1-.488 1-1.09v-9.82c0-.602-.448-1.09-1-1.09Z"
      ></path>
      <path d="M14 16v8.008l20 .01V32m-16 6h12M18 10h12"></path>
    </template>
    <template v-else-if="icon === 'cooperative-handshake'">
      <path d="m24 40 12-12-4 4-4 4-4 4Zm0 0L4 20 16 8l8 8"></path>
      <path
        d="M17 23 32 8l12 12-8 8-8-8-6 6-5-3Zm0 0 7-7m4 20-3-3m7-1-3-3"
      ></path>
    </template>
    <template v-else-if="icon === 'copy-one'">
      <path d="M13 38h28V16H30V4H13v34ZM30 4l11 12"></path>
      <path d="M7 20v24h21"></path>
      <path d="M19 20h4m-4 8h12"></path>
    </template>
    <template v-else-if="icon === 'copyright'">
      <circle r="20" cy="24" cx="24"></circle>
      <path d="M24 17h-4v7h4c3 0 4-2 4-3.5S27 17 24 17Z"></path>
      <path
        d="M20 31v-7m0 0v-7h4c3 0 4 2 4 3.5S27 24 24 24h-1m-3 0h3m5 7-5-7"
      ></path>
    </template>
    <template v-else-if="icon === 'dashboard-one'">
      <path
        d="M24.778 7c-11.046 0-20 8.954-20 20 0 5.23 1.713 10.436 5 14h30c3.286-3.564 5-8.77 5-14 0-11.046-8.954-20-20-20Z"
      ></path>
      <circle r="4" cy="30" cx="24.778"></circle>
      <path
        d="M24.778 20v6m0-14v2m-15 14h2m2-10 1.414 1.414M37.778 28h2m-5-8.586L36.192 18"
      ></path>
    </template>
    <template v-else-if="icon === 'data'">
      <path d="M44 11v27c0 3.314-8.954 6-20 6S4 41.314 4 38V11"></path>
      <path
        d="M44 29c0 3.314-8.954 6-20 6S4 32.314 4 29m40-9c0 3.314-8.954 6-20 6S4 23.314 4 20"
      ></path>
      <ellipse ry="6" rx="20" cy="10" cx="24"></ellipse>
    </template>
    <template v-else-if="icon === 'data-display'">
      <path d="M22 8v12c0 2.21-4.03 4-9 4s-9-1.79-9-4V8"></path>
      <path
        d="M22 14c0 2.21-4.03 4-9 4s-9-1.79-9-4m18-6c0 2.21-4.03 4-9 4s-9-1.79-9-4 4.03-4 9-4 9 1.79 9 4Zm10-2h6a4 4 0 0 1 4 4v6M16 42h-6a4 4 0 0 1-4-4v-6m29 6v6m6 0H29m15-6V26H26v12h18Z"
      ></path>
    </template>
    <template v-else-if="icon === 'data-file'">
      <path d="M8 44V4h23l9 10.5V44H8Z"></path>
      <path d="M15 28h6v7h-6zm-1 7h20M21 23h6v12h-6zm6-5h6v17h-6z"></path>
    </template>
    <template v-else-if="icon === 'data-lock'">
      <path
        d="M39 28v4h-8v-4a4 4 0 0 1 8 0Zm-13 4h18v12H26V32Zm6-26h6a4 4 0 0 1 4 4v6M16 42h-6a4 4 0 0 1-4-4v-6M22 8v12c0 2.21-4.03 4-9 4s-9-1.79-9-4V8"
      ></path>
      <path
        d="M22 14c0 2.21-4.03 4-9 4s-9-1.79-9-4m18-6c0 2.21-4.03 4-9 4s-9-1.79-9-4 4.03-4 9-4 9 1.79 9 4Z"
      ></path>
    </template>
    <template v-else-if="icon === 'data-server'">
      <path
        d="M41 4H7a3 3 0 0 0-3 3v34a3 3 0 0 0 3 3h34a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Z"
      ></path>
      <path d="M4 32h40"></path>
      <path d="M10 38h1m15 0h12m6-1V27M4 37V27"></path>
    </template>
    <template v-else-if="icon === 'data-user'">
      <path d="M22 8v12c0 2.21-4.03 4-9 4s-9-1.79-9-4V8"></path>
      <path
        d="M22 14c0 2.21-4.03 4-9 4s-9-1.79-9-4m18-6c0 2.21-4.03 4-9 4s-9-1.79-9-4 4.03-4 9-4 9 1.79 9 4Zm10-2h6a4 4 0 0 1 4 4v6M16 42h-6a4 4 0 0 1-4-4v-6"
      ></path>
      <circle r="5" cy="29" cx="35"></circle>
      <path d="M44 44H26a9 9 0 1 1 18 0Z"></path>
    </template>
    <template v-else-if="icon === 'database-enter'">
      <path
        d="M44 31c0 5.523-4.477 10-10 10-1.79 0-3.472-.47-4.926-1.295A10.01 10.01 0 0 1 24 31c0-2.568.968-4.91 2.558-6.68A9.975 9.975 0 0 1 34 21c5.523 0 10 4.477 10 10Z"
      ></path>
      <path
        d="M34 12v9a9.975 9.975 0 0 0-7.442 3.32A9.963 9.963 0 0 0 24 31a10.01 10.01 0 0 0 5.074 8.705C26.412 40.51 22.878 41 19 41c-8.284 0-15-2.239-15-5V12"
      ></path>
      <path
        d="M34 12c0 2.761-6.716 5-15 5-8.284 0-15-2.239-15-5s6.716-5 15-5c8.284 0 15 2.239 15 5ZM4 28c0 2.761 6.716 5 15 5 1.807 0 3.54-.106 5.144-.302M4 20c0 2.761 6.716 5 15 5 2.756 0 5.339-.248 7.558-.68M39 31l-4-4m4 4-4 4m4-4H29"
      ></path>
    </template>
    <template v-else-if="icon === 'database-setting'">
      <path
        d="M34 12v9a9.975 9.975 0 0 0-7.442 3.32A9.963 9.963 0 0 0 24 31a10.01 10.01 0 0 0 5.074 8.705C26.412 40.51 22.878 41 19 41c-8.284 0-15-2.239-15-5V12"
      ></path>
      <path
        d="M44 31c0 5.523-4.477 10-10 10-1.79 0-3.472-.47-4.926-1.295A10.01 10.01 0 0 1 24 31c0-2.568.968-4.91 2.558-6.68A9.975 9.975 0 0 1 34 21c5.523 0 10 4.477 10 10ZM34 12c0 2.761-6.716 5-15 5-8.284 0-15-2.239-15-5s6.716-5 15-5c8.284 0 15 2.239 15 5Z"
      ></path>
      <path
        d="M4 28c0 2.761 6.716 5 15 5 1.807 0 3.54-.106 5.144-.302M4 20c0 2.761 6.716 5 15 5 2.756 0 5.339-.248 7.558-.68M34 27l3.464 2v4L34 35l-3.464-2v-4L34 27Z"
      ></path>
    </template>
    <template v-else-if="icon === 'deeplink'">
      <path
        d="M21 34.993v5.978c0 .569-.423 1.029-.944 1.029H4.944C4.423 42 4 41.54 4 40.971V7.03C4 6.46 4.423 6 4.944 6h15.112c.521 0 .944.46.944 1.029v5.978m6 0V7.03c0-.57.423-1.03.944-1.03h15.112c.521 0 .944.46.944 1.029V40.97c0 .569-.423 1.029-.944 1.029H27.944c-.521 0-.944-.46-.944-1.029v-5.978"
      ></path>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M12.5 38a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm23 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      ></path>
      <path d="M16 23.5h16"></path>
      <path
        d="m27.223 28.273 1.591-1.591 3.182-3.182-3.182-3.182-1.59-1.591"
      ></path>
    </template>
    <template v-else-if="icon === 'delete-five'">
      <path d="M8 11h32M18 5h12"></path>
      <path d="M12 17h24v23a3 3 0 0 1-3 3H15a3 3 0 0 1-3-3V17Z"></path>
      <path d="m20 25 8 8m0-8-8 8"></path>
    </template>
    <template v-else-if="icon === 'delivery'">
      <path d="m20 33 6 2s15-3 17-3 2 2 0 4-9 8-15 8-10-3-14-3H4"></path>
      <path
        d="M4 29c2-2 6-5 10-5s13.5 4 15 6-3 5-3 5M16 18v-8a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v16"
      ></path>
      <path d="M25 8h10v9H25z"></path>
    </template>
    <template v-else-if="icon === 'display'">
      <rect rx="2" height="20" width="36" y="6" x="6"></rect>
      <path d="M14 13h8m-8 6h20M8 44l4.889-6h21.778L40 44M24 26v18"></path>
    </template>
    <template v-else-if="icon === 'doc-add'">
      <path
        d="M38 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM17 30h14m-14 6h7m-5-19h10m-5 5V12"
      ></path>
    </template>
    <template v-else-if="icon === 'doc-detail'">
      <path
        d="M39 4H11a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM17 30h14m-14 6h7"
      ></path>
      <path d="M17 12h14v10H17z"></path>
    </template>
    <template v-else-if="icon === 'doc-fail'">
      <path
        d="M38 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM17 30h14m-14 6h7m-4-15 8-8m0 8-8-8"
      ></path>
    </template>
    <template v-else-if="icon === 'doc-search'">
      <path
        d="M38 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
      ></path>
      <path d="M28 16c0 1.38-.56 2.63-1.465 3.535A5 5 0 1 1 28 16Z"></path>
      <path
        d="m30 23-3.465-3.465m0 0a5 5 0 1 0-7.071-7.07 5 5 0 0 0 7.072 7.07ZM17 30h14m-14 6h7"
      ></path>
    </template>
    <template v-else-if="icon === 'doc-search-two'">
      <path
        d="M40 27V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h11m-4-32h14m-14 8h14m-14 8h6"
      ></path>
      <path d="M37 37c0 1.38-.56 2.63-1.465 3.535A5 5 0 1 1 37 37Z"></path>
      <path
        d="m39 44-3.465-3.465m0 0a5 5 0 1 0-7.071-7.07 5 5 0 0 0 7.072 7.07Z"
      ></path>
    </template>
    <template v-else-if="icon === 'doc-success'">
      <path
        d="M38 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM17 30h14m-14 6h7"
      ></path>
      <path d="m30 13-8 8-4-4"></path>
    </template>
    <template v-else-if="icon === 'document-folder'">
      <path
        d="M32 6H22v36h10V6Zm10 0H32v36h10V6ZM10 6l8 1-3.5 35L6 41l4-35Z"
      ></path>
      <path d="M37 18v-3m-10 3v-3"></path>
    </template>
    <template v-else-if="icon === 'dropbox'">
      <g clip-path="url(#a899a__a)">
        <path d="m24 10-12 8 12 8 12-8-12-8Z"></path>
        <path
          d="m24 10 12 8 5-7-11-7-6 6Zm0 0-12 8-5-7 11-7 6 6Zm19 12-7-4-12 8 7 5 12-9ZM5 22l7-4 12 8-7 5-12-9Z"
        ></path>
        <path d="M36 28v9l-12 7-12-7v-9"></path>
      </g>
      <defs>
        <clipPath id="a899a__a">
          <path fill="currentColor" stroke-width="0" d="M0 0h48v48H0z"></path>
        </clipPath>
      </defs>
    </template>
    <template v-else-if="icon === 'edit'">
      <path d="M7 42h36"></path>
      <path d="M11 26.72V34h7.317L39 13.308 31.695 6 11 26.72Z"></path>
    </template>
    <template v-else-if="icon === 'edit-name'">
      <circle r="7" cy="11" cx="24"></circle>
      <path d="M4 41c0-8.837 8.059-16 18-16m9 17 10-10-4-4-10 10v4h4Z"></path>
    </template>
    <template v-else-if="icon === 'edit-two'">
      <path
        d="M42 26v14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h14"
      ></path>
      <path d="M14 26.72V34h7.317L42 13.308 34.695 6 14 26.72Z"></path>
    </template>
    <template v-else-if="icon === 'engineering-brand'">
      <rect rx="2" height="20" width="38" y="6" x="5"></rect>
      <path d="M12 26v16m24-9H12m4 9H8m32 0h-8m4-16v16"></path>
    </template>
    <template v-else-if="icon === 'equalizer'">
      <path d="M11 16v26m13-13v13m0-23V6m13 0v26"></path>
      <path
        d="M11 16a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm13 13a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm13 13a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
      ></path>
    </template>
    <template v-else-if="icon === 'excel'">
      <path
        d="M8 15V6a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-9"
      ></path>
      <path d="M31 15h3m-6 8h6m-6 8h6"></path>
      <path d="M4 15h18v18H4zm6 6 6 6m0-6-6 6"></path>
    </template>
    <template v-else-if="icon === 'exchange-one'">
      <path
        d="M9 18v24h30V18L24 6 9 18Zm8 6h14m-14 6h14m0-6-5-5m-4 16-5-5"
      ></path>
    </template>
    <template v-else-if="icon === 'external-transmission'">
      <path d="M30 25h12m-6-6 6 6-6 6M21 6v20c0 6.74-6.165 13.567-12 16"></path>
      <path
        d="M42 14V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3v-3.5"
      ></path>
    </template>
    <template v-else-if="icon === 'file-cabinet'">
      <path d="M42 4H6v10h36V4Zm0 15H6v10h36V19Zm0 15H6v10h36V34Z"></path>
      <path d="M21 9h6m-6 15h6m-6 15h6"></path>
    </template>
    <template v-else-if="icon === 'file-code'">
      <path
        d="M10 44h28a2 2 0 0 0 2-2V14H30V4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2ZM30 4l10 10"
      ></path>
      <path d="m27 24 5 5-5 5m-6-10-5 5 5 5"></path>
    </template>
    <template v-else-if="icon === 'file-conversion'">
      <path
        d="M10 44h28a2 2 0 0 0 2-2V14H30V4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2ZM30 4l10 10M17 25h14m-14 6h14m0-6-5-5m-4 16-5-5"
      ></path>
    </template>
    <template v-else-if="icon === 'file-conversion-one'">
      <path
        d="M40 23v-9L31 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h12m5-11h14m-14 6h14m0-6-5-5m-4 16-5-5"
      ></path>
      <path d="M30 4v10h10"></path>
    </template>
    <template v-else-if="icon === 'file-date'">
      <path
        d="M10 44h28a2 2 0 0 0 2-2V14H30V4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2ZM30 4l10 10"
      ></path>
      <circle r="8" cy="28" cx="24"></circle>
      <path d="M23 25v4h4"></path>
    </template>
    <template v-else-if="icon === 'file-date-one'">
      <path d="M40 23v-9L31 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h12"></path>
      <circle r="8" cy="36" cx="34"></circle>
      <path d="M33 33v4h4M30 4v10h10"></path>
    </template>
    <template v-else-if="icon === 'file-editing'">
      <path
        d="M10 44h28a2 2 0 0 0 2-2V14H30V4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2ZM30 4l10 10"
      ></path>
      <path d="m21 35 10-10-4-4-10 10v4h4Z"></path>
    </template>
    <template v-else-if="icon === 'file-editing-one'">
      <path
        d="M40 23v-9L31 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h12m10 0 10-10-4-4-10 10v4h4Z"
      ></path>
      <path d="M30 4v10h10"></path>
    </template>
    <template v-else-if="icon === 'file-lock-one'">
      <path d="M40 23v-9L31 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h12"></path>
      <path d="M28 34h14v8H28zm10 0v-3a3 3 0 1 0-6 0v3M30 4v10h10"></path>
    </template>
    <template v-else-if="icon === 'file-protection'">
      <path
        d="M10 44h28a2 2 0 0 0 2-2V14H30V4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2ZM30 4l10 10"
      ></path>
      <path
        d="M17 23.2c0-1.067 7-3.2 7-3.2s7 2.133 7 3.2c0 8.533-7 12.8-7 12.8s-7-4.267-7-12.8Z"
      ></path>
    </template>
    <template v-else-if="icon === 'file-search'">
      <path
        d="M40 20.842V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h16"
      ></path>
      <path
        d="M14 17h6m8 0h6M14 28h6m-6 6h6m-3-14v-6m20.728 23.728 4.243 4.243"
      ></path>
      <circle
        transform="rotate(45 33.485 33.485)"
        r="6"
        cy="33.485"
        cx="33.485"
      ></circle>
    </template>
    <template v-else-if="icon === 'file-settings'">
      <path
        d="M10 44h28a2 2 0 0 0 2-2V14H30V4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2ZM30 4l10 10"
      ></path>
      <circle r="5" cy="27" cx="24"></circle>
      <path
        d="M24 19v3m0 10v3m5.828-14-2.121 2.121M19.828 31l-2.121 2.121M18 21l2.121 2.121M28 31l2.121 2.121M16 27h3m10 0h3"
      ></path>
    </template>
    <template v-else-if="icon === 'file-staff'">
      <path
        d="M10 44h28a2 2 0 0 0 2-2V14H30V4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2ZM30 4l10 10"
      ></path>
      <circle r="4" cy="24" cx="24"></circle>
      <path d="M32 36a8 8 0 1 0-16 0"></path>
    </template>
    <template v-else-if="icon === 'file-zip'">
      <path d="M10 38v6h28v-6m0-18v-6L30 4H10v16"></path>
      <path d="M28 4v10h10"></path>
      <path d="M16 12h4"></path>
      <rect rx="2" height="18" width="40" y="20" x="4"></rect>
      <path d="M11 25h6l-6 8h6"></path>
      <path d="M24 25v8m7-8v8"></path>
      <path
        d="M31 25h3.5a2.5 2.5 0 0 1 2.5 2.5v0a2.5 2.5 0 0 1-2.5 2.5H31"
      ></path>
    </template>
    <template v-else-if="icon === 'find-one'">
      <path
        d="M23.8 14C29.433 14 34 18.477 34 24s-4.567 10-10.2 10c-2.612 0-4.995-.963-6.8-2.546"
      ></path>
      <path
        d="M24 4c11.046 0 20 8.954 20 20s-8.954 20-20 20a19.934 19.934 0 0 1-13.927-5.646A19.94 19.94 0 0 1 4 24a19.933 19.933 0 0 1 5.556-13.833"
      ></path>
      <path d="M9.556 10.167 24 24 10.073 38.354"></path>
    </template>
    <template v-else-if="icon === 'fire'">
      <path
        d="M24 44c8.235 0 15-6.526 15-14.902 0-2.056-.105-4.26-1.245-7.686-1.14-3.426-1.369-3.868-2.574-5.984-.515 4.317-3.27 6.117-3.97 6.655 0-.56-1.666-6.747-4.193-10.45C24.537 8 21.163 5.617 19.185 4c0 3.07-.863 7.634-2.1 9.96-1.236 2.325-1.468 2.41-3.013 4.14-1.544 1.73-2.253 2.265-3.545 4.365C9.236 24.565 9 27.362 9 29.418 9 37.794 15.765 44 24 44Z"
      ></path>
    </template>
    <template v-else-if="icon === 'folder-close'">
      <path
        d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8Zm38 14H5"
      ></path>
      <path d="M5 16v12m38-12v12"></path>
    </template>
    <template v-else-if="icon === 'folder-conversion'">
      <path
        d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8Z"
      ></path>
      <path d="M17 24h14m-14 6h14m0-6-5-5m-4 16-5-5"></path>
    </template>
    <template v-else-if="icon === 'folder-protection'">
      <path
        d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8Z"
      ></path>
      <path
        d="M18 22.8c0-.933 6-2.8 6-2.8s6 1.867 6 2.8c0 7.467-6 11.2-6 11.2s-6-3.733-6-11.2Z"
      ></path>
    </template>
    <template v-else-if="icon === 'folder-settings'">
      <path
        d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8Z"
      ></path>
      <circle r="4" cy="28" cx="24"></circle>
      <path
        d="M24 21v3m0 8v3m4.828-12-2.121 2.121M20.828 31l-2.121 2.121M19 23l2.121 2.121M27 31l2.121 2.121M17 28h3m8 0h3"
      ></path>
    </template>
    <template v-else-if="icon === 'garage'">
      <path d="M6 5h36v38H6z"></path>
      <path d="M12 12h24v6H12z"></path>
      <path d="M16 18v25m16-25v25M16 24h16m-16 6h16m-16 6h16"></path>
    </template>
    <template v-else-if="icon === 'germs'">
      <path
        d="M24 44c1.336 0 2.364-3.75 3.626-4 1.3-.257 2.833 2.979 4.03 2.483 1.217-.505.75-3.616 1.831-4.34 1.091-.73 3.73.924 4.655 0 .925-.925-1.478-4.052-.748-5.143.725-1.082 4.584-.127 5.089-1.344.496-1.197-3.304-3.543-3.047-4.843C39.686 25.55 44 25.336 44 24c0-1.335-3.75-2.64-4-3.903-.257-1.3 2.979-2.556 2.483-3.752-.505-1.218-4.364-.263-5.089-1.345-.73-1.09 1.673-4.217.748-5.142-.925-.925-4.051 1.091-5.142.361-1.082-.724-.127-4.196-1.345-4.701C30.46 5.022 28.3 7.905 27 7.648 25.738 7.397 25.336 4 24 4s-1.88 3.398-3.142 3.647c-1.3.258-3.317-2.625-4.513-2.13-1.218.506-.263 3.617-1.345 4.341-1.09.73-4.217-.925-5.142 0-.925.925 1.667 4.052.937 5.142-.725 1.082-4.773.127-5.278 1.345C5.021 17.54 8.257 19.24 8 20.54c-.25 1.262-4 2.125-4 3.46 0 1.336 4.097 1.927 4.347 3.19.257 1.3-3.326 3.27-2.83 4.466.505 1.217 4.29.262 5.014 1.344.73 1.09-1.598 4.218-.673 5.142.925.925 4.051-1.287 5.142-.557 1.082.725.127 4.393 1.345 4.898 1.196.496 3.212-2.74 4.513-2.483 1.262.25 1.806 4 3.142 4Z"
        clip-rule="evenodd"
      ></path>
      <path d="M29 29a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"></path>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M16.5 26a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      ></path>
    </template>
    <template v-else-if="icon === 'gold-medal'">
      <path
        d="M44 4H31l-4 10.3A15.023 15.023 0 0 1 37.27 22L44 4ZM17 4H4l6.73 18A15.023 15.023 0 0 1 21 14.3L17 4Z"
      ></path>
      <path
        d="M39 29c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-2.528.625-4.91 1.73-7A15.023 15.023 0 0 1 21 14.3c.97-.197 1.973-.3 3-.3s2.03.103 3 .3A15.023 15.023 0 0 1 37.27 22 14.935 14.935 0 0 1 39 29Z"
      ></path>
      <path d="M24 35V22l-3 1m3 12h4m-4 0h-4"></path>
    </template>
    <template v-else-if="icon === 'gold-medal-two'">
      <path
        d="M36 32a11.97 11.97 0 0 0-4-8.944A11.955 11.955 0 0 0 24 20a11.955 11.955 0 0 0-8 3.056A11.97 11.97 0 0 0 12 32c0 6.627 5.373 12 12 12s12-5.373 12-12Z"
      ></path>
      <path
        d="M16 4h16v19.056A11.955 11.955 0 0 0 24 20a11.955 11.955 0 0 0-8 3.056V4Z"
      ></path>
      <path
        d="M16 4h16M16 4H8v10l8 6m0-16v16M32 4h8v10l-8 6m0-16v16m-16 3.056A11.955 11.955 0 0 1 24 20c3.073 0 5.877 1.155 8 3.056m-16 0A11.97 11.97 0 0 0 12 32c0 6.627 5.373 12 12 12s12-5.373 12-12a11.97 11.97 0 0 0-4-8.944m-16 0V20m16 3.056V20"
      ></path>
      <path
        d="M32 4h8v10l-8 6M16 4H8v10l8 6m20 12a11.97 11.97 0 0 0-4-8.944A11.955 11.955 0 0 0 24 20a11.955 11.955 0 0 0-8 3.056A11.97 11.97 0 0 0 12 32c0 6.627 5.373 12 12 12s12-5.373 12-12Z"
      ></path>
      <path
        d="M16 4h16v19.056A11.955 11.955 0 0 0 24 20a11.955 11.955 0 0 0-8 3.056V4Zm8 33V27l-2 1m2 9h2m-2 0h-2"
      ></path>
    </template>
    <template v-else-if="icon === 'hand-washing-fluid'">
      <path
        d="M24 4v7m5 6v-6H19v6M31 4H19.8C17.142 4 15 5.2 15 8m23 18.977V26a9 9 0 0 0-9-9H19a9 9 0 0 0-9 9v9a9 9 0 0 0 9 9h5"
      ></path>
      <path
        d="M40 39.77c0 2.336-2.015 4.23-4.5 4.23S31 42.106 31 39.77c0-2.337 2.94-6.77 4.5-6.77s4.5 4.433 4.5 6.77Z"
      ></path>
    </template>
    <template v-else-if="icon === 'headset'">
      <path
        d="M42 30v-5.538C42 14.265 33.941 6 24 6S6 14.265 6 24.462V30"
      ></path>
      <path d="M34 32a4 4 0 0 1 4-4h4v14h-4a4 4 0 0 1-4-4v-6Z"></path>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M42 32h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-2v-6ZM6 32H4a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2v-6Z"
      ></path>
      <path d="M6 28h4a4 4 0 0 1 4 4v6a4 4 0 0 1-4 4H6V28Z"></path>
    </template>
    <template v-else-if="icon === 'headset-one'">
      <path d="M36 32a8 8 0 1 0 0-16"></path>
      <path d="M36 32a8 8 0 1 0 0-16"></path>
      <path d="M12 16a8 8 0 1 0 0 16"></path>
      <path d="M12 16a8 8 0 1 0 0 16"></path>
      <path
        d="M12 32V16c0-6.627 5.373-12 12-12s12 5.373 12 12v16c0 6.627-5.373 12-12 12"
      ></path>
    </template>
    <template v-else-if="icon === 'help'">
      <path
        d="M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4 19.938 19.938 0 0 0 9.858 9.858 19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z"
      ></path>
      <path d="M24 28.625v-4a6 6 0 1 0-6-6"></path>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M24 37.625a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
      ></path>
    </template>
    <template v-else-if="icon === 'home-two'">
      <path d="M44 44V20L24 4 4 20v24h12V26h16v18h12Z"></path>
      <path d="M24 44V34"></path>
    </template>
    <template v-else-if="icon === 'id-card'">
      <path
        d="M42 8H6a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z"
      ></path>
      <path d="M36 16h-8v8h8v-8Z"></path>
      <path d="M12 32h24M12 16h6m-6 8h6"></path>
    </template>
    <template v-else-if="icon === 'id-card-h'">
      <rect rx="2" height="32" width="40" y="8" x="4"></rect>
      <path d="M17 25a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"></path>
      <path d="M23 31a6 6 0 0 0-12 0m17-11h8m-6 8h6"></path>
    </template>
    <template v-else-if="icon === 'id-card-v'">
      <rect rx="2" height="40" width="32" y="4" x="8"></rect>
      <path d="M24 19a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"></path>
      <path d="M30 25a6 6 0 0 0-12 0m0 6h12m-12 6h7"></path>
    </template>
    <template v-else-if="icon === 'inbox-in'">
      <path d="m5 30 5-24h28l5 24"></path>
      <path d="M5 30h9.91l1.817 6h14.546l1.818-6H43v13H5V30Z"></path>
      <path d="m18 20 6 6 6-6m-6 6V14"></path>
    </template>
    <template v-else-if="icon === 'inbox-success'">
      <path d="M4 30 9 6h30l5 24"></path>
      <path d="M4 30h10.91l1.817 6h14.546l1.818-6H44v13H4V30Z"></path>
      <path d="M19 19.214 23 24l8-8"></path>
    </template>
    <template v-else-if="icon === 'incoming'">
      <path
        d="M18 13a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm19 0a4 4 0 1 0-8 0 4 4 0 0 0 8 0Z"
      ></path>
      <path d="M4 13h6m8 0h11m8 0h7"></path>
      <path d="m10 29-6 6 6 6m-6-6h40"></path>
    </template>
    <template v-else-if="icon === 'inspection'">
      <path d="M43 33V19H5v22a2 2 0 0 0 2 2h17"></path>
      <path d="M5 10a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v9H5v-9Z"></path>
      <path d="M16 5v8m16-8v8"></path>
      <circle r="7" cy="32" cx="30"></circle>
      <path d="m36 37 6 5"></path>
    </template>
    <template v-else-if="icon === 'instruction'">
      <rect rx="2" height="32" width="40" y="8" x="4"></rect>
      <path d="M4 10a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v6H4v-6Z"></path>
      <path d="m25 23-2 11"></path>
      <path d="m31 23 6 5-6 6M17 23l-6 5 6 6"></path>
    </template>
    <template v-else-if="icon === 'internal-transmission'">
      <path d="M42 25H30m6-6-6 6 6 6M21 6v20c0 6.74-6.165 13.567-12 16"></path>
      <path
        d="M42 14V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3v-3.5"
      ></path>
    </template>
    <template v-else-if="icon === 'layers'">
      <path d="M4 11.914 24 19l20-7.086L24 5 4 11.914Z"></path>
      <path d="m4 20 20 7 20-7M4 28l20 7 20-7M4 36l20 7 20-7"></path>
    </template>
    <template v-else-if="icon === 'link'">
      <path
        d="M24.707 9.565 9.858 24.415a9 9 0 0 0 0 12.727v0a9 9 0 0 0 12.728 0l17.678-17.677a6 6 0 0 0 0-8.486v0a6 6 0 0 0-8.486 0L14.101 28.657a3 3 0 0 0 0 4.243v0a3 3 0 0 0 4.242 0l14.85-14.85"
      ></path>
    </template>
    <template v-else-if="icon === 'link-cloud'">
      <path
        d="M13 33c-3.333 0-9-1.5-9-7.5 0-7 7-8.5 9-8.5 1-3.5 3-9 11-9 7 0 10 4 11 7.5 0 0 9 1 9 9.5 0 6-5 8-9 8H22"
      ></path>
      <path d="m27 28-5 5 5 5"></path>
    </template>
    <template v-else-if="icon === 'link-left'">
      <path d="M24 12V4h16v8m0 24v8H24v-8m0-12H4m28 10V14m-20 2-8 8 8 8"></path>
    </template>
    <template v-else-if="icon === 'link-right'">
      <path d="M8 12V4h16v8m0 24v8H8v-8m36-12H24m-8 10V14m20 2 8 8-8 8"></path>
    </template>
    <template v-else-if="icon === 'link-three'">
      <rect
        transform="rotate(45 34.607 3.494)"
        rx="2"
        height="18"
        width="14"
        y="3.494"
        x="34.607"
      ></rect>
      <rect
        transform="rotate(45 16.223 21.879)"
        rx="2"
        height="18"
        width="14"
        y="21.879"
        x="16.223"
      ></rect>
      <path d="M31.072 16.929 16.93 31.071"></path>
    </template>
    <template v-else-if="icon === 'list'">
      <path
        d="m5 10 3 3 6-6M5 24l3 3 6-6M5 38l3 3 6-6m7-11h22M21 38h22M21 10h22"
      ></path>
    </template>
    <template v-else-if="icon === 'list-top'">
      <path
        d="M8 14a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      ></path>
      <path d="M20 24h24M20 38h24M20 10h24"></path>
    </template>
    <template v-else-if="icon === 'list-two'">
      <path
        d="M9 42a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-28a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 14a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      ></path>
      <path d="M21 24h22M21 38h22M21 10h22"></path>
    </template>
    <template v-else-if="icon === 'list-view'">
      <rect rx="3" height="36" width="40" y="6" x="4"></rect>
      <path d="M4 14h40M20 24h16m-16 8h16m-24-8h2m-2 8h2"></path>
    </template>
    <template v-else-if="icon === 'log'">
      <path d="M13 10h28v34H13z"></path>
      <path d="M35 10V4H8a1 1 0 0 0-1 1v33h6m8-16h12m-12 8h12"></path>
    </template>
    <template v-else-if="icon === 'monitor-one'">
      <path
        d="M5 8a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8Z"
      ></path>
      <path
        d="M5 26a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-6Zm17-14-4 5m10-3-3 4"
      ></path>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="2"
        cy="29"
        cx="24"
      ></circle>
      <path d="m17 34-3 8h20l-3-8"></path>
    </template>
    <template v-else-if="icon === 'more-app'">
      <path
        d="M18 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Zm0 24H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2ZM42 4H30a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
      ></path>
      <path d="M28 28h16m-8 8h8m-16 8h16"></path>
    </template>
    <template v-else-if="icon === 'network-tree'">
      <path d="M4 34h8v8H4zM8 6h32v12H8zm16 28V18"></path>
      <path d="M8 34v-8h32v8m-4 0h8v8h-8zm-16 0h8v8h-8zm-6-22h2"></path>
    </template>
    <template v-else-if="icon === 'new-afferent'">
      <path
        d="M42 19V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h10"
      ></path>
      <path d="m32 24-8 8 8 8m-8-8h18"></path>
    </template>
    <template v-else-if="icon === 'new-efferent'">
      <path
        d="M42 19V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h10"
      ></path>
      <path d="m34 24 8 8-8 8m-10-8h18"></path>
    </template>
    <template v-else-if="icon === 'newspaper-folding'">
      <path d="m22 44-1-8m21 8V12H26l1 8 1 8 1 8-7 8h20ZM28 28h5m-6-8h6"></path>
      <path d="M6 4h19l1 8 1 8 1 8 1 8H6V4Zm6 8h7m-7 8h8m-8 8h9"></path>
    </template>
    <template v-else-if="icon === 'open-door'">
      <path d="M20 4v40l22-6V10L20 4Z"></path>
      <path d="M6 10h14v28H6V10Z" clip-rule="evenodd"></path>
      <path d="M28 22v4"></path>
    </template>
    <template v-else-if="icon === 'outbound'">
      <path d="M40 24H24m10-6 6 6-6 6"></path>
      <circle r="4" cy="24" cx="20"></circle>
      <path
        d="M40.706 13A20.102 20.102 0 0 0 38 9.717 19.935 19.935 0 0 0 24 4C12.954 4 4 12.954 4 24s8.954 20 20 20c5.45 0 10.392-2.18 14-5.717A20.104 20.104 0 0 0 40.706 35"
      ></path>
    </template>
    <template v-else-if="icon === 'outgoing'">
      <path
        d="M18 35a4 4 0 1 0-8 0 4 4 0 0 0 8 0Zm19 0a4 4 0 1 0-8 0 4 4 0 0 0 8 0Z"
      ></path>
      <path d="M4 35h6m8 0h11m8 0h7"></path>
      <path d="m38 19 6-6-6-6M4 13h40"></path>
    </template>
    <template v-else-if="icon === 'people'">
      <path
        d="M24 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM6 40.8V42h36v-1.2c0-4.48 0-6.72-.872-8.432a8 8 0 0 0-3.496-3.496C35.92 28 33.68 28 29.2 28H18.8c-4.48 0-6.72 0-8.432.872a8 8 0 0 0-3.496 3.496C6 34.08 6 36.32 6 40.8Z"
      ></path>
    </template>
    <template v-else-if="icon === 'people-safe'">
      <path
        d="M6 9.256 24.009 4 42 9.256v10.778C42 31.362 34.75 40.419 24.003 44 13.253 40.42 6 31.36 6 20.029V9.256Z"
      ></path>
      <circle r="5" cy="18" cx="24"></circle>
      <path d="M32 31a8 8 0 1 0-16 0"></path>
    </template>
    <template v-else-if="icon === 'people-safe-one'">
      <circle r="7" cy="11" cx="24"></circle>
      <path
        d="M4 41c0-8.837 8.059-16 18-16m6 4.2c0-1.067 7-3.2 7-3.2s7 2.133 7 3.2c0 8.533-7 12.8-7 12.8s-7-4.267-7-12.8Z"
      ></path>
    </template>
    <template v-else-if="icon === 'peoples-two'">
      <path
        d="M24 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM12 7.255A6.992 6.992 0 0 0 9 13a6.996 6.996 0 0 0 3.392 6M36 7.255A6.992 6.992 0 0 1 39 13a6.992 6.992 0 0 1-3 5.745M12 40v2h24v-2c0-3.727 0-5.591-.609-7.062a8 8 0 0 0-4.33-4.329C29.591 28 27.727 28 24 28s-5.591 0-7.061.609a8 8 0 0 0-4.33 4.33C12 34.408 12 36.273 12 40Zm32 2v-1.2c0-4.48 0-6.72-.872-8.432a8 8 0 0 0-3.496-3.496M4 42v-1.2c0-4.48 0-6.72.872-8.432a8 8 0 0 1 3.496-3.496"
      ></path>
    </template>
    <template v-else-if="icon === 'permissions'">
      <path
        d="M20 10H6a2 2 0 0 0-2 2v26a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2v-2.5"
      ></path>
      <path d="M10 23h8m-8 8h24"></path>
      <circle r="6" cy="16" cx="34"></circle>
      <path d="M44 28.419C42.047 24.602 38 22 34 22s-5.993 1.133-8.05 3"></path>
    </template>
    <template v-else-if="icon === 'pivot-table'">
      <path
        d="M42 4H6a2 2 0 0 0-2 2l.001 36a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
      ></path>
      <path d="M20.009 34.008H33.5v-14"></path>
      <path
        d="M24.5 38.5 23 37l-3-3 3-3 1.5-1.5m4.5-5 1.5-1.5 3-3 3 3 1.5 1.5"
      ></path>
      <path d="M14 4v40M4 14.038 44 14"></path>
      <path d="M8 4h20M8 44h20"></path>
      <path d="M44 8v10M4 8v10"></path>
    </template>
    <template v-else-if="icon === 'plan'">
      <path
        d="M5 19h38v22a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V19Zm0-9a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v9H5v-9Z"
      ></path>
      <path d="m16 31 6 6 12-12"></path>
      <path d="M16 5v8m16-8v8"></path>
    </template>
    <template v-else-if="icon === 'pull-door'">
      <path d="M6 8v32l18.2 4V4L6 8Zm18.2 0H42v32H24.2M18 22v4"></path>
    </template>
    <template v-else-if="icon === 'pull-requests'">
      <path
        d="M37 44a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM11 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 32a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      ></path>
      <path d="M11 12v24m13-26h9a4 4 0 0 1 4 4v22"></path>
      <path d="m30 16-6-6 6-6"></path>
    </template>
    <template v-else-if="icon === 'push-door'">
      <path d="M6 6h36v36H6"></path>
      <path d="M6 6v36l18-6V12L6 6Zm12 16v4"></path>
    </template>
    <template v-else-if="icon === 'read-book'">
      <path
        d="M24 21v23c-3.291-4-13.371-4-18-4V18c9.874 0 16.114 2 18 3Zm0 0v23c3.291-4 13.371-4 18-4V18c-9.874 0-16.114 2-18 3Z"
      ></path>
      <circle r="8" cy="12" cx="24"></circle>
    </template>
    <template v-else-if="icon === 'receive'">
      <path
        d="M4.592 19.467A2 2 0 0 1 6.537 17h34.926a2 2 0 0 1 1.945 2.467l-5.04 21A2 2 0 0 1 36.423 42H11.577a2 2 0 0 1-1.945-1.533l-5.04-21Z"
      ></path>
      <path d="M11 7h8v10h-8zm8 10 6.5-9L38 17"></path>
      <path d="M15 25h8"></path>
    </template>
    <template v-else-if="icon === 'recycling-pool'">
      <path d="m20 16 4 4 4-4m4 13 4 4 4-4M8 29l4 4 4-4m8-9V4"></path>
      <path
        d="M36 32.867v-13.23a4 4 0 0 1 4-4h4m-32 17.23v-13.23a4 4 0 0 0-4-4H4M4 36v4a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4v-4"
      ></path>
    </template>
    <template v-else-if="icon === 'right-user'">
      <circle r="8" cy="12" cx="24"></circle>
      <path d="M42 44c0-9.941-8.059-18-18-18S6 34.059 6 44"></path>
      <path d="m30 36-8 8-4-4"></path>
    </template>
    <template v-else-if="icon === 'rss'">
      <path
        d="M8 44V6a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v38l-16-8.273L8 44Z"
      ></path>
      <path d="M23.95 13.95v12m-6-6h12"></path>
    </template>
    <template v-else-if="icon === 's-turn-left'">
      <path
        d="M42 38H13c-4 0-7-3-7-7s3-7 7-7h6m0 0h16c4 0 7-3 7-7s-3-7-7-7H17"
      ></path>
      <path d="m37 43 5-5-5-5"></path>
      <circle transform="rotate(-180 12 10)" r="5" cy="10" cx="12"></circle>
    </template>
    <template v-else-if="icon === 'safe-retrieval'">
      <path
        d="M7 9.127 24.008 4 41 9.127v10.515C41 30.695 34.153 40.506 24.003 44 13.849 40.506 7 30.692 7 19.638V9.128Z"
      ></path>
      <path d="M24 30a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"></path>
      <path d="m29 29 6 7"></path>
      <path d="M41 19.643C41 30.695 34.153 40.507 24.002 44"></path>
    </template>
    <template v-else-if="icon === 'save'">
      <path
        d="M6 9a3 3 0 0 1 3-3h25.281L42 13.207V39a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V9Z"
      ></path>
      <path
        d="M24.008 6 24 13.385c0 .34-.448.615-1 .615h-8c-.552 0-1-.275-1-.615V6"
        clip-rule="evenodd"
      ></path>
      <path
        d="M24.008 6 24 13.385c0 .34-.448.615-1 .615h-8c-.552 0-1-.275-1-.615V6h10.008Z"
      ></path>
      <path d="M9 6h25.281M14 26h20m-20 8h10.008"></path>
    </template>
    <template v-else-if="icon === 'save-one'">
      <path
        d="M39.3 6H8.7A2.7 2.7 0 0 0 6 8.7v30.6A2.7 2.7 0 0 0 8.7 42h30.6a2.7 2.7 0 0 0 2.7-2.7V8.7A2.7 2.7 0 0 0 39.3 6Z"
      ></path>
      <path d="M32 6v18H15V6h17Z"></path>
      <path d="M26 13v4M10.997 6H36"></path>
    </template>
    <template v-else-if="icon === 'seal'">
      <path
        d="M5 37a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-5Z"
      ></path>
      <path
        d="M5 31a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V31Z"
      ></path>
      <path
        d="M18.763 15.664a1 1 0 0 1 .942-.664h8.59a1 1 0 0 1 .942.664L34 29H14l4.763-13.336Z"
      ></path>
      <rect rx="5.4" height="10.8" width="18" y="4" x="15"></rect>
    </template>
    <template v-else-if="icon === 'send'">
      <path d="M43 5 29.7 43l-7.6-17.1L5 18.3 43 5Z"></path>
      <path d="M43 5 22.1 25.9"></path>
    </template>
    <template v-else-if="icon === 'seo'">
      <rect rx="1.633" height="32" width="40" y="8" x="4"></rect>
      <path
        d="M16 18.948c-2-2.948-5.502-1.01-5.251 2.02C11 24 15 24 15.249 27.032 15.5 30.062 12 32 10 29.051M26 18h-4v13h4m-4-6h4"
      ></path>
      <rect rx="3" height="13" width="6" y="18" x="32"></rect>
    </template>
    <template v-else-if="icon === 'setting-config'">
      <path
        d="M41.5 10h-6m-8-4v8m0-4h-22m8 14h-8m16-4v8m22-4h-22m20 14h-6m-8-4v8m0-4h-22"
      ></path>
    </template>
    <template v-else-if="icon === 'setting-one'">
      <path d="m34 41 10-17L34 7H14L4 24l10 17h20Z"></path>
      <path d="M24 29a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"></path>
    </template>
    <template v-else-if="icon === 'setting-two'">
      <path
        d="M18.284 43.171a19.995 19.995 0 0 1-8.696-5.304 6 6 0 0 0-5.182-9.838A20.09 20.09 0 0 1 4 24c0-2.09.32-4.106.916-6H5a6 6 0 0 0 5.385-8.65 19.968 19.968 0 0 1 8.267-4.627A6 6 0 0 0 24 8a6 6 0 0 0 5.348-3.277 19.968 19.968 0 0 1 8.267 4.627A6 6 0 0 0 43.084 18 19.99 19.99 0 0 1 44 24c0 1.38-.14 2.728-.406 4.03a6 6 0 0 0-5.182 9.838 19.995 19.995 0 0 1-8.696 5.303 6.003 6.003 0 0 0-11.432 0Z"
      ></path>
      <path d="M24 31a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"></path>
    </template>
    <template v-else-if="icon === 'setting-web'">
      <path
        d="M24 40H7a3 3 0 0 1-3-3V11a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v12.059"
      ></path>
      <path d="M4 11a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v9H4v-9Z"></path>
      <circle
        fill="currentColor"
        stroke-width="0"
        transform="matrix(0 -1 -1 0 10 14)"
        r="2"
      ></circle>
      <circle
        fill="currentColor"
        stroke-width="0"
        transform="matrix(0 -1 -1 0 16 14)"
        r="2"
      ></circle>
      <circle r="3" cy="34" cx="37"></circle>
      <path
        d="M37 41v-4m0-6v-4m-6.062 10.5 3.464-2m5.196-3 3.464-2m-12.124 0 3.464 2m5.196 3 3.464 2"
      ></path>
    </template>
    <template v-else-if="icon === 'shopping-bag'">
      <path
        d="M5 17h38l-4.2 26H9.2L5 17Zm30 0c0-6.627-4.925-12-11-12s-11 5.373-11 12"
      ></path>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="2"
        cy="26"
        cx="17"
      ></circle>
      <path d="M18 33s2 3 6 3 6-3 6-3"></path>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="2"
        cy="26"
        cx="31"
      ></circle>
    </template>
    <template v-else-if="icon === 'shopping-cart'">
      <path d="M39 32H13L8 12h36l-5 20Z"></path>
      <path d="M3 6h3.5L8 12m0 0 5 20h26l5-20H8Z"></path>
      <circle r="3" cy="39" cx="13"></circle>
      <circle r="3" cy="39" cx="39"></circle>
      <path d="M22 22h8m-4 4v-8"></path>
    </template>
    <template v-else-if="icon === 'shopping-mall'">
      <path
        d="M8 44V6a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v38"
        clip-rule="evenodd"
      ></path>
      <path d="M8 44V6a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v38m0-29 10 6v23"></path>
      <path d="M4 44h40"></path>
    </template>
    <template v-else-if="icon === 'six-points'">
      <path
        d="M24 24V12m0 12-10.5 6.062L24 24Zm0 0 10.5 6.062L24 24Zm-10-8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm0 16a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm14 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm14-8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm0-16a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM28 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
      ></path>
    </template>
    <template v-else-if="icon === 'speaker-one'">
      <path d="M4 15h11v18H4zm11 0 18-7v32l-18-7m25-16h2m-3 8h5m-4 8h2"></path>
    </template>
    <template v-else-if="icon === 'star'">
      <path
        d="m23.999 5-6.113 12.478L4 19.49l10.059 9.834L11.654 43 24 36.42 36.345 43 33.96 29.325 44 19.491l-13.809-2.013L24 5Z"
      ></path>
    </template>
    <template v-else-if="icon === 'surveillance-cameras-one'">
      <path
        d="M6 26v16m0-9h7l4-6M7 20l26.474 17.649a1 1 0 0 0 1.069.025L44 32"
      ></path>
      <path
        d="M7.078 12.719a1 1 0 0 1-.11-1.58l7.46-6.63a1 1 0 0 1 1.212-.09l27.065 17.732a1 1 0 0 1-.011 1.68l-9.144 5.82a1 1 0 0 1-1.092-.012l-25.38-16.92Z"
      ></path>
    </template>
    <template v-else-if="icon === 'switch-themes'">
      <path d="M23 4v23h20.993L44 4H23Z" clip-rule="evenodd"></path>
      <path
        d="M31.005 44H18.658c-1.702 0-3.742-.568-5.11-2.387-.925-1.23-1.543-3.03-1.543-5.613V25"
      ></path>
      <path d="m4 33 8.005-8 8.009 8"></path>
      <path d="M30 19h7m-7-7h7"></path>
    </template>
    <template v-else-if="icon === 'system'">
      <path
        d="M18 6H8a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Zm0 22H8a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2Zm17-8a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm5 8H30a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2Z"
      ></path>
    </template>
    <template v-else-if="icon === 'table-file'">
      <path
        d="M42 6H6a2 2 0 0 0-2 2v32a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Z"
      ></path>
      <path
        d="M4 18h40m-26.5 0v24m13-24v24M4 30h40m0-22v32a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V8"
      ></path>
    </template>
    <template v-else-if="icon === 'table-report'">
      <path
        d="M5 7a3 3 0 0 1 3-3h24a3 3 0 0 1 3 3v37H8a3 3 0 0 1-3-3V7Zm30 17a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v17a3 3 0 0 1-3 3h-5V24Z"
      ></path>
      <path d="M11 12h8m-8 7h12"></path>
    </template>
    <template v-else-if="icon === 'texture-two'">
      <path
        d="M40 4H8a4 4 0 0 0-4 4v32a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4Zm-28 8v8m16 8v8m-8-24v8m8-8h8M12 28h8m8-8h8M12 36h8m16-8v8"
      ></path>
    </template>
    <template v-else-if="icon === 'toolkit'">
      <path d="M8 25v13a3 3 0 0 0 3 3h26a3 3 0 0 0 3-3V25"></path>
      <path
        d="M5 15a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-8Z"
      ></path>
      <path d="M31 13V9a2 2 0 0 0-2-2H19a2 2 0 0 0-2 2v4m-2 10v6m18-6v6"></path>
    </template>
    <template v-else-if="icon === 'transfer'">
      <path
        d="M37 28.392v7.015C37 39.048 34.089 42 30.497 42s-6.503-2.952-6.503-6.593l.012-22.264C24.006 9.198 21.095 6 17.503 6 13.912 6 11 9.198 11 13.143v15.25"
      ></path>
      <path
        d="m43 31-6-6-6 6m12-20.273C43 15.182 37 19 37 19s-6-3.818-6-8.273c0-1.519.632-2.975 1.757-4.05A6.148 6.148 0 0 1 37 5a6.15 6.15 0 0 1 4.243 1.677A5.598 5.598 0 0 1 43 10.727Zm-26 24C17 39.182 11 43 11 43s-6-3.818-6-8.273c0-1.519.632-2.975 1.757-4.05A6.148 6.148 0 0 1 11 29a6.15 6.15 0 0 1 4.243 1.677A5.598 5.598 0 0 1 17 34.727Z"
      ></path>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="2"
        cy="11"
        cx="37"
      ></circle>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="2"
        cy="35"
        cx="11"
      ></circle>
    </template>
    <template v-else-if="icon === 'transfer-data'">
      <path d="m10 8-6 6 6 6m28 8 6 6-6 6M4 14h40M4 34h40"></path>
    </template>
    <template v-else-if="icon === 'translate'">
      <path
        d="M28.286 37h11.428M42 42l-2.286-5L42 42Zm-16 0 2.286-5L26 42Zm2.286-5L34 24l5.714 13H28.286ZM16 6l1 3M6 11h22m-18 5s1.79 6.26 6.263 9.74C20.737 29.216 28 32 28 32"
      ></path>
      <path d="M24 11s-1.79 8.217-6.263 12.783C13.263 28.348 6 32 6 32"></path>
    </template>
    <template v-else-if="icon === 'tree-diagram'">
      <circle r="4" cy="24" cx="10"></circle>
      <circle r="4" cy="10" cx="38"></circle>
      <circle r="4" cy="24" cx="38"></circle>
      <circle r="4" cy="38" cx="38"></circle>
      <path d="M34 38H22V10h12M14 24h20"></path>
    </template>
    <template v-else-if="icon === 'trophy'">
      <path
        d="M24 30c6.627 0 12-5.53 12-12.353V4H12v13.647C12 24.47 17.373 30 24 30Z"
      ></path>
      <path
        d="M12 21V11H4c0 6.667 4 10 8 10Zm24 0V11h8c0 6.667-4 10-8 10Z"
        clip-rule="evenodd"
      ></path>
      <path d="M24 32v4"></path>
      <path d="m15 42 3.69-6h10.352L33 42H15Z"></path>
    </template>
    <template v-else-if="icon === 'upload-one'">
      <path
        d="M11.678 20.271C7.275 21.318 4 25.277 4 30c0 5.523 4.477 10 10 10 .947 0 1.864-.132 2.733-.378m19.322-19.351c4.403 1.047 7.677 5.006 7.677 9.729 0 5.523-4.477 10-10 10-.947 0-1.864-.132-2.732-.378M36 20c0-6.627-5.373-12-12-12s-12 5.373-12 12m5.065 7.881L24 20.924 31.132 28M24 38V24.462"
      ></path>
    </template>
    <template v-else-if="icon === 'upload-two'">
      <path
        d="M24.008 41 24 23m16.518 11.316A9.21 9.21 0 0 0 44 24c-1.213-3.83-4.93-5.929-8.947-5.925h-2.321a14.737 14.737 0 1 0-25.31 13.428"
      ></path>
      <path d="M30.364 27.636 24 21.272l-6.364 6.364"></path>
    </template>
    <template v-else-if="icon === 'usb'">
      <path
        d="M12 22a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm24 6a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      ></path>
      <path
        d="m19 9 5-5 5 5m-4 30L12 28.263V22m24 6v4.79L24 41m0-37v39m-3 1h6"
      ></path>
    </template>
    <template v-else-if="icon === 'usb-one'">
      <path d="M42 21H6v21h36V21Z"></path>
      <path d="M14 21V6h20v15m-14-9v2m8-2v2"></path>
    </template>
    <template v-else-if="icon === 'user-positioning'">
      <circle r="6" cy="16" cx="24"></circle>
      <path
        d="M36 36c0-6.627-5.373-12-12-12s-12 5.373-12 12M36 4h8v8M12 4H4v8m32 32h8v-8m-32 8H4v-8"
      ></path>
    </template>
    <template v-else-if="icon === 'vien-charts'">
      <circle r="12" cy="17" cx="24"></circle>
      <circle r="12" cy="31" cx="16"></circle>
      <circle r="12" cy="31" cx="32"></circle>
    </template>
    <template v-else-if="icon === 'view-list'">
      <rect rx="2" height="40" width="32" y="4" x="8"></rect>
      <path d="M21 14h12M21 24h12M21 34h12"></path>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M15 16a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
      ></path>
    </template>
    <template v-else-if="icon === 'volume-small'">
      <path
        d="M24 6v36c-7 0-12.201-9.16-12.201-9.16H6a2 2 0 0 1-2-2V17.01a2 2 0 0 1 2-2h5.799S17 6 24 6Z"
      ></path>
      <path
        d="M32 15h0a11.91 11.91 0 0 1 1.684 1.859A12.07 12.07 0 0 1 36 24c0 2.654-.846 5.107-2.278 7.09A11.936 11.936 0 0 1 32 33"
      ></path>
    </template>
    <template v-else-if="icon === 'warehousing'">
      <path d="M34 24H18m6-6-6 6 6 6"></path>
      <circle r="4" cy="24" cx="38"></circle>
      <path
        d="M40.706 13A20.102 20.102 0 0 0 38 9.717 19.935 19.935 0 0 0 24 4C12.954 4 4 12.954 4 24s8.954 20 20 20c5.45 0 10.392-2.18 14-5.717A20.104 20.104 0 0 0 40.706 35"
      ></path>
    </template>
    <template v-else-if="icon === 'workbench'">
      <path d="M12 33H4V7h40v26H12Z"></path>
      <path d="M16 22v4m8 7v6m0-21v8m8-12v12M12 41h24"></path>
    </template>
    <template v-else-if="icon === 'writing-fluently'">
      <path d="M24 24v-5L39 4l5 5-15 15h-5Z" clip-rule="evenodd"></path>
      <path d="M16 24H9a5 5 0 0 0 0 10h30a5 5 0 0 1 0 10H18"></path>
    </template>
  </base-icon>
</template>
<script>
import BaseIcon from "@/components/icon/BaseIcon";
export default {
  name: "BackMenuIcon",
  components: { BaseIcon },
  props: {
    icon: { type: String },
    weight: { type: Number, default: 4 },
    lineJoin: { type: String, default: "round" },
    lineCap: { type: String, default: "round" },
  },
};
</script>
