import { computed, inject, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { i18n } from "@/conf/lang";
import { DIALOGS } from "@/conf/symbols";
import { switchRoleInfo, userJumpTo } from "@/mods/common/user/userCommonLogic";
import { httpLogout } from "@/api/user";

const PAGE = {
  profile: "/front/setting/profile",
  resetPassword: "/front/setting/password",
  setting: "/front/setting/personal",
};

const DIALOG = {
  profile: "common.user.profile",
  resetPassword: "common.user.resetPassword",
  setting: "common.user.setting",
};

const $t = (c, arr) => i18n.global.t(c, arr);

export function useUserPopupView(propRefs, context) {
  if (!context) context = getCurrentInstance();
  const { emit } = context;
  const store = useStore();
  const router = useRouter();
  const dialogs = inject(DIALOGS);
  const { front } = propRefs;
  const user = computed(() => store.state.user);
  const rolesList = computed(() => store.state.rolesList);
  const roleText = computed(
    () => `${$t("user.role.text")}: ${user.value.role}`
  );
  const positionText = computed(() => {
    const u = user.value;
    const partTime = u.isPartTime
      ? " " + $t("hint.parentheses", [$t("user.position.part_time")])
      : "";
    return `${$t("user.position.text")}: ${u.position}${partTime}`;
  });
  const companyText = computed(
    () => `${$t("fields.company")}: ${user.value.company}`
  );
  const menus = computed(() => {
    return [
      { type: "divider" },
      { label: $t("user.profile"), action: "profile", menuIcon: "me" },
      {
        label: $t("user.password.reset"),
        action: "resetPassword",
        menuIcon: "lock",
      },
      { type: "divider" },
      { label: $t("user.logout"), action: "logout", menuIcon: "power" },
    ];
  });
  const onMenuClick = (e) => {
    emit("update:visible", false);
    if (e.action === "logout") {
      httpLogout().then(() => {
        store.commit("logout");
        return router.replace("/login");
      });
    } else if (e.action === "help") {
      if (front.value) {
        router.push("/front/help/list").then(() => {});
      } else {
        router.push("/system/help_detail").then(() => {});
      }
    } else if (e.action === "gotoBack") {
      userJumpTo(rolesList.value, "back");
    } else if (e.action === "gotoFront") {
      userJumpTo(rolesList.value, "front");
    } else {
      if (front.value) {
        if (PAGE[e.action]) router.push(PAGE[e.action]).then(() => {});
      } else {
        if (DIALOG[e.action]) dialogs.openDialog(DIALOG[e.action], {});
      }
    }
  };
  const onSwitchClick = (useStep) => {
    emit("update:visible", false);
    dialogs
      .openDialog("common.role.selectRole", {
        username: user.value.userId,
        mode: "switch",
        useStep,
      })
      .then((data) => {
        if (data) {
          store.commit("updateModAvailable", false);
          switchRoleInfo(data, true)
            .then(({ url }) => {
              return router.push(url);
            })
            .then(() => {
              store.commit("updateModAvailable", true);
            });
        }
      });
  };
  return {
    user,
    roleText,
    positionText,
    companyText,
    menus,
    onMenuClick,
    onSwitchClick,
  };
}
