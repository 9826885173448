import archViewer from "@/mods/front/dialog/archViewer";
import search from "@/mods/front/dialog/search";
import application from "@/mods/front/dialog/application";
import appraised from "@/mods/front/dialog/appraised";
import utilizationDetail from "@/mods/front/dialog/utilizationDetail";
import portal from "@/mods/front/dialog/portal";
import libraryApproval from "@/mods/front/dialog/libraryApproval";
export default {
  ...archViewer,
  search,
  libraryApproval,
  utilizationDetail,
  ...application,
  ...appraised,
  portal,
};
