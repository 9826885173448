export default {
  addNode: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/catalogConfig/dialog/AddNode"
      ),
  },
};
