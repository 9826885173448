export default {
  addRole: {
    width: "500px",
    okText: "actions.save",
    title: "添加角色",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/roleManage/dialog/AddRoleDialog"
      ),
  },
};
