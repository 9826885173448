<template>
  <a-layout theme="light" class="main-layout">
    <a-layout-header class="main-header">
      <div class="front-header">
        <div class="front-header-logo">
          <img
            v-if="hasLogoImg"
            class="front-header-logo-img"
            :src="logoSrc"
            :alt="logoText"
          />
          <span v-if="hasLogoText">{{ logoText }}</span>
        </div>
        <div class="front-header-right">
          <div class="front-header-user">
            <user-avatar :user="user" />
          </div>
        </div>
      </div>
      <drawer-view />
    </a-layout-header>
    <a-layout-content class="main-container">
      <arch-viewer />
    </a-layout-content>
  </a-layout>
</template>

<script setup>
import ArchViewer from "@/mods/archive/researchManageArchive/components/ArchViewer";
import DrawerView from "@/components/core/DrawerView.vue";
import { useSystemLogo } from "@/components/common/shared/globalInternal";
import { useFrontHeader } from "@/components/front/frontNav";
import UserAvatar from "@/components/user/UserAvatar.vue";
const { logoSrc, logoText, hasLogoImg, hasLogoText } = useSystemLogo("front");
const { user } = useFrontHeader();
</script>

<style scoped>
.main-layout {
  min-width: var(--rmx-min-width);
}
.main-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: var(--rmx-header-height);
  background-color: white;
  min-width: var(--rmx-min-width);
  box-shadow: var(--rmx-card-shadow);
  padding: 0;
}
.main-container {
  background-color: #f6f7fb;
  margin-top: var(--rmx-header-height);
  height: calc(100vh - var(--rmx-header-height));
  overflow: auto;
}
.front-header {
  display: flex;
  align-items: center;
  padding: 0 40px;
  min-width: 1000px;
  justify-content: space-between;
  height: 100%;
}
.front-header-logo {
  font-size: 22px;
  color: var(--rmx-text-1);
  line-height: 1.3;
  white-space: nowrap;
}
.front-header-logo-img {
  height: 40px;
  margin-right: 8px;
}
.front-header-logo-img:last-child {
  margin-right: 0;
}

.front-header-right {
  display: flex;
  align-items: center;
  line-height: 1;
}

.front-header-user {
  display: flex;
  align-items: center;
  margin-right: 32px;
}
</style>
