export default {
  editArchiveCodeDialog: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/rule/EditArchiveCodeDialog"
      ),
  },

  addVersionDialog: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/AddVersionDialog"
      ),
  },
};
