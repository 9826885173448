import BaseLayout from "@/components/core/BaseLayout";

export default [
  {
    path: "/warehouse",
    name: "Warehouse",
    component: BaseLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "entity_register",
        meta: { title: "实体登记" },
        component: () =>
          import(
            /* webpackChunkName: "warehouse" */ "@/mods/warehouse/entityRegister/EntityRegister"
          ),
      },
      {
        path: "archives_register",
        meta: { title: "出入库登记" },
        component: () =>
          import(
            /* webpackChunkName: "warehouse" */ "@/mods/warehouse/archivesRegister/ArchivesRegister"
          ),
      },
      {
        path: "access_manage",
        meta: { title: "门禁管理" },
        component: () =>
          import(
            /* webpackChunkName: "warehouse" */ "@/mods/warehouse/accessManage/AccessManage"
          ),
      },
      {
        path: "asset_fittings",
        meta: {
          title: "耗材管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "warehouse" */ "@/mods/warehouse/warehouseAssetFittings/WarehouseAssetFitting"
          ),
      },
      {
        path: "borrow_manage",
        meta: {
          title: "借阅管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "warehouse" */ "@/mods/warehouse/borrowManage/BorrowManage"
          ),
      },
      {
        path: "archives_disinfect",
        meta: {
          title: "消杀管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "warehouse" */ "@/mods/warehouse/archivesDisinfect/ArchivesDisinfect"
          ),
      },
      {
        path: "virtual_warehouse",
        meta: {
          title: "虚拟库房",
        },
        component: () =>
          import(
            /* webpackChunkName: "warehouse" */ "@/mods/warehouse/virtualWarehouse/VirtualWarehouse"
          ),
      },
      {
        path: "humiture_manage",
        meta: {
          title: "温湿度管理",
        },
        component: () =>
          import(
            /* webpackChunkName: "warehouse" */ "@/mods/warehouse/humitureManage/humitureManage"
          ),
      },
    ],
  },
];
