export const TABS_VIEW = Symbol("TABS_VIEW");
export const TABS_SUB_VIEW = Symbol("TABS_SUB_VIEW");

export const DETAIL_VIEW_PROVIDER = Symbol("DETAIL_VIEW_PROVIDER");

export const BACK_LAYOUT_HOLDER = Symbol("BACK_LAYOUT_HOLDER");

export const BLOCK_RECT_PROVIDER = Symbol("BLOCK_RECT_PROVIDER");

export const DIALOG_FOOTER_PROVIDER = Symbol("DIALOG_FOOTER_PROVIDER");
