export default {
  path: "setting",
  component: () =>
    import(/* webpackChunkName: "front" */ "@/mods/front/setting/SettingBase"),
  children: [
    {
      path: "profile",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/common/user/UserProfile"
        ),
    },
    {
      path: "password",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/common/user/ResetPassword"
        ),
    },
    {
      path: "personal",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/common/user/PersonalSetting"
        ),
    },
  ],
};
