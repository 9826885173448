import { userMenus, loadUserAuthResource } from "@/api/user";
import {
  httpLoadNavNum,
  httpLoadResearchManageTableData,
  httpLoadJoinResearchTableData,
  httpLoadArchiveApprovalTableData,
  httpGetClassifyType,
} from "@/api/archive/researchManageArchive";
import {
  RESEARCH_MANAGE_FLAG,
  AUTH_RELATION,
  RESOURCE_FLAG,
} from "@/mods/archive/researchManageArchive/enums.js";
import { ref, onMounted, watch, unref, reactive } from "vue";
import { TABLE_CELL_WIDTH } from "@/conf/constants";
import { BASE_URL } from "@/conf";
import store from "@/store";

/**
 * 获取用户权限和可展示菜单.
 *
 * @returns {object} - userAllAuth用户所有权限, showMenus可展示菜单.
 */
export function useUserAuthAndMenus() {
  const userAllAuth = ref([]);
  const showMenus = ref([]);
  onMounted(() => {
    Promise.all([userMenus(), loadUserAuthResource()]).then(
      ([menus, auths]) => {
        const researchFlagId =
          menus.find((item) => {
            return item.code === RESEARCH_MANAGE_FLAG;
          })?.id || "";
        showMenus.value = menus
          .filter((item) => auths.includes(item.code))
          .filter((node) => node.fatherid === researchFlagId);
        userAllAuth.value = auths;
      }
    );
  });
  return { userAllAuth, showMenus };
}

/**
 * 侧边栏数据.
 *
 * @param {array} menus - 可展示菜单数据.
 * @returns {object} - currentSideNav当前侧边栏, defaultOpenNav默认展开项，sideNavList侧边栏数据, refreshNavNum刷新num方法.
 */
export function useSideNav(menus) {
  const defaultNav = [
    {
      action: "PLAN_MANAGE",
      label: "计划管理",
    },
    {
      action: "ARCHIVE_RESEARCH",
      label: "档案编研",
    },
    {
      action: "RESULT_MANAGE",
      label: "成果管理",
    },
    {
      action: "OTHER",
      label: "其他",
    },
  ];
  const sideNavList = ref([]);
  const currentSideNav = ref("");
  const defaultOpenNav = ref([]);
  const getNavNum = () => {
    httpLoadNavNum().then((number) => {
      const numberHash = {
        planReady: number.saveing,
        planApproval: number.tasking,
        researchUsurp: number.writing,
        joinResearch: number.themeOutlineWriting,
        archiveApproval: number.themeApplyActive,
        resultApproval: number.resulting,
      };
      sideNavList.value.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((node) => {
            node.count = numberHash[node.action] || 0;
          });
        }
      });
    });
  };
  watch(menus, () => {
    sideNavList.value = defaultNav
      .map((item) => ({
        ...item,
        children: unref(menus)
          .filter((node) => AUTH_RELATION[item.action].includes(node.code))
          .map((node) => ({
            action: node.code,
            label: node.name,
            count: 0,
          })),
      }))
      .filter((item) => item.children.length !== 0);
    currentSideNav.value =
      currentSideNav.value || sideNavList.value[0]?.children[0]?.action;
    const pAction = sideNavList.value.find((item) => {
      return item.children.some((node) => node.action === currentSideNav.value);
    })?.action;
    defaultOpenNav.value = pAction ? [pAction] : [];
    getNavNum();
  });
  return {
    currentSideNav,
    defaultOpenNav,
    sideNavList,
    refreshNavNum: getNavNum,
  };
}

/**
 * 表格field数据.
 *
 * @param {string} navAction - 当前选中的nav.
 * @returns {array} - 表格field,
 */
export function useSetTableField(navAction) {
  const fieldList = [
    {
      column: "operation",
      label: "操作",
      width: TABLE_CELL_WIDTH.OPERATION,
      fixed: "left",
      validate: () => true,
    },
    {
      column: "title",
      label: "专题名称",
      width: TABLE_CELL_WIDTH.TITLE,
      align: "left",
      validate: () => true,
    },
    {
      column: "classifyname",
      label: "专题分类",
      width: "150px",
      validate: () => true,
    },
    {
      column: "name",
      label: "编研节点任务",
      width: TABLE_CELL_WIDTH.NAME,
      align: "left",
      validate: (action) => [RESOURCE_FLAG.JOIN_RESEARCH.TYPE].includes(action),
    },
    {
      column: "securityname",
      label: "密级",
      width: "100px",
      validate: (action) =>
        [RESOURCE_FLAG.RESULT_PUBLISH.TYPE].includes(action),
    },
    {
      column: "originator",
      label: "负责人",
      width: "150px",
      validate: () => true,
    },
    {
      column: "createdtime",
      label: "添加日期",
      width: TABLE_CELL_WIDTH.DATETIME,
      validate: () => true,
    },
    {
      column: "status",
      label: "状态",
      width: TABLE_CELL_WIDTH.STATUS,
      valueMap: {
        colors:
          unref(navAction) !== RESOURCE_FLAG.JOIN_RESEARCH.TYPE
            ? {
                1: "warn",
                2: "warn",
                3: "info",
                4: "info",
                5: "info",
                6: "error",
                7: "error",
                8: "",
              }
            : {
                2: "info",
                3: "success",
                4: "info",
                5: "",
              },
        names:
          unref(navAction) !== RESOURCE_FLAG.JOIN_RESEARCH.TYPE
            ? {
                1: "草稿",
                2: "待修改",
                3: "任务审批",
                4: "编研中",
                5: "成果审批",
                6: "未发布",
                7: "已发布",
                8: "已作废",
              }
            : {
                2: "编研中",
                3: "已完成",
                4: "编研中", // 退回后 继续编研
                5: "已作废",
              },
        tag: true,
      },
      fixed: "right",
      validate: (action) => action !== RESOURCE_FLAG.RESULT_PUBLISH.TYPE,
    },
  ];
  return fieldList.filter((item) => item.validate(unref(navAction)));
}
/**
 * 表格rowOps数据.
 *
 * @param {object} recordData - 表格行数据.
 * @param {object} userInfo - 当前登录人信息.
 * @param {string} [navAction] - 当前选中的nav.
 * @returns {array} - 表格行内操作按钮数据,
 */
export function useSetTableRowOps(recordData, userInfo, navAction) {
  const { status, createdby } = unref(recordData);
  // 判断是否为当前登录人创建的任务
  const isCurrentUserCreateTask = createdby === unref(userInfo).userId;

  const rowOperations = [
    {
      action: "handle",
      label: "处理",
      validate: [1, 2, 4].includes(status),
    },
    {
      action: "delete",
      label: "删除",
      validate: [1].includes(status),
    },
    {
      action: "seeDetail",
      label: "查看",
      validate: [3, 5, 6, 7, 8].includes(status),
    },
    {
      action: "publishTask",
      label: "发布",
      validate: [6, 7].includes(status),
    },
  ];
  return isCurrentUserCreateTask || unref(navAction) === "joinResearch"
    ? rowOperations.filter((item) => item.validate)
    : [
        {
          action: "seeDetail",
          label: "查看",
        },
      ];
}

/**
 * 查询表格数据.
 *
 * @param {object} condition - 查询条件.
 * @param {string} navAction - 当前选中的nav.
 * @returns {array} - 表格行内操作按钮数据,
 */
export function useLoadTableData(condition, navAction) {
  const currentNav = unref(navAction);
  const axiosList = [
    {
      axios: (param) => {
        const status = RESOURCE_FLAG.getStatusFromValue(currentNav);
        return httpLoadResearchManageTableData(param, status);
      },
      validate: [
        RESOURCE_FLAG.PLAN_READY.TYPE,
        RESOURCE_FLAG.PLAN_APPROVAL.TYPE,
        RESOURCE_FLAG.RESEARCH_USURP.TYPE,
        RESOURCE_FLAG.RESULT_APPROVAL.TYPE,
        RESOURCE_FLAG.RESULT_PUBLISH.TYPE,
        RESOURCE_FLAG.INVALID.TYPE,
      ].includes(currentNav),
    },
    {
      axios: (param) => httpLoadJoinResearchTableData(param), // 参与编研
      validate: [RESOURCE_FLAG.JOIN_RESEARCH.TYPE].includes(currentNav),
    },
    {
      axios: (param) => httpLoadArchiveApprovalTableData(param), // 参考档案审核
      validate: [RESOURCE_FLAG.ARCHIVE_APPROVAL.TYPE].includes(currentNav),
    },
  ];
  const axios = axiosList.find((item) => item.validate)?.axios || "";
  return axios
    ? axios(unref(condition)).then((data) => ({
        list: data.list,
        total: data.totalRows,
      }))
    : Promise.resolve({
        list: [],
        total: 0,
      });
}

/**
 * 表格operation数据.
 *
 * @param {string} navAction - 当前选中的nav.
 * @param {array} userAuth - 用户权限.
 * @param {object} userInfo - 当前登录人信息.
 * @returns {array} - 表格field.
 */
export function useSetTableOperation(navAction, userAuth, userInfo = {}) {
  const allAuth = unref(userAuth);
  const currentNav = unref(navAction);
  const hasFtp = unref(userInfo).hasFtp;
  const operation = [
    {
      action: "add",
      label: "添加",
      type: "primary",
      validate:
        [RESOURCE_FLAG.PLAN_READY.TYPE].includes(currentNav) ||
        (allAuth.includes("fastCreateTask") &&
          currentNav === RESOURCE_FLAG.RESEARCH_USURP.TYPE),
    },
    {
      action: "batch",
      label: "批量操作",
      children: [
        { action: "release", label: "发布" },
        { action: "cancelRelease", label: "取消发布" },
      ],
      validate:
        [RESOURCE_FLAG.RESULT_PUBLISH.TYPE].includes(currentNav) &&
        allAuth.includes("subjectmanage"),
    },
    {
      action: "batch",
      label: "导出zip包",
      children: hasFtp
        ? [
            { action: "exportToLocal", label: "导出到本地" },
            { action: "exportToTemporary", label: "导出到临时原文区" },
          ]
        : [{ action: "exportToLocal", label: "导出到本地" }],
      validate: [RESOURCE_FLAG.RESULT_PUBLISH.TYPE].includes(currentNav),
    },
    {
      action: "import",
      label: "导入zip包",
      validate: [RESOURCE_FLAG.RESULT_PUBLISH.TYPE].includes(currentNav),
    },
  ];
  const operations = operation.filter((item) => item.validate);
  return {
    operations,
    showOperation: operations.length !== 0,
  };
}

/**
 * 表格筛选条件相关数据
 * @returns {array} - 表格筛选条件配置项.
 */
export function useTableFilterCondition() {
  // 专题分类Field
  const topicTypeField = reactive({
    column: "classifycode",
    label: "请选择专题分类",
    type: "select",
    clearable: true,
    from: [],
    width: "180px",
  });
  // 筛选区Field
  const filterField = [topicTypeField];

  // 获取专题分类
  const getTopicType = () => {
    httpGetClassifyType().then(({ data }) => {
      topicTypeField.from = data;
    });
  };

  onMounted(() => {
    getTopicType();
  });

  return { filterField };
}

/**
 * 整理附件预览参数
 * @param {Object} attach
 * @returns {Object}
 */
export function formatViewAttachParams(attach) {
  return {
    isCanView: true,
    transferStatus: attach.transferStatus,
    attachCategory: attach.attachcategory,
    browseUrl: `${BASE_URL}common/record/browse`,
    browseParams: {
      "x-access-token": store.state?.accessToken,
      type: 11,
      attachTable: "s_attachment",
      id: attach.id,
      datetime: new Date().getTime() + "",
    },
  };
}

/**
 * 整理附件预览参数
 * @param {Object} attach
 * @returns {Object}
 */
export function formatEleFileViewAttach(attach, archtypeid) {
  return {
    isCanView: true,
    title: `${attach.title}.${attach.extension}`,
    transferStatus: attach.transferStatus,
    attachCategory: attach.attachcategory,
    browseUrl: `${BASE_URL}arrange/using/record/browse/special`,
    browseParams: {
      "x-access-token": store.state?.accessToken,
      type: 21,
      usingtype: 1,
      attachTable: "e_record",
      id: attach.id,
      fondsid: attach.fondsid,
      archtypeid,
    },
  };
}
