<template>
  <div class="back-header" :class="{ 'back-header-full': fullHeader }">
    <template v-if="purePage">
      <div class="back-header-left">
        <div class="back-header-title">
          {{ title }}
        </div>
        <div v-if="navInfo.subtitle" class="back-header-subtitle">
          {{ navInfo.subtitle }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="back-header-left">
        <back-unit-chooser :full-header="fullHeader" />
        <div class="back-header-title">
          {{ title }}
        </div>
        <div v-if="navInfo.subtitle" class="back-header-subtitle">
          {{ navInfo.subtitle }}
        </div>
        <div class="back-header-tools">
          <act-icon
            v-if="navInfo.showSetting"
            type="settingFill"
            :color="iconColor"
            :hover-color="iconHoverColor"
            @action="onIconAction('setting')"
          />
          <act-icon
            v-if="showHelp"
            type="questionCircleFill"
            :color="iconColor"
            :hover-color="iconHoverColor"
            @action="onIconAction('help')"
          ></act-icon>
        </div>
      </div>
      <div class="back-header-right">
        <user-info-header :full-header="fullHeader" />
      </div>
    </template>
  </div>
</template>

<script>
import { computed, inject, toRefs, unref } from "vue";
import { DIALOGS } from "@/conf/symbols";
import UserInfoHeader from "@/components/user/UserInfoHeader";
import ActIcon from "@/components/common/action/ActIcon";
import BackUnitChooser from "@/components/back/BackUnitChooser";
export default {
  name: "BackLayoutHeader",
  components: { BackUnitChooser, ActIcon, UserInfoHeader },
  props: {
    navInfo: { type: Object },
    fullHeader: { type: Boolean },
    purePage: { type: Boolean },
  },
  setup(props) {
    const { navInfo, fullHeader } = toRefs(props);
    const dialogs = inject(DIALOGS, null);
    const meta = computed(() => navInfo.value?.meta || {});
    const showHelp = computed(() => {
      return navInfo.value.showHelp || meta.value.description;
    });
    const title = computed(() => {
      return navInfo.value.title || meta.value.title;
    });
    const iconColor = computed(() => (fullHeader.value ? "white" : undefined));
    const iconHoverColor = computed(() =>
      fullHeader.value ? "var(--rmx-primary-color-2)" : undefined
    );
    const onIconAction = (e) => {
      if (e === "help") {
        dialogs?.openDialog("common.help.introduction", {
          info: unref(navInfo)?.meta || {},
        });
        return;
      }
      navInfo.value?.func?.(e);
    };
    return { title, showHelp, iconColor, iconHoverColor, onIconAction };
  },
};
</script>

<style scoped>
.back-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  background: white;
}
.back-header-full {
  background: none;
  box-shadow: none;
  flex: 1;
  line-height: 1.5;
}
.back-header:not(.back-header-full) {
  box-shadow: var(--rmx-header-shadow);
  padding: 0 20px;
}
.back-header-left {
  display: flex;
  align-items: center;
}
.back-header-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--rmx-text-1);
  letter-spacing: 0;
}
.back-header-subtitle {
  margin-left: 16px;
  font-size: 14px;
  line-height: 18px;
  color: var(--rmx-text-3);
}
.back-header-right {
  display: flex;
}
.back-header-tools {
  display: flex;
  gap: 24px;
  margin-left: 24px;
}
.back-header-full .back-header-title {
  padding-left: 24px;
  border-left: 1px solid var(--rmx-split);
}
.back-header-full .back-header-title,
.back-header-full .back-header-subtitle {
  color: white;
}
</style>
