export default {
  archiveStatisticChart: {
    title: "查看统计图表",
    width: "l",
    footer: null,
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "statistic" */ "@/mods/statistic/archiveStatistic/dialog/ArchiveStatisticChart"
      ),
  },
  archiveStatisticChartBing: {
    title: "查看统计图表",
    width: "l",
    footer: null,
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "statistic" */ "@/mods/statistic/archiveStatistic/dialog/ArchiveStatisticChartBing"
      ),
  },
};
