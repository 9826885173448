<template>
  <div class="picture-viewer">
    <img
      class="picture-viewer-content"
      :src="url"
      :alt="title"
      :title="title"
      @contextmenu="onMenu"
    />
    <iframe ref="iframe" style="display: none" />
  </div>
</template>

<script>
import { ref, toRefs } from "vue";
import { PROPS_ATTACH_FILE } from "@/components/common/shared/compAttrs";

export default {
  name: "PictureViewer",
  props: { ...PROPS_ATTACH_FILE },
  setup(props, { expose }) {
    const { url } = toRefs(props);
    const iframe = ref(null);
    const doPrint = () => {
      const i = iframe.value;
      if (i) {
        i.src = "about:blank";
        i.contentWindow.document.write(
          `<img src="${url.value}" alt="" style="max-width: 100%;margin:auto" onload="print();close();"/>`
        );
      }
    };
    const onMenu = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    expose({ doPrint });
    return { iframe, onMenu };
  },
};
</script>

<style scoped>
.picture-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--rmx-attach-background);
  padding: 6px;
}
.picture-viewer-content {
  position: relative;
  font-size: 6em;
  max-width: 100%;
  max-height: 100%;
  user-select: none;
  word-break: break-all;
}
.picture-viewer-content[alt]:before {
  content: attr(alt);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding-top: 24px;
  font-size: 16px;
  background: var(--rmx-attach-background);
  color: var(--rmx-text-3);
}
.picture-viewer-content[alt]:after {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, #f44336, #e91e63);
  width: 24px;
  height: 24px;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -36px;
  clip-path: polygon(
    20% 0%,
    0% 20%,
    30% 50%,
    0% 80%,
    20% 100%,
    50% 70%,
    80% 100%,
    100% 80%,
    70% 50%,
    100% 20%,
    80% 0%,
    50% 30%
  );
}
</style>
