export default {
  add: {
    title: "新建",
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/dataPackage/dialog/DataPackageAdd"
      ),
  },
  store: {
    width: "xxl",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/dataPackage/dialog/DataPackageStore"
      ),
  },
  storeAdd: {
    title: "新建载体信息",
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/dataPackage/dialog/DataPackageStoreAdd"
      ),
  },
  upload: {
    title: "上传文件",
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/dataPackage/dialog/DataPackageUpload"
      ),
  },
  verifyResult: {
    width: "xl",
    okText: "恢复档案",
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/dataPackage/dialog/DataPackageVerifyResult"
      ),
  },
};
