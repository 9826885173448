export default {
  addNewResource: {
    width: "s",
    okText: "actions.save",
    title: "添加资源",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/resourceManage/dialog/AddNewResource"
      ),
  },
  editFatherResource: {
    width: "s",
    okText: "actions.save",
    title: "变更上级",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/resourceManage/dialog/EditFatherResource"
      ),
  },
};
