export default {
  libraryApprovalDialog: {
    width: "1600px",
    title: "到馆利用申请审批",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "libraryApproval_dialog" */ "@/mods/front/libraryApproval/libraryApproval"
      ),
  },
  editMaskedWords: {
    width: "m",
    title: "编辑遮盖敏感词",
    component: () =>
      import(
        /* webpackChunkName: "libraryApproval_dialog" */ "@/mods/front/components/MaskedWordDialog"
      ),
  },
  approvalDetail: {
    width: "1000px",
    footer: null,
    title: "审批详情",
    component: () =>
      import(
        /* webpackChunkName: "libraryApproval_dialog" */ "@/mods/front/libraryApproval/dialog/ApprovalDetailDialog"
      ),
  },
};
