/*
 * @Author: HuaZhiQiang
 * @Date: 2022-09-26 15:37:39
 * @LastEditors: HuaZhiQiang
 * @LastEditTime: 2022-09-27 16:31:48
 * @Description: file content
 */
export default {
  processDetail: {
    width: "s",
    title: "编辑流程",
    okText: "保存",
    cancelText: "取消",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/manageProcessFlow/dialog/ProcessDetail"
      ),
  },
  setupProcess: {
    width: "xl",
    title: "流程设置",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/manageProcessFlow/dialog/SetupProcess"
      ),
  },
  setObject: {
    width: "s",
    title: "流程节点设置",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/manageProcessFlow/dialog/SetObject"
      ),
  },
  flowChart: {
    width: "xl",
    title: "流程图在线设计",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/manageProcessFlow/dialog/FlowChart"
      ),
  },
};
