<template>
  <front-layout>
    <error-page-view code="404" />
  </front-layout>
</template>

<script>
import FrontLayout from "@/components/common/layout/FrontLayout";
import ErrorPageView from "@/components/common/display/ErrorPageView";
export default {
  name: "FrontNotFound",
  components: { ErrorPageView, FrontLayout },
};
</script>

<style scoped></style>
