import { getQueryStr, isEmptyHtml } from "@/util/strUtil";
import { viewArchDetail } from "@/mods/util/todoUtil";
// import { showMessage } from "@/util/dialog";
import { STORAGE_PREFIX } from "@/conf";
import { WNODE_PROCESS_INSTANCE_LOG_STATUS } from "@/mods/common/enum/flow";
import { showConfirm } from "@/util/dialog";

/**
 * @function getSelection
 * @description: 获取选中文本
 * @return {String} 选中文本
 */
export function getSelection() {
  return new Promise((resolve, reject) => {
    const text = localStorage[STORAGE_PREFIX + "copy_text"]
      ? JSON.parse(localStorage[STORAGE_PREFIX + "copy_text"])
      : "";
    if (text) {
      localStorage[STORAGE_PREFIX + "copy_text"] = JSON.stringify("");
      resolve(text);
    } else {
      reject();
    }
  });
}

/**
 * 在新窗口打开页面
 * @param url 地址
 * @param query 查询
 */
export function openNewWindow(url, query = {}) {
  const q = getQueryStr(query);
  if (url?.[0] === "/") url = url.substring(1);
  window.open(`#/${url}?${q}`, "_blank");
}

export function handleSeeArchiveDetail({ item, isPortalWork, dialogs }) {
  const { archtypeid, objecttype, archid, tableid, isShow, disabledvoldetail } =
    item;
  if (isShow === 0) {
    showConfirm({
      title: "利用库中已删除此档案，无法查看详情",
      okText: "我知道了",
      okCancel: false,
    });
    return;
  }
  if (isPortalWork) {
    /*编研-固定都是利用库的数据，把tableid全换成u开头的*/
    viewArchDetail({
      archDetail: {
        archtypeid,
        objecttype,
        archid,
        tableid: `u${tableid}`,
      },
      dialogs,
    });
  } else {
    openNewWindow("archiveDetail", {
      archTypeId: archtypeid,
      objectType: objecttype,
      dataId: archid,
      disabled: disabledvoldetail,
    });
  }
}

/**
 * 判断是否为有效富文本html内容
 * @param {string} htmlContent 富文本内容
 */
export function isEffectiveHtmlContent(htmlContent) {
  if (!htmlContent) {
    return false;
  }
  const hasTextContent = !isEmptyHtml(htmlContent);
  const imgReg = /<img.*?(?:>|\/>)/gi;
  const hasImage = imgReg.test(htmlContent);
  const videoReg = /<video.*?(?:>|\/>)/gi;
  const hasVideo = videoReg.test(htmlContent);
  const audioReg = /<audio.*?(?:>|\/>)/gi;
  const hasAudio = audioReg.test(htmlContent);
  return hasTextContent || hasImage || hasVideo || hasAudio;
}

// 格式化时间线组件数据
export function formatTimeLineLogData(baseData = []) {
  const iconHash = {
    1: "start",
    6: "success",
    3: "error",
    4: "error",
    5: "error",
  };
  const colorHash = {
    6: "green",
    3: "red",
    4: "red",
    5: "red",
  };
  return baseData.map((item, index) => {
    return {
      id: item.id,
      type:
        (index === 0 || index === baseData.length - 1) && iconHash[item.status]
          ? iconHash[item.status]
          : "",
      color:
        index === 0 && colorHash[item.status] ? colorHash[item.status] : "blue",
      title: item.nodeinfoname,
      time: item.createdtime,
      username: item.username,
      userid: item.userid,
      statusName: item.id
        ? `（${WNODE_PROCESS_INSTANCE_LOG_STATUS.getNameFromValue(
            item.status
          )}）`
        : "（正在处理）",
      memo: item.memo || "",
    };
  });
}

// 格式化修订说明数据
export function formatEditDescData(baseData = []) {
  const actionHash = {
    提交任务: "修改说明",
    退回任务: "退回原因",
  };
  return baseData.map((item) => {
    return {
      id: item.id,
      title: item.action,
      time: item.createdtime,
      username: item.username,
      userid: item.userid,
      statusName: item.description ? `（${actionHash[item.action]}）` : "",
      memo: item.description || "",
    };
  });
}

/**
 * 判断当前节点位置
 * @param {object} checkNode - 要检查的数据.
 * @param {string} checkNode.id - id.
 * @param {string} checkNode.fatherid - 父id.
 * @param {string} checkNode.orderindex - 排序.
 * @param {array} nodeList - 所有数据.
 * @param {string} type - 类型.
 */
export function checkNodePosition(checkNode, nodeList, type) {
  const checkList = nodeList
    .filter((item) => item.fatherid === checkNode.fatherid)
    .sort((a, b) => {
      return a.orderindex - b.orderindex;
    });
  if (type === "isFirst") {
    return checkList[0].id === checkNode.id;
  }
  if (type === "isLast") {
    return checkList.at(-1).id === checkNode.id;
  }
}
