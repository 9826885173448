<template>
  <div
    class="color-picker"
    :class="{
      'color-picker-large': isLarge,
      'color-picker-disabled': disabled,
    }"
  >
    <template v-for="c in options" :key="c">
      <div
        class="color-picker-item"
        :class="{ 'color-picker-item-active': c === realValue }"
        :style="{ '--color-picker-item-color': c }"
        @click="handleClick(c)"
      ></div>
    </template>
    <label
      v-if="input"
      class="color-picker-item color-picker-item-custom"
      :style="{ '--color-picker-custom': realValue }"
    >
      <input
        type="color"
        class="color-picker-item-input"
        :value="realValue"
        :disabled="disabled"
        @input="handleChange"
      />
    </label>
  </div>
</template>

<script>
import { THEME_COLORS } from "@/conf/constants";

export default {
  name: "ColorPicker",
  props: {
    options: { type: Array, default: THEME_COLORS },
    value: { type: String },
    size: { type: String },
    disabled: { type: Boolean },
    input: { type: Boolean },
  },
  emits: ["change", "update:value"],
  expose: [],
  data() {
    return { cachedValue: "" };
  },
  computed: {
    isLarge() {
      return this.size === "l" || this.size === "large";
    },
    realValue: {
      get() {
        return this.value || this.cachedValue;
      },
      set(val) {
        this.cachedValue = val;
        this.$emit("update:value", val);
      },
    },
  },
  methods: {
    handleClick(c) {
      if (!this.disabled) {
        this.realValue = c;
        this.$emit("change", c);
      }
    },
    handleChange(e) {
      if (!this.disabled) {
        const c = e.target.value;
        this.realValue = c;
        this.$emit("change", c);
      }
    },
  },
};
</script>

<style scoped>
.color-picker {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-flow: wrap;
  --color-picker-item-color: transparent;
}
.color-picker-item {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  background: var(--color-picker-item-color);
  width: 24px;
  height: 24px;
  border-radius: 4px;
  position: relative;
  transition: scale ease 0.5s;
}
.color-picker-item-custom {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--rmx-border);
  --color-picker-custom: #ff5c93;
}
.color-picker-item-input {
  appearance: none;
  position: absolute;
  height: 12px;
  width: 12px;
  border: none;
  background: none;
  padding: 0;
  outline: 0;
  left: 0;
  bottom: 0;
}
.color-picker-item-custom:before {
  content: "";
  display: block;
  position: absolute;
  background: var(--color-picker-custom);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.color-picker-large .color-picker-item {
  width: 60px;
  height: 60px;
}
.color-picker-disabled .color-picker-item {
  cursor: not-allowed;
}
.color-picker:not(.color-picker-disabled) .color-picker-item:hover {
  box-shadow: 0 0 4px 2px #cccccc;
  transform: scale(1.1);
}
.color-picker-item::after {
  content: "";
  position: absolute;
  left: 8px;
  top: 2px;
  width: 5px;
  height: 13px;
  border: solid white;
  border-width: 0 4px 4px 0;
  box-sizing: content-box;
  transform: rotate(45deg);
  transition: opacity ease 0.3s;
  opacity: 0;
}
.color-picker-item.color-picker-item-active::after {
  opacity: 1;
}
.color-picker-large .color-picker-item::after {
  content: none;
}
.color-picker-large .color-picker-item.color-picker-item-active {
  border: 2px solid white;
  box-shadow: 0 0 4px 2px var(--color-picker-item-color);
}
</style>
