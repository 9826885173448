<template>
  <div class="ofd-viewer">
    <iframe-view
      ref="iframe"
      :url="realUrl"
      class="pdf-viewer-content"
      @message="onMessage"
    />
  </div>
</template>

<script>
import { computed, ref, toRefs } from "vue";
import { BASE_STATIC_URL, IS_IN_DEBUG } from "@/conf";
import { IFRAME_ACTIONS } from "@/conf/constants";
import { PROPS_ATTACH_FILE } from "@/components/common/shared/compAttrs";
import IframeView from "@/components/common/display/IframeView";

export default {
  name: "OfdViewer",
  components: { IframeView },
  props: { ...PROPS_ATTACH_FILE },
  setup(props, { expose }) {
    const { url } = toRefs(props);
    const realUrl = computed(() => {
      const prefix = IS_IN_DEBUG ? BASE_STATIC_URL : "./";
      return `${prefix}ofd/index.html?file=${encodeURIComponent(url.value)}`;
    });
    const resolver = ref(null);
    const iframe = ref(null);
    const getSelection = () =>
      new Promise((resolve) => {
        resolver.value = resolve;
        iframe.value?.sendMessage({ action: IFRAME_ACTIONS.GET_SELECTION });
      });
    const doPrint = () => {
      iframe.value?.sendMessage({ action: IFRAME_ACTIONS.DO_PRINT });
    };
    const onMessage = (e) => {
      if (e?.data?.action === IFRAME_ACTIONS.GET_SELECTION) {
        resolver.value?.((e.data.text || "").trim());
      }
    };
    expose({ getSelection, doPrint });
    return { iframe, realUrl, onMessage };
  },
};
</script>

<style scoped></style>
