<template>
  <a-layout theme="light" class="main-layout">
    <a-layout-header class="main-header">
      <front-header />
    </a-layout-header>
    <a-layout-content class="main-container">
      <template v-if="$slots.default">
        <slot></slot>
      </template>
      <template v-else>
        <router-view></router-view>
      </template>
    </a-layout-content>
  </a-layout>
</template>

<script>
import FrontHeader from "@/components/front/FrontHeader";
export default {
  name: "BaseHeaderLayout",
  components: { FrontHeader },
};
</script>

<style scoped>
.main-layout {
  min-width: var(--rmx-min-width);
}
.main-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: var(--rmx-header-height);
  background-color: white;
  min-width: var(--rmx-min-width);
  box-shadow: var(--rmx-card-shadow);
  padding: 0;
}
.main-container {
  background-color: #f6f7fb;
  margin-top: var(--rmx-header-height);
  height: calc(100vh - var(--rmx-header-height));
  overflow: auto;
}
</style>
