<script>
import { computed, defineComponent, toRefs } from "vue";
import { useToolAction } from "@/components/common/shared/actionInternal";
import { Menu } from "ant-design-vue";

export default defineComponent({
  name: "MenuView",
  emits: ["click", "update:value", "update:opened"],
  props: {
    menus: { type: Array, default: () => [] },
    mode: { type: String, default: "horizontal" },
    baseUrl: { type: String, default: "/" },
    value: { type: [String, Number] },
    opened: { type: Array },
    useRoute: { type: Boolean },
    useLocale: { type: Boolean },
    extraState: { type: Object, default: () => ({}) },
    parentCustomTitle: { type: Boolean },
    menuIndent: { type: Number },
  },
  setup(props, { emit, slots }) {
    const {
      menus,
      mode,
      value,
      opened,
      useLocale,
      extraState,
      parentCustomTitle,
      menuIndent,
    } = toRefs(props);
    const { opLabel, opTips, checkDisabled } = useToolAction(
      useLocale,
      extraState
    );
    const makeTitle = ({ x, level, menuKey }) => {
      const label = opLabel(x.label);
      return slots.title
        ? slots.title?.({
            level,
            key: menuKey,
            action: x.action,
            title: label,
            menu: x,
            selected: menuKey === value.value,
          })
        : label;
    };
    const generateChildren = (children, { pk = "", level = 0 } = {}) => {
      if (!children) return [];
      return children.map((x) => {
        if (x.type === "divider") {
          return <Menu.Divider />;
        }
        const path = `${pk}/${x.key}`;
        const menuKey = (x.action ?? x.url) || path;
        const menuSlot = {
          icon: slots.icon
            ? () =>
                slots.icon?.({ level, key: menuKey, action: x.action, menu: x })
            : undefined,
        };
        if (!x.children) {
          return (
            <Menu.Item
              key={menuKey}
              disabled={checkDisabled(x)}
              title={opTips(x)}
              v-slots={menuSlot}
              originData={x}
            >
              {makeTitle({ x, level, menuKey })}
            </Menu.Item>
          );
        }
        const menuTitle = parentCustomTitle.value
          ? makeTitle({ x, level, menuKey })
          : opLabel(x.label);
        return (
          <Menu.SubMenu
            key={menuKey}
            title={menuTitle}
            disabled={checkDisabled(x)}
            v-slots={menuSlot}
          >
            {generateChildren(x.children, { pk: path, level: level + 1 })}
          </Menu.SubMenu>
        );
      });
    };
    const handleClick = (e) => {
      emit("click", { action: e.key, item: e?.item?.originData });
    };
    const handleSelect = ({ selectedKeys }) => {
      if (Array.isArray(selectedKeys)) emit("update:value", selectedKeys[0]);
    };
    const handleOpenChange = (keys) => {
      if (Array.isArray(keys)) emit("update:opened", keys);
    };
    const selectKeys = computed(() =>
      value.value ? [value.value] : undefined
    );
    return () => {
      const content = generateChildren(menus.value);
      return (
        <Menu
          key={"menu_view"}
          mode={mode.value}
          selectedKeys={selectKeys.value}
          openKeys={opened.value}
          inlineIndent={menuIndent.value}
          onClick={handleClick}
          onSelect={handleSelect}
          onOpenChange={handleOpenChange}
        >
          {content}
        </Menu>
      );
    };
  },
});
</script>

<style scoped></style>
