<template>
  <div class="user-popup-view">
    <div class="user-popup-view-desc">
      <div class="user-popup-view-header">
        <div class="user-popup-view-name" :title="user.name">
          {{ user.name }}
        </div>
      </div>
      <div class="user-popup-view-info" :title="companyText">
        {{ companyText }}
      </div>
      <div class="user-popup-view-info" :title="positionText">
        <span class="user-popup-view-info-text">{{ positionText }}</span>
        <common-icon
          v-if="user.switchable && user.hasMultiplePositions"
          icon="exchange-four"
          class="user-popup-view-switch"
          :title="$t('user.position.switch')"
          @click="onSwitchClick('position')"
        />
      </div>
      <div class="user-popup-view-info" :title="roleText">
        <span class="user-popup-view-info-text">{{ roleText }}</span>
        <common-icon
          v-if="user.switchable && user.roleSwitchable"
          icon="exchange-four"
          class="user-popup-view-switch"
          :title="$t('user.role.switch')"
          @click="onSwitchClick('role')"
        />
      </div>
    </div>
    <div class="user-popup-view-menu">
      <template v-for="m in menus" :key="m.action">
        <div v-if="m.type === 'divider'" class="user-popup-view-menu-divider" />
        <div v-else class="user-popup-view-menu-item" @click="onMenuClick(m)">
          <div class="user-popup-view-menu-icon">
            <common-icon :icon="m.menuIcon" />
          </div>
          <div class="user-popup-view-menu-text">{{ m.label }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { useUserPopupView } from "@/components/user/userPopupViewFunc";
import CommonIcon from "@/components/icon/CommonIcon";

export default {
  name: "UserPopupView",
  components: { CommonIcon },
  props: {
    visible: { type: Boolean },
    front: { type: Boolean },
  },
  setup(props, { emit }) {
    const {
      user,
      roleText,
      positionText,
      companyText,
      menus,
      onMenuClick,
      onSwitchClick,
    } = useUserPopupView(toRefs(props), { emit });
    return {
      user,
      roleText,
      positionText,
      companyText,
      menus,
      onMenuClick,
      onSwitchClick,
    };
  },
};
</script>

<style scoped>
.user-popup-view {
  margin: -12px -16px;
  width: 240px;
}
.user-popup-view-desc {
  padding: 18px;
}
.user-popup-view-header {
  display: flex;
  align-items: center;
}
.user-popup-view-name {
  flex: 1;
  font-weight: bold;
  font-size: 18px;
}
.user-popup-view-switch {
  font-size: 20px;
  color: var(--rmx-primary-color);
  cursor: pointer;
  vertical-align: middle;
  line-height: 1;
}
.user-popup-view-info {
  margin-top: 8px;
  white-space: break-spaces;
}
.user-popup-view-info-text {
  margin-right: 8px;
}
.user-popup-view-menu-divider {
  height: 1px;
  background: var(--rmx-split);
}
.user-popup-view-menu-item {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  cursor: pointer;
}
.user-popup-view-menu-item:hover {
  background: var(--rmx-primary-color-1);
  color: var(--rmx-primary-color);
}
.user-popup-view-menu-text {
  margin-left: 8px;
}
.user-popup-view-menu-icon {
  font-size: 20px;
  color: var(--rmx-icon-1);
}
.user-popup-view-menu-item:hover .user-popup-view-menu-icon {
  color: var(--rmx-primary-color);
}
</style>
