<template>
  <a-form
    ref="formRef"
    class="field-render rmx-form"
    :name="formName"
    :label-col="{ style: labelStyle }"
    :style="formStyle"
    :colon="false"
    :layout="layout"
    :model="formData"
    @finish="onSubmit"
  >
    <slot
      :do-submit="() => doSubmit()"
      :do-reset="() => doReset()"
      :loading="formLoading"
    >
      <form-group
        :fields="fields"
        :value="formData"
        :validators="validators"
        :default-span="formDefaultSpan"
        :default-width="defaultWidth"
        :with-wrap="useGrid"
        :use-grid="useGrid"
        :has-placeholder="hasPlaceholder"
        :auto-placeholder="autoPlaceholder"
        :use-locale="useLocale"
        :disabled="disabled"
        :size="size"
        @action="$emit('action', $event)"
        @change="$emit('change', $event)"
      >
        <template
          v-if="$slots.custom"
          #custom="{ itemName, field, disabled, itemValue, setValue }"
        >
          <slot
            name="custom"
            :item-name="itemName"
            :item-value="itemValue"
            :field="field"
            :disabled="disabled"
            :set-value="setValue"
          ></slot>
        </template>
      </form-group>
      <a-form-item v-if="formShowAction" label="">
        <slot
          name="actions"
          :do-submit="() => doSubmit()"
          :do-reset="() => doReset()"
          :loading="formLoading"
        >
          <a-space>
            <template v-for="act in formActions" :key="act.action">
              <act-button
                v-if="act.action === 'submit'"
                :type="act.type"
                html-type="submit"
                :loading="formLoading"
              >
                {{ act.label }}
              </act-button>
              <act-button
                v-else-if="act.action === 'reset'"
                :type="act.type"
                :disabled="formLoading"
                :debounce="actionDebounce"
                @action="doReset"
              >
                {{ act.label }}
              </act-button>
              <act-button
                v-else
                :type="act.type"
                :disabled="formLoading"
                :debounce="actionDebounce"
                @action="onAction(act)"
              >
                {{ act.label }}
              </act-button>
            </template>
          </a-space>
        </slot>
      </a-form-item>
    </slot>
  </a-form>
</template>

<script>
import { computed, toRefs } from "vue";
import { i18n } from "@/conf/lang";
import {
  EMITS_ABSTRACT_FORM,
  PROPS_ABSTRACT_FORM,
} from "@/components/common/shared/compAttrs";
import { useAbstractForm } from "@/components/common/shared/formInternal";
import ActButton from "@/components/common/action/ActButton";
import FormGroup from "@/components/common/form/FormGroup";

export default {
  name: "FieldRender",
  components: { ActButton, FormGroup },
  emits: [...EMITS_ABSTRACT_FORM],
  props: {
    ...PROPS_ABSTRACT_FORM,
  },
  setup(props, { emit, expose }) {
    const propsRef = toRefs(props);
    const {
      formRef,
      formName,
      formLoading,
      formData,
      formStyle,
      formDefaultSpan,
      labelStyle,
      formShowAction,
      doSubmit,
      doValidate,
      doReset,
      doClearValidation,
      onSubmit,
      onAction,
    } = useAbstractForm(propsRef, { emit });
    const { actions } = propsRef;
    const formActions = computed(() =>
      actions.value.map((x) => {
        if (x === "submit") {
          return {
            type: "primary",
            label: i18n.global.t("actions.submit"),
            action: "submit",
          };
        } else if (x === "reset") {
          return {
            type: "default",
            label: i18n.global.t("actions.reset"),
            action: "reset",
          };
        }
        return x;
      })
    );
    expose({
      doSubmit,
      doReset,
      doValidate,
      doClearValidation,
    });
    return {
      formRef,
      formName,
      formLoading,
      formData,
      formDefaultSpan,
      labelStyle,
      formShowAction,
      formStyle,
      formActions,
      doSubmit,
      doValidate,
      doReset,
      doClearValidation,
      onAction,
      onSubmit,
    };
  },
};
</script>

<style scoped></style>
