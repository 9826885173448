<template>
  <a-tooltip :title="tips">
    <slot>
      <info-circle-outlined class="tips-icon-icon" />
    </slot>
  </a-tooltip>
</template>

<script>
import { InfoCircleOutlined } from "@ant-design/icons-vue";

export default {
  name: "TipsIcon",
  props: { tips: { type: String } },
  components: { InfoCircleOutlined },
};
</script>

<style scoped>
.tips-icon-icon {
  color: var(--rmx-icon-3);
  font-size: 14px;
  cursor: help;
}
.tips-icon-icon:not(:first-child) {
  margin-left: 4px;
}
</style>
