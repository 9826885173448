import BaseLayout from "@/components/core/BaseLayout";

export default [
  {
    path: "/workbench",
    name: "Workbench",
    component: BaseLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "personal",
        meta: {
          title: "个人",
        },
        component: () =>
          import(
            /* webpackChunkName: "workbench" */ "@/mods/workbench/personal/WorkbenchPersonal"
          ),
      },
      {
        path: "fullstat",
        meta: {
          title: "驾驶舱",
        },
        component: () =>
          import(
            /* webpackChunkName: "workbench" */ "@/mods/workbench/fullstat/FullstatHome"
          ),
      },
      {
        path: "statistic_home",
        meta: {
          title: "统计",
        },
        component: () =>
          import(
            /* webpackChunkName: "workbench" */ "@/mods/workbench/statistics/StatisticsHome"
          ),
      },
    ],
  },
];
