import { createStore } from "vuex";
import md5 from "js-md5";
import mutations from "@/store/mutations";
import { DEFAULT_TITLE, STORAGE_PREFIX, ADMIN_USER_NAME } from "@/conf";
import { snakeCase } from "@/util/strUtil";
import { BACK_ROLE_TYPE } from "@/mods/common/enum/flow";

const storeVal = (name, defVal, storeKey) => {
  const key = STORAGE_PREFIX + (storeKey || snakeCase(name));
  return JSON.parse(localStorage[key] ?? JSON.stringify(defVal));
};

const storeStr = (name, defVal = "", storeKey = "") => {
  const key = STORAGE_PREFIX + (storeKey || snakeCase(name));
  return localStorage[key] ?? `${defVal}`;
};

export default createStore({
  state: {
    language: storeStr("language", navigator.language.toLowerCase()),
    skin: storeStr("skin"),
    modAvailable: true,
    usePortal: storeVal("usePortal", true),
    loginExpire: storeVal("loginExpire", 0),
    accessToken: storeStr("accessToken", ""),
    loginType: storeStr("loginType", ""),
    user: storeVal("user", {}, "login_user"),
    userCounts: {},
    rememberInfo: storeVal("rememberInfo", {}),
    systemConfig: {
      title: DEFAULT_TITLE,
      logoInfo: {
        systemTitle: DEFAULT_TITLE,
        backLogoSrc: "",
        backLogoType: "1",
        loginLogoSrc: "",
        loginLogoType: "1",
        frontLogoSrc: "",
        frontLogoType: "1",
        searchLogoSrc: "",
        searchLogoType: "1",
      },
      systemType: undefined,
    },
    rememberPsw: storeVal("rememberPsw", {}),
    roleInfo: storeVal("roleInfo", {}),
    rolesList: storeVal("rolesList", []),
    provisionalInfo: {},
    searchHistory: JSON.parse(localStorage[STORAGE_PREFIX + "history"] ?? "{}"),
    cardefaultNum: storeStr("cardefaultNum", 4),
    logId: storeStr("logId", ""),
  },
  getters: {
    isGOV(state) {
      return state.systemConfig.systemType === "Z";
    },
    isLOC(state) {
      return state.systemConfig.systemType === "J";
    },
    isGuan(state) {
      return state.provisionalInfo.isLibrary;
    },
    isShi(state) {
      return state.provisionalInfo.isRoom;
    },
    isAdmin(state) {
      return md5(state.provisionalInfo.userCode || "") === ADMIN_USER_NAME;
    },
    isCompanyRole(state) {
      return state.provisionalInfo.roleType === BACK_ROLE_TYPE.COMPANY.CODE;
    },
  },
  mutations: mutations,
  actions: {},
  modules: {},
});
