<template>
  <span role="img" :aria-label="icon" tabindex="-1" class="base-icon">
    <template v-if="$slots.custom">
      <slot name="custom"></slot>
    </template>
    <template v-else>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        fill="none"
        focusable="false"
        aria-hidden="true"
        width="1em"
        height="1em"
        :stroke-linejoin="lineJoin"
        :stroke-linecap="lineCap"
        stroke="currentColor"
        :stroke-width="weight"
      >
        <slot>
          <template v-if="emptyHolder === 'menu'">
            <rect x="6" y="6" width="36" height="36" rx="3" />
            <path d="M6 17H42" />
            <path d="M17 42V17" />
          </template>
        </slot>
      </svg>
    </template>
  </span>
</template>

<script>
export default {
  name: "BaseIcon",
  props: {
    icon: { type: String },
    weight: { type: Number, default: 4 },
    lineJoin: { type: String, default: "round" },
    lineCap: { type: String, default: "round" },
    emptyHolder: { type: String },
  },
};
</script>

<style scoped>
.base-icon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-rendering: optimizelegibility;
  text-transform: none;
  vertical-align: -0.125em;
}
</style>
