<template>
  <div class="audio-viewer">
    <audio class="audio-content" :src="url" controls="controls">
      您的浏览器不支持播放音频
    </audio>
  </div>
</template>

<script>
import { PROPS_ATTACH_FILE } from "@/components/common/shared/compAttrs";

export default {
  name: "AudioViewer",
  props: { ...PROPS_ATTACH_FILE },
};
</script>

<style scoped>
.audio-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--rmx-attach-background);
  padding: 6px;
}
.audio-content {
  flex: 1;
  height: 100%;
  max-width: 100%;
  user-select: none;
  outline: none;
}
</style>
