export default [
  {
    path: "/user",
    name: "User",
    meta: {
      requireAuth: false,
    },
    children: [
      {
        path: "user_register",
        meta: {
          title: "用户注册",
        },
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/mods/user/userRegister/UserRegister"
          ),
      },
    ],
  },
];
