export default {
  virtualWareHouseDetail: {
    width: "xl",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/warehouse/virtualWarehouse/dialog/VirtualWarehouseDetail"
      ),
  },
  archListDialog: {
    width: "l",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/warehouse/virtualWarehouse/dialog/ArchListDialog"
      ),
  },
  changeBackWidth: {
    title: "修改脊背宽度",
    width: "s",
    okText: "actions.save",
    okCancel: false,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/warehouse/virtualWarehouse/dialog/ChangeBackWidth"
      ),
  },
  selectCompactShelves: {
    title: "选择密集架",
    width: "xl",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/warehouse/virtualWarehouse/dialog/SelectCompactShelves"
      ),
  },
  childArchiveList: {
    title: "文件列表",
    width: "xxl",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/warehouse/virtualWarehouse/dialog/ChildArchiveList"
      ),
  },
};
