import { onMounted, onUnmounted } from "vue";
import {
  httpSwitchUserRole,
  httpUpdateUserInfo,
  loadUserAuthResource,
} from "@/api/user";
import storeIns from "@/store";
import routerIns from "@/router";
import { httpLoadUserNotificationCounts } from "@/api/user/notification";
import { simpleDeepClone } from "@/util/dataUtil";
import { closeSelfWindow } from "@/util/uiUtil";
import { showConfirm } from "@/util/dialog";
import { i18n } from "@/conf/lang";

export function loadUserInfo() {
  return httpUpdateUserInfo().then(({ code, data }) => {
    if (code !== 0) {
      throw new Error("get user info fail");
    }
    const {
      userName,
      userId,
      userCode,
      roles,
      activeComAttr,
      activeComAttrId,
      activeComAttrName,
      companyattr: companyAttr,
      comAttrId,
      comAttrName,
      companyList,
      loginstatus,
      hasFtp,
    } = data;
    const curUserInfo = roles.find((item) => item.used === 1); // 当前使用的角色used为1
    const parentInfo = roles.find((item) => item.id === curUserInfo?.pid);
    const unitList = roles.filter((item) => item.pid === "0"); // 单位节点父Id为"0"
    const deptList = roles.filter((item) => {
      return unitList.some((node) => node.id === item.pid);
    });
    const companies = (companyList || []).map((x) => ({
      ...x,
      text: x.companyName,
      value: x.companyId,
      type: +x.companyAttr,
    }));
    const isInSelfUnit = comAttrId === activeComAttrId;
    const loginType = +curUserInfo.status === 1 ? "front" : "back";
    const numOfBack = roles.filter((x) => [0, 2].includes(x.status)).length;
    const numOfFront = roles.filter((x) => x.status === 1).length;
    const numOfThePosition = roles.filter(
      (x) => x.pid === parentInfo.id
    ).length;
    const hasMultiplePositions = deptList.length > 1;
    const hasMultipleRoles = numOfBack + numOfFront > 1;
    const switchable = hasMultipleRoles && isInSelfUnit;
    return {
      username: userName,
      userId: userId,
      userCode,

      unitAttr: activeComAttr,
      unitId: activeComAttrId,
      unitName: activeComAttrName,

      jobId: curUserInfo.pid,
      jobName: parentInfo?.name,
      roleId: curUserInfo.id,
      roleName: curUserInfo.name,
      status: curUserInfo.status,

      companyAttr,
      companyAttrId: comAttrId,
      companyAttrName: comAttrName,
      companies,

      isLibrary: +activeComAttr === 1,
      isRoom: +activeComAttr === 2,

      isMultipleDept: deptList.length > 1,
      isSingleDept: deptList.length === 1,
      isPartTime: +parentInfo?.stationtype === 1,

      isInSelfUnit,
      canEnterChild: !!curUserInfo.enterSubordinate,
      roletype: curUserInfo.roletype,
      roleType: curUserInfo.roletype,
      loginStatus: loginstatus, // 1: 正常登录 2：虚拟登录
      hasFtp,
      hasMultiplePositions,
      hasMultipleRoles,
      hasBackRoles: numOfBack > 0,
      hasFrontRoles: numOfFront > 0,
      loginType,
      switchable,
      roleSwitchable: numOfThePosition > 1,
    };
  });
}

export function refreshGlobalUserInfo() {
  return loadUserInfo().then((data) => {
    const {
      userId,
      username,
      roleName,
      roleId,
      jobName,
      jobId,
      unitId,
      companyAttrId,
      companyAttrName,
      isRoom,
      isLibrary,
      isPartTime,
      hasMultiplePositions,
      hasMultipleRoles,
      hasBackRoles,
      hasFrontRoles,
      status,
      isInSelfUnit,
      loginType,
      switchable,
      roleSwitchable,
    } = data;
    storeIns.commit("updateProvisionalInfo", data);
    storeIns.commit("setUserInfo", {
      userId,
      name: username,
      role: roleName,
      roleId,
      positionId: jobId,
      position: jobName,
      unitId,
      companyAttrId,
      company: companyAttrName,
      companyId: companyAttrId,
      status: +status,
      isRoom,
      isLibrary,
      isPartTime,
      hasMultiplePositions,
      hasMultipleRoles,
      hasBackRoles,
      hasFrontRoles,
      isInSelfUnit,
      loginType,
      switchable,
      roleSwitchable,
    });
    storeIns.commit("setLoginType", { type: loginType });
    return refreshUserCounts();
  });
}
const UNIQUE_ID = `uid_${Date.now()}_${Math.floor(Math.random() * 100)}`;

export function useAssignUserInfo() {
  const channel = new BroadcastChannel("user_sync");
  onMounted(() => {
    refreshGlobalUserInfo().then(() => {});
    channel.onmessage = (e) => {
      const d = JSON.parse(e.data || "{}");
      if (d.eid !== UNIQUE_ID) {
        const syncData = () => {
          const user = simpleDeepClone(storeIns.state.user);
          storeIns.commit("syncUserState", d);
          if (d?.user?.roleId !== user.roleId) {
            routerIns
              .push(d?.user?.status === 1 ? "/front" : "/portal")
              .then(() => {});
          }
        };
        if (d.closeOther) {
          closeSelfWindow(() => {
            syncData();
          });
          return;
        }
        syncData();
      }
    };
  });
  onUnmounted(() => {
    channel.close();
  });
  return {};
}

function _httpSwitchUserRole({ unitId, jobId, roleId, attrId, status } = {}) {
  return new Promise((resolve) => {
    httpSwitchUserRole({ unitId, jobId, roleId, attrId, status }).then(
      (res) => {
        if (res.code === 0) {
          resolve(res);
        }
      }
    );
  });
}

export function syncUserInnerState(closeOther) {
  const channel = new BroadcastChannel("user_sync");
  channel.postMessage(
    JSON.stringify({
      eid: UNIQUE_ID,
      user: storeIns.state.user,
      accessToken: storeIns.state.accessToken,
      loginType: storeIns.state.loginType,
      provisionalInfo: storeIns.state.provisionalInfo,
      userCounts: storeIns.state.userCounts,
      closeOther,
    })
  );
}

export function beforeSwitchRole() {
  return showConfirm({
    content: i18n.global.t("user.switch.auto"),
    okText: i18n.global.t("actions.continue"),
  });
}

export function switchRoleInfo(
  { unitId, jobId, roleId, attrId, status } = {},
  closeOther
) {
  return new Promise((resolve) => {
    _httpSwitchUserRole({ unitId, jobId, roleId, attrId, status })
      .then(() => {
        return refreshGlobalUserInfo();
      })
      .then(() => {
        resolve({ url: status === 1 ? "/front" : "/portal" });
        syncUserInnerState(closeOther);
      });
  });
}

export function userJumpTo(roles, where = "back") {
  roles = roles || [];
  const status = where === "back" ? 2 : 1;
  const targetRoles = roles.filter((x) => x.status === status);
  const role =
    targetRoles.find((x) => x.pid === storeIns.state.user.positionId) ||
    targetRoles[0];
  if (role) {
    const roleId = role.id;
    const jobId = role.pid;
    const unitId = roles.find((x) => x.id === jobId)?.pid;
    storeIns.commit("updateModAvailable", false);
    switchRoleInfo({ roleId, jobId, unitId, status }, true)
      .then(() => {
        return routerIns.push(where === "back" ? "/portal" : "/front");
      })
      .then(() => {
        storeIns.commit("updateModAvailable", true);
      });
  }
}

export function refreshUserCounts() {
  return httpLoadUserNotificationCounts({
    where: storeIns.state.loginType,
  }).then((data) => {
    storeIns.commit("setUserCounts", data);
  });
}

const authInfo = {};
export function userAuthList(force) {
  return new Promise((resolve) => {
    if (!authInfo.auth || force) {
      loadUserAuthResource().then((auth) => {
        authInfo.auth = auth;
        resolve(auth);
      });
    } else {
      resolve(authInfo.auth || []);
    }
  });
}

/**
 * 检测用户权限
 * @param{String[]|String} auth 权限
 */
export function checkUserAuth(auth) {
  const list = authInfo.auth || [];
  if (Array.isArray(auth)) {
    const res = {};
    auth.forEach((x) => {
      res[x] = list.includes(x);
    });
    return res;
  } else {
    return list.includes(auth);
  }
}
