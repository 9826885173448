export default {
  editPsw: {
    width: "500px",
    title: "修改密码",
    component: () =>
      import(
        /* webpackChunkName: "login_dialog" */ "@/mods/user/login/dialog/EditPassword"
      ),
  },
};
