<template>
  <div class="marked-text-wrap">
    <template v-for="(chunk, i) in chunks" :key="i">
      <template v-if="checkInclude(splitWords, chunk)">
        <span
          :class="{
            'marked-text': checkInclude(markedList, chunk),
            'highlight-text': checkInclude(highlightList, chunk),
          }"
        >
          {{ chunk }}
        </span>
      </template>
      <template v-else>
        {{ chunk }}
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "MarkedText",
  props: {
    text: { type: String, default: "" },
    markedColor: { type: String, default: "red" },
    highlightColor: { type: String, default: "yellow" },
    markedWords: { type: Array, default: () => [] },
    highlightWords: { type: Array, default: () => [] },
    ignoreCase: { type: Boolean, default: false },
  },
  computed: {
    highlightList() {
      if (!this.ignoreCase) return this.highlightWords;
      return this.highlightWords.map((x) => x.toLowerCase());
    },
    markedList() {
      if (!this.ignoreCase) return this.markedWords;
      return this.markedWords.map((x) => x.toLowerCase());
    },
    splitWords() {
      return [...new Set([...this.highlightList, ...this.markedList])].sort(
        (a, b) => b?.length - a?.length
      );
    },
    splitExp() {
      const flag = this.ignoreCase ? "i" : undefined;
      const words = this.splitWords.map((x) =>
        x.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&")
      );
      return new RegExp(`(${words.join("|")})`, flag);
    },
    chunks() {
      return this.text.split(this.splitExp).filter((x) => x);
    },
  },
  methods: {
    checkInclude(arr, it) {
      if (!arr || !it) return false;
      if (this.ignoreCase) {
        return arr.includes(it.toLowerCase());
      } else {
        return arr.includes(it);
      }
    },
  },
};
</script>

<style scoped>
.marked-text-wrap {
}
.highlight-text {
  color: white;
  background: v-bind(highlightColor);
}
.marked-text {
  color: v-bind(markedColor);
}
</style>
