import common from "@/mods/common/dialog";
import demo from "@/mods/demo/dialog";
import archive from "@/mods/archive/dialog";
import front from "@/mods/front/dialog";
import setting from "@/mods/setting/dialog";
import system from "@/mods/system/dialog";
import warehouse from "@/mods/warehouse/dialog";
import workbench from "@/mods/workbench/dialog";
import statistic from "@/mods/statistic/dialog";
import user from "@/mods/user/dialog";

export default {
  common,
  demo,
  archive,
  front,
  setting,
  system,
  statistic,
  warehouse,
  workbench,
  user,
};
