import RouterHolder from "@/components/core/RouterHolder";

export default {
  path: "search",
  component: RouterHolder,
  children: [
    {
      path: "/front/search",
      redirect: "/front/search/quick",
    },
    {
      path: "quick",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/search/QuickSearch"
        ),
    },
    {
      path: "card",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/search/CardSearch"
        ),
    },
    {
      path: "advanced",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/search/AdvancedSearch"
        ),
    },
  ],
};
