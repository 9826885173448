<template>
  <div
    class="icon-button"
    :class="{ 'icon-button-checked': checked }"
    role="button"
    @click="onClick"
  >
    <template v-if="comp">
      <component :is="comp" />
    </template>
    <common-icon v-else :icon="type" />
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { debounceFunc } from "@/util/uiUtil";
import {
  SettingOutlined,
  ReloadOutlined,
  ExportOutlined,
  BarChartOutlined,
} from "@ant-design/icons-vue";
import CommonIcon from "@/components/icon/CommonIcon";

const ICON_MAP = {
  chart: BarChartOutlined,
  export: ExportOutlined,
  refresh: ReloadOutlined,
  setting: SettingOutlined,
};

export default {
  name: "IconButton",
  components: { CommonIcon },
  emits: ["click", "action"],
  props: {
    type: { type: String },
    debounce: { type: Number, default: 0 },
    checked: { type: Boolean },
  },
  setup(props, { emit }) {
    const { type, debounce } = toRefs(props);
    const comp = computed(() => {
      return ICON_MAP[type.value];
    });
    const onAction = debounceFunc(
      (e) => emit("action", e),
      debounce.value,
      true
    );
    const onClick = (e) => {
      emit("click", e);
      onAction(e);
    };
    return { comp, onClick };
  },
};
</script>

<style scoped>
.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--rmx-border);
  border-radius: var(--rmx-border-radius);
  cursor: pointer;
  color: var(--rmx-icon-2);
  font-size: 18px;
  transition: color ease 0.3s, border-color ease 0.3s,
    background-color ease 0.3s;
}
.icon-button:hover {
  border-color: var(--rmx-primary-color);
  color: var(--rmx-primary-color);
}
.icon-button-checked {
  border-color: var(--rmx-primary-color);
  background: var(--rmx-primary-color);
  color: white;
}
.icon-button-checked:hover {
  border-color: var(--rmx-primary-color-5);
  background: var(--rmx-primary-color-5);
  color: white;
}
</style>
