<template>
  <slot></slot>
  <drawer-view
    v-model:visible="drawerConfig.visible"
    :view="drawerConfig.name"
    :params="drawerConfig.params"
  />
  <template v-for="d in dialogs" :key="d.name">
    <async-dialog
      v-if="d.url"
      v-model:visible="d.visible"
      :title="d.options.title"
      :subtitle="d.options.subtitle"
      :ok-text="d.options.okText"
      :cancel-text="d.options.cancelText"
      :width="d.options.width"
      :footer="d.options.footer"
      destroy-on-close
      #default="{ dragging }"
      @dialog-canceled="handleCancel(d)"
      @dialog-finished="d.onFinished"
    >
      <iframe-view
        :url="d.url"
        bridge
        :style="{
          height: d.options.height,
          pointerEvents: dragging ? 'none' : '',
        }"
      />
    </async-dialog>
    <async-dialog
      v-else
      :dialog="d.name"
      v-bind="d.params"
      :title="d.options.title"
      :subtitle="d.options.subtitle"
      :ok-text="d.options.okText"
      :cancel-text="d.options.cancelText"
      :width="d.options.width"
      v-model:visible="d.visible"
      destroy-on-close
      @dialog-canceled="handleCancel(d)"
      @dialog-finished="d.onFinished"
    ></async-dialog>
  </template>
</template>

<script>
import { provide, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { DIALOGS, DRAWER_VIEW } from "@/conf/symbols";
import AsyncDialog from "@/components/core/AsyncDialog";
import IframeView from "@/components/common/display/IframeView";
import DrawerView from "@/components/core/DrawerView";

export default {
  name: "DialogProvider",
  components: { DrawerView, IframeView, AsyncDialog },
  setup(props, { expose }) {
    const dialogs = ref([]);
    const drawerConfig = ref({});
    const route = useRoute();
    const openDialog = (name, params, options = {}) => {
      return new Promise((resolve) => {
        dialogs.value.push({
          name,
          params,
          options,
          visible: true,
          onFinished: (data) => {
            resolve(data);
          },
          onCancel: () => {
            resolve(null);
          },
        });
      });
    };
    const openIframeDialog = (url, options = {}) => {
      return new Promise((resolve) => {
        dialogs.value.push({
          name: url,
          url,
          options,
          visible: true,
          onFinished: (data) => {
            resolve(data);
          },
          onCancel: () => {
            resolve(null);
          },
        });
      });
    };
    const handleCancel = (dialog) => {
      dialog.onCancel();
      const index = dialogs.value.indexOf(dialog);
      if (index > -1) {
        dialogs.value.splice(index, 1);
      }
    };
    const openDrawer = (name, params, options) => {
      drawerConfig.value = { name, params, options, visible: true };
    };
    const closeDrawer = () => {
      drawerConfig.value = {};
    };
    watch(
      () => route.path,
      () => {
        dialogs.value = [];
        drawerConfig.value = {};
      }
    );
    expose({});
    provide(DIALOGS, { openDialog, openIframeDialog });
    provide(DRAWER_VIEW, { openDrawer, closeDrawer });
    return { dialogs, drawerConfig, handleCancel };
  },
};
</script>

<style scoped></style>
