<template>
  <base-icon
    :icon="icon"
    :weight="weight"
    :line-cap="lineCap"
    :line-join="lineJoin"
  >
    <template v-if="icon === 'add-one'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="M24 16v16m-8-8h16"></path>
    </template>
    <template v-else-if="icon === 'add-text'">
      <path d="M4 8h28m-4 13h16M18 42V8m18 34V21"></path>
    </template>
    <template v-else-if="icon === 'agreement'">
      <rect rx="2" height="40" width="32" y="4" x="8"></rect>
      <path d="M16 4h9v16l-4.5-4-4.5 4V4Z"></path>
      <path d="M16 28h10m-10 6h16"></path>
    </template>
    <template v-else-if="icon === 'alarm'">
      <path d="M14 25c0-5.523 4.477-10 10-10s10 4.477 10 10v16H14V25Z"></path>
      <path
        d="M24 5v3m11.892 1.328-1.929 2.298m8.256 8.661-2.955.521m-33.483-.521 2.955.521m3.373-11.48 1.928 2.298M6 41h37"
      ></path>
    </template>
    <template v-else-if="icon === 'all-application'">
      <path
        d="M18 6H8a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Zm0 22H8a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2ZM40 6H30a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Zm0 22H30a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2Z"
      ></path>
    </template>
    <template v-else-if="icon === 'announcement'">
      <rect rx="2" height="26" width="40" y="15" x="4"></rect>
      <path d="m24 7-8 8h16l-8-8ZM12 24h18m-18 8h8"></path>
    </template>
    <template v-else-if="icon === 'area-map'">
      <path d="m18 24-6 9-8-4.5V42h40V15l-7 8-6-5-7 8-6-2Z"></path>
      <path d="M4 28.5V17l7 6 5.5-8 6 3L31 9l5.5 4.5L44 6v9.5"></path>
    </template>
    <template v-else-if="icon === 'arrow-circle-down'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="M24 15v18m9-9-9 9-9-9"></path>
    </template>
    <template v-else-if="icon === 'arrow-circle-left'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="M32.492 24.5h-18m9-9-9 9 9 9"></path>
    </template>
    <template v-else-if="icon === 'arrow-circle-right'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="M14.492 24.5h18m-9-9 9 9-9 9"></path>
    </template>
    <template v-else-if="icon === 'arrow-circle-up'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="M24 33.5v-18m9 9-9-9-9 9"></path>
    </template>
    <template v-else-if="icon === 'avatar'">
      <path
        d="M5.004 42.231a.78.78 0 0 0 .791.769h36.407a.78.78 0 0 0 .792-.769v-.918c.018-.277.055-1.657-.855-3.184-.574-.963-1.407-1.794-2.476-2.472-1.293-.82-2.938-1.413-4.928-1.77a29.236 29.236 0 0 1-3.002-.584c-2.632-.672-2.862-1.267-2.864-1.273a.763.763 0 0 0-.066-.169c-.022-.11-.075-.528.027-1.647.258-2.843 1.783-4.523 3.008-5.873.386-.425.751-.828 1.032-1.222 1.213-1.7 1.325-3.635 1.33-3.755a2 2 0 0 0-.087-.628c-.12-.37-.343-.6-.507-.77a2.874 2.874 0 0 1-.113-.12c-.012-.014-.044-.052-.015-.243a19.01 19.01 0 0 0 .203-1.857c.056-1.002.099-2.5-.16-3.959a6.031 6.031 0 0 0-.172-.825c-.273-1.004-.711-1.862-1.32-2.57-.105-.115-2.653-2.8-10.05-3.35-1.023-.076-2.034-.035-3.03.016a4.39 4.39 0 0 0-.875.108c-.764.197-.968.681-1.021.952-.089.45.067.798.17 1.03.015.033.034.074.001.182-.171.266-.442.506-.717.733-.08.067-1.934 1.667-2.036 3.756-.275 1.589-.255 4.064.07 5.775.02.095.047.235.002.33-.35.313-.746.668-.745 1.478.004.082.117 2.016 1.33 3.717.28.394.645.796 1.03 1.221l.002.001c1.225 1.35 2.75 3.03 3.008 5.872.101 1.12.048 1.537.027 1.648a.758.758 0 0 0-.067.169c-.001.006-.23.599-2.85 1.27-1.512.387-3 .585-3.045.59-1.934.327-3.569.906-4.86 1.721-1.065.673-1.9 1.507-2.48 2.477-.928 1.55-.903 2.962-.89 3.22v.923Z"
      ></path>
    </template>
    <template v-else-if="icon === 'back'">
      <path
        d="M44 40.836c-4.893-5.973-9.238-9.362-13.036-10.168-3.797-.805-7.412-.927-10.846-.365V41L4 23.545 20.118 7v10.167c6.349.05 11.746 2.328 16.192 6.833 4.445 4.505 7.009 10.117 7.69 16.836Z"
        clip-rule="evenodd"
      ></path>
    </template>
    <template v-else-if="icon === 'book-open'">
      <path
        d="M5 7h11a8 8 0 0 1 8 8v27a6 6 0 0 0-6-6H5V7Zm38 0H32a8 8 0 0 0-8 8v27a6 6 0 0 1 6-6h13V7Z"
      ></path>
    </template>
    <template v-else-if="icon === 'bookmark'">
      <path
        d="M10 44a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H10Z"
      ></path>
      <path d="M21 22V4h12v18l-6-6.273L21 22Z" clip-rule="evenodd"></path>
      <path d="M10 4h28"></path>
    </template>
    <template v-else-if="icon === 'bookmark-one'">
      <path d="M34 10V4H8v34l6-3"></path>
      <path d="M14 44V10h26v34l-13-6.273L14 44Z"></path>
    </template>
    <template v-else-if="icon === 'bookmark-three'">
      <path d="M7 9a3 3 0 0 1 3-3h31v36H10a3 3 0 0 1-3-3V9Z"></path>
      <path d="M7 34h34M7 30v8m34-8v8"></path>
      <path d="M15 6h10v20l-5-4-5 4V6Z"></path>
      <path d="M11 6h18"></path>
    </template>
    <template v-else-if="icon === 'building-three'">
      <path d="m24 8 20 13v23H4V21L24 8Z" clip-rule="evenodd"></path>
      <path d="M20 44V23l-8 5v16m16 0V23l8 5v16m5 0H8"></path>
    </template>
    <template v-else-if="icon === 'calendar'">
      <path
        d="M5 19h38v21a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V19ZM5 9a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v10H5V9Z"
      ></path>
      <path d="M16 4v8m16-8v8m-4 22h6m-20 0h6m8-8h6m-20 0h6"></path>
    </template>
    <template v-else-if="icon === 'calendar-three'">
      <rect rx="2" height="36" width="40" y="8" x="4"></rect>
      <path
        d="M4 20h40M4 32h40M17 4v8m14-8v8m-14 8v24m14-24v24m13-31v26M4 13v26m10 5h20"
      ></path>
    </template>
    <template v-else-if="icon === 'chart-graph'">
      <path d="M17 6h14v9H17zM6 33h14v9H6zm22 0h14v9H28z"></path>
      <path d="M24 16v8m-11 9v-9h22v9"></path>
    </template>
    <template v-else-if="icon === 'chart-histogram'">
      <path d="M6 6v36h36M14 30v4m8-12v12m8-28v28m8-20v20"></path>
    </template>
    <template v-else-if="icon === 'chart-line'">
      <path d="M6 6v36h36"></path>
      <path d="m14 34 8-16 10 9L42 6"></path>
    </template>
    <template v-else-if="icon === 'check'">
      <path d="M43 11 16.875 37 5 25.182"></path>
    </template>
    <template v-else-if="icon === 'check-one'">
      <path
        d="M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4 19.938 19.938 0 0 0 9.858 9.858 19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z"
      ></path>
      <path d="m16 24 6 6 12-12"></path>
    </template>
    <template v-else-if="icon === 'check-small'">
      <path d="m10 24 10 10 20-20"></path>
    </template>
    <template v-else-if="icon === 'circle-double-down'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="m31 17-7 7-7-7m14 9-7 7-7-7"></path>
    </template>
    <template v-else-if="icon === 'circle-double-left'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="m22 31-7-7 7-7m9 14-7-7 7-7"></path>
    </template>
    <template v-else-if="icon === 'circle-double-right'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="m17 31 7-7-7-7m9 14 7-7-7-7"></path>
    </template>
    <template v-else-if="icon === 'circle-double-up'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="m31 22-7-7-7 7m14 9-7-7-7 7"></path>
    </template>
    <template v-else-if="icon === 'city-one'">
      <path d="M4 42h40"></path>
      <rect rx="2" height="20" width="12" y="22" x="8"></rect>
      <rect rx="2" height="38" width="20" y="4" x="20"></rect>
      <path d="M28 32.008h4m-20 0h4m12-9h4m-4-9h4"></path>
    </template>
    <template v-else-if="icon === 'clear'">
      <path d="M20 5.914h8v8h15v8H5v-8h15v-8Z" clip-rule="evenodd"></path>
      <path d="M8 40h32V22H8v18Z"></path>
      <path d="M16 39.898v-5.984m8 5.984v-6m8 6v-5.984M12 40h24"></path>
    </template>
    <template v-else-if="icon === 'close'">
      <path d="m8 8 32 32M8 40 40 8"></path>
    </template>
    <template v-else-if="icon === 'close-one'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="M29.657 18.343 18.343 29.657m0-11.314 11.314 11.314"></path>
    </template>
    <template v-else-if="icon === 'close-small'">
      <path d="m14 14 20 20m-20 0 20-20"></path>
    </template>
    <template v-else-if="icon === 'comment'">
      <path d="M44 6H4v30h9v5l10-5h21V6ZM14 19.5v3m10-3v3m10-3v3"></path>
    </template>
    <template v-else-if="icon === 'comments'">
      <path d="M33 38H22v-8h14v-8h8v16h-5l-3 3-3-3Z"></path>
      <path d="M4 6h32v24H17l-4 4-4-4H4V6Z"></path>
      <path d="M12 22h6m-6-8h12"></path>
    </template>
    <template v-else-if="icon === 'computer'">
      <path d="M19 32h10v9H19z"></path>
      <rect rx="2" height="24" width="38" y="8" x="5"></rect>
      <path d="M22 27h4M14 41h20"></path>
    </template>
    <template v-else-if="icon === 'consume'">
      <path
        d="M4 14a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V14Z"
      ></path>
      <path d="m19 19 5 5 5-5m-11 6h12m-12 6h12m-6-6v10M8 6h32"></path>
    </template>
    <template v-else-if="icon === 'copy-one'">
      <path d="M13 38h28V16H30V4H13v34ZM30 4l11 12"></path>
      <path d="M7 20v24h21"></path>
      <path d="M19 20h4m-4 8h12"></path>
    </template>
    <template v-else-if="icon === 'data'">
      <path d="M44 11v27c0 3.314-8.954 6-20 6S4 41.314 4 38V11"></path>
      <path
        d="M44 29c0 3.314-8.954 6-20 6S4 32.314 4 29m40-9c0 3.314-8.954 6-20 6S4 23.314 4 20"
      ></path>
      <ellipse ry="6" rx="20" cy="10" cx="24"></ellipse>
    </template>
    <template v-else-if="icon === 'data-screen'">
      <rect rx="3" height="30" width="40" y="6" x="4"></rect>
      <path d="M24 36v7m8-29L16 28m-6 15h28"></path>
      <circle r="3" cy="17" cx="15"></circle>
      <circle r="3" cy="25" cx="33"></circle>
    </template>
    <template v-else-if="icon === 'data-sheet'">
      <rect rx="2" height="32" width="40" y="8" x="4"></rect>
      <path d="M32 25v7m-8-16v16m-8-12v12"></path>
    </template>
    <template v-else-if="icon === 'database-enter'">
      <path
        d="M44 31c0 5.523-4.477 10-10 10-1.79 0-3.472-.47-4.926-1.295A10.01 10.01 0 0 1 24 31c0-2.568.968-4.91 2.558-6.68A9.975 9.975 0 0 1 34 21c5.523 0 10 4.477 10 10Z"
      ></path>
      <path
        d="M34 12v9a9.975 9.975 0 0 0-7.442 3.32A9.963 9.963 0 0 0 24 31a10.01 10.01 0 0 0 5.074 8.705C26.412 40.51 22.878 41 19 41c-8.284 0-15-2.239-15-5V12"
      ></path>
      <path
        d="M34 12c0 2.761-6.716 5-15 5-8.284 0-15-2.239-15-5s6.716-5 15-5c8.284 0 15 2.239 15 5ZM4 28c0 2.761 6.716 5 15 5 1.807 0 3.54-.106 5.144-.302M4 20c0 2.761 6.716 5 15 5 2.756 0 5.339-.248 7.558-.68M39 31l-4-4m4 4-4 4m4-4H29"
      ></path>
    </template>
    <template v-else-if="icon === 'date-comes-back'">
      <path
        d="M36 8H9a3 3 0 0 0-3 3v28a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V18"
      ></path>
      <path
        d="m32.243 12.485 4.242-4.242L32.243 4M24 17v17m8-10v10M16 24v10"
      ></path>
    </template>
    <template v-else-if="icon === 'delete'">
      <path d="M9 10v34h30V10H9Z"></path>
      <path d="M20 20v13m8-13v13M4 10h40"></path>
      <path d="m16 10 3.289-6h9.488L32 10H16Z"></path>
    </template>
    <template v-else-if="icon === 'direction-adjustment-three'">
      <path
        d="m18 10 6-6m0 0 6 6m-6-6v20m0 0v20m0-20h20m-20 0H4m14 14 6 6m0 0 6-6m8-20 6 6m0 0-6 6M10 18l-6 6m0 0 6 6"
      ></path>
    </template>
    <template v-else-if="icon === 'doc-detail'">
      <path
        d="M39 4H11a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM17 30h14m-14 6h7"
      ></path>
      <path d="M17 12h14v10H17z"></path>
    </template>
    <template v-else-if="icon === 'doc-search'">
      <path
        d="M38 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
      ></path>
      <path d="M28 16c0 1.38-.56 2.63-1.465 3.535A5 5 0 1 1 28 16Z"></path>
      <path
        d="m30 23-3.465-3.465m0 0a5 5 0 1 0-7.071-7.07 5 5 0 0 0 7.072 7.07ZM17 30h14m-14 6h7"
      ></path>
    </template>
    <template v-else-if="icon === 'doc-search-two'">
      <path
        d="M40 27V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h11m-4-32h14m-14 8h14m-14 8h6"
      ></path>
      <path d="M37 37c0 1.38-.56 2.63-1.465 3.535A5 5 0 1 1 37 37Z"></path>
      <path
        d="m39 44-3.465-3.465m0 0a5 5 0 1 0-7.071-7.07 5 5 0 0 0 7.072 7.07Z"
      ></path>
    </template>
    <template v-else-if="icon === 'document-folder'">
      <path
        d="M32 6H22v36h10V6Zm10 0H32v36h10V6ZM10 6l8 1-3.5 35L6 41l4-35Z"
      ></path>
      <path d="M37 18v-3m-10 3v-3"></path>
    </template>
    <template v-else-if="icon === 'dot'">
      <path
        fill="currentColor"
        stroke-width="0"
        d="M24 33a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      ></path>
    </template>
    <template v-else-if="icon === 'double-down'">
      <path d="M36 12 24 24 12 12m24 12L24 36 12 24"></path>
    </template>
    <template v-else-if="icon === 'double-left'">
      <path d="M24 36 12 24l12-12m12 24L24 24l12-12"></path>
    </template>
    <template v-else-if="icon === 'double-right'">
      <path d="m12 12 12 12-12 12m12-24 12 12-12 12"></path>
    </template>
    <template v-else-if="icon === 'double-up'">
      <path d="m12 24 12-12 12 12M12 36l12-12 12 12"></path>
    </template>
    <template v-else-if="icon === 'down'">
      <path d="M36 18 24 30 12 18"></path>
    </template>
    <template v-else-if="icon === 'download'">
      <path d="M6 24.008V42h36V24"></path>
      <path d="m33 23-9 9-9-9m8.992-17v26"></path>
    </template>
    <template v-else-if="icon === 'download-one'">
      <path
        d="M11.678 20.271C7.275 21.318 4 25.277 4 30c0 5.523 4.477 10 10 10 .947 0 1.864-.132 2.733-.378m19.322-19.351c4.403 1.047 7.677 5.006 7.677 9.729 0 5.523-4.477 10-10 10-.947 0-1.864-.132-2.732-.378M36 20c0-6.627-5.373-12-12-12s-12 5.373-12 12m5.065 10.119L24 37.076 31.132 30M24 20v13.538"
      ></path>
    </template>
    <template v-else-if="icon === 'download-two'">
      <path
        d="M40.518 34.316A9.21 9.21 0 0 0 44 24c-1.213-3.83-4.93-5.929-8.947-5.925h-2.321a14.737 14.737 0 1 0-25.31 13.428M24.008 41 24 23m6.364 11.636L24 41l-6.364-6.364"
      ></path>
    </template>
    <template v-else-if="icon === 'edit'">
      <path d="M7 42h36"></path>
      <path d="M11 26.72V34h7.317L39 13.308 31.695 6 11 26.72Z"></path>
    </template>
    <template v-else-if="icon === 'edit-one'">
      <path
        d="M29 4H9a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V20.005"
      ></path>
      <path d="M13 18h8m-8 10h12"></path>
      <path d="M41 6.001 29.003 17.996"></path>
    </template>
    <template v-else-if="icon === 'edit-two'">
      <path
        d="M42 26v14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h14"
      ></path>
      <path d="M14 26.72V34h7.317L42 13.308 34.695 6 14 26.72Z"></path>
    </template>
    <template v-else-if="icon === 'editor'">
      <path
        d="M40 33v9a2 2 0 0 1-2 2h-6.5M40 16V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h6"
      ></path>
      <path d="M16 16h14m-7 28 17-21m-24 1h8"></path>
    </template>
    <template v-else-if="icon === 'efferent-four'">
      <path
        d="M20 5H10a2 2 0 0 0-2 2v34a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V24.75M28 5h12v12m-19 7L39 6"
      ></path>
    </template>
    <template v-else-if="icon === 'exchange-four'">
      <path
        d="M39 6H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3ZM15 21h18m-18 6h18m0-6-7-7m-4 20-7-7"
      ></path>
    </template>
    <template v-else-if="icon === 'export'">
      <path
        d="M42 27c0 6-4 16-18 16S6 33 6 27M24.008 5.1V33M12 17 24 5l12 12"
      ></path>
    </template>
    <template v-else-if="icon === 'file-conversion'">
      <path
        d="M10 44h28a2 2 0 0 0 2-2V14H30V4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2ZM30 4l10 10M17 25h14m-14 6h14m0-6-5-5m-4 16-5-5"
      ></path>
    </template>
    <template v-else-if="icon === 'file-date-one'">
      <path d="M40 23v-9L31 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h12"></path>
      <circle r="8" cy="36" cx="34"></circle>
      <path d="M33 33v4h4M30 4v10h10"></path>
    </template>
    <template v-else-if="icon === 'file-pdf'">
      <path
        d="M10 4h20l10 10v28a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2Z"
      ></path>
      <path d="M18 18h12v7.992L18.008 26 18 18Z" clip-rule="evenodd"></path>
      <path d="M18 18v16"></path>
    </template>
    <template v-else-if="icon === 'file-search'">
      <path
        d="M40 20.842V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h16"
      ></path>
      <path
        d="M14 17h6m8 0h6M14 28h6m-6 6h6m-3-14v-6m20.728 23.728 4.243 4.243"
      ></path>
      <circle
        transform="rotate(45 33.485 33.485)"
        r="6"
        cy="33.485"
        cx="33.485"
      ></circle>
    </template>
    <template v-else-if="icon === 'file-success-one'">
      <path d="M40 23v-9L31 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h12"></path>
      <path d="m26 38 6 5 9-11M30 4v10h10"></path>
    </template>
    <template v-else-if="icon === 'file-word'">
      <path
        d="M10 4h20l10 10v28a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2Z"
      ></path>
      <path d="m16.008 20 3 14 5-10 5 10 3-14"></path>
    </template>
    <template v-else-if="icon === 'filter'">
      <path d="m6 9 14.4 16.818v12.626L27.6 42V25.818L42 9H6Z"></path>
    </template>
    <template v-else-if="icon === 'find'">
      <path d="M4 7h40M4 23h11M4 39h11"></path>
      <path d="M31.5 34a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Z"></path>
      <path d="m37 32 7 7.05"></path>
    </template>
    <template v-else-if="icon === 'folder'">
      <path
        d="M7 6a2 2 0 0 1 2-2h30a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V6Zm9 23h4m-4 6h10"
      ></path>
      <path d="M8 5s3.765 13 16 13S40 5 40 5"></path>
      <circle r="4" cy="18" cx="24"></circle>
      <path d="M15 4H9a2 2 0 0 0-2 2v6m26-8h6a2 2 0 0 1 2 2v6"></path>
    </template>
    <template v-else-if="icon === 'folder-close'">
      <path
        d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8Zm38 14H5"
      ></path>
      <path d="M5 16v12m38-12v12"></path>
    </template>
    <template v-else-if="icon === 'folder-focus-one'">
      <path
        d="M43 23v-9a2 2 0 0 0-2-2H24l-5-6H7a2 2 0 0 0-2 2v32a2 2 0 0 0 2 2h15"
      ></path>
      <path
        d="m35 27 2.243 4.913 5.365.615-3.979 3.651 1.073 5.293L35 38.816l-4.702 2.656 1.073-5.293-3.98-3.651 5.366-.615L35 27Z"
      ></path>
    </template>
    <template v-else-if="icon === 'folder-open'">
      <path
        d="M4 9v32l5-20h30.5v-6a2 2 0 0 0-2-2H24l-5-6H6a2 2 0 0 0-2 2Z"
      ></path>
      <path d="m40 41 4-20H8.812L4 41h36Z"></path>
    </template>
    <template v-else-if="icon === 'forbid'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
        clip-rule="evenodd"
      ></path>
      <path d="m15 15 18 18"></path>
    </template>
    <template v-else-if="icon === 'full-screen-two'">
      <path
        d="M30 6h12v12M18 6H6v12m24 24h12V30M18 42H6V30M42 6 29 19M19 29 6 42"
      ></path>
    </template>
    <template v-else-if="icon === 'hard-disk-one'">
      <path
        d="M39 6H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3Z"
      ></path>
      <path d="M32 6H16v10h16V6ZM17 36h14"></path>
    </template>
    <template v-else-if="icon === 'help'">
      <path
        d="M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4 19.938 19.938 0 0 0 9.858 9.858 19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z"
      ></path>
      <path d="M24 28.625v-4a6 6 0 1 0-6-6"></path>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M24 37.625a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
      ></path>
    </template>
    <template v-else-if="icon === 'hourglass-full'">
      <path d="M7 4h34M7 44h34"></path>
      <path
        d="M11 44c2.667-13.339 7-20.006 13-20 6 .006 10.333 6.672 13 20H11Z"
      ></path>
      <path
        d="M37 4c-2.667 13.339-7 20.006-13 20-6-.006-10.333-6.672-13-20h26Z"
      ></path>
      <path d="M21 15h6m-8 23h10"></path>
    </template>
    <template v-else-if="icon === 'id-card-h'">
      <rect rx="2" height="32" width="40" y="8" x="4"></rect>
      <path d="M17 25a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"></path>
      <path d="M23 31a6 6 0 0 0-12 0m17-11h8m-6 8h6"></path>
    </template>
    <template v-else-if="icon === 'id-card-v'">
      <rect rx="2" height="40" width="32" y="4" x="8"></rect>
      <path d="M24 19a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"></path>
      <path d="M30 25a6 6 0 0 0-12 0m0 6h12m-12 6h7"></path>
    </template>
    <template v-else-if="icon === 'inbox-r'">
      <rect rx="3" height="36" width="36" y="6" x="6"></rect>
      <path d="M4 31h11l2 4h14l2-4h11m-2 5V26M6 36V26m11-11h14m-14 8h14"></path>
    </template>
    <template v-else-if="icon === 'info'">
      <path
        d="M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4 19.938 19.938 0 0 0 9.858 9.858 19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z"
      ></path>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M24 11a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
      ></path>
      <path d="M24.5 34V20h-2M21 34h7"></path>
    </template>
    <template v-else-if="icon === 'inspection'">
      <path d="M43 33V19H5v22a2 2 0 0 0 2 2h17"></path>
      <path d="M5 10a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v9H5v-9Z"></path>
      <path d="M16 5v8m16-8v8"></path>
      <circle r="7" cy="32" cx="30"></circle>
      <path d="m36 37 6 5"></path>
    </template>
    <template v-else-if="icon === 'label'">
      <path d="M9 4h30v40L24 33.429 9 44V4Z"></path>
      <path d="M9 4h30v12H9z"></path>
    </template>
    <template v-else-if="icon === 'left'">
      <path d="M31 36 19 24l12-12"></path>
    </template>
    <template v-else-if="icon === 'link'">
      <path
        d="M24.707 9.565 9.858 24.415a9 9 0 0 0 0 12.727v0a9 9 0 0 0 12.728 0l17.678-17.677a6 6 0 0 0 0-8.486v0a6 6 0 0 0-8.486 0L14.101 28.657a3 3 0 0 0 0 4.243v0a3 3 0 0 0 4.242 0l14.85-14.85"
      ></path>
    </template>
    <template v-else-if="icon === 'link-one'">
      <path
        d="m26.24 16.373-9.14-9.14c-2.661-2.661-7.035-2.603-9.768.131-2.734 2.734-2.793 7.107-.131 9.768l7.935 7.936m17.767-2.065 7.935 7.935c2.661 2.662 2.603 7.035-.13 9.769-2.735 2.734-7.108 2.792-9.77.13l-9.14-9.14"
      ></path>
      <path
        d="M26.11 26.142c2.733-2.734 2.792-7.108.13-9.769m-4.441 5.425c-2.734 2.734-2.792 7.108-.131 9.769"
      ></path>
    </template>
    <template v-else-if="icon === 'link-right'">
      <path d="M8 12V4h16v8m0 24v8H8v-8m36-12H24m-8 10V14m20 2 8 8-8 8"></path>
    </template>
    <template v-else-if="icon === 'list'">
      <path
        d="m5 10 3 3 6-6M5 24l3 3 6-6M5 38l3 3 6-6m7-11h22M21 38h22M21 10h22"
      ></path>
    </template>
    <template v-else-if="icon === 'list-numbers'">
      <path
        d="M20 9h22M20 19h22M20 29h22M20 39h22M6 29h6v3l-6 6v1h6M7 11l2-2v10m0 0H7m2 0h2"
      ></path>
    </template>
    <template v-else-if="icon === 'list-two'">
      <path
        d="M9 42a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-28a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0 14a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      ></path>
      <path d="M21 24h22M21 38h22M21 10h22"></path>
    </template>
    <template v-else-if="icon === 'list-view'">
      <rect rx="3" height="36" width="40" y="6" x="4"></rect>
      <path d="M4 14h40M20 24h16m-16 8h16m-24-8h2m-2 8h2"></path>
    </template>
    <template v-else-if="icon === 'lock'">
      <rect rx="2" height="22" width="36" y="22" x="6"></rect>
      <path
        d="M14 22v-8c0-5.523 4.477-10 10-10s10 4.477 10 10v8m-10 8v6"
      ></path>
    </template>
    <template v-else-if="icon === 'log'">
      <path d="M13 10h28v34H13z"></path>
      <path d="M35 10V4H8a1 1 0 0 0-1 1v33h6m8-16h12m-12 8h12"></path>
    </template>
    <template v-else-if="icon === 'logout'">
      <path d="M23.992 6H6v36h18m9-9 9-9-9-9m-17 8.992h26"></path>
    </template>
    <template v-else-if="icon === 'mail'">
      <path d="M4 39h40V9H4v30Z"></path>
      <path d="m4 9 20 15L44 9"></path>
      <path d="M24 9H4v15m40 0V9H24"></path>
    </template>
    <template v-else-if="icon === 'mail-open'">
      <path
        d="M44 18v21.818C44 41.023 43.105 42 42 42H6c-1.105 0-2-.977-2-2.182V18l20 13 20-13Z"
      ></path>
      <path d="M4 17.784 24 4l20 13.784"></path>
    </template>
    <template v-else-if="icon === 'me'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
        clip-rule="evenodd"
      ></path>
      <path d="M24 23a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"></path>
      <path
        d="M10.022 38.332C10.366 33.121 14.702 29 20 29h8c5.291 0 9.623 4.11 9.977 9.311"
      ></path>
    </template>
    <template v-else-if="icon === 'message-one'">
      <path d="M4 6h40v30H29l-5 5-5-5H4V6Z"></path>
      <path d="M23 21h2.003m7.998 0H35m-21.999 0H15"></path>
    </template>
    <template v-else-if="icon === 'message-success'">
      <path d="M25.5 36H21l-10 5v-5H4V6h40v11m-32-3h6m-6 6h12"></path>
      <path d="m29 30 6 5 9-11"></path>
    </template>
    <template v-else-if="icon === 'minus'">
      <path d="M10.5 24h28"></path>
    </template>
    <template v-else-if="icon === 'modify'">
      <path
        d="m20.07 9.586-4.242-4.243a2 2 0 0 0-2.828 0L7.343 11a2 2 0 0 0 0 2.829l4.243 4.242m17.343 19.343 4.242 4.243a2 2 0 0 0 2.829 0L41.657 36a2 2 0 0 0 0-2.828l-4.243-4.243"
      ></path>
      <rect
        transform="rotate(45 34.606 4.908)"
        rx="2"
        height="42"
        width="12"
        y="4.908"
        x="34.606"
      ></rect>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="2"
        cy="24"
        cx="24"
      ></circle>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="2"
        cy="28"
        cx="20"
      ></circle>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="2"
        cy="20"
        cx="28"
      ></circle>
    </template>
    <template v-else-if="icon === 'more'">
      <circle
        fill="currentColor"
        stroke-width="0"
        r="3"
        cy="24"
        cx="12"
      ></circle>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="3"
        cy="24"
        cx="24"
      ></circle>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="3"
        cy="24"
        cx="36"
      ></circle>
    </template>
    <template v-else-if="icon === 'more-app'">
      <path
        d="M18 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Zm0 24H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V30a2 2 0 0 0-2-2ZM42 4H30a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
      ></path>
      <path d="M28 28h16m-8 8h8m-16 8h16"></path>
    </template>
    <template v-else-if="icon === 'more-one'">
      <circle
        fill="currentColor"
        stroke-width="0"
        r="3"
        cy="12"
        cx="24"
      ></circle>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="3"
        cy="24"
        cx="24"
      ></circle>
      <circle
        fill="currentColor"
        stroke-width="0"
        r="3"
        cy="35"
        cx="24"
      ></circle>
    </template>
    <template v-else-if="icon === 'museum-one'">
      <path d="M4 44h40"></path>
      <path d="M8 8.364 24 4l16 4.364V14H8V8.364Z"></path>
      <path d="M10 14v24m7-24v24m7-24v24m7-24v24m7-24v24"></path>
      <path d="M7 38h34v6H7z"></path>
    </template>
    <template v-else-if="icon === 'new-efferent'">
      <path
        d="M42 19V9a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h10"
      ></path>
      <path d="m34 24 8 8-8 8m-10-8h18"></path>
    </template>
    <template v-else-if="icon === 'newspaper-folding'">
      <path d="m22 44-1-8m21 8V12H26l1 8 1 8 1 8-7 8h20ZM28 28h5m-6-8h6"></path>
      <path d="M6 4h19l1 8 1 8 1 8 1 8H6V4Zm6 8h7m-7 8h8m-8 8h9"></path>
    </template>
    <template v-else-if="icon === 'next'">
      <path
        d="M4 40.836c4.893-5.973 9.238-9.362 13.036-10.168 3.797-.805 7.412-.927 10.846-.365V41L44 23.545 27.882 7v10.167c-6.349.05-11.746 2.328-16.192 6.833C7.245 28.505 4.681 34.117 4 40.836Z"
        clip-rule="evenodd"
      ></path>
    </template>
    <template v-else-if="icon === 'number'">
      <g fill="none" fill-rule="evenodd">
        <path
          d="M40.18 23.088c0 5.018-3.597 9.087-8.035 9.087-4.437 0-8.035-4.069-8.035-9.087 0-5.02 3.598-9.088 8.035-9.088 4.438 0 8.035 4.069 8.035 9.088Z"
        ></path>
        <path
          d="M46.492 31.773a2.21 2.21 0 1 1-4.421 0 2.21 2.21 0 0 1 4.421 0"
          fill="currentColor"
          stroke-width="0"
        ></path>
        <path d="M5 32.084V14.139l13.751 17.872v-17.92"></path>
      </g>
    </template>
    <template v-else-if="icon === 'off-screen-two'">
      <path
        d="M41 19H29V7M18 6H6v12m24 24h12V30M7 29h12v12M42 6 29 19M19 29 6 42"
      ></path>
    </template>
    <template v-else-if="icon === 'page'">
      <rect rx="3" height="36" width="36" y="6" x="6"></rect>
      <path d="M6 17h36M17 42V17"></path>
    </template>
    <template v-else-if="icon === 'people'">
      <path
        d="M24 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM6 40.8V42h36v-1.2c0-4.48 0-6.72-.872-8.432a8 8 0 0 0-3.496-3.496C35.92 28 33.68 28 29.2 28H18.8c-4.48 0-6.72 0-8.432.872a8 8 0 0 0-3.496 3.496C6 34.08 6 36.32 6 40.8Z"
      ></path>
    </template>
    <template v-else-if="icon === 'peoples-two'">
      <path
        d="M24 20a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM12 7.255A6.992 6.992 0 0 0 9 13a6.996 6.996 0 0 0 3.392 6M36 7.255A6.992 6.992 0 0 1 39 13a6.992 6.992 0 0 1-3 5.745M12 40v2h24v-2c0-3.727 0-5.591-.609-7.062a8 8 0 0 0-4.33-4.329C29.591 28 27.727 28 24 28s-5.591 0-7.061.609a8 8 0 0 0-4.33 4.33C12 34.408 12 36.273 12 40Zm32 2v-1.2c0-4.48 0-6.72-.872-8.432a8 8 0 0 0-3.496-3.496M4 42v-1.2c0-4.48 0-6.72.872-8.432a8 8 0 0 1 3.496-3.496"
      ></path>
    </template>
    <template v-else-if="icon === 'permissions'">
      <path
        d="M20 10H6a2 2 0 0 0-2 2v26a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2v-2.5"
      ></path>
      <path d="M10 23h8m-8 8h24"></path>
      <circle r="6" cy="16" cx="34"></circle>
      <path d="M44 28.419C42.047 24.602 38 22 34 22s-5.993 1.133-8.05 3"></path>
    </template>
    <template v-else-if="icon === 'picture'">
      <path
        d="M39 6H9a3 3 0 0 0-3 3v30a3 3 0 0 0 3 3h30a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3Z"
      ></path>
      <path
        d="M18 23a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm9.79 3.22a2 2 0 0 1 3.243.053l8.775 12.583c.924 1.326-.025 3.144-1.64 3.144H16l11.79-15.78Z"
      ></path>
    </template>
    <template v-else-if="icon === 'plan'">
      <path
        d="M5 19h38v22a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V19Zm0-9a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v9H5v-9Z"
      ></path>
      <path d="m16 31 6 6 12-12"></path>
      <path d="M16 5v8m16-8v8"></path>
    </template>
    <template v-else-if="icon === 'platte'">
      <path
        d="M24 44c5.96 0 2.336-8.864 6-13 3.126-3.53 14-1.914 14-7 0-11.046-8.954-20-20-20S4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path
        d="M28 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-12 4a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm1 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      ></path>
    </template>
    <template v-else-if="icon === 'plus'">
      <path d="m24.06 10-.036 28M10 24h28"></path>
    </template>
    <template v-else-if="icon === 'power'">
      <path
        d="M14.5 8a19.05 19.05 0 0 0-4.75 3.84C6.794 15.146 5 19.49 5 24.245 5 34.603 13.507 43 24 43s19-8.397 19-18.755c0-4.756-1.794-9.099-4.75-12.405A19.02 19.02 0 0 0 33.5 8M24 4v20"
      ></path>
    </template>
    <template v-else-if="icon === 'preview-close'">
      <path
        d="M6 16c.635 1.22 1.596 2.35 2.823 3.355C12.26 22.173 17.779 24 24 24s11.739-1.827 15.177-4.645C40.404 18.35 41.365 17.22 42 16m-13.023 8 2.071 7.727m6.305-10.373 5.657 5.656M5 27.01l5.657-5.657m6.271 10.375L18.998 24"
      ></path>
    </template>
    <template v-else-if="icon === 'preview-close-one'">
      <path
        d="M9.858 18C6.238 21 4 24 4 24s8.954 12 20 12c1.37 0 2.708-.185 4-.508M20.032 12.5c1.282-.318 2.61-.5 3.968-.5 11.046 0 20 12 20 12s-2.239 3-5.858 6m-17.828-9.379a5 5 0 0 0 7.186 6.95M42 42 6 6"
      ></path>
    </template>
    <template v-else-if="icon === 'preview-open'">
      <path
        d="M24 36c11.046 0 20-12 20-12s-8.954-12-20-12S4 24 4 24s8.954 12 20 12Z"
      ></path>
      <path d="M24 29a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"></path>
    </template>
    <template v-else-if="icon === 'printer'">
      <path d="M37 32H11v12h26V32Z"></path>
      <path d="M4 20h40v18h-6.983v-6H10.98v6H4V20Z" clip-rule="evenodd"></path>
      <path d="M38 4H10v16h28V4Z"></path>
    </template>
    <template v-else-if="icon === 'printer-two'">
      <path d="M38 20V8a2 2 0 0 0-2-2H12a2 2 0 0 0-2 2v12"></path>
      <rect rx="2" height="22" width="36" y="20" x="6"></rect>
      <path d="M20 34h15v8H20v-8Zm-8-8h3"></path>
    </template>
    <template v-else-if="icon === 'protect'">
      <path
        d="M6 9.256 24.009 4 42 9.256v10.778A26.316 26.316 0 0 1 24.003 45 26.32 26.32 0 0 1 6 20.029V9.256Z"
      ></path>
      <path d="m15 23 7 7 12-12"></path>
    </template>
    <template v-else-if="icon === 'redo'">
      <path
        d="M36.728 36.728A17.943 17.943 0 0 1 24 42c-9.941 0-18-8.059-18-18S14.059 6 24 6c4.97 0 9.47 2.015 12.728 5.272C38.386 12.93 42 17 42 17"
      ></path>
      <path d="M42 8v9h-9"></path>
    </template>
    <template v-else-if="icon === 'reduce-one'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="M16 24h16"></path>
    </template>
    <template v-else-if="icon === 'refresh'">
      <path
        d="M42 8v16M6 24v16m36-16c0-9.941-8.059-18-18-18a17.947 17.947 0 0 0-12.952 5.5M6 24c0 9.941 8.059 18 18 18a17.94 17.94 0 0 0 12.5-5.048"
      ></path>
    </template>
    <template v-else-if="icon === 'remind'">
      <path
        d="M24 4c-7.732 0-14 6.268-14 14v20h28V18c0-7.732-6.268-14-14-14Z"
      ></path>
      <path
        d="M10 38V18c0-7.732 6.268-14 14-14s14 6.268 14 14v20M4 38h40m-20 6a5 5 0 0 0 5-5v-1H19v1a5 5 0 0 0 5 5Z"
      ></path>
    </template>
    <template v-else-if="icon === 'return'">
      <path d="m13 8-7 6 7 7"></path>
      <path
        d="M6 14h22.994c6.883 0 12.728 5.62 12.996 12.5.284 7.27-5.723 13.5-12.996 13.5H11.998"
      ></path>
    </template>
    <template v-else-if="icon === 'right'">
      <path d="m19 12 12 12-12 12"></path>
    </template>
    <template v-else-if="icon === 'rss'">
      <path
        d="M8 44V6a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v38l-16-8.273L8 44Z"
      ></path>
      <path d="M23.95 13.95v12m-6-6h12"></path>
    </template>
    <template v-else-if="icon === 'schedule'">
      <rect rx="2" height="30" width="40" y="10" x="4"></rect>
      <path d="M14 6v8m11 9H14m20 8H14M34 6v8"></path>
    </template>
    <template v-else-if="icon === 'seal'">
      <path
        d="M5 37a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-5Z"
      ></path>
      <path
        d="M5 31a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V31Z"
      ></path>
      <path
        d="M18.763 15.664a1 1 0 0 1 .942-.664h8.59a1 1 0 0 1 .942.664L34 29H14l4.763-13.336Z"
      ></path>
      <rect rx="5.4" height="10.8" width="18" y="4" x="15"></rect>
    </template>
    <template v-else-if="icon === 'search'">
      <path
        d="M21 38c9.389 0 17-7.611 17-17S30.389 4 21 4 4 11.611 4 21s7.611 17 17 17Z"
      ></path>
      <path
        d="M26.657 14.343A7.975 7.975 0 0 0 21 12a7.975 7.975 0 0 0-5.657 2.343m17.879 18.879 8.485 8.485"
      ></path>
    </template>
    <template v-else-if="icon === 'send'">
      <path d="M43 5 29.7 43l-7.6-17.1L5 18.3 43 5Z"></path>
      <path d="M43 5 22.1 25.9"></path>
    </template>
    <template v-else-if="icon === 'send-email'">
      <path d="M44 24V9H4v30h20m20-5H30m9-5 5 5-5 5"></path>
      <path d="m4 9 20 15L44 9"></path>
    </template>
    <template v-else-if="icon === 'setting-one'">
      <path d="m34 41 10-17L34 7H14L4 24l10 17h20Z"></path>
      <path d="M24 29a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"></path>
    </template>
    <template v-else-if="icon === 'setting-two'">
      <path
        d="M18.284 43.171a19.995 19.995 0 0 1-8.696-5.304 6 6 0 0 0-5.182-9.838A20.09 20.09 0 0 1 4 24c0-2.09.32-4.106.916-6H5a6 6 0 0 0 5.385-8.65 19.968 19.968 0 0 1 8.267-4.627A6 6 0 0 0 24 8a6 6 0 0 0 5.348-3.277 19.968 19.968 0 0 1 8.267 4.627A6 6 0 0 0 43.084 18 19.99 19.99 0 0 1 44 24c0 1.38-.14 2.728-.406 4.03a6 6 0 0 0-5.182 9.838 19.995 19.995 0 0 1-8.696 5.303 6.003 6.003 0 0 0-11.432 0Z"
      ></path>
      <path d="M24 31a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"></path>
    </template>
    <template v-else-if="icon === 'share-three'">
      <path d="M42 38v4H6v-4M30 6l10 10-10 10"></path>
      <path d="M40 16C20 16 6 19 6 32"></path>
    </template>
    <template v-else-if="icon === 'shopping'">
      <path d="M39 32H13L8 12h36l-5 20Z"></path>
      <path d="M3 6h3.5L8 12m0 0 5 20h26l5-20H8Z"></path>
      <circle r="3" cy="39" cx="13"></circle>
      <circle r="3" cy="39" cx="39"></circle>
    </template>
    <template v-else-if="icon === 'shopping-filled'">
      <path fill="currentColor" d="M39 32H13L8 12h36l-5 20Z"></path>
      <path d="M3 6h3.5L8 12m0 0 5 20h26l5-20H8Z"></path>
      <circle r="3" cy="39" cx="13"></circle>
      <circle r="3" cy="39" cx="39"></circle>
    </template>
    <template v-else-if="icon === 'shopping-mall'">
      <path
        d="M8 44V6a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v38"
        clip-rule="evenodd"
      ></path>
      <path d="M8 44V6a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v38m0-29 10 6v23"></path>
      <path d="M4 44h40"></path>
    </template>
    <template v-else-if="icon === 'star'">
      <path
        d="m23.999 5-6.113 12.478L4 19.49l10.059 9.834L11.654 43 24 36.42 36.345 43 33.96 29.325 44 19.491l-13.809-2.013L24 5Z"
      ></path>
    </template>
    <template v-else-if="icon === 'star-filled'">
      <path
        d="m23.999 5-6.113 12.478L4 19.49l10.059 9.834L11.654 43 24 36.42 36.345 43 33.96 29.325 44 19.491l-13.809-2.013L24 5Z"
        fill="currentColor"
      ></path>
    </template>
    <template v-else-if="icon === 'switch'">
      <path d="M42 19H6M30 7l12 12M6.799 29h36m-36 0 12 12"></path>
    </template>
    <template v-else-if="icon === 'tag-one'">
      <path
        d="M42.17 29.245 29.262 42.151a3.6 3.6 0 0 1-5.094 0L8 26V8h18l16.17 16.17a3.6 3.6 0 0 1 0 5.075Z"
      ></path>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M18.5 21a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
      ></path>
    </template>
    <template v-else-if="icon === 'text-message'">
      <path d="M44 7H4v30h15l5 5 5-5h15V7Z"></path>
      <path d="M14 16h6m-6 8h2m13-10 7 14m-7-14-7 14m2-4h10"></path>
    </template>
    <template v-else-if="icon === 'text-style-one'">
      <path
        d="m4 42 4.941-12M32 42l-4.941-12m0 0L25 25 18 8l-7 17-2.059 5m18.118 0H8.94M28 10h16M32 20h12m-8 10h8m-4 10h4"
      ></path>
    </template>
    <template v-else-if="icon === 'time'">
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Z"
      ></path>
      <path d="M24.008 12v12.01l8.479 8.48"></path>
    </template>
    <template v-else-if="icon === 'to-top'">
      <path d="M24.008 14.1V42M12 26l12-12 12 12M12 6h24"></path>
    </template>
    <template v-else-if="icon === 'translate'">
      <path
        d="M28.286 37h11.428M42 42l-2.286-5L42 42Zm-16 0 2.286-5L26 42Zm2.286-5L34 24l5.714 13H28.286ZM16 6l1 3M6 11h22m-18 5s1.79 6.26 6.263 9.74C20.737 29.216 28 32 28 32"
      ></path>
      <path d="M24 11s-1.79 8.217-6.263 12.783C13.263 28.348 6 32 6 32"></path>
    </template>
    <template v-else-if="icon === 'unlink'">
      <path
        d="m25.893 16.03-7.779-7.778c-2.863-2.863-7.41-2.959-10.157-.213-2.746 2.746-2.65 7.293.214 10.157l7.778 7.778m15.967-3.904 7.778 7.779c2.864 2.864 3.235 7.274.214 10.157s-7.294 2.65-10.157-.213l-7.779-7.779m-.734-10.938-3.889-3.89M30.313 30.15l-3.889-3.889"
      ></path>
    </template>
    <template v-else-if="icon === 'unlock'">
      <rect rx="2" height="22" width="34" y="22.048" x="7"></rect>
      <path
        d="M14 22v-7.995c-.005-5.135 3.923-9.438 9.086-9.954S32.967 6.974 34 12.006M24 30v6"
      ></path>
    </template>
    <template v-else-if="icon === 'up'">
      <path d="m13 30 12-12 12 12"></path>
    </template>
    <template v-else-if="icon === 'upload'">
      <mask
        style="mask-type: alpha"
        height="48"
        width="48"
        y="0"
        x="0"
        maskUnits="userSpaceOnUse"
        id="ac123__a"
      >
        <path fill="currentColor" stroke-width="0" d="M48 0H0v48h48V0Z"></path>
      </mask>
      <g mask="url(#ac123__a)">
        <path d="M6 24.008V42h36V24m-9-9-9-9-9 9m8.992 17V6"></path>
      </g>
    </template>
    <template v-else-if="icon === 'user'">
      <circle r="8" cy="12" cx="24"></circle>
      <path d="M42 44c0-9.941-8.059-18-18-18S6 34.059 6 44"></path>
    </template>
    <template v-else-if="icon === 'view-grid-list'">
      <rect rx="3" height="36" width="36" y="6" x="6"></rect>
      <path d="M13 13h8v8h-8zm0 14h8v8h-8z"></path>
      <path d="M27 28h8m-8 7h8m-8-22h8m-8 7h8"></path>
    </template>
    <template v-else-if="icon === 'view-list'">
      <rect rx="2" height="40" width="32" y="4" x="8"></rect>
      <path d="M21 14h12M21 24h12M21 34h12"></path>
      <path
        fill="currentColor"
        stroke-width="0"
        d="M15 16a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        clip-rule="evenodd"
        fill-rule="evenodd"
      ></path>
    </template>
    <template v-else-if="icon === 'workbench'">
      <path d="M12 33H4V7h40v26H12Z"></path>
      <path d="M16 22v4m8 7v6m0-21v8m8-12v12M12 41h24"></path>
    </template>
    <template v-else-if="icon === 'write'">
      <path
        d="M5.325 43.5h8.485l31.113-31.113-8.486-8.485L5.325 35.015V43.5Z"
      ></path>
      <path d="m27.952 12.387 8.485 8.485"></path>
    </template>
    <template v-else-if="icon === 'zoom-in'">
      <path
        d="M21 38c9.389 0 17-7.611 17-17S30.389 4 21 4 4 11.611 4 21s7.611 17 17 17Z"
      ></path>
      <path d="M21 15v12m-5.984-5.984L27 21m6.222 12.222 8.485 8.485"></path>
    </template>
    <template v-else-if="icon === 'zoom-out'">
      <path
        d="M21 38c9.389 0 17-7.611 17-17S30.389 4 21 4 4 11.611 4 21s7.611 17 17 17Z"
      ></path>
      <path d="M15 21h12m6.222 12.222 8.485 8.485"></path>
    </template>
    <template v-else>
      <path
        d="M8 6a2 2 0 0 1 2-2h20l10 10v28a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V6Z"
      ></path>
      <path d="M16 20h16m-16 8h16"></path>
    </template>
  </base-icon>
</template>

<script>
import BaseIcon from "@/components/icon/BaseIcon";

export default {
  name: "CommonIcon",
  components: { BaseIcon },
  props: {
    icon: { type: String },
    weight: { type: Number, default: 4 },
    lineJoin: { type: String, default: "round" },
    lineCap: { type: String, default: "round" },
  },
};
</script>

<style scoped></style>
