export default {
  addHelp: {
    okText: "actions.save",
    cancelText: "actions.cancel",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/helpManage/dialog/AddHelp"
      ),
  },
};
