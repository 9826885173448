<template>
  <div class="closeable-field">
    <slot></slot>
    <div v-if="showClose" class="closeable-field-close" @click="handleClose">
      <close-outlined />
    </div>
  </div>
</template>

<script>
import { CloseOutlined } from "@ant-design/icons-vue";

export default {
  name: "CloseableField",
  components: { CloseOutlined },
  emits: ["close"],
  props: {
    showClose: { type: Boolean, default: true },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.closeable-field {
  position: relative;
}
.closeable-field-close {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  height: 18px;
  width: 18px;
  transform: translate(50%, -50%);
  transform-origin: 100% 0;
  border-radius: 50%;
  background: black;
  color: white;
  font-size: 12px;
  cursor: pointer;
}
.closeable-field-close:hover {
  background: #555555;
}
.closeable-field:hover .closeable-field-close {
  display: flex;
}
</style>
