import { createEnum } from "@/mods/common/enum";
// 档案列表按钮操作
import { ARCH_LIST_OPERATE } from "@/mods/archive/archiveTreasury/enum/ARCH_LIST_OPERATE";
// 档案列表右键操作
import { ARCH_LIST_CONTEXT_OPERATE } from "@/mods/archive/archiveTreasury/enum/ARCH_LIST_CONTEXT_OPERATE";

export { ARCH_LIST_OPERATE, ARCH_LIST_CONTEXT_OPERATE };
// 档案列表选项卡

export const ARCH_LIST_TAB = createEnum(
  {
    TOCOLLECT: { CODE: 0, NAME: "待整理" },
    COLLECTED: { CODE: 7, NAME: "已整理" },
    SAVED: { CODE: 1, NAME: "已归档" },
    TOCHECK: { CODE: 20, NAME: "待检查" },
    CHECKED: { CODE: 24, NAME: "已检查" },
    TOARRANGE: { CODE: 28, NAME: "需整理" },
    USED: { CODE: 30, NAME: "可利用" },
    DELETED: { CODE: 9, NAME: "回收站" },
    UNSAVED: { CODE: 11, NAME: "不归档" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);

// 档案列表大小
export const ARCH_LIST_TABLE_SIZE = createEnum(
  {
    SMALL: { CODE: 0, NAME: "小号字", RESCODE: "small" },
    MIDDLE: { CODE: 2, NAME: "中号字", RESCODE: "middle" },
    LARGE: { CODE: 1, NAME: "大号字", RESCODE: "large" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);

// 档案列表行操作
export const ARCH_LIST_ROW_OPERATE = createEnum(
  {
    EDITROW: {
      CODE: "EDITROW",
      NAME: "编辑",
      ACTION: "editRow",
    },
    DETAILROW: {
      CODE: "DETAILROW",
      NAME: "查看",
      ACTION: "detailRow",
    },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);
