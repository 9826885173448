import BaseLayout from "@/components/core/BaseLayout";

export default [
  {
    path: "/portalsso",
    name: "PortalSSO",
    component: BaseLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "portal_work_sso",
        name: "PortalSSO",
        meta: {
          title: "SSO待办测试",
        },
        component: () => import("@/mods/portalsso/PortalSSO"),
      },
    ],
  },
];
