export default {
  addUserGroup: {
    width: "s",
    title: "添加用户组",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/managementAuthorization/dialog/AddUserGroup"
      ),
  },
  modifyPersonnelInformation: {
    width: "s",
    title: "用户信息",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/managementAuthorization/dialog/ModifyPersonnelInformation"
      ),
  },
  empowerAsUser: {
    width: "s",
    title: "用户授权",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/managementAuthorization/dialog/EmpowerAsUser"
      ),
  },
  empowerGuan: {
    width: "s",
    title: "分工授权",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/managementAuthorization/dialog/EmpowerGuan"
      ),
  },
  empowerShi: {
    width: "m",
    title: "分工授权",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/managementAuthorization/dialog/EmpowerShi"
      ),
  },
};
