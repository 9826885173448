import { createEnum } from "@/mods/common/enum";

// 档案
import {
  ARCH_STORAGE,
  ARCH_STATUS,
  ARCH_OBJTYPE,
} from "@/mods/archive/archiveTreasury/enum/ARCH_BASE";

// 目录树
import { CATALOG_TREE_NODE_TYPE } from "@/mods/archive/archiveTreasury/enum/CATALOG_TREE";

// 档案列表
import {
  ARCH_LIST_TAB,
  ARCH_LIST_ROW_OPERATE,
  ARCH_LIST_TABLE_SIZE,
  ARCH_LIST_OPERATE,
  ARCH_LIST_CONTEXT_OPERATE,
} from "@/mods/archive/archiveTreasury/enum/ARCH_LIST";

export {
  ARCH_LIST_TAB,
  ARCH_LIST_ROW_OPERATE,
  ARCH_LIST_TABLE_SIZE,
  ARCH_LIST_OPERATE,
  ARCH_LIST_CONTEXT_OPERATE,
  ARCH_STORAGE,
  ARCH_STATUS,
  ARCH_OBJTYPE,
  CATALOG_TREE_NODE_TYPE,
};

// 档案管理状态
export const ARCH_MANAGE_STATUS = createEnum(
  {
    COMPANY: { CODE: 2, NAME: "公司级" },
    DEPARTMENT: { CODE: 1, NAME: "部门级" },
    PERSON: { CODE: 0, NAME: "个人级" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);

// 用户角色类型
export const BACK_ROLE_TYPE = createEnum(
  {
    COMPANY: { CODE: 6, NAME: "公司级" },
    DEPARTMENT: { CODE: 5, NAME: "部门级" },
    PERSON: { CODE: 4, NAME: "员工级" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);
// 文件下载类型
export const DOWNLOAD_FILE_TYPE = createEnum(
  {
    SOURCE: { CODE: 10, NAME: "当前文件" },
    PDF: { CODE: 11, NAME: "PDF文件" },
    WATER: { CODE: 21, NAME: "水印文件" },
    ELECT_SEAL: { CODE: 22, NAME: "电子章文件" },
    WATER_AND_SEAL: { CODE: 23, NAME: "水印+电子章文件" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);
// 门类整理状态
export const ARCHTYPE_COLLECTTYPE = createEnum(
  {
    SINGLE: { CODE: 1, NAME: "单状态" },
    MULTI: { CODE: 2, NAME: "多状态" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);

export const SERVICE_FROM = createEnum(
  {
    NEW: { CODE: 1, NAME: "手工著录" },
    EDIT: { CODE: 2, NAME: "条目保存" },
    CUTIN: { CODE: 3, NAME: "插录" },
    APPEND: { CODE: 4, NAME: "追加" },
    VIEW: { CODE: 5, NAME: "查看" },
    ONLY_EDIT: { CODE: 6, NAME: "编辑" },
  },
  {
    TYPE: "CODE",
    NAME: "NAME",
  }
);

// 上传类型
export const UPLOAD_TYPE = createEnum(
  {
    NORMAL_UPLOAD: { TYPE: "local", NAME: "普通上传" },
    FTP_UPLOAD: { TYPE: "ftp", NAME: "ftp上传" },
  },
  {
    TYPE: "TYPE",
    NAME: "NAME",
  }
);

// 批量著录类型
export const EXCEL_IMPORT_TYPE = Object.freeze({
  ADD: { CODE: 1, NAME: "新增导入" },
  UPDATE: { CODE: 2, NAME: "更新导入" },
});
