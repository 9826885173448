<template>
  <base-icon
    :icon="icon"
    :weight="weight"
    :line-cap="lineCap"
    :line-join="lineJoin"
  >
    <template v-if="icon === 'audio-file'">
      <path d="M8 44V4h23l9 10.5V44H8Z"></path>
      <path d="m32 14-6 2.969V31.5"></path>
      <circle r="5.5" cy="31.5" cx="20.5"></circle>
    </template>
    <template v-else-if="icon === 'bill'">
      <path
        d="M10 6a2 2 0 0 1 2-2h24a2 2 0 0 1 2 2v38l-7-5-7 5-7-5-7 5V6Zm8 16h12m-12 8h12M18 14h12"
      ></path>
    </template>
    <template v-else-if="icon === 'book-one'">
      <path d="M7 37V11a6 6 0 0 1 6-6h22v26H13c-3.3 0-6 2.684-6 6Z"></path>
      <path d="M35 31H13a6 6 0 0 0 0 12h28V7M14 37h20"></path>
    </template>
    <template v-else-if="icon === 'building-two'">
      <path d="m21 13-10 7v24"></path>
      <path d="m21 4 10 7v13l7 5v15H21V4Z" clip-rule="evenodd"></path>
      <path d="M4 44h40"></path>
    </template>
    <template v-else-if="icon === 'chart-graph'">
      <path d="M17 6h14v9H17zM6 33h14v9H6zm22 0h14v9H28z"></path>
      <path d="M24 16v8m-11 9v-9h22v9"></path>
    </template>
    <template v-else-if="icon === 'consume'">
      <path
        d="M4 14a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V14Z"
      ></path>
      <path d="m19 19 5 5 5-5m-11 6h12m-12 6h12m-6-6v10M8 6h32"></path>
    </template>
    <template v-else-if="icon === 'data-file'">
      <path d="M8 44V4h23l9 10.5V44H8Z"></path>
      <path d="M15 28h6v7h-6zm-1 7h20M21 23h6v12h-6zm6-5h6v17h-6z"></path>
    </template>
    <template v-else-if="icon === 'deposit'">
      <path d="M12 16v28h24V16"></path>
      <path
        d="m19 20 5 6 5-6M18 32h12m-12-6h12m-6 0v12M14 10h20m2 8h6V4H6v14h6"
      ></path>
    </template>
    <template v-else-if="icon === 'doc-search'">
      <path
        d="M38 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
      ></path>
      <path d="M28 16c0 1.38-.56 2.63-1.465 3.535A5 5 0 1 1 28 16Z"></path>
      <path
        d="m30 23-3.465-3.465m0 0a5 5 0 1 0-7.071-7.07 5 5 0 0 0 7.072 7.07ZM17 30h14m-14 6h7"
      ></path>
    </template>
    <template v-else-if="icon === 'document-folder'">
      <path
        d="M32 6H22v36h10V6Zm10 0H32v36h10V6ZM10 6l8 1-3.5 35L6 41l4-35Z"
      ></path>
      <path d="M37 18v-3m-10 3v-3"></path>
    </template>
    <template v-else-if="icon === 'engineering-brand'">
      <rect rx="2" height="20" width="38" y="6" x="5"></rect>
      <path d="M12 26v16m24-9H12m4 9H8m32 0h-8m4-16v16"></path>
    </template>
    <template v-else-if="icon === 'engineering-vehicle'">
      <path d="M32 6h6M10 36H6v-8h26v8H18m14 0V12h6.5L44 24v12h-3"></path>
      <path d="M4 8h22v14H7L4 8Z"></path>
      <circle r="4" cy="38" cx="37"></circle>
      <circle r="4" cy="38" cx="14"></circle>
    </template>
    <template v-else-if="icon === 'file-cabinet'">
      <path d="M42 4H6v10h36V4Zm0 15H6v10h36V19Zm0 15H6v10h36V34Z"></path>
      <path d="M21 9h6m-6 15h6m-6 15h6"></path>
    </template>
    <template v-else-if="icon === 'file-focus-one'">
      <path d="M40 23v-9L31 4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h12"></path>
      <path
        d="m34 27 2.523 5.527 6.036.692-4.476 4.108 1.207 5.954L34 40.293l-5.29 2.988 1.207-5.954-4.477-4.108 6.037-.692L34 27ZM30 4v10h10"
      ></path>
    </template>
    <template v-else-if="icon === 'folder'">
      <path
        d="M7 6a2 2 0 0 1 2-2h30a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V6Zm9 23h4m-4 6h10"
      ></path>
      <path d="M8 5s3.765 13 16 13S40 5 40 5"></path>
      <circle r="4" cy="18" cx="24"></circle>
      <path d="M15 4H9a2 2 0 0 0-2 2v6m26-8h6a2 2 0 0 1 2 2v6"></path>
    </template>
    <template v-else-if="icon === 'folder-close'">
      <path
        d="M5 8a2 2 0 0 1 2-2h12l5 6h17a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8Zm38 14H5"
      ></path>
      <path d="M5 16v12m38-12v12"></path>
    </template>
    <template v-else-if="icon === 'gold-medal'">
      <path
        d="M44 4H31l-4 10.3A15.023 15.023 0 0 1 37.27 22L44 4ZM17 4H4l6.73 18A15.023 15.023 0 0 1 21 14.3L17 4Z"
      ></path>
      <path
        d="M39 29c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-2.528.625-4.91 1.73-7A15.023 15.023 0 0 1 21 14.3c.97-.197 1.973-.3 3-.3s2.03.103 3 .3A15.023 15.023 0 0 1 37.27 22 14.935 14.935 0 0 1 39 29Z"
      ></path>
      <path d="M24 35V22l-3 1m3 12h4m-4 0h-4"></path>
    </template>
    <template v-else-if="icon === 'helmet-one'">
      <path
        d="M24 11c-9.389 0-17 7.815-17 17.454V35h34v-6.546C41 18.814 33.389 11 24 11ZM4 35h40v6H4z"
      ></path>
      <path d="M21 6h6v18h-6z"></path>
    </template>
    <template v-else-if="icon === 'market-analysis'">
      <path
        d="M44 11a3 3 0 0 0-3-3H7a3 3 0 0 0-3 3v9h40v-9ZM4.112 39.03l12.176-12.3 6.58 6.3L30.91 26l4.48 4.368"
      ></path>
      <path d="M44 18v19a3 3 0 0 1-3 3H12m7.112-26h18M11.11 14h2M4 18v9"></path>
    </template>
    <template v-else-if="icon === 'monitor'">
      <path d="M4 10h32v28H4z"></path>
      <path d="m44 14-8 6.75v6.5L44 34V14Z" clip-rule="evenodd"></path>
      <path d="m17 19 6 5-6 5"></path>
    </template>
    <template v-else-if="icon === 'notes'">
      <path
        d="M8 6a2 2 0 0 1 2-2h20l10 10v28a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V6Z"
      ></path>
      <path d="M16 20h16m-16 8h16"></path>
    </template>
    <template v-else-if="icon === 'palace'">
      <path
        d="M4 18h40L24 6 4 18Zm40 24H4m5-24v24m10-24v24m10-24v24m10-24v24"
      ></path>
    </template>
    <template v-else-if="icon === 'pic'">
      <path
        d="M5 10a2 2 0 0 1 2-2h34a2 2 0 0 1 2 2v28a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V10Z"
        clip-rule="evenodd"
      ></path>
      <path
        d="M14.5 18a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        clip-rule="evenodd"
      ></path>
      <path
        d="m15 24 5 4 6-7 17 13v4a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-4l10-10Z"
      ></path>
    </template>
    <template v-else-if="icon === 'save-one'">
      <path
        d="M39.3 6H8.7A2.7 2.7 0 0 0 6 8.7v30.6A2.7 2.7 0 0 0 8.7 42h30.6a2.7 2.7 0 0 0 2.7-2.7V8.7A2.7 2.7 0 0 0 39.3 6Z"
      ></path>
      <path d="M32 6v18H15V6h17Z"></path>
      <path d="M26 13v4M10.997 6H36"></path>
    </template>
    <template v-else-if="icon === 'slide-two'">
      <path d="M4 8h40"></path>
      <path d="M8 8h32v26H8V8Z" clip-rule="evenodd"></path>
      <path d="m31 18 3 3-3 3m-14 0-3-3 3-3m-1 24 8-8 8 8"></path>
    </template>
    <template v-else-if="icon === 'trophy'">
      <path
        d="M24 30c6.627 0 12-5.53 12-12.353V4H12v13.647C12 24.47 17.373 30 24 30Z"
      ></path>
      <path
        clip-rule="evenodd"
        d="M12 21V11H4c0 6.667 4 10 8 10Zm24 0V11h8c0 6.667-4 10-8 10Z"
      ></path>
      <path d="M24 32v4"></path>
      <path d="m15 42 3.69-6h10.352L33 42H15Z"></path>
    </template>
    <template v-else-if="icon === 'two-ellipses'">
      <path
        d="M40.579 7.349c3.765 3.764-.622 14.255-9.799 23.431-9.176 9.177-19.667 13.564-23.431 9.799-3.765-3.765.622-14.255 9.798-23.432C26.324 7.971 36.814 3.584 40.58 7.35Z"
      ></path>
      <path
        d="M7.485 7.349c-3.764 3.764.623 14.255 9.799 23.431 9.176 9.177 19.667 13.564 23.432 9.799 3.764-3.765-.623-14.255-9.799-23.432C21.741 7.971 11.25 3.584 7.485 7.35Z"
      ></path>
    </template>
    <template v-else-if="icon === 'video-file'">
      <path
        d="M10 44h28a2 2 0 0 0 2-2V14H30V4H10a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2ZM30 4l10 10"
      ></path>
      <path d="M14 21h13.493v3.5L34 22v11l-6.507-2.5V34H14V21Z"></path>
    </template>
    <template v-else-if="icon === 'waterfalls-v'">
      <path
        d="M23 20V6H6v14h17Zm19 22V28H25v14h17ZM31 6v14h11V6H31ZM6 28v14h11V28H6Z"
      ></path>
    </template>
    <template v-else>
      <path
        d="M8 6a2 2 0 0 1 2-2h20l10 10v28a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V6Z"
      ></path>
      <path d="M16 20h16m-16 8h16"></path>
    </template>
  </base-icon>
</template>

<script>
import BaseIcon from "@/components/icon/BaseIcon";

export default {
  name: "MetaDataIcon",
  components: { BaseIcon },
  props: {
    icon: { type: String },
    weight: { type: Number, default: 4 },
    lineJoin: { type: String, default: "round" },
    lineCap: { type: String, default: "round" },
  },
};
</script>

<style scoped></style>
