import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { DEFAULT_TITLE, SUPPORT_LANGUAGE_LIST } from "@/conf";
import { loadLanguageAsync } from "@/conf/lang";

export function useSystemLogo(logoType = "") {
  const store = useStore();
  const prefix = logoType ? `${logoType}Logo` : "logo";
  const logoInfo = computed(() => {
    const config = store.state.systemConfig || {};
    return config.logoInfo || {};
  });
  const logoSrc = computed(() => logoInfo.value[`${prefix}Src`]);
  const hasLogoImg = computed(() => {
    return +logoInfo.value?.[`${prefix}Type`] > 1 && logoSrc.value;
  });
  const hasLogoText = computed(() => {
    const type = +logoInfo.value?.[`${prefix}Type`];
    return !type || !logoSrc.value || type === 1 || type === 3;
  });
  const logoText = computed(() => logoInfo.value.systemTitle || DEFAULT_TITLE);
  return { logoSrc, logoText, hasLogoImg, hasLogoText };
}

export function useSystemLanguage() {
  const store = useStore();
  const route = useRoute();
  const language = computed(() => store.state.language);
  const languages = computed(() =>
    SUPPORT_LANGUAGE_LIST.map((x) => ({ ...x, action: x.value }))
  );
  const onLanguageChange = (lang) => {
    store.commit("updateLanguage", lang);
    const path = route.path.split("/");
    loadLanguageAsync(lang, path[1]).then(() => {});
  };
  return { language, languages, onLanguageChange };
}
