export default {
  log: {
    title: "查看日志",
    width: "s",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/outApply/dialog/OutApplyViewLog"
      ),
  },
};
