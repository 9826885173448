export default {
  receiveOfflineBatchDetail: {
    component: () =>
      import(
        /* webpackChunkName: "archive_detail" */ "@/mods/archive/receiveOfflineArchive/ReceiveOfflineBatchDetail"
      ),
  },
  insideDocDetail: {
    component: () =>
      import(
        /* webpackChunkName: "archive_detail" */ "@/mods/archive/receiveOfflineArchive/modules/InsideDoc.vue"
      ),
  },
};
