export default {
  searchResult: {
    width: "1200px",
    okText: "actions.save",
    title: "档案列表",
    component: () =>
      import(
        /* webpackChunkName: "warehouse_dialog" */ "@/mods/warehouse/entityRegister/dialog/SearchResult"
      ),
  },
};
