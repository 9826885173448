export function createEnum(
  definition,
  field = {
    TYPE: "key",
    NAME: "label",
  }
) {
  const typeToValueMap = {};
  const typeToKeyMap = {};
  const typeToNameMap = {};
  for (const key of Object.keys(definition)) {
    const TYPE = definition[key][field.TYPE];
    const NAME = definition[key][field.NAME];
    typeToKeyMap[TYPE] = key;
    typeToNameMap[TYPE] = NAME;
    typeToValueMap[key] = definition[key];
  }
  const enumerate = {};
  Object.assign(
    enumerate,
    {
      getKeyFromValue: (value) => typeToKeyMap[value] || "",
      getNameFromValue: (value) => typeToNameMap[value] || "",
    },
    typeToValueMap
  );
  Object.defineProperty(enumerate, "getKeyFromValue", {
    enumerable: false,
    writable: false,
  });
  Object.defineProperty(enumerate, "getNameFromValue", {
    enumerable: false,
    writable: false,
  });
  return enumerate;
}
