export default {
  archiveInfo: {
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/collectArchive/dialog/ArchiveInfo"
      ),
  },
  addEndowmentRegistry: {
    width: "s",
    title: "添加捐献登记单",
    okText: "保存",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/collectArchive/dialog/AddEndowmentRegistry"
      ),
  },
};
