import axios from "axios";
import store from "@/store";
import { BASE_URL } from "@/conf";
import router from "@/router";
import { showConfirm, showMessage, showNotification } from "@/util/dialog";
import { DEBUG_LANG_KEY } from "@/conf/lang";
import { compatMessageInfo } from "@/util/strUtil";
import { debounceFunc } from "@/util/uiUtil";

axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = 0;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (config) => {
    const method = config.method;
    if (store.state?.accessToken && method !== "get") {
      config.headers["x-access-token"] = store.state?.accessToken;
    }
    const lang = store.state?.language;
    if (lang && lang !== DEBUG_LANG_KEY) {
      config.headers["accept-language"] = lang;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const getActionType = (code, action) => {
  const act = action.split("_");
  if (act.length === 2) return act[1];
  if (code < 0) return "error";
  if (code === 0) return "success";
};
export const httpActionHandler = (data) => {
  return new Promise((resolve, reject) => {
    const action = data.action || "";
    const { title, content } = compatMessageInfo(data);
    if (action === "message" || action.startsWith("message_")) {
      showMessage(title, getActionType(data.code, action));
      resolve(data);
    } else if (action === "confirm" || action.startsWith("confirm_")) {
      showConfirm({
        type: getActionType(data.code, action),
        title,
        content,
        returnCancel: true,
      }).then(() => {
        resolve(data);
      });
    } else if (action === "alert" || action.startsWith("alert_")) {
      showConfirm({
        type: getActionType(data.code, action),
        title,
        content,
        okCancel: false,
        okText: "actions.got_it",
      }).then(() => {
        resolve(data);
      });
    } else if (action === "notify" || action.startsWith("notify_")) {
      showNotification({
        type: getActionType(data.code, action),
        title,
        content,
        okCancel: false,
      });
      resolve(data);
    } else if (data.code < 0) {
      showMessage(title, getActionType(data.code, action));
      reject(data);
    } else {
      resolve(data);
    }
  });
};
const handleNoLogin = debounceFunc(
  () => {
    router.push("/login").then(() => {
      showMessage("errors.not_login", "info");
      store.commit("logout");
    });
  },
  500,
  true
);
axios.interceptors.response.use(
  (res) => {
    if (res?.config?.responseType === "blob") {
      return res;
    }
    if (typeof res.data !== "object") {
      return Promise.reject(res);
    }
    if (+res.data?.code === -401) {
      handleNoLogin();
      return Promise.reject(res.data);
    }
    if (res.data?.code < 0 || res.data?.action) {
      return httpActionHandler(res.data);
    }
    return res.data;
  },
  (error) => {
    const { status } = error?.response ?? {};
    if (status === 401) {
      handleNoLogin();
      return Promise.reject(error);
    }
    if (error?.code === "ERR_CANCELED") {
      return Promise.reject(error);
    }
    showMessage("errors.server", "error");
    return Promise.reject(error);
  }
);

export function cancelablePost(url, data, processor = (r) => r, config) {
  const ctr = new AbortController();
  const p = axios
    .post(url, data, {
      ...(config ?? {}),
      signal: ctr.signal,
    })
    .then((res) => processor(res))
    .catch(() => {});
  p.cancelRequest = () => {
    ctr.abort();
  };
  return p;
}

export function cancelableRequest(config) {
  const ctr = new AbortController();
  const { processor = (r) => r, ...rest } = config ?? {};
  const p = axios
    .request({
      ...rest,
      signal: ctr.signal,
    })
    .then((res) => processor(res))
    .catch(() => {});
  p.cancelRequest = () => {
    ctr.abort();
  };
  return p;
}

export function checkAppVersion() {
  return new Promise((resolve) => {
    fetch(`./index.html?v=${Date.now()}`)
      .then((r) => r.text())
      .then((text) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/html");
        const meta = document.querySelector('meta[name="e-doc-version"]');
        const newMeta = doc.querySelector('meta[name="e-doc-version"]');
        resolve({
          changed: meta?.content !== newMeta?.content,
        });
      });
  });
}

export { axios as http };
