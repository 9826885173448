<template>
  <div ref="listRef" :class="{ 'overflowList-view': enabled }">
    <template v-if="enabled">
      <template v-for="item in state.visibleList" :key="item[itemKey] || item">
        <slot name="item" :item="item"></slot>
      </template>
      <div v-if="showEllipsis" class="overflowList-view-end">
        <slot name="overflow" :items="state.overflowList"></slot>
      </div>
      <div ref="padRef" class="overflowList-view-pad"></div>
    </template>
    <template v-else>
      <template v-for="item in items" :key="item[itemKey] || item">
        <slot name="item" :item="item"></slot>
      </template>
    </template>
  </div>
</template>

<script>
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs,
  watch,
  watchEffect,
} from "vue";
import { debounceFunc } from "@/util/uiUtil";

const NONE = 0;
const GROW = 1;
const SHRINK = 2;

export default {
  name: "OverflowView",
  props: {
    items: { type: Array },
    enabled: { type: Boolean },
    itemKey: { type: String, default: "key" },
  },
  setup(props, { expose }) {
    const { items, enabled } = toRefs(props);

    const listRef = ref(null);
    const padRef = ref(null);
    const prevWidth = ref(0);
    let observer = null;

    const state = ref({
      status: GROW,
      last: 0,
      overflowList: [],
      visibleList: items.value,
    });

    const showEllipsis = computed(
      () => enabled.value && state.value?.overflowList?.length > 0
    );

    const process = (newStatus) => {
      const { status, overflowList = [], last, visibleList = [] } = state.value;
      if (newStatus === NONE) {
        state.value.status = NONE;
      } else if (newStatus === GROW) {
        const count = status === NONE ? overflowList.length : last;
        state.value = {
          status: GROW,
          last: count,
          overflowList: [],
          visibleList: items?.value,
        };
      } else if (newStatus === SHRINK) {
        if (visibleList.length > 1) {
          const newVisible = [...visibleList];
          const next = newVisible.pop();
          if (next !== undefined) {
            state.value = {
              status: status !== GROW ? SHRINK : status,
              overflowList: [next, ...overflowList],
              visibleList: newVisible,
            };
          }
        } else {
          state.value.status = NONE;
        }
      } else {
        state.value.status = NONE;
      }
    };

    const partition = (growing) => {
      if (padRef.value) {
        let newStatus;
        if (growing) {
          newStatus = GROW;
        } else {
          const rect = padRef.value.getBoundingClientRect();
          newStatus = rect.width < 1 ? SHRINK : NONE;
        }
        process(newStatus);
        nextTick(() => {
          if (state.value.status !== NONE) {
            partition(false);
          }
        });
      }
    };

    const adjust = (width = 0) => {
      partition(width > prevWidth.value);
      prevWidth.value = width;
    };

    const fix = () => {
      if (listRef.value) {
        adjust(listRef.value.clientWidth);
      }
    };

    let timeout = null;
    const init = () => {
      observer = new ResizeObserver(
        debounceFunc(
          (entries) => {
            entries.forEach((it) => {
              adjust(it.contentRect.width);
            });
            if (timeout !== null) clearTimeout(timeout);
            timeout = setTimeout(() => {
              fix();
              timeout = null;
            }, 500);
          },
          100,
          true
        )
      );
      if (listRef.value) {
        observer.observe(listRef.value);
      }
      partition(false);
    };

    const dispose = () => {
      if (observer && listRef.value) {
        observer.unobserve(listRef.value);
        observer = null;
      }
    };

    watch(
      () => enabled.value,
      (val) => {
        if (val) {
          init();
        } else {
          dispose();
        }
      }
    );

    watch(
      () => items.value,
      () => fix()
    );

    watchEffect(() => {
      state.value = {
        status: GROW,
        last: 0,
        overflowList: [],
        visibleList: items?.value,
      };
    });

    onMounted(() => {
      if (enabled.value) init();
    });
    onBeforeUnmount(() => {
      dispose();
    });
    expose({});
    return { listRef, padRef, state, showEllipsis };
  },
};
</script>

<style scoped>
.overflowList-view {
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
}
.overflowList-view-end {
  flex: 0 0 auto;
}
.overflowList-view-pad {
  flex: 1;
  width: 1px;
}
</style>
