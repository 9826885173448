<template>
  <div class="front-layout">
    <div v-if="$slots.header" class="front-layout-header">
      <slot name="header"></slot>
    </div>
    <div v-if="empty || loading" class="front-layout-body-center rmx-card">
      <loading-view v-if="loading" :loading-text="loadingText" />
      <img-empty-tips
        v-else-if="empty"
        :type="emptyIcon"
        :description="emptyText"
      />
    </div>
    <template v-else-if="$slots.body">
      <slot name="body"></slot>
    </template>
    <div v-else ref="bodyRef" class="front-layout-body rmx-card">
      <slot></slot>
    </div>
    <div v-if="$slots.footer" class="front-layout-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { PROPS_CONTAINER } from "@/components/common/shared/compAttrs";
import { useSizeProvider } from "@/components/common/shared/uiInternal";
import LoadingView from "@/components/common/display/LoadingView";
import ImgEmptyTips from "@/components/common/display/ImgEmptyTips";

export default {
  name: "FrontLayout",
  components: { ImgEmptyTips, LoadingView },
  props: {
    ...PROPS_CONTAINER,
    purePage: { type: Boolean },
  },
  setup() {
    const bodyRef = ref(null);
    useSizeProvider(bodyRef, "FrontLayout");
    return { bodyRef };
  },
};
</script>

<style scoped>
.front-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.front-layout-header {
  display: flex;
  align-items: center;
  min-height: 64px;
  padding: 0 40px;
  border-top: 4px solid var(--rmx-primary-color);
  background: white;
  box-shadow: var(--rmx-card-shadow);
  z-index: 1;
}
.front-layout-body-center,
.front-layout-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 1px;
  margin: var(--rmx-pad-m);
  overflow: hidden;
}
.front-layout-body-center {
  align-items: center;
  justify-content: center;
}
.front-layout-footer {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  padding: 0 16px;
  min-height: 60px;
  background: white;
  box-shadow: var(--rmx-footer-shadow);
}
</style>
