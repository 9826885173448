<template>
  <div class="arch-item">
    <div class="arch-item-content">
      <div class="arch-item-header">
        <div class="arch-header-area">
          <colored-tag color="info" outline class="search-result-item-prefix">
            {{ levelName }}
          </colored-tag>
          <colored-tag :color="scopeTag.color">{{ scopeTag.text }}</colored-tag>
          <template v-if="!item.title">
            <div class="arch-item-title" @click.stop="handleTitleClick(item)">
              --
            </div>
          </template>
          <div
            v-else
            class="arch-item-title"
            :title="item.title"
            @click.stop="handleTitleClick(item)"
          >
            {{ item.title }}
          </div>
          <colored-tag v-if="securityTag" :color="securityTag.color">
            {{ securityTag.text }}
          </colored-tag>
          <template v-if="$slots.titleSuffix">
            <slot name="titleSuffix" :arch-item="item"></slot>
          </template>
        </div>
        <div>
          <template v-if="$slots.extraHeader">
            <slot name="extraHeader" :arch-item="item"></slot>
          </template>
        </div>
      </div>
      <div class="arch-item-fieldsAllInfo">
        <div class="arch-item-field-content">
          <div
            class="arch-item-field"
            v-for="field in displayFields"
            :key="field.field"
            :title="`${field.name} : ${item[field.field]}`"
          >
            {{ field.name }} : {{ item[field.field] }}
          </div>
        </div>

        <div class="arch-item-extraAll">
          <template v-if="$slots.extraAllInfo">
            <slot name="extraAllInfo" :arch-item="item"></slot>
          </template>
        </div>
        <div>
          <template v-if="$slots.extraArchNum">
            <slot name="extraArchNum" :arch-item="item"></slot>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColoredTag from "@/components/common/display/ColoredTag";
import { ARCH_LEVEL_NAMES } from "@/mods/front/conf/enum";
import { searchListScopeTag } from "@/mods/front/util/frontUtil";
export default {
  name: "ArchItem",
  components: { ColoredTag },
  emits: ["choose", "showDetail", "update:checked"],
  props: {
    fields: { type: [Array, Object], default: () => [] },
    item: { type: Object, default: () => {} },
    keywords: { type: Array, default: () => [] },
    maskedWords: { type: Array, default: () => [] },
    selected: { type: Boolean, default: false },
    checked: { type: Boolean, default: false },
    checkAble: { type: Boolean, default: false },
    showDetail: { type: Boolean },
    isAllInfo: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    levelName() {
      const level = ARCH_LEVEL_NAMES[this.item.objectType] || "document";
      return this.$t(`fields.object.${level}`);
    },
    scopeTag() {
      return searchListScopeTag(this.item);
    },
    securityTag() {
      if (this.item.isSecurity) {
        return {
          color: "error",
          text: `★${
            this.item.securityText || this.$t("front.security.confidential")
          }★`,
        };
      }
      return undefined;
    },
    isVolume() {
      return this.item.objectType || 2;
    },
    displayFields() {
      if (Array.isArray(this.fields)) {
        return this.fields;
      }
      return this.fields;
    },
    itemStyle() {
      const style = {};
      if (this.displayFields.length !== 5) {
        style["--arch-item-fields-columns"] = this.displayFields.length;
      }
      return style;
    },
  },
  methods: {
    handleChecked(e) {
      this.$emit("update:checked", !!e.target?.checked);
    },
    handleTitleClick(item) {
      this.$emit("choose", item);
    },
    handleShowDetail() {
      this.$emit("showDetail", {});
    },
  },
};
</script>

<style scoped>
.arch-item {
  display: flex;
  position: relative;
}
.arch-item-content {
  flex: 1;
}
.arch-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0 18px 0;
  border-bottom: 1px solid var(--rmx-split);
}
.arch-item-title {
  font-weight: bold;
  font-size: 18px;
  color: var(--rmx-text-1);
  line-height: 22px;
  cursor: pointer;
  max-width: 840px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 12px;
}
@media screen and (min-width: 1800px) {
  .arch-item-title {
    max-width: calc(100vw - 500px);
  }
}
.arch-item-title:hover,
.arch-item-selected .arch-item-title {
  color: var(--rmx-primary-color);
}
.arch-item-fieldsAllInfo {
  display: flex;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  position: relative;
  padding: 18px 0 4px 0;
}
.arch-item-field {
  display: inline-block;
  margin-right: 20px;
  color: var(--rmx-text-2);
}
.arch-item-extraAll {
  flex: 1;
  margin-left: 20px;
}
.arch-item-field-content {
  display: block;
  max-width: calc(100vw - 820px);
  min-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arch-header-area {
  display: flex;
  flex: 1;
}
</style>
