export default {
  languageEdit: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/languageManage/dialog/LanguageEdit"
      ),
  },
  languageMigrate: {
    width: "s",
    okText: "确认迁移",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/languageManage/dialog/LanguageMigrate"
      ),
  },
  languageConfig: {
    width: "m",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/languageManage/dialog/LanguageConfig"
      ),
  },
  languageConfigEdit: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/languageManage/dialog/LanguageConfigEdit"
      ),
  },
  languageResource: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/languageManage/dialog/LanguageResource"
      ),
  },
};
