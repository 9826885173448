import { i18n } from "@/conf/lang";
import { getQueryStr } from "@/util/strUtil";
import store from "@/store";
import { openWindowWith } from "@/util/uiUtil";
const $t = (c, arr) => i18n.global.t(c, arr);
/**
 * 用于处理列表数据不规范
 * @param x 数据
 * @param data 额外的数据
 * @returns
 * */
export function searchListMapper(x, data = {}) {
  const filesNum = x.filesNum || +x.filesnum || 0;
  const entityNum = x.entityNum || +x.fillingnum || 0;
  return {
    ...x,
    archId: x.archId || x.archid || x.id,
    status: +x.status,
    borrowCartId: x.borrowCartId || x.borrowcartid,
    collectionId: x.collectionId || x.shopcartid,
    securityCode: +x.securityCode,
    isSecurity: !!+x.isSecrecy || !!+x.issecrecy || !!+x.securityCode,
    securityText: x.securityText || x.securityname || x.security,
    useScope: x.useScope || +x.usescope,
    filesNum,
    entityNum,
    objectType: x.objectType || x.objecttype || data.objectType,
    archTypeId: x.archTypeId || x.archtypeid || data.archTypeId,
    fondsName: x.fondsName || x.fondsname,
    archCode: x.archCode || x.archcode,
    archDepartment: x.archDepartment || x.fillingdepartment,
    archModelName: x.archModelName || x.modelname || x.model,
    isDisable: x.isDisable || +x.isshow === 0,
    borrowCartDisabled: (+x.filesnum === 0 && +x.fillingnum === 0) || false,
    scopeType: x.scopeType || x.companyAttr,
    companyAttr: x.companyAttr || x.companyattr,
  };
}

/**
 * 在新窗口打开页面
 * @deprecated 4月21号会删除该函数
 * @param url 地址
 * @param query 查询
 */
export function openNewWindow(url, query = {}) {
  console.error(
    `请使用openWindowWith({url:"${url}",query:${JSON.stringify(query)})`
  );
  const q = getQueryStr(query);
  if (url?.[0] === "/") url = url.substring(1);
  window.open(`#/${url}?${q}`, "_blank");
}

export function showArchDetail({
  archId,
  archTypeId,
  objectType,
  source = "",
  orderId = "",
}) {
  openWindowWith({
    url: "front/archives/arch_detail",
    query: {
      archTypeId,
      objectType,
      source,
      dataId: archId,
      orderId,
    },
  });
}

/*
 * 申请单详情页档案利用状态
 * */
const APPLY_ORDER_STATUS_TEXT = {
  "": "rejected", //已拒绝
  0: "rejected",
  1: "adopt", //已通过
  3: "returned", //已归还
  4: "unReturned", //无法归还
  7: "renew", //续借中
  8: "stopUtilize", //终止利用
  9: "overdue", //已逾期
};
/*
 * 申请单结束利用状态值
 * */
const APPLY_ORDER_END_STATUS = [25, 30];

/**
 * 区分档案的状态
 * @param item 档案数据
 * @param entType 利用方式
 */
export function computeStatus({ item, entType }) {
  if (
    (item.applyflow === 1 || item.applyflow === "") &&
    item.offerflow === ""
  ) {
    return "pending"; //待处理
  } else {
    if (entType === 8) {
      if (APPLY_ORDER_END_STATUS.includes(item.status) && item.eleauth === 1) {
        return "end"; //已结束
      } else if (item.eleauth === 1) {
        return "adopt"; //通过
      } else {
        return "rejected"; //已拒绝
      }
    } else {
      if (item.status === 2) {
        return entType === 7 ? "received" : "borrowing";
      } else if (APPLY_ORDER_STATUS_TEXT[item.status]) {
        return APPLY_ORDER_STATUS_TEXT[item.status];
      } else if (
        !item.outOrderId &&
        item.eleauth === 0 &&
        item.applyflow === 1
      ) {
        return "rejected";
      } else {
        return "pending";
      }
    }
  }
}

const _searchListScopeTag = (scope, isRoom) => {
  if (isRoom) {
    if (scope === 4) {
      return {
        color: "error",
        text: $t("front.fields.not_provided"),
      };
    } else if (scope === 3) {
      return {
        color: "warn",
        text: $t("front.scope.controlled"),
      };
    } else if (scope === 2) {
      return {
        color: "info",
        text: $t("front.scope.unit"),
      };
    } else if (scope === 1) {
      return {
        color: "success",
        text: $t("front.scope.outside"),
      };
    }
  } else {
    if (scope === 3) {
      return {
        color: "error",
        text: $t("front.fields.not_provided"),
      };
    }
    if (scope === 2) {
      return {
        color: "warn",
        text: $t("front.scope.controlled"),
      };
    }
  }
  return { color: "success", text: $t("front.scope.public") };
};

/**
 * 档案权限标签
 * @param item 档案数据
 * @param isRoom 是否馆室
 */
export function searchListScopeTag(item, isRoom) {
  isRoom = isRoom === undefined ? store.state.user.isRoom : isRoom;
  const scope = +item.useScope;
  const scopeType = +item.scopeType;
  if (scopeType) {
    return _searchListScopeTag(scope, scopeType === 2);
  }
  return _searchListScopeTag(scope, isRoom);
}
