import { defineAsyncComponent } from "vue";

export function useAsyncCompDef(comp, cached = {}, configs = {}) {
  let config, componentDef;
  if (typeof comp === "function") {
    componentDef = defineAsyncComponent(comp);
  } else {
    const compKeys = comp.split(".");
    if (compKeys.length > 1) {
      let cur = configs;
      compKeys.forEach((x) => (cur = cur?.[x]));
      config = cur;
    } else {
      config = configs[comp];
    }
    if (!cached[comp]) {
      cached[comp] = defineAsyncComponent(config.component);
    }
    componentDef = cached[comp];
  }
  return { config, componentDef };
}
