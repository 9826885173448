export default {
  view: {
    component: () =>
      import(
        /* webpackChunkName: "system_detail" */ "@/mods/system/dataPackage/detailView/DataPackageView"
      ),
  },
  verify: {
    component: () =>
      import(
        /* webpackChunkName: "system_detail" */ "@/mods/system/dataPackage/detailView/DataPackageVerify"
      ),
  },
};
