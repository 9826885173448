<template>
  <div
    ref="scroller"
    class="scroller-view rmx-scrollbar"
    @scroll="handleScroll"
  >
    <slot></slot>
  </div>
</template>

<script>
import { debounceFunc } from "@/util/uiUtil";
import { ref, toRefs, watch } from "vue";

export default {
  name: "ScrollerView",
  props: {
    delay: { type: Number, default: 500 },
    scrollLeft: { type: Number },
    scrollTop: { type: Number },
  },
  emits: ["viewScroll"],
  setup(props, { emit }) {
    const { delay, scrollLeft, scrollTop } = toRefs(props);
    const scroller = ref(null);
    const handleScroll = debounceFunc((e) => {
      const el = e.target;
      emit("viewScroll", { top: el.scrollTop, left: el.scrollLeft });
    }, delay.value);
    watch(
      () => scrollLeft.value,
      (val) => {
        if (val !== undefined && scroller.value) {
          scroller.value.scrollLeft = val;
        }
      }
    );
    watch(
      () => scrollTop.value,
      (val) => {
        if (val !== undefined && scroller.value) {
          scroller.value.scrollTop = val;
        }
      }
    );
    return { handleScroll, scroller };
  },
};
</script>

<style scoped>
.scroller-view {
  overflow-y: auto;
}
</style>
