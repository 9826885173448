<template>
  <div class="attach-list">
    <div
      v-for="item in items"
      :key="item.id || item"
      class="attach-list-item"
      :class="{
        'attach-list-item-active': selection?.id === item.id,
        'attach-list-item-disabled': item.isDisabled,
      }"
      @click="handleClick(item)"
    >
      <div class="attach-list-item-text">
        {{ item.title }}.{{ item.extension }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AttachList",
  emits: ["select", "update:selection"],
  props: {
    selection: { type: Object },
    items: { type: Array, default: () => [] },
    scrollTop: { type: Number },
  },
  methods: {
    handleClick(item) {
      this.$emit("update:selection", item);
      this.$emit("select", item);
    },
  },
};
</script>

<style scoped>
.attach-list {
  height: 100%;
  counter-reset: rmx-al 0;
}
.attach-list-item {
  display: flex;
  color: var(--rmx-text-1);
  line-height: 22px;
  cursor: pointer;
  align-items: center;
  padding: 16px 0;
  counter-increment: rmx-al;
  border-bottom: 1px solid var(--rmx-split);
}
.attach-list-item:first-child {
  padding-top: 6px;
  padding-bottom: 20px;
}
.attach-list-item:hover {
  color: var(--rmx-primary-color);
}
.attach-list-item-active {
  color: var(--rmx-primary-color);
}
.attach-list-item-text {
  word-break: break-all;
}
.attach-list-item-text:before {
  content: counter(rmx-al) "、";
}
.attach-list-item-disabled {
  color: var(--rmx-icon-3);
  pointer-events: none;
}
</style>
