<template>
  <div class="detail-view">
    <slot></slot>
    <transition-group name="detail-content-view-trans">
      <template v-for="d in views" :key="d.name">
        <detail-content-view
          :view="d.name"
          :params="d.params"
          @back="handleBack($event, d)"
        />
      </template>
    </transition-group>
  </div>
</template>

<script>
import { provide, ref } from "vue";
import { DETAIL_VIEW } from "@/conf/symbols";
import { DETAIL_VIEW_PROVIDER } from "@/components/common/shared/internal";
import DetailContentView from "@/components/core/DetailContentView";

export default {
  name: "DetailView",
  components: { DetailContentView },
  props: {},
  emits: [],
  setup(props, { slots, expose }) {
    const views = ref([]);
    const closeAll = () => {
      views.value = [];
    };
    const handler = {
      closeAll,
      navigateTo: (name, params) =>
        new Promise((resolve) => {
          views.value.push({
            name,
            params,
            onFinished: (data) => resolve(data),
          });
        }),
      setOperations: () => {},
      setTitle: () => {},
      setFinished: () => {},
    };
    const handleBack = (e, d) => {
      d.onFinished(e);
      const index = views.value.indexOf(d);
      if (index > -1) {
        views.value.splice(index, 1);
      }
    };
    if (!slots.content) {
      provide(DETAIL_VIEW, handler);
    }
    provide(DETAIL_VIEW_PROVIDER, handler);
    expose({ handler, closeAll });
    return { views, handler, handleBack };
  },
};
</script>

<style scoped>
.detail-view {
  position: relative;
  overflow: hidden;
}
.detail-content-view-trans-enter-active,
.detail-content-view-trans-leave-active {
  transition: all 0.3s ease;
}
.detail-content-view-trans-enter-from {
  transform: translateX(50%);
  opacity: 0;
}
.detail-content-view-trans-leave-to {
  transform: translateX(50%);
  opacity: 0;
}
</style>
