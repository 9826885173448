<template>
  <div
    class="simple-data-list"
    :class="{ 'simple-data-list-count': countable }"
  >
    <div
      v-for="item in list"
      :key="item.id || item"
      class="simple-data-list-item"
    >
      <slot name="listItem" :item="item"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleDataList",
  props: {
    list: { type: Array, default: () => [] },
    countable: { type: Boolean },
  },
};
</script>

<style scoped>
.simple-data-list {
  counter-reset: rmx-sdl 0;
}
.simple-data-list-count {
  --simple-data-list-counter: counter(rmx-sdl) ".";
}
.simple-data-list-item {
  display: flex;
  align-items: center;
  margin-top: 8px;
  counter-increment: rmx-sdl;
}
.simple-data-list-item:hover {
  background: var(--rmx-background);
  color: var(--rmx-primary-color);
}
.simple-data-list-count .simple-data-list-item:before {
  content: var(--simple-data-list-counter);
  color: var(--rmx-text-2);
  padding: 0 8px;
}
</style>
