export const ICON_NAMES_MENU = [
  "abnormal",
  "ad-product",
  "address-book",
  "afferent-three",
  "agreement",
  "all-application",
  "analysis",
  "audit",
  "baby-feet",
  "bill",
  "bloom",
  "boiler",
  "book-one",
  "book-open",
  "bookmark",
  "bookshelf",
  "box",
  "branch-one",
  "building-one",
  "building-three",
  "camera-one",
  "category-management",
  "certificate",
  "chart-graph",
  "chart-histogram",
  "chart-line",
  "chart-line-area",
  "chart-pie-one",
  "checklist",
  "circle-three",
  "city",
  "city-one",
  "clear",
  "clue",
  "connection-arrow",
  "connection-box",
  "cooperative-handshake",
  "copy-one",
  "copyright",
  "dashboard-one",
  "data",
  "data-display",
  "data-file",
  "data-lock",
  "data-server",
  "data-user",
  "database-enter",
  "database-setting",
  "deeplink",
  "delete-five",
  "delivery",
  "display",
  "doc-add",
  "doc-detail",
  "doc-fail",
  "doc-search",
  "doc-search-two",
  "doc-success",
  "document-folder",
  "dropbox",
  "edit",
  "edit-name",
  "edit-two",
  "engineering-brand",
  "equalizer",
  "excel",
  "exchange-one",
  "external-transmission",
  "file-cabinet",
  "file-code",
  "file-conversion",
  "file-conversion-one",
  "file-date",
  "file-date-one",
  "file-editing",
  "file-editing-one",
  "file-lock-one",
  "file-protection",
  "file-search",
  "file-settings",
  "file-staff",
  "file-zip",
  "find-one",
  "fire",
  "folder-close",
  "folder-conversion",
  "folder-protection",
  "folder-settings",
  "garage",
  "germs",
  "gold-medal",
  "gold-medal-two",
  "hand-washing-fluid",
  "headset",
  "headset-one",
  "help",
  "home-two",
  "id-card",
  "id-card-h",
  "id-card-v",
  "inbox-in",
  "inbox-success",
  "incoming",
  "inspection",
  "instruction",
  "internal-transmission",
  "layers",
  "link",
  "link-cloud",
  "link-left",
  "link-right",
  "link-three",
  "list",
  "list-top",
  "list-two",
  "list-view",
  "log",
  "monitor-one",
  "more-app",
  "network-tree",
  "new-afferent",
  "new-efferent",
  "newspaper-folding",
  "open-door",
  "outbound",
  "outgoing",
  "people",
  "people-safe",
  "people-safe-one",
  "peoples-two",
  "permissions",
  "pivot-table",
  "plan",
  "pull-door",
  "pull-requests",
  "push-door",
  "read-book",
  "receive",
  "recycling-pool",
  "right-user",
  "rss",
  "s-turn-left",
  "safe-retrieval",
  "save",
  "save-one",
  "seal",
  "send",
  "seo",
  "setting-config",
  "setting-one",
  "setting-two",
  "setting-web",
  "shopping-bag",
  "shopping-cart",
  "shopping-mall",
  "six-points",
  "speaker-one",
  "star",
  "surveillance-cameras-one",
  "switch-themes",
  "system",
  "table-file",
  "table-report",
  "texture-two",
  "toolkit",
  "transfer",
  "transfer-data",
  "translate",
  "tree-diagram",
  "trophy",
  "upload-one",
  "upload-two",
  "usb",
  "usb-one",
  "user-positioning",
  "vien-charts",
  "view-list",
  "volume-small",
  "warehousing",
  "workbench",
  "writing-fluently",
];

export const ICON_NAMES_META_DATA = [
  "audio-file",
  "bill",
  "book-one",
  "building-two",
  "chart-graph",
  "consume",
  "data-file",
  "deposit",
  "doc-search",
  "document-folder",
  "engineering-brand",
  "engineering-vehicle",
  "file-cabinet",
  "file-focus-one",
  "folder",
  "folder-close",
  "gold-medal",
  "helmet-one",
  "market-analysis",
  "monitor",
  "notes",
  "palace",
  "pic",
  "save-one",
  "slide-two",
  "trophy",
  "two-ellipses",
  "video-file",
  "waterfalls-v",
];

export const ICON_NAMES_COMMON = [
  "add-one",
  "add-text",
  "agreement",
  "alarm",
  "all-application",
  "announcement",
  "area-map",
  "arrow-circle-down",
  "arrow-circle-left",
  "arrow-circle-right",
  "arrow-circle-up",
  "avatar",
  "back",
  "book-open",
  "bookmark",
  "bookmark-one",
  "bookmark-three",
  "building-three",
  "calendar",
  "calendar-three",
  "chart-graph",
  "chart-histogram",
  "chart-line",
  "check",
  "check-one",
  "check-small",
  "circle-double-down",
  "circle-double-left",
  "circle-double-right",
  "circle-double-up",
  "city-one",
  "clear",
  "close",
  "close-one",
  "close-small",
  "comment",
  "comments",
  "computer",
  "consume",
  "copy-one",
  "data",
  "data-screen",
  "data-sheet",
  "database-enter",
  "date-comes-back",
  "delete",
  "direction-adjustment-three",
  "doc-detail",
  "doc-search",
  "doc-search-two",
  "document-folder",
  "dot",
  "double-down",
  "double-left",
  "double-right",
  "double-up",
  "down",
  "download",
  "download-one",
  "download-two",
  "edit",
  "edit-one",
  "edit-two",
  "editor",
  "efferent-four",
  "exchange-four",
  "export",
  "file-conversion",
  "file-date-one",
  "file-pdf",
  "file-search",
  "file-success-one",
  "file-word",
  "filter",
  "find",
  "folder",
  "folder-close",
  "folder-focus-one",
  "folder-open",
  "forbid",
  "full-screen-two",
  "hard-disk-one",
  "help",
  "hourglass-full",
  "id-card-h",
  "id-card-v",
  "inbox-r",
  "info",
  "inspection",
  "label",
  "left",
  "link",
  "link-one",
  "link-right",
  "list",
  "list-numbers",
  "list-two",
  "list-view",
  "lock",
  "log",
  "logout",
  "mail",
  "mail-open",
  "me",
  "message-one",
  "message-success",
  "minus",
  "modify",
  "more",
  "more-app",
  "more-one",
  "museum-one",
  "new-efferent",
  "newspaper-folding",
  "next",
  "number",
  "off-screen-two",
  "page",
  "people",
  "peoples-two",
  "permissions",
  "picture",
  "plan",
  "platte",
  "plus",
  "power",
  "preview-close",
  "preview-close-one",
  "preview-open",
  "printer",
  "printer-two",
  "protect",
  "redo",
  "reduce-one",
  "refresh",
  "remind",
  "return",
  "right",
  "rss",
  "schedule",
  "seal",
  "search",
  "send",
  "send-email",
  "setting-one",
  "setting-two",
  "share-three",
  "shopping",
  "shopping-filled",
  "shopping-mall",
  "star",
  "star-filled",
  "switch",
  "tag-one",
  "text-message",
  "text-style-one",
  "time",
  "to-top",
  "translate",
  "unlink",
  "unlock",
  "up",
  "upload",
  "user",
  "view-grid-list",
  "view-list",
  "workbench",
  "write",
  "zoom-in",
  "zoom-out",
];
