export default {
  addOrEditWord: {
    okText: "actions.save",
    cancelText: "actions.cancel",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/thesaurusManage/dialog/AddOrEditWord"
      ),
  },
  importThesaurusWordsDialog: {
    okText: "actions.save",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/thesaurusManage/dialog/ImportThesaurusWordsDialog"
      ),
  },
};
