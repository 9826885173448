<template>
  <a-popover
    v-model:visible="displayVisible"
    :trigger="trigger"
    :placement="placement"
  >
    <template v-if="title" #title>
      <div class="popover-view-title">{{ title }}</div>
    </template>
    <template #content>
      <slot name="content"></slot>
    </template>
    <slot></slot>
  </a-popover>
</template>

<script>
import { toRefs } from "vue";
import { UI_PLACEMENT, UI_TRIGGER } from "@/conf/constants";
import { useCachedProps } from "@/components/common/shared/compInternal";

export default {
  name: "PopoverView",
  props: {
    title: { type: String },
    visible: { type: Boolean },
    placement: { type: String, validator: (v) => v in UI_PLACEMENT },
    trigger: { type: String, validator: (v) => v in UI_TRIGGER },
  },
  emits: ["update:visible"],
  setup(props, { emit, expose }) {
    const propRefs = toRefs(props);
    const { makeValProp } = useCachedProps(propRefs, { emit });
    const displayVisible = makeValProp("visible", false);
    expose({});
    return { displayVisible };
  },
};
</script>

<style scoped>
.popover-view-title {
  font-size: 14px;
  color: var(--rmx-text-1);
}
</style>
