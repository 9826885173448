<template>
  <front-layout>
    <template #body>
      <front-sub-layout>
        <template #header>
          <div>
            <arch-item
              class="page-arch-meta-item"
              :fields="archItemFields"
              :item="archItem"
            >
              <template #extraAllInfo="{ archItem }">
                <div class="extra-right">
                  <div class="extra-right-sum">
                    <a @click="handleAllFieldInfo">查看完整信息</a>
                  </div>
                  <div class="extra-right-sum">
                    <a
                      v-if="archItem.isVolDoc"
                      :class="{
                        'arch-text-disabled':
                          archItem.disabledVolDetail || disabledVol,
                      }"
                      @click="handleAllArchives(archItem)"
                      >查看所属案卷</a
                    >
                  </div>
                </div>
              </template>
            </arch-item>
          </div>
        </template>
        <div class="arch-viewer-area">
          <base-list
            ref="list"
            style="height: calc(100vh - 260px)"
            class="arch-detail"
            list-padding="8px"
            v-if="objectType === 2"
            load-on-mounted
            :async-load="loader"
          >
            <template #extraToolbar>
              <span class="volume-title">卷内文件</span>
              <span class="volume-all"
                >共<span class="volume-num">{{ list.length }}</span
                >件</span
              >
            </template>
            <template #listItem="{ item, setFieldValue }">
              <search-result-item
                :item="item"
                :fields="fields"
                :disabled="item.isDisable"
                :marked-words="keywords"
                @field-value-change="onFieldValueChange($event, setFieldValue)"
                @choose="handleVolumeDocItem(item)"
              >
              </search-result-item>
            </template>
          </base-list>
          <div
            v-if="objectType === 3 && formatFile.length > 0"
            class="view-attach-container"
          >
            <view-attach
              :fileList="formatFile"
              @viewDownLoad="handleDownload([selectFile])"
              @viewCopy="handleCopyText"
            >
            </view-attach>
          </div>
          <div
            v-if="objectType === 3 && attachments.length > 0"
            class="arch-viewer-document-list"
          >
            <div class="arch-viewer-document-list-area">
              <div class="arch-viewer-title">
                <div class="arch-viewer-document-list-area-title">电子文件</div>
                <div class="arch-viewer-document-list-area-title">
                  <act-button @click="handleDownload(attachments)"
                    >批量下载</act-button
                  >
                </div>
              </div>
              <attach-list
                style="height: 100%"
                class="file-attach-list rmx-scrollbar"
                :items="attachments"
                v-model:selection="selectFile"
              />
            </div>
          </div>
          <empty-block
            v-if="objectType === 3 && formatFile.length === 0"
            class="view-attach-container"
            description="暂无电子文件"
            type="no_data"
          ></empty-block>
        </div>
      </front-sub-layout>
    </template>
  </front-layout>
</template>

<script>
// import AttachView from "@/components/common/file/AttachView";
import EmptyBlock from "@/mods/common/normal/EmptyBlock.vue";
import ViewAttach from "@/mods/common/normal/ViewAttach";
import { httpCurrentDetail, httpVolumeDoc } from "@/api/front/archDetail";
import { httpFileList } from "@/api/archive/researchManageArchive";
import AttachList from "@/mods/front/components/AttachList";
import ArchItem from "@/mods/front/components/ArchItem";
import ActButton from "@/components/common/action/ActButton";
import FrontLayout from "@/components/common/layout/FrontLayout";
import FrontSubLayout from "@/components/common/layout/FrontSubLayout";
import { DIALOGS } from "@/conf/symbols";
import BaseList from "@/components/common/list/BaseList";
import SearchResultItem from "@/mods/front/search/SearchResultItem";
import { openNewWindow } from "@/mods/archive/researchManageArchive/business";
import { showConfirm, showMessage } from "@/util/dialog";
import { STORAGE_PREFIX } from "@/conf";
import { formatEleFileViewAttach } from "@/mods/archive/researchManageArchive/hook/ResearchManageArchiveFun";
import {
  httpAdjustExist,
  httpVerifyRecordDownload,
} from "@/api/archive/archList";
import { httpDownload } from "@/util/fileUtil";
import { HTTP_METHODS } from "@/conf/constants";
export default {
  name: "ArchViewer",
  components: {
    SearchResultItem,
    BaseList,
    FrontSubLayout,
    FrontLayout,
    ActButton,
    AttachList,
    ViewAttach,
    ArchItem,
    EmptyBlock,
  },
  inject: { dialogs: DIALOGS },
  data() {
    return {
      id: "",
      tableId: "",
      archItemFields: [],
      archItem: {},
      listFields: [],
      list: [],
      selectFile: undefined,
      attachments: [],
      dataid: "",
      archTypeId: "",
      objectType: null,
      source: "",
      allFieldsParams: {},
      allQuery: {},
      archItemRoot: {},
      relationArchItem: {},
      keywords: [],
      fields: [],
      disabledVol: false,
    };
  },
  computed: {
    formatFile() {
      return this.selectFile
        ? [
            {
              ...formatEleFileViewAttach(
                this.selectFile,
                this.allQuery.archTypeId
              ),
              actionShow: {
                copy: true,
                download: true,
              },
              actionUse: {
                copy: true,
                download: true,
              },
            },
          ]
        : [];
    },
  },
  mounted() {
    const {
      archTypeId,
      dataId,
      objectType,
      source,
      orderId,
      disabled = "",
    } = this.$route.query;
    this.objectType = +objectType;
    this.disabledVol = disabled === "true";
    console.log(disabled);
    this.allQuery = {
      dataId,
      archTypeId,
      objectType: +objectType,
      source,
      orderId,
    };
    this.loadDetail();
  },
  methods: {
    handleCopyText({ selection }) {
      const text = selection;
      if (!text) {
        showConfirm({
          content: "用户可在文件详情页复制需要的文本信息",
          title: "请选择需要复制的文本信息",
          okCancel: false,
          okText: "我知道了",
        });
        return null;
      }
      localStorage[STORAGE_PREFIX + "copy_text"] = JSON.stringify(text);
      showMessage("复制成功");
    },

    onFieldValueChange(e, setFieldValue) {
      setFieldValue(e.field, e.value);
    },
    loadDetail() {
      if (this.objectType === 2) {
        this.loader();
      } else if (this.objectType === 3) {
        this.loadFileList();
      }
      this.loadCurrentDetail();
    },
    loadCurrentDetail() {
      return httpCurrentDetail({
        ...this.allQuery,
        readOnly: true,
        tableId: "u",
      }).then((data) => {
        this.archItemRoot = data.allList;
        this.archItem = data.list;
        this.archItemFields = data.allList.showField;
      });
    },
    loader() {
      return httpVolumeDoc({
        ...this.allQuery,
        readOnly: true,
        tableId: "u",
      }).then((data) => {
        this.list = data.list;
        this.fields = data.fields;
        this.keywords = data.keywords;
        return data;
      });
    },
    loadFileList() {
      httpFileList({
        archtypeid: this.allQuery.archTypeId,
        id: this.allQuery.dataId,
      }).then((data) => {
        this.attachments = data.list.map((item) => ({
          ...item,
          title: item.title.split(".")[0],
        }));
        if (this.attachments.length > 0) {
          this.selectFile = this.attachments[0];
        }
      });
    },
    handleAllFieldInfo() {
      this.dialogs?.openDialog(
        "archive.researchManageArchive.allFieldInfo",
        {
          allField: this.archItemFields,
          allFieldsParams: this.allQuery,
        },
        {
          title: this.objectType === 3 ? "文件信息" : "案卷信息",
        }
      );
    },
    handleAllArchives(item) {
      if (item.disabledVolDetail || this.disabledVol) return;
      openNewWindow("archiveDetail", {
        archTypeId: item.archTypeId,
        objectType: 2,
        source: item.source,
        dataId: item.volid,
      });
    },
    handleVolumeDocItem(item) {
      openNewWindow("archiveDetail", {
        archTypeId: this.allQuery.archTypeId,
        objectType: item.objectType,
        source: item.source,
        dataId: item.id,
      });
    },
    handleDownload(attach) {
      const ids = attach.map((item) => ({ id: item.id }));
      httpAdjustExist(
        {
          id: this.allQuery.archTypeId,
          objecttype: this.allQuery.objectType,
          ids: [this.allQuery.dataId],
        },
        true
      ).then((res) => {
        if (res.code === 0) {
          this.dialogs
            .openDialog("common.archive.mainTableDownloadEFile", {
              type: 1,
              info: res.data,
            })
            .then((res) => {
              const requireData = {
                ids,
                type: res.type,
                fondsid: attach[0]?.fondsid,
                archtypeid: this.allQuery.archTypeId,
              };
              httpVerifyRecordDownload(requireData, true).then((resData) => {
                if (resData.code === 0) {
                  httpDownload(
                    "arrange/using/record/download",
                    { param: JSON.stringify(requireData) },
                    "",
                    HTTP_METHODS.GET
                  );
                }
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.extra-right {
  display: flex;
  gap: 20px;
}
.page-arch-meta-item {
  margin: 8px;
}
.arch-viewer-area {
  display: flex;
  flex: 1;
  gap: 16px;
  height: 100%;
}
.view-attach-container {
  flex: 1;
  padding: 16px 24px 24px;
  background: #fff;
}
.arch-viewer-document-list-area {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 424px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(52, 56, 67, 0.05);
  border-radius: 4px;
  padding: 20px 24px 24px;
  margin-bottom: 16px;
}
.arch-viewer-document-list-area-title {
  color: var(--rmx-text-1);
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 20px;
}
.arch-viewer-document-list-area-arch {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 424px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(52, 56, 67, 0.05);
  border-radius: 4px;
  padding: 24px;
}
.arch-viewer-doc-attach-view {
  width: 100%;
  height: 100%;
  justify-content: center;
}
.arch-viewer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.extra-right-sum {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arch-extra-op {
  display: flex;
  gap: 18px;
}
.volume-num {
  color: var(--rmx-error-color);
}
.volume-title {
  font-weight: bold;
  margin: 5px 16px 0 24px;
  font-size: 16px;
}
.volume-all {
  color: var(--rmx-text-2);
  margin-top: 5px;
}
.extra-right-file {
  display: flex;
  gap: 10px;
  color: var(--rmx-text-2);
}
.extra-right-file-content {
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.volume-content {
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.arch-detail {
  background: #ffffff;
}
.file-attach-list {
  overflow: auto;
}
.arch-text-disabled {
  color: var(--rmx-text-4);
  cursor: not-allowed;
}
</style>
