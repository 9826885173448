<template>
  <div
    ref="tagsInputWrap"
    class="tags-input"
    :class="{
      'tags-input-with-border': bordered,
      'tags-resizable': resize,
    }"
    @click="onContainerClick"
  >
    <div
      ref="tagsInputContent"
      class="tags-input-content rmx-scrollbar"
      :class="{ 'tags-input-content-multiline': multiline }"
      :style="tagStyle"
    >
      <template v-for="item in tags" :key="item.value || item">
        <colored-tag
          class="tags-input-tag"
          :closable="actionable"
          :fill="fill"
          :outline="outline"
          :rect="rect"
          :round="round"
          :disabled="disabled"
          use-outer-style
          @close="onDel(item)"
          @click="onChoose(item)"
        >
          <template v-if="icon">
            <option-icon :icon="icon" />
          </template>
          {{ item.text || item }}
        </colored-tag>
      </template>
      <input
        v-if="showInput"
        ref="textInput"
        class="tags-input-input"
        :placeholder="hint"
        @keyup="onKeyUp"
        @keydown="onKeyDown"
        @focusout="onFocusOut"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from "vue";
import { useCachedProps } from "@/components/common/shared/compInternal";
import { useTagStyle } from "@/components/common/shared/styleInternal";
import ColoredTag from "@/components/common/display/ColoredTag";
import OptionIcon from "@/components/common/display/OptionIcon";

export default {
  name: "TagsInput",
  components: { OptionIcon, ColoredTag },
  emits: ["change", "enter", "choose", "update:value"],
  props: {
    value: { type: Array },
    input: { type: Boolean, default: false },
    max: { type: Number, default: 0 },
    placeholder: { type: String, default: "" },
    bordered: { type: Boolean, default: true },
    multiline: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    resize: { type: Boolean },
    color: { type: String, default: "info" },
    icon: { type: String },
    outline: { type: Boolean },
    fill: { type: Boolean },
    round: { type: Boolean },
    rect: { type: Boolean },
  },
  setup(props, { emit }) {
    const propRefs = toRefs(props);
    const { makeRefProp } = useCachedProps(propRefs, { emit });
    const {
      input,
      disabled,
      readonly,
      max,
      placeholder,
      color,
      outline,
      fill,
    } = propRefs;

    const tags = makeRefProp("value", []);

    const textInput = ref(null);
    const tagsInputWrap = ref(null);
    const tagsInputContent = ref(null);

    const hint = computed(() =>
      tags.value.length > 0 ? "" : placeholder.value
    );
    const actionable = computed(() => !disabled.value && !readonly.value);
    const showInput = computed(() => input.value && actionable.value);
    const addable = computed(() => {
      if (input.value) {
        if (max.value < 1) return true;
        return tags.value.length < max.value;
      }
      return false;
    });
    const tagStyle = useTagStyle(color, outline, fill, actionable);
    const addValue = (v) => {
      if (v && !tags.value.includes(v) && addable.value) {
        const value = [...tags.value, v];
        tags.value = value;
        emit("change", value);
      }
    };
    const onKeyUp = (e) => {
      const v = e.target.value;
      if (e.code === "Enter") {
        e.target.value = "";
        if (v) {
          addValue(v);
        } else {
          emit("enter", {});
        }
      } else if (e.code === "Space") {
        if (v) {
          if (!v.trim()) {
            e.target.value = "";
          }
          if (v.trim() && v !== v.trim()) {
            e.target.value = "";
            addValue(v.trim());
          }
        }
      }
    };
    const onKeyDown = (e) => {
      const v = e.target.value;
      if (e.code === "Backspace") {
        if (!v) {
          tags.value.pop();
          emit("change", tags.value);
        }
      }
    };
    const onDel = (v) => {
      if (tags.value.includes(v)) {
        const values = tags.value.filter((x) => x !== v);
        tags.value = values;
        emit("change", values);
      }
    };
    const onFocusOut = (e) => {
      const v = e.target.value;
      e.target.value = "";
      addValue(v);
    };
    const onContainerClick = (e) => {
      const t = e.target;
      if (
        showInput.value &&
        (t === tagsInputWrap.value || t === tagsInputContent.value)
      ) {
        textInput.value.focus();
      }
    };
    const onChoose = (e) => {
      if (!disabled.value) emit("choose", e);
    };
    return {
      tags,
      hint,
      actionable,
      showInput,
      textInput,
      tagsInputWrap,
      tagsInputContent,
      tagStyle,
      onKeyUp,
      onKeyDown,
      onDel,
      onFocusOut,
      onContainerClick,
      onChoose,
    };
  },
};
</script>

<style scoped>
.tags-input {
  display: flex;
  flex-direction: column;
  min-height: 32px;
}
.tags-input-with-border {
  border: 1px solid var(--rmx-border);
  background: white;
  border-radius: var(--rmx-border-radius);
  transition: all 0.3s, height 0s;
}
.tags-input-with-border:focus-within {
  border-color: var(--rmx-primary-color-4);
  box-shadow: 0 0 0 2px var(--rmx-primary-shadow);
}
.tags-resizable {
  overflow: hidden;
  resize: vertical;
}
.tags-input-content {
  display: flex;
  align-items: center;
}
.tags-input-content:not(.tags-input-content-multiline) {
  overflow-x: auto;
}
.tags-input-with-border .tags-input-content:not(.tags-input-content-multiline) {
  padding: 0 4px;
  height: 30px;
}
.tags-input-content-multiline {
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px 2px;
  overflow-y: auto;
  padding: 8px;
  line-height: 1;
}
.tags-input-tag {
  cursor: pointer;
  padding: 1px 7px;
  font-size: 14px;
  margin-right: 4px;
}
.tags-input-input {
  border: none;
  outline: none;
  background: transparent;
  flex: 1;
  width: 10px;
  font-size: 16px;
  padding: 2px;
}
.tags-input-input::placeholder {
  color: var(--rmx-text-3);
  font-size: 14px;
}
</style>
