import logManage from "@/mods/system/detailView/logManage";
import registerAudit from "@/mods/system/detailView/registerAudit";
import dataPackage from "@/mods/system/detailView/dataPackage";
import fourDesign from "@/mods/system/detailView/fourDesign";

export default {
  logManage,
  dataPackage,
  registerAudit,
  fourDesign,
};
