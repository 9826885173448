export default {
  addExternalHandover: {
    title: "加入鉴定批次",
    component: () =>
      import("@/mods/archive/externalArchive/dialog/AddNewExternalHandover"),
  },
  addInsertWareArch: {
    title: "参考已有门类添加",
    width: "m",
    component: () =>
      import("@/mods/archive/externalArchive/dialog/AddInsertWareArch"),
  },
  returnReceive: {
    title: "退回",
    component: () =>
      import("@/mods/archive/externalArchive/dialog/ReturnReceive"),
  },
  handoverApproveDetail: {
    title: "移交审批",
    width: 1600,
    okText: "提交",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/externalArchive/detailView/ExternalApproveDetail"),
  },
  receptionApproveDetail: {
    title: "接收审批",
    width: 1600,
    okText: "提交",
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/externalArchive/detailView/ExternalApproveDetail"),
  },
  viewVolChildren: {
    title: "文件列表",
    width: "l",
    footer: null,
    destroyOnClose: true,
    component: () =>
      import("@/mods/archive/externalArchive/dialog/ViewVolChildren"),
  },
};
