export default {
  appraisalDetail: {
    title: "", //鉴定单详情
    component: () =>
      import("@/mods/archive/archivalAppraisal/detailView/AppraisalDetail"),
  },
  disposalPlanOfAppraisal: {
    title: "", //处置方案审批单详情
    component: () =>
      import(
        "@/mods/archive/archivalAppraisal/detailView/DisposalPlanOfAppraisal"
      ),
  },
  qualificationPlanOfAppraisal: {
    title: "", //鉴定计划审批单详情
    component: () =>
      import(
        "@/mods/archive/archivalAppraisal/detailView/QualificationPlanOfAppraisal"
      ),
  },
};
