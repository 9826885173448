<template>
  <a-empty
    class="img-empty-tips"
    :image="image"
    :image-style="imgStyle"
    :style="style"
  >
    <template #description>
      <div class="img-empty-desc">
        <slot name="empty">{{ emptyText }}</slot>
      </div>
    </template>
  </a-empty>
</template>

<script>
import { UI_SIZE } from "@/conf/constants";

const no_file = require("@/assets/common/no_file.png");
const IMG_MAP = {
  catalog: require("@/assets/common/catalog_tips.png"),
  setting: require("@/assets/common/setting_tips.png"),
  no_data: require("@/assets/common/no_data.png"),
  no_result: require("@/assets/common/no_result.png"),
  no_file,
  no_e_file: no_file,
  no_collection: require("@/assets/common/no_collection.png"),
  no_message: require("@/assets/common/no_message.png"),
  no_permission: require("@/assets/common/no_permission.png"),
  no_todo: require("@/assets/common/no_todo.png"),
  no_announcement: require("@/assets/common/no_announcement.png"),
  no_subject: require("@/assets/common/no_subject.png"),
  empty_cart: require("@/assets/common/empty_cart.png"),
  file_not_support: require("@/assets/common/file_not_support.png"),
};
const TEXT_MAP = {
  catalog: "hint.catalog",
  setting: "hint.no_data",
  no_data: "hint.no_data",
  no_result: "hint.no_result",
  no_file: "hint.no_file",
  no_e_file: "hint.no_e_file",
  no_collection: "hint.no_collection",
  no_message: "hint.no_message",
  no_permission: "hint.no_data",
  no_todo: "hint.no_todo",
  no_announcement: "hint.no_data",
  no_subject: "hint.no_data",
  empty_cart: "hint.no_data",
  file_not_support: "hint.file.type_not_support",
};
const SIZE_MAP = {
  small: "150px",
  medium: "180px",
  large: "240px",
};
const FONT_SIZE = {
  small: "16px",
  medium: "18px",
  large: "18px",
};
export default {
  name: "ImgEmptyTips",
  props: {
    type: { type: String, default: "no_data" },
    description: { type: String },
    size: { type: String, default: UI_SIZE.large },
  },
  computed: {
    image() {
      return IMG_MAP[this.type];
    },
    emptyText() {
      return (
        this.description ||
        (TEXT_MAP[this.type] ? this.$t(TEXT_MAP[this.type]) : "")
      );
    },
    sizeKey() {
      return UI_SIZE[this.size];
    },
    style() {
      return { "--img-empty-desc-size": FONT_SIZE[this.sizeKey] || "" };
    },
    imgStyle() {
      return { height: SIZE_MAP[this.sizeKey] || this.size };
    },
  },
};
</script>

<style scoped>
.img-empty-tips {
  --img-empty-desc-size: 18px;
}
.img-empty-desc {
  font-size: var(--img-empty-desc-size);
  margin-top: calc(-192px + 9 * 1em);
  line-height: 1;
  color: var(--rmx-text-3);
  text-align: center;
}
</style>
