export default {
  editMigrationBatch: {
    width: "l",
    title: "迁移任务",
    okText: "保存",
    cancelText: "关闭",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/migrationArchiveData/dialog/EditMigrationBatch"
      ),
  },
  modifyIntermediateTableData: {
    width: "m",
    title: "修改中间表数据",
    okText: "保存",
    cancelText: "关闭",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/migrationArchiveData/dialog/ModifyIntermediateTableData"
      ),
  },
  checkDataAndMigrate: {
    width: "l",
    title: "数据检查并迁入",
    okText: "开始迁入",
    cancelText: "关闭",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/migrationArchiveData/dialog/CheckDataAndMigrate"
      ),
  },
  viewFailedArchives: {
    width: "m",
    title: "查看未通过档案",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/migrationArchiveData/dialog/ViewFailedArchives"
      ),
  },
  importData: {
    width: "s",
    title: "导入",
    okText: "导入",
    cancelText: "关闭",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/migrationArchiveData/dialog/ImportData"
      ),
  },
  createAttachment: {
    width: "s",
    title: "创建附件",
    okText: "开始创建",
    cancelText: "关闭",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/migrationArchiveData/dialog/CreateAttachment"
      ),
  },
  batchDataModification: {
    width: "s",
    title: "批量修改",
    okText: "保存",
    cancelText: "关闭",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/migrationArchiveData/dialog/BatchDataModification"
      ),
  },
  fieldCorrespondence: {
    width: "m",
    title: "字段对应",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/migrationArchiveData/dialog/FieldCorrespondence"
      ),
  },
};
