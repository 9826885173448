export default {
  editAttachType: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/EditAttachType"
      ),
  },
  editAttachFormat: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/EditAttachFormat"
      ),
  },
};
