export default {
  addProblem: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/fondsInnerTransfer/dialog/AddProblem"
      ),
  },
  editProblem: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/fondsInnerTransfer/dialog/EditProblem"
      ),
  },
  selectCollectors: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/fondsInnerTransfer/dialog/SelectCollectors"
      ),
  },
  fondsInnerTransferApproval: {
    width: "xxl",
    okText: "actions.save",
    title: "对内移交审批详情",
    component: () =>
      import(
        /* webpackChunkName: "archinve_dialog" */ "@/mods/archive/fondsInnerTransfer/dialog/FondsInnerTransferApproval"
      ),
  },
};
