<template>
  <div
    class="act-icon"
    :class="{
      'act-icon-danger': danger,
      'act-icon-check-able': checkAble,
      'act-icon-checked': iconChecked,
      'act-icon-loading': iconLoading,
      'act-icon-disabled': disabled,
    }"
    :style="style"
  >
    <a-tooltip :title="title">
      <slot>
        <component
          v-if="!forceCommonIcon && icon"
          :is="icon"
          class="act-icon-content"
          @click.prevent.stop="onClick"
        ></component>
        <common-icon
          v-else
          :icon="type"
          class="act-icon-content"
          @click.prevent.stop="onClick"
        />
      </slot>
    </a-tooltip>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { useCachedProps } from "@/components/common/shared/compInternal";
import { debounceFunc } from "@/util/uiUtil";
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DragOutlined,
  EditOutlined,
  EyeOutlined,
  FormOutlined,
  LoadingOutlined,
  PrinterOutlined,
  QuestionCircleFilled,
  SaveOutlined,
  SettingFilled,
  SettingOutlined,
  ShoppingCartOutlined,
  StarFilled,
  StarOutlined,
  SyncOutlined,
  UploadOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons-vue";
import CommonIcon from "@/components/icon/CommonIcon";

const ICON_MAP = {
  cart: ShoppingCartOutlined,
  copy: CopyOutlined,
  delete: DeleteOutlined,
  download: DownloadOutlined,
  drag: DragOutlined,
  edit: EditOutlined,
  form: FormOutlined,
  loading: LoadingOutlined,
  print: PrinterOutlined,
  questionCircleFill: QuestionCircleFilled,
  refresh: SyncOutlined,
  save: SaveOutlined,
  setting: SettingOutlined,
  settingFill: SettingFilled,
  star: StarOutlined,
  starFill: StarFilled,
  upload: UploadOutlined,
  view: EyeOutlined,
  zoomIn: ZoomInOutlined,
  zoomOut: ZoomOutOutlined,
};

const SIZE = {
  s: "14px",
  m: "16px",
  l: "18px",
  small: "14px",
  medium: "16px",
  large: "18px",
};

export default {
  name: "ActIcon",
  components: { CommonIcon },
  props: {
    title: { type: String },
    checkAble: { type: Boolean },
    checked: { type: Boolean },
    danger: { type: Boolean },
    type: { type: String },
    size: { type: String },
    color: { type: String },
    hoverColor: { type: String },
    loading: { type: Boolean },
    disabled: { type: Boolean },
    wait: { type: Number, default: 500 },
    asyncSubmit: { type: [Function, Object] },
    forceCommonIcon: { type: Boolean },
  },
  emits: ["click", "action", "update:checked", "update:loading"],
  setup(props, { emit }) {
    const propRefs = toRefs(props);
    const { makeValProp } = useCachedProps(propRefs, { emit });
    const {
      type,
      size,
      color,
      hoverColor,
      checkAble,
      disabled,
      wait,
      asyncSubmit,
    } = propRefs;
    const iconChecked = makeValProp("checked");
    const iconLoading = makeValProp("loading");
    const icon = computed(() => {
      if (iconLoading.value) {
        return ICON_MAP.loading;
      }
      return ICON_MAP[type.value];
    });
    const style = computed(() => {
      const s = {};
      const sz = size.value;
      if (sz) s["--act-icon-size"] = SIZE[sz] ? SIZE[sz] : sz;
      if (color.value) s["--act-icon-color"] = color.value;
      if (hoverColor.value) s["--act-icon-hover"] = hoverColor.value;
      return s;
    });
    const doSubmit = () => {
      if (asyncSubmit.value) {
        const timeout = setTimeout(
          () => (iconLoading.value = true),
          wait.value
        );
        asyncSubmit.value({ checked: iconChecked.value }).then((data) => {
          clearTimeout(timeout);
          iconLoading.value = false;
          if (checkAble.value) {
            iconChecked.value = data;
          }
        });
      }
    };
    const handleAction = debounceFunc(
      (e) => {
        emit("action", e);
        doSubmit();
      },
      wait.value,
      true
    );
    const onClick = (e) => {
      if (!disabled.value) {
        emit("click", e);
        handleAction();
      }
    };
    return { icon, iconChecked, iconLoading, style, onClick };
  },
};
</script>

<style scoped>
.act-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --act-icon-size: 16px;
  --act-icon-color: var(--rmx-icon-2);
  --act-icon-hover: var(--rmx-primary-color);
  font-size: var(--act-icon-size);
  color: var(--act-icon-color);
}
.act-icon-content {
  cursor: pointer;
}
.act-icon.act-icon-disabled,
.act-icon.act-icon-disabled .act-icon-content {
  cursor: not-allowed;
  color: var(--rmx-icon-2);
  opacity: 0.5;
}
.act-icon:hover {
  color: var(--act-icon-hover);
}
.act-icon.act-icon-danger:hover {
  color: var(--rmx-error-color);
}
.act-icon.act-icon-check-able:hover {
  color: var(--rmx-icon-active);
}
.act-icon.act-icon-checked {
  color: var(--rmx-icon-active);
}
.act-icon.act-icon-loading,
.act-icon.act-icon-loading .act-icon-content {
  cursor: progress;
  opacity: 0.5;
}
</style>
