import BaseLayout from "@/components/core/BaseLayout";

export default [
  {
    path: "/demo",
    name: "Demo",
    component: BaseLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "form",
        name: "DemoForm",
        component: () =>
          import(/* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoForm"),
      },
      {
        path: "list",
        name: "DemoList",
        component: () =>
          import(/* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoList"),
      },
      {
        path: "user_manage",
        name: "DemoUserManage",
        meta: {
          title: "人员管理样例",
        },
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/UserManage"
          ),
      },
      {
        path: "table",
        name: "DemoTable",
        component: () =>
          import(/* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoTable"),
      },
      {
        path: "form_example",
        name: "FormExample",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/FormExample"
          ),
      },
      {
        path: "select_company",
        name: "选择单位示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoSelectCompany"
          ),
      },
      {
        path: "select_user",
        name: "选择人员示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoSelectUser"
          ),
      },
      {
        path: "print_report",
        name: "打印报表",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoPrintReport"
          ),
      },
      {
        path: "env_info",
        name: "获取环境信息示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoGetEnvInfo"
          ),
      },
      {
        path: "archive_box",
        name: "密集架",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoArchiveBox"
          ),
      },
      {
        path: "archive_select",
        name: "选择档案示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoArchiveSelect"
          ),
      },
      {
        path: "process_user",
        name: "选择审批流程人员示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoChooseApprovalProcessUser"
          ),
      },
      {
        path: "package_shell",
        name: "公共样式包裹组件示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoPackageShell"
          ),
      },
      {
        path: "view_attach",
        name: "附件预览（内嵌）",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoViewAttach"
          ),
      },
      {
        path: "view_attach_list",
        name: "附件预览（弹窗）",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoViewAttachInWindow"
          ),
      },
      {
        path: "interface_definition",
        name: "界面定义",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoInterfaceDefinition"
          ),
      },
      {
        path: "text_editor",
        name: "文本编辑器",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoTextEditor"
          ),
      },
      {
        path: "edit_password",
        name: "修改密码示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoEditPsw"
          ),
      },
      {
        path: "archive_box_mode",
        name: "密集架(拉取选中)",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoArchiveBoxMode"
          ),
      },
      {
        path: "pop_view",
        name: "气泡卡片示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoPopView"
          ),
      },
      {
        path: "select_role",
        name: "选择角色示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoSelectRole"
          ),
      },
      {
        path: "four_design",
        name: "四性检测弹窗示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoFourDesign"
          ),
      },
      {
        path: "timeline",
        name: "时间线组件示例",
        component: () =>
          import(
            /* webpackChunkName: "demo" */ "@/mods/demo/subMod/DemoTimeLine"
          ),
      },
    ],
  },
];
