import {
  computed,
  getCurrentInstance,
  onMounted,
  ref,
  unref,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useCachedProps } from "@/components/common/shared/compInternal";
import { i18n } from "@/conf/lang";
import { httpActionHandler } from "@/util/http";
import { showConfirm } from "@/util/dialog";
import {
  downloadFileWith,
  getFileUrlFromRelative,
  mimeCheck,
  shortenFilename,
  uploadFile,
} from "@/util/fileUtil";
import { DEFAULT_UPLOAD_URL } from "@/conf/constants";
import { formatFileSize } from "@/util/strUtil";
import { Upload } from "ant-design-vue";

const $t = (k, arr) => i18n.global.t(k, arr);

export function useUploader(useType, propRefs, context) {
  if (!context) context = getCurrentInstance();
  const { emit } = context;
  const { makeRefProp } = useCachedProps(propRefs, context);
  const store = useStore();
  const {
    uploadAction,
    uploadChecker,
    asyncRemove,
    uploadParam,
    uploadType,
    uploadBusinessId,
    value,
    raw,
    disabled,
    readonly,
    maxlength,
    max,
  } = propRefs;
  const rawList = ref([]);
  const valueList = makeRefProp("value", []);
  const uploadAccept = computed(() => {
    return propRefs.accept.value || (useType === "image" ? "image/*" : "*/*");
  });
  const uploadDisabled = computed(() => {
    return readonly.value || disabled.value;
  });
  const uploadAct = computed(() => {
    const ua = unref(uploadAction);
    if (typeof ua === "function") {
      return ua;
    } else if (ua === null || typeof ua === "object") {
      return undefined;
    }
    return getFileUrlFromRelative(ua || DEFAULT_UPLOAD_URL);
  });
  const uploadRequest = computed(() => {
    const ua = unref(uploadAction);
    if (typeof ua === "object")
      return (options) => {
        uploadFile({
          file: options.file,
          uploadUrl: ua.uploadUrl || DEFAULT_UPLOAD_URL,
          checkUrl: ua.checkUrl,
          slice: ua.slice,
          data: uploadBody.value,
          onProgress: ({ type, progress }) => {
            let percent =
              type === "hashing" ? progress / 3 : 33.3 + (progress * 2) / 3;
            options.onProgress({ percent }, options);
          },
        }).then((fileData) => {
          options.onSuccess({ code: 0, data: fileData }, options);
        });
      };
    return undefined;
  });
  const uploadHeader = computed(() => {
    return { "x-access-token": store.state.accessToken };
  });
  const uploadBody = computed(() => {
    const data = uploadParam.value ? { ...uploadParam.value } : {};
    if (uploadType.value) data.type = uploadType.value;
    if (uploadBusinessId.value) data.businessId = uploadBusinessId.value;
    return data;
  });
  const displayList = computed(() => {
    return rawList.value.map((file) => {
      let info = file?.response?.data;
      if (!file?.uploadFileId) {
        const filename = file.name || "";
        const tmp = filename.split(".");
        const ext = tmp.length > 1 ? tmp[tmp.length - 1].toLowerCase() : "";
        const name = filename.substring(0, filename.length - ext.length - 1);
        const size = formatFileSize(file?.size || 0);
        info = { id: file?.uid, ext, name, size };
        if (file?.status === "done") {
          info.error = true;
        }
      }
      return {
        ...info,
        url: getFileUrlFromRelative(info.url),
        loading: file?.status === "uploading",
        progress: Math.floor(file?.percent || 0),
      };
    });
  });
  const downloadable = computed(() => !disabled.value);
  const removable = computed(() => !readonly.value && !disabled.value);
  const uploadAble = computed(() => {
    if (max.value > 1) {
      return rawList.value.length < max.value;
    }
    return true;
  });
  const syncValue = (changed, item) => {
    const list = rawList.value.map((x) => x?.response?.data).filter((x) => !!x);
    const remain = item?.id ? list.filter((x) => x.id !== item.id) : list;
    const newList = raw.value ? remain : remain.map((x) => x.id);
    valueList.value = newList;
    if (changed) emit("change", newList);
  };
  const fillBack = (list) => {
    const ids = raw.value ? list.map((x) => x.id) : list;
    const filtered = rawList.value.filter((x) => {
      const data = x?.response?.data;
      if (!data && x?.status !== "done") {
        return true;
      }
      return ids.includes(data?.id);
    });
    const filteredIds = filtered.map((x) => x.uploadFileId);
    const addList = [];
    list.forEach((x) => {
      if (typeof x === "object" && !filteredIds.includes(x.id)) {
        addList.push({
          uid: x.id,
          uploadFileId: x.id,
          name: `${x.name}.${x.ext}`,
          status: "done",
          response: { code: 0, data: x },
        });
      }
    });
    rawList.value = [...filtered, ...addList];
  };
  const beforeUpload = (file) =>
    new Promise((resolve, reject) => {
      const rejectAlert = (content) => {
        showConfirm({
          content,
          okCancel: false,
          okText: "actions.got_it",
        }).then(() => resolve(Upload.LIST_IGNORE));
      };
      if (maxlength.value && file.size > maxlength.value) {
        return rejectAlert(
          $t("hint.file.size_exceed", [formatFileSize(maxlength.value)])
        );
      }
      if (file.size === 0) {
        return rejectAlert($t("hint.file.not_empty"));
      }
      if (max.value > 1 && rawList.value.length >= max.value) {
        reject();
        return;
      }
      if (!mimeCheck(uploadAccept.value, file)) {
        return rejectAlert($t("hint.file.type_not_match"));
      }
      if (typeof uploadChecker.value === "function") {
        const check = uploadChecker.value(file);
        if (check !== true) {
          if (check.then) {
            return check.then(
              () => {
                resolve(true);
              },
              (reason) => {
                if (reason) {
                  rejectAlert(reason);
                } else {
                  resolve(Upload.LIST_IGNORE);
                }
              }
            );
          }
          return rejectAlert(check);
        }
      }
      resolve(true);
    });
  const onUploaderChange = (e) => {
    const file = e.file;
    const res = file.response;
    if (file.status === "done") {
      if (res.code !== 0) {
        httpActionHandler(res)
          .then(() => {})
          .catch(() => {});
      } else {
        file.uploadFileId = res.data.id;
        syncValue(true);
      }
    } else if (file.status === "removed") {
      onRemove(res.data);
    }
  };
  const onRemove = (item) => {
    if (item.error) {
      rawList.value = rawList.value.filter((x) => x.uid !== item.id);
      return;
    }
    if (asyncRemove.value) {
      asyncRemove.value(item).then(() => {
        syncValue(true, item);
      });
    } else {
      syncValue(true, item);
    }
  };
  watch(
    () => value.value,
    (list) => {
      fillBack(list);
    }
  );
  watch(
    () => raw.value,
    () => syncValue()
  );
  onMounted(() => {
    const val = value.value;
    if (Array.isArray(val) && val.length > 0) {
      fillBack(val);
    }
  });
  return {
    uploadAccept,
    uploadDisabled,
    uploadAct,
    uploadRequest,
    uploadHeader,
    uploadBody,
    rawList,
    displayList,
    valueList,
    uploadAble,
    downloadable,
    removable,
    beforeUpload,
    onUploaderChange,
    onRemove,
  };
}

export function useGallery(type, propRefs, context) {
  if (!context) context = getCurrentInstance();
  const { emit } = context;
  const { removable, downloadable, customEvents } = propRefs;
  const deleteHint = computed(() =>
    $t("hint.delete_what", [
      $t(type === "image" ? "things.image" : "things.file"),
    ])
  );
  const canWhat = (v) => (it) => {
    const f = unref(v);
    return typeof f === "function" ? f(it) : f;
  };
  const fileSize = (x) => {
    return typeof x === "number" ? formatFileSize(x) : x;
  };
  const canRemove = canWhat(removable);
  const canDownload = canWhat(downloadable);
  const onDownload = (file) => {
    if (customEvents.value?.download === true) {
      emit("download", file);
    } else {
      downloadFileWith(file);
    }
  };
  return { deleteHint, fileSize, canRemove, canDownload, onDownload };
}

export function useUploadOverlay(displayList) {
  const current = computed(() => {
    const list = unref(displayList);
    return list.find((x) => x.loading);
  });
  const uploading = computed(() => !!current.value);
  const uploadProgress = computed(() => {
    return current.value?.progress || 0;
  });
  const uploadProgressText = computed(() => {
    const f = current.value;
    if (!f) return "";
    const filename = shortenFilename(`${f.name}.${f.ext}`);
    const key =
      f.progress >= 100 ? "hint.file.uploaded" : "hint.file.uploading";
    return $t(key, [filename]);
  });
  return { uploading, uploadProgress, uploadProgressText };
}
