<template>
  <div v-if="fullHeader" class="back-sided-nav-full rmx-scrollbar">
    <menu-view
      class="back-sided-nav-menus"
      :menus="menus"
      mode="inline"
      :value="curUrl"
      parent-custom-title
      :menu-indent="16"
      :opened="openedKeys"
      @update:opened="onOpenedKeyChange"
      @click="onMenuClick"
    >
      <template #icon="{ level, menu }">
        <span>
          <back-menu-icon
            v-if="showAllIcon || level === 0"
            class="sided-nav-item-icon"
            :icon="menu.icon"
          />
        </span>
      </template>
      <template #title="{ title, level }">
        <span v-if="level === 0" class="sided-nav-item-title">{{ title }}</span>
        <span v-else>{{ title }}</span>
      </template>
    </menu-view>
  </div>
  <div
    v-else
    class="back-sided-nav"
    :class="{ 'back-sided-nav-collapsed': collapsed }"
  >
    <back-logo-view :collapsed="collapsed" class="main-logo" />
    <a-popover placement="rightTop" v-model:visible="mainMenuVisible">
      <template #title>
        <div class="main-menu-title">{{ $t("fields.menu.all") }}</div>
      </template>
      <template #content>
        <scrollable-view class="back-menu-wrap" :step="100">
          <div class="back-menu-list">
            <div
              v-for="group in menus"
              :key="group.key"
              class="back-menu-group"
            >
              <div class="back-menu-group-title">{{ group.label }}</div>
              <div class="back-menu-group-content">
                <div
                  v-for="item in group.children"
                  :key="item.key"
                  class="back-menu-item"
                  :class="{ 'back-menu-item-active': item === curMenu }"
                  @click="onNavClick(item)"
                >
                  <back-menu-icon :icon="item.icon" />
                  <span class="back-menu-item-label">{{ item.label }}</span>
                </div>
              </div>
            </div>
          </div>
          <template #prefix="{ canDecrease, doDecrease }">
            <div
              class="back-menu-scroll-handler"
              :class="{ 'back-menu-scroll-handler-enabled': canDecrease }"
              @click="doDecrease"
            >
              <left-circle-filled />
            </div>
          </template>
          <template #postfix="{ canIncrease, doIncrease }">
            <div
              class="back-menu-scroll-handler"
              :class="{ 'back-menu-scroll-handler-enabled': canIncrease }"
              @click="doIncrease"
            >
              <right-circle-filled />
            </div>
          </template>
        </scrollable-view>
      </template>
      <div class="main-menu" :title="$t('fields.menu.all')">
        <appstore-filled />
        <div v-if="!collapsed" class="main-menu-text">
          <div class="main-menu-label">
            {{ $t("fields.menu.all") }}
          </div>
          <right-outlined class="main-menu-icon" />
        </div>
      </div>
    </a-popover>
    <template v-if="curGroup">
      <div class="back-sided-nav-title">
        {{ collapsed ? curGroup.short : curGroup.label }}
      </div>
      <scrollable-view class="back-sided-nav-scroll" direction="vertical">
        <template #prefix="{ canDecrease, doDecrease }">
          <div
            class="back-sided-nav-scroll-up"
            :class="{ 'back-sided-nav-scroll-disabled': !canDecrease }"
            @click="doDecrease"
          >
            <caret-up-outlined />
          </div>
        </template>
        <template #postfix="{ canIncrease, doIncrease }">
          <div
            class="back-sided-nav-scroll-down"
            :class="{ 'back-sided-nav-scroll-disabled': !canIncrease }"
            @click="doIncrease"
          >
            <caret-down-outlined />
          </div>
        </template>
        <div class="back-sided-nav-list">
          <template v-for="item in curGroup.children" :key="item.key">
            <div
              class="sided-nav-item"
              :class="{ 'sided-nav-item-active': item === curMenu }"
              @click="onNavClick(item)"
            >
              <back-menu-icon class="sided-nav-item-icon" :icon="item.icon" />
              <div class="sided-nav-item-text">{{ item.label }}</div>
            </div>
          </template>
        </div>
      </scrollable-view>
    </template>
    <div v-else class="back-sided-nav-placeholder"></div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useBackMenus } from "@/components/back/backNav";
import {
  AppstoreFilled,
  RightOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  LeftCircleFilled,
  RightCircleFilled,
} from "@ant-design/icons-vue";
import BackMenuIcon from "@/components/back/BackMenuIcon";
import ScrollableView from "@/components/common/display/ScrollableView";
import BackLogoView from "@/components/back/BackLogoView";
import MenuView from "@/components/common/action/MenuView";

export default {
  name: "BackSidedNav",
  components: {
    MenuView,
    BackLogoView,
    ScrollableView,
    BackMenuIcon,
    AppstoreFilled,
    RightOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    LeftCircleFilled,
    RightCircleFilled,
  },
  emits: ["navChanged", "loaded", "authChanged"],
  props: {
    collapsed: { type: Boolean },
    fullHeader: { type: Boolean },
    showAllIcon: { type: Boolean },
  },
  setup(props, { expose, emit }) {
    const route = useRoute();
    const router = useRouter();
    const openedKeys = ref([]);
    const { menus, loaded, hasAuth } = useBackMenus();
    const flatMenus = computed(() =>
      menus.value.flatMap((x) => {
        if (Array.isArray(x.children)) {
          return [x, ...x.children];
        }
        return x;
      })
    );
    const mainMenuVisible = ref(false);
    const curMenu = computed(() => {
      const path = route.path;
      const menu = flatMenus.value.find((x) => x.url === path);
      if (menu) return menu;
      return undefined;
    });
    const curGroup = computed(() => {
      if (curMenu.value) {
        return menus.value.find((x) => x.children.includes(curMenu.value));
      }
      return undefined;
    });
    const curUrl = computed(() => {
      if (curMenu.value) {
        return curMenu.value.url;
      }
      return "/404";
    });
    const gotoNav = (item) => {
      const url = item?.url || "";
      if (url.startsWith("http://") || url.startsWith("https://")) {
        window.open(url);
      } else if (url.startsWith("@")) {
        window.open(url.substring(1));
      } else {
        router.push(url);
      }
    };
    const onNavClick = (item) => {
      mainMenuVisible.value = false;
      gotoNav(item);
    };
    watch(
      () => curMenu.value,
      (item) => {
        emit("navChanged", item);
      }
    );
    watch(
      () => hasAuth.value,
      (item) => {
        emit("authChanged", item);
      },
      { immediate: true }
    );
    watch(
      () => curGroup.value,
      (g) => {
        if (g) {
          const key = `/${g.key}`;
          openedKeys.value = [key];
        }
      },
      { immediate: true }
    );
    watch(
      () => loaded.value,
      (v) => {
        emit("loaded", v);
      }
    );
    const onMenuClick = (e) => {
      gotoNav(e.item);
    };
    const onOpenedKeyChange = (keys) => {
      const old = openedKeys.value;
      const last = keys.find((x) => old.indexOf(x) === -1);
      if (!last) {
        openedKeys.value = keys;
      } else {
        openedKeys.value = [last];
      }
    };
    expose({});
    return {
      menus,
      flatMenus,
      mainMenuVisible,
      openedKeys,
      curMenu,
      curGroup,
      curUrl,
      gotoNav,
      onNavClick,
      onMenuClick,
      onOpenedKeyChange,
    };
  },
};
</script>

<style scoped>
.back-sided-nav-full {
  padding: 16px 0;
  height: 100%;
  overflow: auto;
}
.back-sided-nav-menus {
  border-right: 1px solid transparent;
}
.main-logo {
  padding: 16px 16px 0;
}
.main-menu {
  cursor: pointer;
  color: white;
  padding: 16px 8px;
  border-bottom: 1px solid var(--rmx-primary-color-1);
  font-size: 16px;
  text-align: center;
}
.main-menu-text {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 96px;
  margin-left: 8px;
  font-size: 14px;
}
.main-menu-label {
  flex: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main-menu-icon {
  font-size: 14px;
  margin-left: 8px;
}
.main-menu-title {
  color: var(--rmx-text-1);
  font-size: 20px;
}
.back-sided-nav-scroll {
  height: calc(100vh - 179px);
}
.back-sided-nav-collapsed .back-sided-nav-scroll {
  height: calc(100vh - 174px);
}
.back-sided-nav-placeholder {
  height: calc(100vh - 235px);
}
.back-sided-nav-collapsed .back-sided-nav-placeholder {
  height: calc(100vh - 270px);
}
.back-sided-nav-scroll-up,
.back-sided-nav-scroll-down {
  background: var(--rmx-primary-color-5);
  cursor: pointer;
  margin: 8px 32px;
  font-size: 12px;
  color: white;
  text-align: center;
}
.back-sided-nav-scroll-up.back-sided-nav-scroll-disabled,
.back-sided-nav-scroll-down.back-sided-nav-scroll-disabled {
  background: var(--rmx-primary-color-7);
  cursor: not-allowed;
  color: #cccccc;
}
.back-sided-nav-scroll-up {
  border-radius: 8px 8px 4px 4px;
}
.back-sided-nav-scroll-down {
  border-radius: 4px 4px 8px 8px;
}
.back-sided-nav-collapsed .back-sided-nav-scroll-up,
.back-sided-nav-collapsed .back-sided-nav-scroll-down {
  margin: 8px 16px;
}
.back-sided-nav-title {
  position: relative;
  color: white;
  background: var(--rmx-primary-color-4);
  margin: 8px;
  padding: 4px;
  text-align: center;
  border-radius: 2px;
  font-size: 15px;
  font-weight: bold;
}
.back-sided-nav-title:before {
  display: block;
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  left: calc(50% - 5px);
  background: var(--rmx-primary-color-4);
  transform: rotate(45deg);
  bottom: -5px;
}
.back-sided-nav-list {
  padding-top: 8px;
}
.sided-nav-item {
  display: flex;
  align-items: center;
  gap: 8px 12px;
  font-size: 15px;
  line-height: 1;
  padding: 16px 12px;
  color: white;
  cursor: pointer;
}
.sided-nav-item:hover {
  background: var(--rmx-primary-color-7);
}
.sided-nav-item.sided-nav-item-active {
  background: var(--rmx-primary-color-6);
}
.back-sided-nav-collapsed .sided-nav-item {
  flex-direction: column;
  padding: 12px;
  font-size: 14px;
}
.back-sided-nav-collapsed .sided-nav-item-icon {
  font-size: 18px;
}
.sided-nav-item-icon {
  font-size: 16px;
}
.sided-nav-item-title {
  font-size: 15px;
}
.back-menu-wrap {
  height: 700px;
  width: 1300px;
}
@media screen and (max-width: 1500px) {
  .back-menu-wrap {
    width: calc(100vw - 220px);
  }
}
.back-menu-scroll-handler {
  display: flex;
  align-items: center;
  height: 300px;
  border-radius: 8px;
  align-self: center;
  position: relative;
  z-index: 1;
  width: 16px;
  color: var(--rmx-primary-color-3);
}
.back-menu-scroll-handler:hover {
  background: #cccccc33;
}
.back-menu-scroll-handler-enabled {
  cursor: pointer;
  color: var(--rmx-primary-color-5);
}
.back-menu-list {
  display: flex;
  gap: 16px;
  width: max-content;
}
.back-menu-group {
  width: 150px;
}
.back-menu-group-title {
  padding-bottom: 16px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 8px;
  font-size: 15px;
  color: var(--rmx-text-1);
  line-height: 15px;
  font-weight: bold;
  cursor: default;
}
.back-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--rmx-text-2);
  font-size: 14px;
}
.back-menu-item:hover {
  color: var(--rmx-primary-color-5);
}
.back-menu-item.back-menu-item-active {
  color: var(--rmx-primary-color-5);
}
.back-menu-item-label {
  margin-left: 8px;
}
</style>
