export default {
  applyApproval: {
    width: "s",
    okText: "actions.submit",
    title: "front.approval.submit",
    component: () =>
      import(
        /* webpackChunkName: "utilizationDetail_dialog" */ "@/mods/front/utilizationDetail/dialog/ApplyApprovalDialog"
      ),
  },
  choosePurpose: {
    width: "s",
    okText: "actions.save",
    title: "front.utilize.select_purpose",
    component: () =>
      import(
        /* webpackChunkName: "utilizationDetail_dialog" */ "@/mods/front/utilizationDetail/dialog/ChoosePurposeDialog"
      ),
  },
  branchApproval: {
    width: "s",
    okText: "actions.submit",
    title: "front.approval.submit",
    component: () =>
      import(
        /* webpackChunkName: "utilizationDetail_dialog" */ "@/mods/front/utilizationDetail/dialog/BranchApprovalDialog"
      ),
  },
  utilizationDetailDialog: {
    width: "1600px",
    title: "front.apply.form_approval",
    component: () =>
      import(
        /* webpackChunkName: "utilizationDetail_dialog" */ "@/mods/front/utilizationDetail/UtilizationApproval"
      ),
  },
  archList: {
    width: "m",
    footer: null,
    destroyOnClose: true,
    title: "front.fields.arch_detail",
    component: () =>
      import(
        /* webpackChunkName: "utilizationDetail_dialog" */ "@/mods/front/utilizationDetail/dialog/ArchDetailDialog"
      ),
  },
};
