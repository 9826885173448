export default {
  specialAccessRightsDialog: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/special/SpecialAccessRightsDialog"
      ),
  },
  selectDialog: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/special/SelectDialog"
      ),
  },
  archPreviewDialog: {
    width: "l",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/special/ArchPreviewDialog"
      ),
  },
};
