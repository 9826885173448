<template>
  <a-button
    :class="{
      'rmx-btn-secondly': type === 'secondly',
      'rmx-btn-narrow': narrow,
    }"
    :type="realType"
    :html-type="htmlType"
    :shape="shape"
    :disabled="disabled"
    :loading="loading"
    :size="realSize"
    :block="block"
    :ghost="ghost"
    @click="handleClick($event)"
  >
    <template v-if="$slots.icon" #icon>
      <slot name="icon"></slot>
    </template>
    <slot></slot>
  </a-button>
</template>

<script>
import { computed, toRefs } from "vue";
import { DEFAULT_ACTION_DEBOUNCE, UI_SIZE } from "@/conf/constants";
import { debounceFunc } from "@/util/uiUtil";

const TYPES = ["default", "primary", "secondly", "dashed", "text", "link"];
const BTN_TYPE = ["button", "submit", "reset"];
const BTN_SHAPE = ["default", "circle", "round"];
export default {
  name: "ActButton",
  props: {
    type: { type: String, validator: (v) => TYPES.includes(v) },
    htmlType: { type: String, validator: (v) => BTN_TYPE.includes(v) },
    shape: { type: String, validator: (v) => BTN_SHAPE.includes(v) },
    block: { type: Boolean },
    ghost: { type: Boolean },
    size: { type: String, validator: (v) => v in UI_SIZE },
    disabled: { type: Boolean },
    narrow: { type: Boolean },
    loading: { type: Boolean },
    debounce: { type: Number, default: DEFAULT_ACTION_DEBOUNCE },
  },
  emits: ["click", "action"],
  setup(props, { emit }) {
    const { type, size, debounce } = toRefs(props);
    const realType = computed(() =>
      type.value === "secondly" ? undefined : type.value
    );
    const realSize = computed(() => {
      const s = UI_SIZE[size.value];
      if (s === UI_SIZE.medium) return "middle";
      return s;
    });
    const handleAction = debounceFunc(
      (e) => emit("action", e),
      debounce.value,
      true
    );
    const handleClick = (e) => {
      emit("click", e);
      handleAction(e);
    };
    return { realType, realSize, handleClick };
  },
};
</script>
