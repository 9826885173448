import RouterHolder from "@/components/core/RouterHolder";

export default {
  path: "announcement",
  component: RouterHolder,
  children: [
    {
      path: "/front/announcement",
      redirect: "/front/announcement/list",
    },
    {
      path: "list",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/announcement/AnnouncementList"
        ),
    },
    {
      path: "detail",
      meta: { purePage: true },
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/announcement/AnnouncementDetail"
        ),
    },
  ],
};
