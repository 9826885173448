export const FORM_WIDTH = { s: "320px", m: "400px", l: "600px" };
export const FORM_ITEM_WIDTH = { s: "320px", m: "400px", l: "600px" };
export const FORM_ITEM_BOOLEAN = ["checkbox", "switch", "option_checkbox"];
export const FORM_ITEM_NUMBER = ["number", "rate", "slider"];

export const DEFAULT_PAGER_LIMIT = 30;
export const DEFAULT_PAGER_OPTIONS = ["5", "10", "15", "20", "30", "50"];

export const THEME_COLORS = [
  "#1996ff",
  "#0960bd",
  "#009688",
  "#ee4f12",
  "#9c27b0",
  "#ff9800",
  "#3fc35a",
  "#e02020",
];

export const NAMED_COLORS = {
  success: "#52c41a",
  error: "#f5222d",
  warn: "#fa541c",
  info: "#1890ff",
  process: "#1890ff",
  pink: "#c41d7f",
  red: "#cf1322",
  orange: "#d46b08",
  green: "#42b759",
  cyan: "#00bbf2",
  blue: "#096dd9",
  purple: "#531dab",
};

export const SORT_ORDER = {
  asc: "asc",
  desc: "desc",
};

export const UI_SIZE = {
  extraLarge: "xl",
  large: "large",
  medium: "medium",
  small: "small",
  extraSmall: "xs",
  xl: "xl",
  l: "large",
  m: "medium",
  s: "small",
  xs: "xs",
};

export const UI_DIRECTION = {
  horizontal: "horizontal",
  vertical: "vertical",
  inline: "inline",
};

export const UI_ALIGN = {
  left: "left",
  center: "center",
  right: "right",
};

export const UI_PLACEMENT = {
  top: "top",
  left: "left",
  right: "right",
  bottom: "bottom",

  topLeft: "topLeft",
  topRight: "topRight",
  bottomLeft: "bottomLeft",
  bottomRight: "bottomRight",

  leftTop: "leftTop",
  leftBottom: "leftBottom",
  rightTop: "rightTop",
  rightBottom: "rightBottom",
};

export const UI_TRIGGER = {
  hover: "hover",
  focus: "focus",
  click: "click",
  contextmenu: "contextmenu",
};

export const TABLE_WIDTH = {
  s: "600px",
};

export const TABLE_CELL_WIDTH = {
  OPERATION: "100px",
  SWITCH: "100px",
  DATE: "120px",
  TIME: "120px",
  STATUS: "120px",
  ENTITY: "150px",
  DATETIME: "200px",
  NAME: "200px",
  CODE: "300px",
  CONTENT: "400px",
  TITLE: "600px",
};

export const DIALOG_WIDTH = {
  s: "500px",
  m: "860px",
  l: "1000px",
  xl: "1200px",
  xxl: "1600px",
};

export const DRAWER_WIDTH = {
  s: 700,
  m: 900,
  l: 1000,
};

export const HTTP_METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const DEFAULT_UPLOAD_URL = "common/file/upload";

export const DEFAULT_ACTION_DEBOUNCE = 800;

export const IFRAME_ACTIONS = {
  GET_SELECTION: "GET_SELECTION",
  DO_PRINT: "DO_PRINT",
};
