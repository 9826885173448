export default {
  modifyMetadata: {
    title: "修改字段",
    width: "s",
    okText: "actions.save",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/ModifyMetadata"
      ),
  },
  moveToArchives: {
    width: "s",
    okText: "actions.save",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/MoveToArchives"
      ),
  },
  fondsInnerMove: {
    width: "s",
    okText: "actions.save",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/FondsInnerMove"
      ),
  },
  adjustGroup: {
    title: "调整分组",
    width: "s",
    okText: "actions.save",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/AdjustGroup"
      ),
  },
  boxPut: {
    title: "装盒",
    width: "m",
    okText: "actions.save",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/BoxPut"
      ),
  },
  sameGroupNumber: {
    width: "m",
    okText: "确认编号",
    cancelText: "关闭",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/SameGroupNumber"
      ),
  },
  changeOrder: {
    width: "s",
    title: "请输入目标行号",
    okText: "actions.save",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/ChangeOrder"
      ),
  },
  mergeComponents: {
    width: "m",
    title: "合并组件",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/MergeComponents"
      ),
  },
  moveOrCopyArch: {
    width: "s",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/MoveOrCopyArch"
      ),
  },
  batchDownload: {
    width: "s",
    title: "批量下载",
    okText: "actions.download",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/BatchDownload"
      ),
  },
  batchElectronPrint: {
    width: "s",
    title: "批量打印",
    okText: "打印",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/BatchElectronPrint"
      ),
  },
  batchImportFile: {
    width: "l",
    title: "批量导入电子文件",
    okText: "开始导入",
    footer: null,
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/BatchImportFile"
      ),
  },
  excelImport: {
    width: "m",
    title: "导入文件条目",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/ExcelImport"
      ),
  },
  excelImportChooseType: {
    width: "s",
    title: "导入条目类型",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/ExcelImportChooseType"
      ),
  },
  summaryArchive: {
    width: "s",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/SummaryArchive"
      ),
  },
  batchCartoning: {
    width: "m",
    title: "批量装盒",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/BatchCartoning"
      ),
  },
  boxDoc: {
    width: "m",
    title: "盒内文件",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/BoxDoc"
      ),
  },
  addDesiFile: {
    title: "加入指定案卷",
    width: "m",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/AddDesiFile.vue"
      ),
  },
  setContentOfList: {
    title: "设置列表显示内容",
    width: "m",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/SetContentOfList.vue"
      ),
  },
  inVolumeOrder: {
    title: "卷内调序",
    width: "m",
    destroyOnClose: true,
    okText: "更新卷内序号",
    cancelText: "关闭",
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/InVolumeOrder.vue"
      ),
  },
  batchGroupVolume: {
    width: "l",
    title: "批量组卷",
    footer: null,
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/BatchGroupVolume"
      ),
  },
  addAppraisal: {
    width: "m",
    title: "加入鉴定批次",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/AddAppraisal"
      ),
  },
  changeBox: {
    title: "换盒",
    width: "m",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/ChangeBox.vue"
      ),
  },
  changeVolume: {
    title: "加入指定案卷",
    width: "m",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/ChangeVolume.vue"
      ),
  },
  batchElectronicHook: {
    title: "批量挂接电子文件",
    width: "xl",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/BatchHooking"
      ),
  },
  batchEditAuth: {
    title: "修改权限",
    width: "m",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/BatchEditAuth.vue"
      ),
  },
  adjustPiece: {
    width: "s",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/AdjustPiece"
      ),
  },
  sameClassifyOrder: {
    width: "m",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/SameClassifyOrder.vue"
      ),
  },
  tableCustomization: {
    title: "表格自定义",
    width: "s",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/TableCustomization.vue"
      ),
  },
  setTableFilter: {
    width: "m",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/SetTableFilter.vue"
      ),
  },

  addTableFilterView: {
    width: "s",
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/AddTableFilterView.vue"
      ),
  },

  customViewManage: {
    width: "m",
    destroyOnClose: true,
    footer: [],
    component: () =>
      import(
        /* webpackChunkName: "archive_dialog" */ "@/mods/archive/archiveTreasury/dialog/CustomViewManage.vue"
      ),
  },
};
