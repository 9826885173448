import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.variable.min.css";
import "@/assets/remix.css";
import { i18n } from "@/conf/lang";
// full-header switch
localStorage.setItem("e_doc_full_header", true);

createApp(App).use(i18n).use(store).use(router).use(Antd).mount("#app");
