export default {
  addUser: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/userManage/dialog/AddUser"
      ),
  },
  editUserStatus: {
    width: "s",
    okText: "actions.save",
    title: "用户信息",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/userManage/dialog/EditUserStatus"
      ),
  },
  moveDept: {
    width: "s",
    okText: "actions.save",
    title: "用户信息",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/userManage/dialog/MoveDept"
      ),
  },
  selectDept: {
    width: "s",
    okText: "actions.save",
    title: "选择组织部门",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/userManage/dialog/SelectDept"
      ),
  },
  resetPassword: {
    width: "s",
    okText: "actions.save",
    title: "密码重置",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/userManage/dialog/ResetPassword"
      ),
  },
  sortUser: {
    width: "m",
    okText: "actions.save",
    title: "排序",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/userManage/dialog/SortUser"
      ),
  },
};
