<template>
  <div
    class="meta-descriptions"
    :class="{
      'meta-descriptions-bordered': bordered,
      'meta-descriptions-table': table,
      'meta-descriptions-ellipsis': ellipsis,
      'meta-descriptions-vertical': direction === 'vertical',
    }"
    :style="style"
  >
    <template v-for="field in fields" :key="field.column">
      <div
        class="meta-description-item"
        :class="{ 'meta-description-item-block': field.block }"
        :style="itemStyle(field)"
      >
        <div class="meta-description-label" :title="field.label">
          {{ field.label }}
        </div>
        <div
          class="meta-description-content"
          :class="{ 'meta-description-content-max-line': field.maxLine }"
          :title="titleText(field)"
        >
          <template v-if="field.slot">
            <slot name="content" :field="field"></slot>
          </template>
          <template v-else-if="field.valueMap">
            <status-view
              :value-map="field.valueMap"
              :status="value[field.column]"
              :use-locale="useLocale"
            ></status-view>
          </template>
          <template v-else>
            <template v-if="field.action">
              <act-link
                class="meta-description-action"
                :text="contentText(field)"
                @action="onAction(field)"
              />
            </template>
            <template v-else>
              {{ contentText(field) }}
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { UI_DIRECTION } from "@/conf/constants";
import StatusView from "@/components/common/display/StatusView";
import ActLink from "@/components/common/action/ActLink";
export default {
  name: "MetaDescriptions",
  components: { ActLink, StatusView },
  emits: ["action"],
  props: {
    value: { type: Object, default: () => ({}) },
    fields: { type: Array, default: () => [] },
    bordered: { type: Boolean, default: false },
    table: { type: Boolean },
    columns: { type: Number, default: 1 },
    ellipsis: { type: Boolean, default: true },
    labelWidth: { type: String },
    direction: { type: String, validator: (v) => v in UI_DIRECTION },
    functions: { type: Object, default: () => ({}) },
    useLocale: { type: Boolean },
  },
  setup(props, { emit, expose }) {
    const { columns, labelWidth, functions, value } = toRefs(props);
    const style = computed(() => {
      const s = {};
      if (columns.value > 1) {
        s["--rmx-meta-columns"] = columns.value;
      }
      if (labelWidth.value) {
        s["--rmx-meta-label-width"] = labelWidth.value;
      }
      return s;
    });
    const contentText = (field) => {
      if (typeof field.func === "function") {
        return field.func(value.value, field);
      } else if (typeof functions.value[field.func] === "function") {
        return functions.value[field.func](value.value, field);
      } else if (field.arrayConnect) {
        return value.value[field.column]?.join?.(field.arrayConnect);
      } else {
        return field.text || value.value[field.column];
      }
    };
    const titleText = (field) => {
      if (field.title) {
        return field.title;
      } else if (field.valueMap || field.slot) {
        return "";
      }
      return field.label + ": " + contentText(field);
    };
    const onAction = (e) => {
      emit("action", { action: e.column });
    };
    const itemStyle = (field) => {
      const s = {};
      let offset = field.offset ? field.offset : "auto";
      if (field.block) {
        s["grid-column"] = `${offset} / span ${columns.value}`;
      } else if (field.span) {
        s["grid-column"] = `${offset} / span ${field.span}`;
      }
      if (field.maxLine) {
        s["--rmx-meta-max-line"] = field.maxLine;
      }
      return s;
    };
    expose({});
    return { style, contentText, titleText, itemStyle, onAction };
  },
};
</script>

<style scoped>
.meta-descriptions {
  --rmx-meta-columns: 1;
  --rmx-meta-max-line: 2;
  --rmx-meta-padding: 8px 16px;
  --rmx-meta-label-width: 120px;
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(var(--rmx-meta-columns), 1fr);
  gap: 16px 24px;
}
.meta-descriptions-bordered {
  border: 1px solid var(--rmx-border);
  background: var(--rmx-content-color-opacity);
  border-radius: var(--rmx-border-radius);
  padding: 24px;
}
.meta-descriptions-table {
  position: relative;
  gap: 0;
  border-top: 1px solid var(--rmx-border);
  border-right: 1px solid var(--rmx-border);
  background: var(--rmx-content-color-opacity);
}
.meta-descriptions-table:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--rmx-border);
}
.meta-description-item {
  display: flex;
  align-items: flex-start;
  overflow: hidden;
}
.meta-descriptions-table .meta-description-item {
  position: relative;
  align-items: center;
  border-left: 1px solid var(--rmx-border);
  border-bottom: 1px solid var(--rmx-border);
  height: 100%;
}
.meta-descriptions-table .meta-description-item:last-child {
  border-right: 1px solid var(--rmx-border);
  margin-right: -1px;
}
.meta-descriptions-table .meta-description-item:last-child:after {
  content: "";
  position: absolute;
  right: -1px;
  width: 1px;
  height: 100%;
  background: var(--rmx-border);
}
.meta-description-item-block {
  display: block;
}
.meta-descriptions-table .meta-description-item-block {
  display: flex;
}
.meta-descriptions-vertical .meta-description-item {
  flex-direction: column;
  overflow: hidden;
}
.meta-description-label {
  position: relative;
  color: var(--rmx-text-2);
  font-size: 14px;
  line-height: 22px;
  max-width: 11.5em;
  padding-right: 0.5em;
  margin-right: 0.5em;
}
.meta-descriptions-ellipsis .meta-description-label:after {
  content: ":";
  position: absolute;
  right: 0;
}
.meta-description-item-block .meta-description-label {
  display: inline;
}
.meta-descriptions-table .meta-description-label {
  display: block;
  width: var(--rmx-meta-label-width);
  padding: var(--rmx-meta-padding);
  margin: 0;
}
.meta-descriptions-table .meta-description-label:after {
  content: none;
}
.meta-descriptions-vertical .meta-description-label {
  max-width: unset;
}
.meta-descriptions-vertical.meta-descriptions-table .meta-description-label {
  width: 100%;
}
.meta-description-content {
  font-size: 14px;
  line-height: 22px;
  color: var(--rmx-text-1);
  flex: 1;
}
.meta-descriptions-ellipsis .meta-description-label,
.meta-descriptions-ellipsis .meta-description-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.meta-description-item-block .meta-description-content {
  display: inline;
  white-space: normal;
}
.meta-descriptions-table .meta-description-content {
  display: block;
  padding: var(--rmx-meta-padding);
  border-left: 1px solid var(--rmx-border);
  background: white;
  min-height: 38px;
  height: 100%;
}
.meta-description-content.meta-description-content-max-line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--rmx-meta-max-line);
  overflow: hidden;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.meta-descriptions-vertical .meta-description-content {
  max-width: unset;
  width: 100%;
}
.meta-descriptions-vertical.meta-descriptions-table .meta-description-content {
  border-left: none;
  border-top: 1px solid var(--rmx-border);
}
.meta-description-action {
  cursor: pointer;
  color: var(--rmx-primary-color);
}
</style>
