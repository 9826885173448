export default {
  registerAuditDetail: {
    title: "用户详情",
    width: "m",
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/registerAudit/dialog/RegisterAuditDetail"
      ),
  },
};
