export function generateTree(
  list = [],
  { key = "key", pId = "pId", addMark = false } = {}
) {
  const res = [];
  const map = {};
  let hasChildren = false;
  list.forEach((x) => {
    if (Array.isArray(x.children) && x.children.length > 0) {
      hasChildren = true;
    }
    map[x[key]] = x;
  });
  if (hasChildren) {
    return list;
  }
  list.forEach((x) => {
    if (addMark && x.isParent === undefined) {
      x.isParent = false;
      x.isLeaf = true;
    }
    const parent = map[x[pId]];
    if (parent) {
      if (!parent.children) {
        if (addMark) {
          parent.isParent = true;
          parent.isLeaf = false;
        }
        parent.children = [];
      }
      parent.children.push(x);
    } else {
      res.push(x);
    }
  });
  return res;
}

export function flattenTree(tree, result = []) {
  for (const it of tree) {
    if (it) {
      result.push(it);
      if (it.children) {
        flattenTree(it.children, result);
      }
    }
  }
  return result;
}

export function findNodeAndParentsBy(tree, func = () => true) {
  const stack = [];
  for (const it of tree) {
    stack.push({ node: it, parents: [] });
  }
  while (stack.length > 0) {
    const { node, parents } = stack.pop();
    if (func(node)) {
      parents.push(node);
      return parents;
    }
    if (node.children) {
      for (const it of node.children) {
        stack.push({ node: it, parents: parents.concat(node) });
      }
    }
  }
  return [];
}

export function intersect(...lists) {
  const valid = lists.filter((arr) => Array.isArray(arr) && arr.length > 0);
  if (valid.length === 0) {
    return [];
  }
  const [a, ...rest] = valid;
  return a.filter((v) => rest.every((arr) => arr.includes(v)));
}

export function subtract(a, b) {
  if (!Array.isArray(a)) return [];
  if (!Array.isArray(b)) return a;
  return a.filter((x) => !b.includes(x));
}

export function pickGroupedIds(dataList = []) {
  if (!dataList || dataList.length === 0) {
    return { list: [], size: 0 };
  }
  let list = [];
  let tableMap = {};
  for (const it of dataList) {
    if (!tableMap[it.tableId]) {
      tableMap[it.tableId] = [];
      list.push({ tableId: it.tableId, ids: tableMap[it.tableId] });
    }
    tableMap[it.tableId].push(it.archId || it.id);
  }
  return { list, size: dataList.length };
}

export function pickGroupedIdsByAttr(dataList, attrs = []) {
  if (!dataList || dataList.length === 0) {
    return [];
  }
  let list = [];
  let dataMap = {};
  for (const it of dataList) {
    const dataKey = attrs.map((a) => it[a]).join("_");
    if (!dataMap[dataKey]) {
      dataMap[dataKey] = [];
      const item = { ids: dataMap[dataKey] };
      attrs.forEach((a) => (item[a] = it[a]));
      list.push(item);
    }
    dataMap[dataKey].push(it.archId || it.id);
  }
  return list;
}

export function compatBool(val) {
  if (val === null || val === undefined) return undefined;
  return +!!val;
}

const EXP_JSON_BIGINT = /([[:]*)\s*([-+Ee0-9.]{16,})\s*([,}\]])/g;
export function safeJSONString(str) {
  if (!str) return str;
  return str.replace(EXP_JSON_BIGINT, '$1"$2"$3');
}
export function safeJSONParse(str) {
  return JSON.parse(safeJSONString(str));
}

export function simpleDeepClone(data) {
  return JSON.parse(JSON.stringify(data));
}

export function uniqueArray(arr, field, keepLast = false) {
  if (!field) {
    return [...new Set([...arr])];
  }
  const tmp = {};
  const callback = (x) => tmp && (tmp[x[field]] = x);
  if (keepLast) {
    arr.forEach(callback);
  } else {
    for (let i = arr.length - 1; i > -1; i--) {
      callback(arr[i]);
    }
  }
  return Object.values(tmp);
}

export function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    return {};
  }
}
