export default {
  addFieldAsso: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/AddFieldAsso"
      ),
  },
};
