export default {
  usingStatisticChart: {
    title: "查看统计图表",
    width: "l",
    footer: null,
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "statistic" */ "@/mods/statistic/usingStatistic/dialog/UsingStatisticChart"
      ),
  },
  usingStatisticChartLine: {
    title: "查看统计图表",
    width: "l",
    footer: null,
    destroyOnClose: true,
    component: () =>
      import(
        /* webpackChunkName: "statistic" */ "@/mods/statistic/usingStatistic/dialog/UsingStatisticChartLine"
      ),
  },
};
