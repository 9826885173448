<template>
  <template v-if="canEnterChild">
    <popover-view
      v-if="switchable"
      placement="bottomLeft"
      trigger="click"
      v-model:visible="popupVisible"
    >
      <div
        class="back-unit-chooser back-unit-chooser-clickable"
        :class="{ 'back-unit-chooser-full': fullHeader }"
      >
        <div class="back-unit-chooser-title" :title="current?.title">
          {{ current?.title }}
        </div>
        <down-outlined class="back-unit-chooser-down" />
      </div>
      <template #content>
        <div class="back-unit-chooser-content">
          <a-input-search
            :placeholder="$t('hint.keyword')"
            v-model:value="searchValue"
            @search="onSearch"
          />
          <base-tree
            tree-padding="16px 0"
            :auto-process="false"
            :list="tree"
            height="300px"
            v-model:selected="selection"
            v-model:expanded="expanded"
          ></base-tree>
          <div class="back-unit-chooser-footer">
            <act-button
              type="primary"
              size="small"
              :disabled="selection.length !== 1"
              @action="onSelect"
            >
              {{ $t("actions.confirm") }}
            </act-button>
          </div>
        </div>
      </template>
    </popover-view>
    <template v-else>
      <div
        class="back-unit-chooser"
        :class="{ 'back-unit-chooser-full': fullHeader }"
      >
        <div class="back-unit-chooser-title" :title="current?.title">
          {{ current?.title }}
        </div>
        <div
          class="back-unit-chooser-back"
          :title="$t('actions.back')"
          @click="onBack"
        >
          <common-icon icon="return" />
        </div>
      </div>
    </template>
  </template>
</template>

<script>
import { useBackUnit } from "@/components/back/backNav";
import { DownOutlined } from "@ant-design/icons-vue";
import PopoverView from "@/components/common/action/PopoverView";
import BaseTree from "@/components/common/list/BaseTree";
import ActButton from "@/components/common/action/ActButton";
import CommonIcon from "@/components/icon/CommonIcon";
export default {
  name: "BackUnitChooser",
  components: { CommonIcon, ActButton, BaseTree, PopoverView, DownOutlined },
  props: {
    fullHeader: { type: Boolean },
  },
  setup() {
    const {
      popupVisible,
      switchable,
      canEnterChild,
      tree,
      selection,
      expanded,
      current,
      searchValue,
      onSelect,
      onBack,
      onSearch,
    } = useBackUnit();
    return {
      popupVisible,
      expanded,
      switchable,
      canEnterChild,
      tree,
      selection,
      current,
      searchValue,
      onSelect,
      onBack,
      onSearch,
    };
  },
};
</script>

<style scoped>
.back-unit-chooser {
  display: flex;
  align-items: center;
  max-width: 240px;
  --drop-icon-color: var(--rmx-text-3);
}
.back-unit-chooser:not(.back-unit-chooser-full) {
  margin-right: 24px;
  padding-right: 24px;
  border-right: 1px solid var(--rmx-split);
}
.back-unit-chooser-full {
  color: white;
  padding-right: 40px;
  --drop-icon-color: white;
}
.back-unit-chooser-title {
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  margin-right: 8px;
  cursor: default;
}
.back-unit-chooser-clickable .back-unit-chooser-title {
  cursor: pointer;
}
.back-unit-chooser-clickable:hover {
  opacity: 0.75;
}
.back-unit-chooser-footer {
  display: flex;
  justify-content: flex-end;
}
.back-unit-chooser-back {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--rmx-primary-color-3);
  color: white;
  padding: 5px 9px;
  border-radius: 16px;
  font-size: 14px;
  cursor: pointer;
}
.back-unit-chooser-back:hover {
  background: var(--rmx-primary-color-4);
}
.back-unit-chooser-full .back-unit-chooser-back {
  background: #ffffff4d;
}
.back-unit-chooser-full .back-unit-chooser-back:hover {
  background: #ffffff6b;
}
.back-unit-chooser-down {
  font-size: 12px;
  color: var(--drop-icon-color);
}
</style>
