export default {
  whiteUserEdit: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "system_dialog" */ "@/mods/system/securityManage/dialog/WhiteUserEdit"
      ),
  },
};
