<template>
  <overflow-view
    class="grouped-toolbar"
    :class="{ 'grouped-toolbar-narrow': narrow }"
    item-key="action"
    :items="displayOperations"
    :enabled="shrinkEnabled"
  >
    <template #item="{ item: op }">
      <div v-if="op.type === 'divider'" class="grouped-toolbar-divider"></div>
      <act-button
        v-else-if="!Array.isArray(op.children)"
        :type="op.type || defaultType"
        :disabled="checkDisabled(op)"
        :title="opTips(op)"
        :size="btnSize"
        :narrow="narrow"
        :debounce="actionDebounce"
        @action="onAction(op)"
      >
        {{ opLabel(op.label) }}
      </act-button>
      <dropdown-menus
        v-else
        :operations="op.children"
        :disabled="checkDisabled(op)"
        :extra-state="extraState"
        @action="onAction"
      >
        <act-button
          :type="op.type || defaultType"
          :disabled="checkDisabled(op)"
          :title="opTips(op)"
          :size="btnSize"
          :narrow="narrow"
        >
          {{ opLabel(op.label) }}
          <down-outlined class="grouped-toolbar-dropdown" />
        </act-button>
      </dropdown-menus>
    </template>
    <template #overflow="{ items }">
      <dropdown-menus
        :operations="items"
        :size="btnSize"
        :extra-state="extraState"
        @action="onAction"
      >
        <act-button :type="defaultType" narrow>
          <span class="grouped-toolbar-ellipsis">
            <ellipsis-icon />
          </span>
        </act-button>
      </dropdown-menus>
    </template>
  </overflow-view>
</template>

<script>
import { computed, toRefs } from "vue";
import { DEFAULT_ACTION_DEBOUNCE, UI_SIZE } from "@/conf/constants";
import { useToolAction } from "@/components/common/shared/actionInternal";
import { DownOutlined } from "@ant-design/icons-vue";
import ActButton from "@/components/common/action/ActButton";
import OverflowView from "@/components/common/display/OverflowView";
import DropdownMenus from "@/components/common/action/DropdownMenus";
import EllipsisIcon from "@/components/icon/EllipsisIcon";

export default {
  name: "GroupedToolbar",
  emits: ["action"],
  components: {
    EllipsisIcon,
    DropdownMenus,
    OverflowView,
    ActButton,
    DownOutlined,
  },
  props: {
    operations: { type: Array, default: () => [] },
    defaultType: { type: String },
    extraState: { type: Object, default: () => ({}) },
    shrinkEnabled: { type: Boolean },
    btnSize: { type: String, default: "m", validator: (v) => v in UI_SIZE },
    narrow: { type: Boolean },
    useLocale: { type: Boolean },
    actionDebounce: { type: Number, default: DEFAULT_ACTION_DEBOUNCE },
  },
  setup(props, { emit }) {
    const { operations, extraState, useLocale } = toRefs(props);
    const { opLabel, opTips, checkDisabled } = useToolAction(
      useLocale,
      extraState
    );
    const displayOperations = computed(() =>
      operations.value.flatMap((x, i, arr) => {
        if (Array.isArray(x)) {
          if (i === arr.length - 1) return [...x];
          return [...x, { type: "divider", action: `d${i}` }];
        }
        return x;
      })
    );
    const onAction = (e) => emit("action", e?.action);
    return { displayOperations, opLabel, opTips, checkDisabled, onAction };
  },
};
</script>

<style scoped>
.grouped-toolbar {
  display: flex;
  align-items: center;
  gap: 10px;
}
.grouped-toolbar-narrow {
  gap: 6px;
}
.grouped-toolbar-divider {
  display: block;
  width: 1px;
  margin: 0 4px;
  background: var(--rmx-split);
  height: 32px;
}
.grouped-toolbar-dropdown {
  font-size: 10px;
}
.grouped-toolbar-ellipsis {
  display: flex;
  font-size: 18px;
  line-height: 1;
}
.grouped-toolbar :deep(.ant-btn-link) {
  padding: 4px;
}
</style>
