<template>
  <div
    class="base-tree rmx-tree rmx-scrollbar"
    :class="{ 'rmx-tree-bordered': bordered }"
    :style="[treeStyle]"
  >
    <a-tree
      show-icon
      block-node
      :checkable="checkAble"
      :show-line="{ showLeafIcon: false }"
      :tree-data="treeData"
      :load-data="onLoadTree"
      :height="treeHeight"
      :disabled="disabled"
      :check-strictly="checkStrictly"
      v-model:expanded-keys="expandedKeys"
      v-model:selected-keys="selectedKeys"
      v-model:checked-keys="checkedKeys"
      @select="onNodeSelected"
      @check="onNodeChecked"
    >
      <template #title="{ title, data }">
        <template v-if="$slots.title">
          <slot name="title" :title="title" :data="data"></slot>
        </template>
        <div v-else class="base-tree-node" :title="title">
          <div class="base-tree-title">{{ title }}</div>
        </div>
      </template>
    </a-tree>
  </div>
</template>

<script>
import {
  computed,
  nextTick,
  onMounted,
  ref,
  toRefs,
  watch,
  watchEffect,
} from "vue";
import {
  findNodeAndParentsBy,
  flattenTree,
  generateTree,
  simpleDeepClone,
} from "@/util/dataUtil";
import {
  EMITS_ABSTRACT_TREE,
  PROPS_ABSTRACT_TREE,
} from "@/components/common/shared/compAttrs";
import { useCachedProps } from "@/components/common/shared/compInternal";

export default {
  name: "BaseTree",
  emits: EMITS_ABSTRACT_TREE,
  props: {
    ...PROPS_ABSTRACT_TREE,
    height: { type: [String, Number] },
    treePadding: { type: String },
  },
  setup(props, { emit, expose }) {
    const propRefs = toRefs(props);
    const { makeRefProp } = useCachedProps(propRefs, { emit });
    const {
      keyConf,
      list,
      asyncLoad,
      loadOnMounted,
      treePadding,
      height,
      checkAble,
      checkStrictly,
      value,
      autoProcess,
    } = propRefs;
    const makeNodeProp = (name) =>
      computed(() => keyConf.value?.[name] || name);
    const treeData = ref([]);
    const nodeKey = makeNodeProp("key");
    const nodePId = makeNodeProp("pId");
    const nodeValue = makeNodeProp("value");
    const expandedKeys = makeRefProp("expanded", []);
    const selectedKeys = makeRefProp("selected", []);
    const checked = makeRefProp("checked", []);
    const checkedKeys = computed({
      get() {
        if (checkStrictly.value) {
          return {
            checked: checked.value,
            halfChecked: [],
          };
        } else {
          return checked.value;
        }
      },
      set(val) {
        if (checkStrictly.value) {
          checked.value = val.checked;
        } else {
          checked.value = val;
        }
      },
    });

    const treeStyle = computed(() => {
      const style = {};
      if (treePadding.value) {
        style.padding = treePadding.value;
      }
      if (height.value) {
        style.height = height.value;
      }
      return style;
    });

    const treeHeight = computed(() => {
      if (!height.value) return undefined;
      const tmp = Number.parseInt(height.value);
      const pad = treePadding.value ? Number.parseInt(treePadding.value) : 16;
      return tmp - pad * 2 - 2;
    });

    const gt = (list) =>
      generateTree(list, {
        key: nodeKey.value,
        pId: nodePId.value,
        addMark: true,
      });
    const processNode = (node) => {
      node.isLeaf = !node.isParent;
    };
    const openNode = (n) => {
      const k = n[nodeKey.value];
      if (!expandedKeys.value.includes(k)) {
        expandedKeys.value.push(k);
      }
    };
    const selectNode = (node, trigger) => {
      if (!node) return;
      selectedKeys.value = [node[nodeKey.value]];
      if (trigger) {
        emit("nodeSelect", { target: node, trigger: "manual" });
      }
    };
    const loadBySelectNode = (node) => {
      loadNodeChildren(node).then(({ byLoad }) => {
        openNode(node);
        if (byLoad) emitLoad(node);
      });
    };
    const emitLoad = (target, trigger = "async") => {
      emit("loaded", {
        target,
        trigger,
        isRoot: !target,
        expandNode,
        chooseNode,
        expandNodesPath,
        chooseNodesPath,
      });
    };
    const loadNodeChildren = (n, force) =>
      new Promise((resolve) => {
        if (!n || n.isLeaf) {
          resolve({});
          return;
        }
        if (!n.children || force) {
          const loader = asyncLoad.value;
          if (!loader) return;
          loader(n).then((list) => {
            if (Array.isArray(list) && list.length > 0) {
              list.forEach((n) => processNode(n));
            }
            n.children = list;
            treeData.value = [...treeData.value];
            resolve({ byLoad: true });
          });
        } else {
          resolve({ byLoad: false });
        }
      });
    const loadNodes = (node, forceLoad) =>
      new Promise((resolve) => {
        if (!node) {
          const loader = asyncLoad.value;
          if (!loader) {
            resolve();
            return;
          }
          loader().then((list) => {
            if (!Array.isArray(list)) return;
            list.forEach((n) => processNode(n));
            if (list.length === 1 && !list[0].children) {
              loader(list[0]).then((children) => {
                children.forEach((n) => processNode(n));
                treeData.value = gt([list[0], ...children]);
                expandFirstLevel();
                emitLoad();
              });
            } else {
              treeData.value = list;
              emitLoad();
            }
            resolve();
          });
          return;
        }
        loadNodeChildren(node.dataRef, forceLoad).then(({ byLoad }) => {
          if (byLoad) emitLoad(node.dataRef);
          resolve();
        });
      });
    const onLoadTree = (node) => loadNodes(node);
    const onNodeSelected = (id, { node }) => {
      loadBySelectNode(node.dataRef);
      emit("nodeSelect", { node, target: node.dataRef, trigger: "select" });
    };
    const onNodeChecked = (checked, e) => {
      const v = e?.checkedNodes.map((x) => x[nodeValue.value]);
      emit("update:value", v);
      emit("change", v);
    };
    const load = () => {
      if (asyncLoad.value) {
        expandedKeys.value = [];
        loadNodes(undefined, true);
      }
    };
    const expandFirstLevel = () => {
      const tmp = treeData.value;
      if (tmp.length !== 1) return;
      nextTick(() => {
        openNode(tmp[0]);
        if (!asyncLoad.value) {
          expandedKeys.value = [...expandedKeys.value];
        }
      });
    };
    const traversePath = (callback, ids, children = [], level = 0) =>
      new Promise((resolve) => {
        if (level > ids.length) {
          resolve({ level });
          return;
        }
        const n = children.find((x) => x[nodeKey.value] === ids[level]);
        if (!n) {
          resolve({ level });
          return;
        }
        loadNodeChildren(n).then(() => {
          callback(n);
          traversePath(callback, ids, n.children, level + 1).then((r) => {
            if (r.last) {
              resolve(r);
            } else {
              resolve({ ...r, last: n });
            }
          });
        });
      });
    const _expandNode = (id) => {
      const k = nodeKey.value;
      const path = findNodeAndParentsBy(treeData.value, (x) => x[k] === id);
      if (path.length > 0) {
        const ids = path.filter((x) => !x.isLeaf).map((x) => x[k]);
        expandedKeys.value = [
          ...new Set([...(expandedKeys.value || []), ...ids]),
        ];
      }
      return path;
    };
    const expandNodesPath = (ids = []) => {
      return traversePath((n) => openNode(n), ids, treeData.value);
    };
    const expandNode = (id) => {
      _expandNode(id);
    };

    const chooseNode = (id, trigger) => {
      const p = _expandNode(id);
      const node = p[p.length - 1];
      if (trigger) loadBySelectNode(node);
      selectNode(node, trigger);
    };
    const chooseNodesPath = (ids = [], trigger, chooseLast) =>
      new Promise((resolve) => {
        expandNodesPath(ids).then((res) => {
          nextTick(() => {
            if (res.level === ids.length || chooseLast) {
              if (trigger) loadBySelectNode(res.last);
              selectNode(res.last, trigger);
            }
            resolve({ ...res });
          });
        });
      });
    const findNodeAndParents = (func = () => false) => {
      return findNodeAndParentsBy(treeData.value, (x) => func(x));
    };
    const getTreeData = (flatten = false) => {
      if (flatten) {
        return simpleDeepClone(flattenTree(treeData.value));
      }
      return simpleDeepClone(treeData.value);
    };
    watch(
      () => list.value,
      (treeList) => {
        treeList.forEach((n) => processNode(n));
        const shouldProcess = asyncLoad.value || !autoProcess.value;
        if (shouldProcess) {
          treeData.value = treeList;
        } else {
          treeData.value = gt(treeList);
          expandFirstLevel();
        }
        nextTick(() => {
          emitLoad(undefined, "set");
        });
      },
      { immediate: true }
    );
    watchEffect(() => {
      if (checkAble.value && Array.isArray(value.value) && !asyncLoad.value) {
        checked.value = list.value
          .filter((x) => value.value.includes(x[nodeValue.value]))
          .map((x) => x[nodeKey.value]);
      }
    });
    onMounted(() => {
      if (loadOnMounted.value && asyncLoad.value && list.value.length === 0) {
        loadNodes();
      }
    });
    expose({
      load,
      expandNode,
      chooseNode,
      expandNodesPath,
      chooseNodesPath,
      findNodeAndParents,
      getTreeData,
    });
    return {
      treeData,
      expandedKeys,
      selectedKeys,
      checkedKeys,
      treeStyle,
      treeHeight,
      onLoadTree,
      onNodeSelected,
      onNodeChecked,
    };
  },
};
</script>

<style scoped>
.base-tree {
  overflow: auto;
  padding: var(--rmx-pad-m);
}
.rmx-tree-bordered {
  border: 1px solid var(--rmx-border);
  border-radius: var(--rmx-border-radius);
}
.base-tree-node {
  display: flex;
  align-items: center;
}
.base-tree-title {
  flex: 1;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
