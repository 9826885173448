<template>
  <div
    class="space-view"
    :class="{
      'space-view-default': !useOuterStyle,
      'space-view-grid': grid,
      'space-view-flex': !grid,
      'space-view-equal-parts': equalParts,
    }"
    :style="style"
  >
    <slot></slot>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { UI_DIRECTION } from "@/conf/constants";
import { useSpaceViewStyle } from "@/components/common/shared/styleInternal";

export default {
  name: "SpaceView",
  props: {
    contentPadding: { type: String },
    grid: { type: Boolean },
    direction: { type: String, validator: (v) => v in UI_DIRECTION },
    gap: { type: String },
    column: { type: Number },
    columnWidths: { type: Array, default: () => [] },
    row: { type: Number },
    equalParts: { type: Boolean },
    useOuterStyle: { type: Boolean },
  },
  setup(props) {
    const style = useSpaceViewStyle(toRefs(props));
    return { style };
  },
};
</script>

<style scoped>
.space-view {
  padding: var(--space-view-padding);
}
.space-view-default {
  --space-view-padding: var(--rmx-pad-m);
  --space-view-direction: column;
}
.space-view-flex {
  display: flex;
  flex-direction: var(--space-view-direction);
  gap: var(--space-view-gap);
}
.space-view-grid {
  display: grid;
  gap: var(--space-view-gap);
  grid-template-columns: var(--space-view-column-temp);
}
.space-view-grid.space-view-default {
  --space-view-gap: var(--rmx-pad-m);
  --space-view-column: 1;
  --space-view-part-size: auto;
  --space-view-column-temp: repeat(
    var(--space-view-column),
    var(--space-view-part-size)
  );
}
.space-view-flex.space-view-equal-parts > :deep(*) {
  flex: 1;
}
</style>
