import { STORAGE_PREFIX } from "@/conf";
import { snakeCase } from "@/util/strUtil";

const storageState = (state, name, value, storeKey) => {
  const key = STORAGE_PREFIX + (storeKey || snakeCase(name));
  localStorage[key] = typeof value === "string" ? value : JSON.stringify(value);
  state[name] = value;
};

const removeState = (state, name, defVal, storeKey) => {
  const key = STORAGE_PREFIX + (storeKey || snakeCase(name));
  delete localStorage[key];
  state[name] = defVal;
};

export default {
  updateLanguage(state, lang) {
    storageState(state, "language", lang);
  },
  updateSkin(state, skin) {
    storageState(state, "skin", skin);
  },
  updateUsePortal(state, usePortal) {
    storageState(state, "usePortal", usePortal);
  },
  setLogin(state, { accessToken, type, expireTime }) {
    if (!expireTime) {
      expireTime = Date.now() + 1000 * 60 * 30;
    }
    storageState(state, "loginExpire", expireTime);
    storageState(state, "accessToken", accessToken);
    storageState(state, "loginType", type);
  },
  setUserInfo(state, user) {
    storageState(state, "user", user, "login_user");
  },
  setUserCounts(state, counts) {
    state.userCounts = Object.assign({}, state.userCounts, counts);
  },
  updateRememberInfo(state, rememberInfo = {}) {
    storageState(state, "rememberInfo", rememberInfo);
  },
  logout(state) {
    removeState(state, "loginExpire", 0);
    removeState(state, "accessToken", "");
    removeState(state, "user", {}, "login_user");
    removeState(state, "loginType", "");
    state.provisionalInfo = {};
    state.md5Username = "";
    state.userCounts = {};
  },
  syncUserState(
    state,
    {
      user = {},
      accessToken = "",
      loginType = "",
      provisionalInfo = {},
      userCounts = {},
    } = {}
  ) {
    state.user = user;
    state.accessToken = accessToken;
    state.loginType = loginType;
    state.provisionalInfo = provisionalInfo;
    state.md5Username = "";
    state.userCounts = userCounts;
  },
  updateSystemConfig(state, conf) {
    state.systemConfig = conf;
  },
  updateRememberPsw(state, data) {
    storageState(state, "rememberPsw", data);
  },
  clearRememberPsw(state) {
    removeState(state, "rememberPsw", {});
  },
  updateRoleInfo(state, { username, data }) {
    let obj = JSON.parse(JSON.stringify(state.roleInfo));
    obj[username] = data;
    storageState(state, "roleInfo", obj);
  },
  clearRoleInfo(state, { username }) {
    state.roleInfo[username] = undefined;
    localStorage[STORAGE_PREFIX + "role_info"] = JSON.stringify(state.roleInfo);
  },
  resetRoleInfo(state) {
    localStorage[STORAGE_PREFIX + "role_info"] = JSON.stringify({});
    state.roleInfo = {};
  },
  updateProvisionalInfo(state, data) {
    state.provisionalInfo = data;
  },
  updateModAvailable(state, v) {
    state.modAvailable = v;
  },
  setLoginType(state, { type }) {
    storageState(state, "loginType", type);
  },
  setRolesList(state, { rolesList }) {
    localStorage[STORAGE_PREFIX + "roles_list"] = JSON.stringify(rolesList);
    state.rolesList = rolesList;
  },
  updateSearchHistory(state, history) {
    localStorage[STORAGE_PREFIX + "history"] = JSON.stringify(history);
    state.searchHistory = history;
  },
  updateCarDefaultNum(state, number = 4) {
    storageState(state, "cardefaultNum", number);
  },
};
