<template>
  <div class="photo-gallery">
    <a-image-preview-group
      :preview="{ visible: inPreview, onVisibleChange, current }"
    >
      <div
        v-for="(img, i) in list"
        :key="img.id"
        class="photo-gallery-item"
        :class="{ 'photo-gallery-item-error': img.error }"
      >
        <div v-if="img.loading" class="photo-gallery-holder">
          <a-progress type="circle" :width="80" :percent="img.progress" />
        </div>
        <div v-else class="photo-gallery-holder">
          <a-image :style="{ display: 'none' }" :src="getUrl(img)"></a-image>
          <img class="photo-gallery-image" :src="getUrl(img)" alt="" />
          <div class="photo-gallery-tool-wrap">
            <div
              v-if="!img.error"
              class="photo-gallery-tool"
              :title="$t('actions.preview')"
              @click="onPreview(img, i)"
            >
              <eye-outlined />
            </div>
            <div
              v-if="canDownload(img)"
              class="photo-gallery-tool"
              :title="$t('actions.download')"
              @click="onDownload(img)"
            >
              <download-outlined />
            </div>
            <a-popconfirm
              v-if="canRemove(img)"
              :title="deleteHint"
              :ok-text="$t('actions.yes')"
              :cancel-text="$t('actions.no')"
              @confirm="$emit('remove', img)"
            >
              <div class="photo-gallery-tool" :title="$t('actions.delete')">
                <delete-outlined />
              </div>
            </a-popconfirm>
          </div>
        </div>
      </div>
    </a-image-preview-group>
    <slot></slot>
  </div>
</template>

<script>
import { ref, toRefs } from "vue";
import { useGallery } from "@/components/common/shared/fileCompInternal";
import { getFileUrlFromRelative } from "@/util/fileUtil";
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "PhotoGallery",
  props: {
    list: { type: Array, default: () => [] },
    removable: { type: Boolean },
    downloadable: { type: Boolean },
    customEvents: { type: Object, default: () => ({}) },
  },
  emits: ["remove", "download"],
  components: { EyeOutlined, DeleteOutlined, DownloadOutlined },
  setup(props, { emit }) {
    const propRefs = toRefs(props);
    const { deleteHint, canRemove, canDownload, onDownload } = useGallery(
      "image",
      propRefs,
      { emit }
    );
    const inPreview = ref(false);
    const current = ref(0);
    const getUrl = (f) => getFileUrlFromRelative(f.previewUrl || f.url, true);
    const onPreview = (item, i) => {
      if (item) {
        inPreview.value = true;
        current.value = i;
      }
    };
    const onVisibleChange = (v) => {
      inPreview.value = v;
    };
    return {
      current,
      inPreview,
      deleteHint,
      canRemove,
      canDownload,
      getUrl,
      onDownload,
      onPreview,
      onVisibleChange,
    };
  },
};
</script>

<style scoped>
.photo-gallery {
  display: flex;
  gap: 24px;
  min-height: 104px;
  flex-wrap: wrap;
}
.photo-gallery-item {
  display: block;
  width: 104px;
  height: 104px;
  border: 1px solid var(--rmx-split);
  padding: 8px;
}
.photo-gallery-item-error {
  border: 1px solid var(--rmx-error-color);
  background: #ff5c934d;
}
.photo-gallery-holder {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.photo-gallery-tool-wrap {
  display: flex;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000007f;
  transition: all 0.5s;
  align-items: center;
  justify-content: space-around;
  backdrop-filter: blur(2px);
}
.photo-gallery-item:hover .photo-gallery-tool-wrap {
  opacity: 1;
}
.photo-gallery-image {
  max-height: 100%;
}
.photo-gallery-tool {
  font-size: 16px;
  color: white;
  cursor: pointer;
  padding: 4px;
  line-height: 1;
  border-radius: 50%;
}
.photo-gallery-tool:hover {
  background: #ffffff33;
}
</style>
