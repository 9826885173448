import {
  getCurrentInstance,
  computed,
  reactive,
  watch,
  isVNode,
  ref,
} from "vue";

export function useCachedProps(propRefs, context) {
  if (!context) context = getCurrentInstance();
  const { emit } = context;
  const cachedData = reactive({});
  const setter = (prop) => (val) => {
    emit(`update:${prop}`, val);
    cachedData[prop] = val;
  };
  const makeRefProp = (prop, def) => {
    if (def !== undefined) cachedData[prop] = def;
    return computed({
      get() {
        const v = propRefs[prop].value;
        return v !== undefined ? v : cachedData[prop];
      },
      set: setter(prop),
    });
  };
  const makeValProp = (prop, def, fillBack = true) => {
    if (def !== undefined) cachedData[prop] = def;
    if (fillBack) {
      watch(
        () => propRefs[prop].value,
        (newVal) => {
          cachedData[prop] = newVal;
        }
      );
    }
    return computed({
      get: () => propRefs[prop].value || cachedData[prop],
      set: setter(prop),
    });
  };
  return { makeRefProp, makeValProp };
}

export function flattenVNodes(vNodes) {
  const result = [];
  const traverse = (children) => {
    if (!Array.isArray(children)) return;
    children.forEach((child) => {
      if (isVNode(child)) {
        result.push(child);
        const st = child.component?.subTree;
        if (st) {
          result.push(st);
          traverse(st);
        }
        if (child.children) {
          traverse(child.children);
        }
      }
    });
  };
  traverse(vNodes);
  return result;
}

export function useCompChildren({ tag } = {}, context) {
  if (!context) context = getCurrentInstance();
  const children = ref([]);
  const refresh = () => {
    const nodes = flattenVNodes(context.subTree.children);
    if (tag) {
      children.value = nodes
        .filter((x) => x.type.name === tag)
        .map((x) => x?.component?.proxy);
    } else {
      children.value = nodes.map((x) => x?.component?.proxy);
    }
  };
  return { children, refresh };
}
