import BaseLayout from "@/components/core/BaseLayout";
export default [
  {
    path: "/portal",
    name: "BackPortal",
    component: BaseLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "",
        name: "BackPortal",
        component: () =>
          import(/* webpackChunkName: "demo" */ "@/views/portal/BackPortal"),
      },
    ],
  },
];
