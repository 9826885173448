export default {
  editInterfaceInfo: {
    okText: "actions.save",
    cancelText: "actions.cancel",
    width: "m",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/interfaceManage/dialog/EditInterfaceInfo"
      ),
  },
  customFields: {
    title: "自定义接口字段",
    footer: null,
    width: "xl",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/interfaceManage/dialog/CustomFieldsDialog"
      ),
  },
  editCustomFields: {
    okText: "actions.save",
    cancelText: "actions.cancel",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/interfaceManage/dialog/EditCustomFieldDIalog"
      ),
  },
  attachCheckList: {
    title: "附件校验规则列表",
    footer: null,
    width: "m",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/interfaceManage/dialog/AttachCheckListDialog"
      ),
  },
  editAttachRule: {
    okText: "actions.save",
    cancelText: "actions.cancel",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/interfaceManage/dialog/EditAttachRulesDialog"
      ),
  },
  specialStorageRules: {
    title: "设置特殊规则",
    okText: "actions.save",
    cancelText: "actions.cancel",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/interfaceManage/dialog/SpecialStorageRules"
      ),
  },
};
