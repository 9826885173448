export default {
  addArchTypeOrder: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/order/AddArchTypeOrderDialog"
      ),
  },
};
