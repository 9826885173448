<template>
  <div class="icons-toolbar">
    <template v-for="op in operations" :key="op.action">
      <icon-button
        :type="op.type"
        :checked="checked.includes(op.action)"
        :debounce="actionDebounce"
        @action="onAction(op)"
      />
    </template>
  </div>
</template>

<script>
import { DEFAULT_ACTION_DEBOUNCE } from "@/conf/constants";
import IconButton from "@/components/common/action/IconButton";
export default {
  name: "IconsToolbar",
  emits: ["action"],
  props: {
    operations: { type: Array, default: () => [] },
    checked: { type: Array, default: () => [] },
    actionDebounce: { type: Number, default: DEFAULT_ACTION_DEBOUNCE },
  },
  components: { IconButton },
  setup(props, { emit }) {
    const onAction = (e) => emit("action", e?.action);
    return { onAction };
  },
};
</script>

<style scoped>
.icons-toolbar {
  display: flex;
  gap: 8px;
}
</style>
