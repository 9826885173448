export default {
  selectFloder: {
    width: "s",
    okText: "actions.save",
    title: "选择目标文件夹",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/personalFileArea/dialog/SelectRemoveFloder"
      ),
  },
  addNewFloder: {
    title: "新建子文件夹",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/personalFileArea/dialog/DirNameChange"
      ),
  },
  rename: {
    title: "重命名文件夹",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/personalFileArea/dialog/DirNameChange"
      ),
  },
  addAuthUser: {
    title: "添加授权用户",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/personalFileArea/dialog/AuthUsersChange"
      ),
  },
};
