<template>
  <a-drawer
    v-model:visible="isVisible"
    :width="width"
    placement="right"
    :body-style="{ padding: '0' }"
    :header-style="{ background: '#f6f7fB' }"
    @after-visible-change="onVisibleChange"
  >
    <template #title>
      <div class="drawer-title">{{ displayTitle }}</div>
    </template>
    <suspense v-if="componentDef" @resolve="onLoaded">
      <component
        :is="componentDef"
        :key="view"
        ref="contentRef"
        v-bind="params"
      ></component>
    </suspense>
  </a-drawer>
</template>

<script>
import {
  ref,
  toRefs,
  defineAsyncComponent,
  nextTick,
  shallowRef,
  unref,
  watch,
  computed,
} from "vue";
import { useCachedProps } from "@/components/common/shared/compInternal";
import drawerView from "@/conf/drawerView";
import { i18n } from "@/conf/lang";
import { DRAWER_WIDTH } from "@/conf/constants";

const cached = {};

export default {
  name: "DrawerView",
  props: {
    params: { type: Object },
    view: { type: String },
    visible: { type: Boolean },
  },
  emits: ["update:visible"],
  setup(props, { emit }) {
    const propRefs = toRefs(props);
    const { view, params } = propRefs;
    const title = ref("");
    const width = ref(1100);
    const config = ref(null);
    const contentRef = ref(null);
    const componentDef = shallowRef(null);
    const { makeValProp } = useCachedProps(propRefs, { emit });
    const isVisible = makeValProp("visible", false, true);
    const displayTitle = computed(() => {
      return title.value ? i18n.global.t(title.value) : "";
    });
    const onVisibleChange = (visible) => {
      if (!visible) {
        isVisible.value = false;
      }
    };
    const process = (view) => {
      const comp = unref(view);
      if (!comp) return;
      const compKeys = comp.split(".");
      let conf;
      if (compKeys.length > 1) {
        let cur = drawerView;
        compKeys.forEach((x) => (cur = cur?.[x]));
        conf = cur;
      } else {
        conf = drawerView[comp];
      }
      if (!conf) return;
      if (!cached[comp]) {
        cached[comp] = defineAsyncComponent(conf.component);
      }
      title.value = conf.title;
      config.value = conf;
      width.value = DRAWER_WIDTH[conf.width] || conf.width || 1100;
      componentDef.value = cached[comp];
    };
    watch(
      () => unref(view),
      (view) => {
        process(view);
      },
      { immediate: true }
    );
    const handler = {};
    const onLoaded = () => {
      nextTick(() => {
        contentRef.value?.onViewShowed?.({ handler, params: unref(params) });
      });
    };
    watch(
      () => isVisible.value,
      (v) => {
        if (v) {
          nextTick(() => {
            contentRef.value?.onViewShowed?.({
              handler,
              params: unref(params),
            });
          });
        }
      }
    );
    return {
      width,
      displayTitle,
      isVisible,
      contentRef,
      componentDef,
      onVisibleChange,
      onLoaded,
    };
  },
};
</script>

<style scoped></style>
