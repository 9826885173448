export default {
  setSeparatorDialog: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/classify/SetSeparatorDialog"
      ),
  },

  addVersionDialog: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/AddVersionDialog"
      ),
  },
  addNewQuesDialog: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/classify/AddNewQuesDialog"
      ),
  },

  editTreeNodeParent: {
    width: "750px",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/classify/EditTreeNodeParent"
      ),
  },

  eleClick: {
    footer: null,
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/detailView/classify/EleClick"
      ),
  },
};
