<template>
  <iframe ref="iframe" :src="url" class="iframe-view" @load="onLoaded"></iframe>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from "vue";
import { useIframeBridge } from "@/components/common/shared/iframeInternal";

export default {
  name: "IframeView",
  emits: ["update:scrollTop", "update:scrollLeft", "message", "called"],
  props: {
    url: { type: String, default: "" },
    scrollTop: { type: Number },
    scrollLeft: { type: Number },
    bridge: { type: Boolean },
  },
  setup(props, { emit, expose }) {
    const { bridge, scrollTop, scrollLeft } = toRefs(props);
    const iframe = ref(null);
    const loadCount = ref(1);
    const sameOrigin = computed(() => {
      const c = loadCount.value;
      try {
        return !!iframe.value.contentDocument && !!c;
      } catch (e) {
        return false;
      }
    });
    const { onMessage, sendMessage } = useIframeBridge(iframe, bridge, {
      emit,
    });
    const scrollTo = (top, left) => {
      if (sameOrigin.value) {
        const doc = iframe.value.contentDocument.documentElement;
        doc.scrollLeft = left;
        doc.scrollTop = top;
      }
    };
    const onLoaded = () => {
      const win = iframe.value.contentWindow;
      loadCount.value++;
      if (sameOrigin.value) {
        const doc = iframe.value.contentDocument.documentElement;
        win.addEventListener("scroll", () => {
          emit("update:scrollTop", doc.scrollTop);
          emit("update:scrollLeft", doc.scrollLeft);
        });
      }
    };
    onMounted(() => {
      window.addEventListener("message", onMessage);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("message", onMessage);
    });
    watch(
      () => ({ left: scrollLeft.value, top: scrollTop.value }),
      (value) => scrollTo(value.top, value.left)
    );
    expose({ sendMessage });
    return { iframe, onLoaded };
  },
};
</script>

<style scoped>
.iframe-view {
  height: 100%;
  width: 100%;
  border: none;
}
</style>
