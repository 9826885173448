<template>
  <div
    class="back-logo-view"
    :class="{ 'back-logo-full': fullHeader }"
    :title="logoText"
    @click="onLogoClick"
  >
    <template v-if="fullHeader">
      <img v-if="hasLogoImg" class="back-logo-img" :src="logoSrc" alt="logo" />
      <div v-if="hasLogoText" class="back-logo-text">{{ logoText }}</div>
    </template>
    <template v-else>
      <img class="back-logo-img" :src="logoAside" alt="logo" />
    </template>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useSystemLogo } from "@/components/common/shared/globalInternal";

export default {
  name: "BackLogoView",
  props: {
    collapsed: { type: Boolean },
    fullHeader: { type: Boolean },
  },
  setup(props) {
    const { collapsed } = toRefs(props);
    const store = useStore();
    const router = useRouter();
    const { logoSrc, logoText, hasLogoImg, hasLogoText } =
      useSystemLogo("back");
    const systemConfig = computed(() => store.state.systemConfig || {});
    const logoAside = computed(() => {
      if (collapsed.value) {
        return systemConfig.value?.logoSmall || "logo_small.png";
      } else {
        return systemConfig.value.logo || "logo.png";
      }
    });
    const onLogoClick = () => {
      router.push("/portal");
    };
    return {
      logoAside,
      logoSrc,
      hasLogoImg,
      hasLogoText,
      logoText,
      onLogoClick,
    };
  },
};
</script>

<style scoped>
.back-logo-view {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.back-logo-view.back-logo-full {
  margin-right: 40px;
}
.back-logo-view.back-logo-full .back-logo-img {
  height: 36px;
}
.back-logo-view:not(.back-logo-full) .back-logo-img {
  max-width: 120px;
}
.back-logo-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  margin-left: 8px;
}
</style>
