import { i18n } from "@/conf/lang";
import { getFileUrlFromRelative, getRelativeFileUrl } from "@/util/fileUtil";

export function frontChannelId(orderId) {
  return (orderId || "").replaceAll("-", "_").toLowerCase();
}

export function getDate(timeStr, autoHideYear = false) {
  const date = new Date(timeStr);
  const now = new Date();
  const y =
    autoHideYear && date.getFullYear() === now.getFullYear()
      ? ""
      : date.getFullYear() + "-";
  let m = date.getMonth() + 1;
  m = m < 10 ? `0${m}-` : `${m}-`;
  let d = date.getDate();
  d = d < 10 ? `0${d}` : `${d}`;
  return `${y}${m}${d}`;
}

export function upperFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function lowerFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function getQueryStr(param = {}) {
  return Object.keys(param)
    .map((k) => {
      const v = param[k];
      const ek = encodeURIComponent(k);
      if (Array.isArray(v)) {
        return v.map((x) => `${ek}=${encodeURIComponent(x)}`).join("&");
      }
      return `${ek}=${encodeURIComponent(v)}`;
    })
    .join("&");
}

export function snakeCase(str) {
  if (!str) return str;
  return str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
}

const TAGS_NOT_ALLOWED = ["iframe", "script"];
const EXP_HTML_TAG = /<([a-z][a-z0-9]*)([^>]*?)(\/?)>/g;
const EXP_STYLE_ATTR = /style="([^"]*)"*|\\/i;
const EXP_SRC_ATTR = /src\s*=\s*['"]([^'"]+)['"]/i;
const EXP_HREF_ATTR = /href\s*=\s*['"]([^'"]+)['"]/i;
const EXP_G_SRC_ATTR = /src\s*=\s*['"]([^'"]+)['"]/gi;
const EXP_ALT_ATTR = /alt\s*=\s*['"]([^'"]+)['"]/i;
const EXP_NOT_ALLOW = new RegExp(
  TAGS_NOT_ALLOWED.map((x) => `<${x}[^>]*>(<\\/${x}>)?`).join("|"),
  "ig"
);
export function safeHtmlContent(html, { imgAlt = "" } = {}) {
  if (!html) return html;
  return html
    .replace(EXP_NOT_ALLOW, "")
    .replace(EXP_HTML_TAG, (g1, g2, g3, g4) => {
      let style = g3 ? EXP_STYLE_ATTR.exec(g3)?.[0] || "" : "";
      if (style && !style.endsWith('"')) style += '"';
      style = style ? ` ${style} ` : style;
      if (g2 === "img") {
        if (!g3) return `<${g2}${g4}>`;
        let src = EXP_SRC_ATTR.exec(g3)?.[1] || "";
        if (!src) return "";
        if (src.startsWith("@@/")) {
          src = getFileUrlFromRelative(src.substring(3), true);
        }
        const alt = EXP_ALT_ATTR.exec(g3)?.[1] || imgAlt;
        return `<img src="${src}" alt="${alt}" ${style}${g4}>`;
      } else if (g2 === "a") {
        if (!g3) return `<${g2}${g4}>`;
        let href = EXP_HREF_ATTR.exec(g3)?.[1] || "";
        let act = "";
        if (href.startsWith("@")) {
          act = ` data-action="${href.substring(1)}" `;
        } else {
          act = ` data-href="${href}" `;
        }
        return `<${g2}${act}${g4}>`;
      }
      return `<${g2}${style}${g4}>`;
    });
}

export function replaceHtmlSrcUrl(html, replaceFor = "write") {
  if (!html) return html;
  const replacer =
    replaceFor === "write"
      ? (g1, g2) => {
          const src = getRelativeFileUrl(g2 || "", true);
          const s = src !== g2 ? `@@/${src}` : src;
          return `src="${s}"`;
        }
      : (g1, g2) => {
          let src = g2 || "";
          if (src.startsWith("@@/")) {
            src = getFileUrlFromRelative(src.substring(3), true);
          }
          return `src="${src}"`;
        };
  return html.replaceAll(EXP_G_SRC_ATTR, replacer);
}

const EXP_ANY_HTML_TAG = /<[^>]+>/g;
const EXP_WHITESPACE = /^\s*$/;
const EXP_NEWLINE = /[\r\n]/g;
const EXP_SPACE = /[ \t]*(?:&nbsp;|\u00A0)[ \t]*/g;

export function isEmptyHtml(html) {
  const tmp = html
    .replace(EXP_ANY_HTML_TAG, "")
    .replace(EXP_NEWLINE, "")
    .replace(EXP_SPACE, "")
    .trim();
  return EXP_WHITESPACE.test(tmp);
}

const STORAGE_SIZE_UNIT = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
export function formatFileSize(size = 0) {
  if (!size) return "0B";
  const k = 1024;
  const i = Math.floor(Math.log(size) / Math.log(k));
  return (size / Math.pow(k, i)).toPrecision(3) + " " + STORAGE_SIZE_UNIT[i];
}

export function simpleTemplate(text, data) {
  if (!data || !text) return text;
  for (const k in data) {
    text = text.replace(`<%${k}%>`, data[k]);
  }
  return text;
}

export function compatMessageInfo(data) {
  const message = data?.message;
  const isObject = typeof message === "object";
  let title = (isObject ? message.name || message.code : message) ?? "";
  let content = (isObject ? message.sub?.name || message.sub?.code : "") ?? "";
  if (isObject) {
    title = simpleTemplate(title, message.val);
    content = simpleTemplate(content, message.sub?.val);
  }
  if (title.includes("##")) {
    const arr = title.split("##");
    title = arr[0];
    content = arr[1];
  }
  return { title, content };
}

export function formatList(list, type = "and") {
  const literal = i18n.global.t("hint.list.literal");
  const t = i18n.global.t(`hint.list.${type}`);
  return list.reduce(
    (o, x, i, arr) =>
      !i ? o + x : i === arr.length - 1 ? o + t + x : o + literal + x,
    ""
  );
}

export function strHashCode(str) {
  if (!str) return str;
  let hash = 1315423911;
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i);
    hash ^= (hash << 5) + c + (hash >> 2);
  }
  return (hash & 0x7fffffff).toString(16);
}
