export default {
  saveAppraised: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "dialog" */ "@/mods/front/myUtilization/dialog/AppraisedForm"
      ),
  },
};
