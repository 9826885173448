import { http } from "@/util/http";
import { httpExtSettingAuth } from "@/api/common";

//查询单位下档案库
export function httpOrgArchType(comid) {
  return http.post("home/advancedsearch/archtype/init", { comid });
}

const PERMISSION_MAP = {
  onlineprint: "onlinePrint",
  downloadsource: "downloadSource",
};
const frontConfigCache = {
  data: { permissions: { excerpt: false } },
  expire: 0,
};

/**
 * 获取前台的配置
 * @returns {Promise<{permissions: {excerpt: boolean},showPurpose:boolean,showUploadAttach:boolean}>}
 */
export function httpFrontConfig() {
  return new Promise((resolve) => {
    if (Date.now() < frontConfigCache.expire) {
      resolve(frontConfigCache.data);
    } else {
      const config = { permissions: {} };
      httpExtSettingAuth({ identify: "archuseconfig" }).then(
        ({ detailData } = {}) => {
          detailData.permissionData.forEach((x) => {
            const key = PERMISSION_MAP[x] || x;
            config.permissions[key] = true;
          });
          config.permissionData = detailData.permissionData;
          config.showPurpose = !!detailData.showPurpose;
          config.showUploadAttach = !!detailData.showUploadAttach;
          frontConfigCache.data = config;
          resolve(frontConfigCache.data);
        }
      );
    }
  });
}
