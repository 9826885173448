export default {
  informationEdit: {
    okText: "actions.save",
    cancelText: "actions.cancel",
    width: "l",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/informationManage/dialog/InformationEdit"
      ),
  },

  addChannel: {
    okText: "actions.save",
    cancelText: "actions.cancel",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/informationManage/dialog/AddChannel"
      ),
  },
  chooseCompany: {
    title: "选择单位",
    okText: "actions.determine",
    cancelText: "actions.cancel",
    width: "s",
    component: () =>
      import(
        /* webpackChunkName: "system" */ "@/mods/system/informationManage/dialog/ChooseCompany"
      ),
  },
};
