<template>
  <div
    class="user-info-header"
    :class="{ 'user-info-header-full': fullHeader }"
  >
    <div class="user-info-header-tools">
      <div class="user-info-header-act-item" @click="showNotification">
        <bell-filled class="user-info-header-icon" />
        <count-tag :count="notificationCount" dot></count-tag>
      </div>
    </div>
    <popover-view placement="bottomRight" v-model:visible="menuVisible">
      <div class="user-info-header-user">
        <user-avatar :user="user"></user-avatar>
        <down-outlined class="user-info-header-user-icon" />
      </div>
      <template #content>
        <user-popup-view v-model:visible="menuVisible" />
      </template>
    </popover-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DIALOGS, DRAWER_VIEW } from "@/conf/symbols";
import PopoverView from "@/components/common/action/PopoverView";
import UserAvatar from "@/components/user/UserAvatar";
import UserPopupView from "@/components/user/UserPopupView";
import { BellFilled, DownOutlined } from "@ant-design/icons-vue";
import CountTag from "@/components/common/display/CountTag";
export default {
  name: "UserInfoHeader",
  components: {
    CountTag,
    UserPopupView,
    UserAvatar,
    PopoverView,
    DownOutlined,
    BellFilled,
  },
  props: { fullHeader: { type: Boolean } },
  inject: { dialogs: DIALOGS, drawer: DRAWER_VIEW },
  data() {
    return { menuVisible: false };
  },
  computed: {
    ...mapState(["systemConfig", "user", "userCounts"]),
    todoCount() {
      return this.userCounts.todo || 0;
    },
    messageCount() {
      return this.userCounts.message || 0;
    },
    notificationCount() {
      return this.todoCount + this.messageCount;
    },
  },
  methods: {
    showNotification() {
      this.drawer?.openDrawer?.("notificationList", { entry: "todo" });
    },
  },
};
</script>

<style scoped>
.user-info-header {
  display: flex;
}
.user-info-header-user {
  display: flex;
  align-items: center;
  gap: 8px;
}
.user-info-header-tools {
  display: flex;
  align-items: center;
  margin-right: 32px;
  line-height: 1;
}
.user-info-header-act-item {
  display: flex;
  cursor: pointer;
}
.user-info-header-act-item + .user-info-header-act-item {
  margin-left: 24px;
}
.user-info-header-icon {
  font-size: 18px;
  color: var(--rmx-icon-2);
}
.user-info-header-full .user-info-header-user-icon,
.user-info-header-full .user-info-header-icon {
  color: white;
}
.user-info-header-icon:hover {
  color: var(--rmx-primary-color);
}
.user-info-header-full .user-info-header-icon:hover {
  color: var(--rmx-primary-color-2);
}
</style>
