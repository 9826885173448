export default {
  path: "utilization",
  component: () =>
    import(
      /* webpackChunkName: "front" */ "@/mods/front/myUtilization/UtilizationBase"
    ),
  children: [
    {
      path: "favorite",
      meta: {
        title: "收藏夹",
      },
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/myUtilization/FavoriteList"
        ),
    },
    {
      path: "excerpt",
      meta: {
        title: "我的摘录",
      },
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/myUtilization/MyExcept"
        ),
    },
    {
      path: "online",
      meta: {
        title: "利用申请",
      },
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/myUtilization/OnlineApply"
        ),
    },
    {
      path: "application",
      meta: {
        title: "协查申请",
      },
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/myUtilization/ApplicationList"
        ),
    },
    {
      path: "pushed",
      meta: {
        title: "收到的推送",
      },
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/myUtilization/ReceivePushed"
        ),
    },
  ],
};
