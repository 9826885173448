import RouterHolder from "@/components/core/RouterHolder";

export default {
  path: "todo",
  component: RouterHolder,
  children: [
    {
      path: "/front/todo",
      redirect: "/front/todo/list",
    },
    {
      path: "list",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/todoList/TodoList"
        ),
    },
  ],
};
