export default {
  researchManageTaskDetail: {
    component: () =>
      import(
        /* webpackChunkName: "archive_detail" */ "@/mods/archive/researchManageArchive/detailView/ResearchManageTaskDetail"
      ),
  },
  editResearchNodeTask: {
    component: () =>
      import(
        /* webpackChunkName: "archive_detail" */ "@/mods/archive/researchManageArchive/detailView/EditResearchNodeTask"
      ),
  },
  archiveApplyDetail: {
    component: () =>
      import(
        /* webpackChunkName: "archive_detail" */ "@/mods/archive/researchManageArchive/detailView/ArchiveApplyDetail"
      ),
  },
  joinResearch: {
    component: () =>
      import(
        /* webpackChunkName: "archive_detail" */ "@/mods/archive/researchManageArchive/detailView/JoinResearch"
      ),
  },
};
