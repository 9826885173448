export default {
  user: {
    edit: {
      width: "750px",
      okText: "actions.save",
      component: () =>
        import(
          /* webpackChunkName: "demo_dialog" */ "@/mods/demo/subMod/UserEditDialog"
        ),
    },
  },
  interface: {
    interfaceDefinition: {
      width: "xl",
      title: "界面定义",
      okText: "actions.save",
      component: () =>
        import(
          /* webpackChunkName: "demo_dialog" */ "@/mods/demo/subMod/dialog/InterFaceDefinitionDialog"
        ),
    },
  },
  editPsw: {
    editPassword: {
      width: "s",
      title: "修改密码",
      okText: "actions.save",
      component: () =>
        import(
          /* webpackChunkName: "demo_dialog" */ "@/mods/demo/subMod/dialog/EditPasswordDialog"
        ),
    },
  },
  interfaceDefinition: {
    addMetaData: {
      width: "s",
      title: "添加元数据",
      component: () =>
        import(
          /* webpackChunkName: "demo_dialog" */ "@/mods/demo/subMod/dialog/AddMetaData"
        ),
    },
  },
};
