import archDestructionDetail from "@/mods/archive/detailView/archDestructionDetail";
import assistanceQuery from "@/mods/archive/detailView/assistanceQueryDetail";
import participationAppraisal from "@/mods/archive/detailView/participationAppraisal";
import archivalAppraisal from "@/mods/archive/detailView/archivalAppraisal";
import archTransfer from "@/mods/archive/detailView/archTransfer";
import fondsInnerTransfer from "@/mods/archive/detailView/fondsInnerTransfer";
import pushManageDetail from "@/mods/archive/detailView/pushManageDetail";

import dataApplicationDetail from "@/mods/archive/detailView/dataApplicationDetail";
import dataReviewDetail from "@/mods/archive/detailView/dataReviewDetail";
import externalArchive from "@/mods/archive/detailView/externalArchive";
import migrationArchiveData from "@/mods/archive/detailView/migrationArchiveData";
import usingAppraise from "@/mods/archive/detailView/usingAppraise";
import receiveOfflineArchive from "@/mods/archive/detailView/receiveOfflineArchive";
import outApply from "@/mods/archive/detailView/outApply";
import researchManageArchive from "@/mods/archive/detailView/researchManageArchive";
import topicLibrary from "@/mods/archive/detailView/topicLibrary";
import projectManage from "@/mods/archive/detailView/projectManage";
import collectArchive from "@/mods/archive/detailView/collectArchive";
import archDonation from "@/mods/archive/detailView/archDonation";
import appointmentInquiry from "@/mods/archive/detailView/appointmentInquiry";

export default {
  archDestructionDetail,
  assistanceQuery,
  participationAppraisal,
  archivalAppraisal,
  ...dataApplicationDetail,
  ...dataReviewDetail,
  externalArchive,
  archTransfer,
  fondsInnerTransfer,
  migrationArchiveData,
  pushManageDetail,
  usingAppraise,
  receiveOfflineArchive,
  outApply,
  researchManageArchive,
  topicLibrary,
  projectManage,
  collectArchive,
  archDonation,
  appointmentInquiry,
};
