<template>
  <a-spin :spinning="loading">
    <template #indicator>
      <loading-outlined class="loading-view-icon" />
    </template>
    <template v-if="loadingText" #tip>
      <div class="loading-view-text">{{ loadingText }}</div>
    </template>
    <slot></slot>
  </a-spin>
</template>

<script>
import { LoadingOutlined } from "@ant-design/icons-vue";

export default {
  name: "LoadingView",
  components: { LoadingOutlined },
  props: {
    loading: { type: Boolean, default: true },
    loadingText: { type: String },
  },
};
</script>

<style scoped>
.loading-view-icon {
  font-size: 64px;
  color: var(--rmx-primary-color);
}
.loading-view-text {
  margin-top: var(--rmx-pad-m);
}
</style>
