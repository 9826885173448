export const ARCH_OBJECT_LEVEL = {
  project: 1,
  volume: 2,
  document: 3,
  box: 4,
};

export const ARCH_LEVEL_NAMES = {
  1: "project",
  2: "volume",
  3: "document",
  4: "box",
};

export const FULLTEXT_TYPE = {
  catalog: 2,
  original: 1,
};
