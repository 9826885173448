import RouterHolder from "@/components/core/RouterHolder";

export default {
  path: "subject",
  component: RouterHolder,
  children: [
    {
      path: "/front/subject",
      redirect: "/front/subject/list",
    },
    {
      path: "list",
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/subject/SubjectList"
        ),
    },
    {
      path: "detail",
      meta: { purePage: true },
      component: () =>
        import(
          /* webpackChunkName: "front" */ "@/mods/front/subject/SubjectDetail"
        ),
    },
  ],
};
