<template>
  <div
    class="titled-group"
    :class="{
      'titled-group-bordered': bordered,
      'titled-group-split-decorate': splitDecorate,
    }"
    :style="style"
  >
    <div class="titled-group-header">
      <div class="titled-group-title">{{ title }}</div>
      <div v-if="showOperation" class="titled-group-operations">
        <grouped-toolbar
          :operations="operations"
          btn-size="small"
          @action="$emit('action', $event)"
        />
      </div>
    </div>
    <div class="titled-group-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import GroupedToolbar from "@/components/common/action/GroupedToolbar";

export default {
  name: "TitledGroup",
  components: { GroupedToolbar },
  props: {
    title: { type: String, default: "" },
    bordered: { type: Boolean },
    splitDecorate: { type: Boolean },
    padding: { type: String },
    operations: { type: Array },
  },
  emits: ["action"],
  setup(props, { expose }) {
    const { padding, operations } = toRefs(props);
    const showOperation = computed(() => {
      const ops = operations.value;
      return Array.isArray(ops) && ops.length > 0;
    });
    const style = computed(() => {
      const s = {};
      if (padding.value) {
        s["--title-group-body-padding"] = padding.value;
      }
      return s;
    });
    expose({});
    return { style, showOperation };
  },
};
</script>

<style scoped>
.titled-group {
  --title-group-body-padding: 0;
}
.titled-group-bordered {
  --title-group-body-padding: var(--rmx-pad-m);
}
.titled-group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  color: var(--rmx-text-1);
  border-bottom: 1px solid var(--rmx-split);
  padding-bottom: 12px;
  margin-bottom: 16px;
}
.titled-group-title {
  padding-left: 8px;
  border-left: 4px solid var(--rmx-primary-color-5);
  white-space: nowrap;
}
.titled-group-split-decorate .titled-group-header {
  border-bottom: none;
}
.titled-group-split-decorate .titled-group-header:after {
  content: "";
  height: 4px;
  width: 100%;
  margin-left: 16px;
  background: var(--rmx-primary-color-1);
}
.titled-group-body {
  padding: var(--title-group-body-padding);
}
.titled-group:not(:last-child) > .titled-group-body {
  margin-bottom: var(--rmx-pad-l);
}
.titled-group-bordered > .titled-group-body {
  border: 1px solid var(--rmx-border);
  background: var(--rmx-content-color-opacity);
}
</style>
