export default {
  editMeta: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/meta/EditMetaDialog"
      ),
  },

  chooseAddMeta: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/meta/ChooseAddMetaDialog"
      ),
  },

  catalogConfig: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/catalogConfig/CatalogConfig"
      ),
  },

  metaInterfaceDefinition: {
    width: "m",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/meta/MetaInterfaceDefinition"
      ),
  },

  addDefinitionMetaData: {
    width: "s",
    title: "添加元数据",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/meta/AddDefinitionMetaData"
      ),
  },

  frontDefine: {
    width: "l",
    okText: "actions.save",
    component: () =>
      import(
        /* webpackChunkName: "setting_dialog" */ "@/mods/setting/archive/dialog/meta/FrontDefine"
      ),
  },
};
