export default {
  notificationList: {
    title: "user.notification",
    width: "m",
    component: () =>
      import(
        /* webpackChunkName: "common_list" */ "@/mods/common/notification/NotificationList"
      ),
  },
  borrowCart: {
    title: "front.navs.borrow_cart",
    width: "l",
    component: () =>
      import(
        /* webpackChunkName: "common_list" */ "@/mods/front/borrowCart/BorrowCart"
      ),
  },
};
