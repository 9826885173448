import { openWindowWith } from "@/util/uiUtil";
import { SERVICE_FROM } from "@/mods/archive/archiveTreasury/enum";
import {
  httpLoadBackRoleAuth,
  httpLoadFrontRoleBrowseAuth,
  httpLoadUserRoleInfo,
} from "@/api/common";
/**
 * 查看档案详情
 * @param archDetail 档案详情
 * @param archDetail.archtypeid 门类id
 * @param archDetail.objecttype 对象类型
 * @param archDetail.archid 档案id
 * @param archDetail.tableid 表名
 * @param [archDetail.authArchTypeId] 判断分工授权所用门类id，若为空则取archtypeid字段
 * @param [archDetail.onlyArchType] 为true时只判断门类通用权限，忽略档案个性化权限（为了适配对外接收场景）
 * @param dialogs 弹窗
 */
export async function viewArchDetail({ archDetail, dialogs }) {
  const roleStatus = (await httpLoadUserRoleInfo()).status; // 当前人员登录的角色类型
  const isFront = roleStatus === 1; // 当前角色是前台角色
  const isBack = [0, 2].includes(roleStatus); // 当前角色是后台角色
  let frontRoleHasBrowseAuth = null; // 前台角色有原文浏览权限
  const backRoleHasAuth = await httpLoadBackRoleAuth({
    archTypeId: archDetail.authArchTypeId || archDetail.archtypeid,
  }); // 当前岗位的后台角色有相应分工授权
  if (isBack && backRoleHasAuth) {
    // 打开著录弹窗
    dialogs.openDialog("common.archive.archRecord", {
      info: {
        archtypeid: archDetail.archtypeid,
        objecttype: archDetail.objecttype,
        id: archDetail.archid,
        needInVolList: true,
        isUsing: archDetail.tableid.indexOf("u") === 0,
      },
      serviceFrom: SERVICE_FROM.VIEW.CODE,
    });
    return null;
  }
  frontRoleHasBrowseAuth = await httpLoadFrontRoleBrowseAuth({
    archId: archDetail.archid,
    archTypeId: archDetail.archtypeid,
    objectType: archDetail.objecttype,
    tableId: archDetail.tableid,
    authArchTypeId: archDetail.authArchTypeId,
    onlyArchType: archDetail.onlyArchType,
  });
  if (isBack) {
    // 打开后台档案详情页
    dialogs.openDialog("archive.researchManageArchive.approvalArchiveDetails", {
      archTypeId: archDetail.archtypeid,
      dataid: archDetail.archid,
      objectType: archDetail.objecttype,
      tableId: archDetail.tableid,
      disAbleAttach: !frontRoleHasBrowseAuth,
    });
    return null;
  }
  // 前台
  if (isFront) {
    const browseAuth = frontRoleHasBrowseAuth || backRoleHasAuth;
    // 打开前台档案详情页
    openWindowWith({
      url: "front/archives/arch_detail",
      query: {
        readOnly: true, // 立即申请按钮需要禁掉
        archTypeId: archDetail.archtypeid,
        objectType: archDetail.objecttype,
        dataId: archDetail.archid,
        tableId: archDetail.tableid,
        browseAuth,
      },
    });
    return null;
  }
}
