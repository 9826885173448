export default {
  accessEdit: {
    width: "s",
    okText: "actions.save",
    component: () => import("@/mods/warehouse/accessManage/dialog/AccessEdit"),
  },
  accessRecordEdit: {
    width: "s",
    okText: "actions.save",
    component: () =>
      import("@/mods/warehouse/accessManage/dialog/AccessRecordEdit"),
  },
};
