import { http } from "@/util/http";

export function httpLoadUserNotificationCounts({ where = "front" } = {}) {
  if (where === "front") {
    return http.post("home/init/count/list", {}).then(({ data }) => {
      const message = +data.unReadMessageCount || 0;
      const todo = +data.portalWorkCount || 0;
      const collection = +data.collectionCount || 0;
      const excerpt = +data.excerptCount || 0;
      const usingApplication =
        (+data.recordCount || 0) +
        (+data.entityWJCount || 0) +
        (+data.entityXCJYCount || 0) +
        (+data.entityFZWFCount || 0);
      const findApplication = +data.findCount || 0;
      const pushed = +data.sendCount || 0;
      const utilization = usingApplication + findApplication + pushed;
      return {
        message,
        todo,
        notification: message + todo,
        collection,
        borrowCart: +data.borrowCount || 0,
        announcement: +data.cmsCount | 0,
        excerpt,
        usingApplication,
        findApplication,
        pushed,
        utilization,
      };
    });
  } else {
    return http.post("common/userCount/list", {}).then(({ data }) => {
      const message = +data.unreadCount || 0;
      const todo = +data.waitForWorkCount || 0;
      return {
        message,
        todo,
        notification: message + todo,
      };
    });
  }
}

export function httpCheckUserCanJump({
  userId,
  roleId,
  positionId,
  comId,
  type,
} = {}) {
  return http.post("common/personal/out/portal/work/jump/check", {
    userId,
    roleId,
    positionId,
    comId,
    type,
  });
}
