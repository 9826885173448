import sha1 from "js-sha1";
import md5 from "js-md5";
import { encode, decode } from "js-base64";

export function base64Encode(content) {
  return encode(content);
}

export function base64Decode(content) {
  return decode(content);
}

export function hashText(content, algo = "md5") {
  return createHash(algo).update(content).digest();
}

export function createHash(algo = "md5") {
  let ins;
  if (algo === "sha1") {
    ins = sha1.create();
  } else {
    ins = md5.create();
  }
  const handler = {
    update: (data) => {
      ins.update(data);
      return handler;
    },
    digest: () => ins.hex(),
  };
  return handler;
}
