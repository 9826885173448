import BaseHeaderLayout from "@/components/core/BaseHeaderLayout";
import FrontNotFound from "@/mods/front/error/FrontNotFound";
import setting from "@/mods/front/router/setting";
import announcement from "@/mods/front/router/announcement";
import subject from "@/mods/front/router/subject";
import search from "@/mods/front/router/search";
import utilization from "@/mods/front/router/utilization";
import archDetail from "@/mods/front/router/archDetail";
import todo from "@/mods/front/router/todo";
import message from "@/mods/front/router/message";
import help from "@/mods/front/router/help";
import projectDetail from "@/mods/front/router/projectDetail";
export default [
  {
    path: "/front",
    name: "Front",
    component: BaseHeaderLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "/front/",
        redirect: "/front/portal",
      },
      {
        path: "portal",
        name: "FrontPortal",
        component: () =>
          import(
            /* webpackChunkName: "front" */ "@/mods/front/portal/FrontPortal"
          ),
      },
      {
        path: "catalog",
        component: () =>
          import(
            /* webpackChunkName: "front" */ "@/mods/front/search/CatalogSearch"
          ),
      },
      {
        path: "utilization_detail",
        component: () =>
          import(
            /* webpackChunkName: "front" */ "@/mods/front/utilizationDetail/UtilizationWrap"
          ),
      },
      {
        path: "show_utilization",
        component: () =>
          import(
            /* webpackChunkName: "front" */ "@/mods/front/showUtilization/UseUtilizationDetail"
          ),
      },
      setting,
      search,
      announcement,
      subject,
      utilization,
      archDetail,
      todo,
      message,
      help,
      projectDetail,
      {
        path: ":pathMatch(.*)*",
        name: "FrontNotFound",
        component: FrontNotFound,
      },
    ],
  },
];
