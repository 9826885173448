<template>
  <config-provider :locale="locale" :auto-insert-space-in-button="false">
    <dialog-provider>
      <slot></slot>
    </dialog-provider>
  </config-provider>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import zhTW from "ant-design-vue/es/locale/zh_TW";
import jaJP from "ant-design-vue/es/locale/ja_JP";
import enUS from "ant-design-vue/es/locale/en_US";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { ConfigProvider } from "ant-design-vue";
import { systemConfig } from "@/api/common";
import { DEFAULT_TITLE, STORAGE_PREFIX } from "@/conf";
import { loadLanguageAsync } from "@/conf/lang";
import { closeSelfWindow } from "@/util/uiUtil";
import { checkAppVersion } from "@/util/http";
import DialogProvider from "@/components/core/DialogProvider";

const LOCALE_MAP = {
  ja: jaJP,
  en: enUS,
  "zh-cn": zhCN,
  "zh-tw": zhTW,
};

export default {
  name: "GlobalProvider",
  components: { ConfigProvider, DialogProvider },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    let locale = ref(zhCN);
    dayjs.locale("cn");
    const changeSkin = (skin) => {
      ConfigProvider.config({
        theme: {
          primaryColor: skin || "#1996ff",
        },
      });
    };
    changeSkin(store.state.skin);
    const onStorage = (e) => {
      if (e.storageArea === window.localStorage) {
        if (e.key === `${STORAGE_PREFIX}skin`) {
          store.state.skin = e.newValue;
        } else if (e.key === `${STORAGE_PREFIX}language`) {
          const lang = e.newValue;
          store.state.language = lang;
          const path = route.path.split("/");
          loadLanguageAsync(lang, path[1]).then(() => {});
        } else if (e.key === `${STORAGE_PREFIX}access_token`) {
          if (!e.newValue && e.oldValue) {
            closeSelfWindow(() => {
              store.commit("syncUserState", {});
              router.push("/login");
            });
          }
        }
      }
    };
    const onWindowFocus = () => {
      if (route.path === "/login") {
        checkAppVersion().then((e) => {
          if (e.changed) location.reload();
        });
      }
      const requireAuth = route.matched.some((x) => x?.meta?.requireAuth);
      if (!requireAuth) return;
      if (!localStorage[`${STORAGE_PREFIX}access_token`]) {
        store.commit("syncUserState", {});
        if (route.path !== "/login") {
          router.push("/login");
        }
      }
    };
    onMounted(() => {
      locale.value = LOCALE_MAP[store.state.language];
      systemConfig().then((res) => {
        if (res.code === 0) {
          const config = res.data ?? {};
          if (!config.title) {
            config.title =
              config?.logoInfo?.systemTitle ||
              config?.logoInfo?.title ||
              DEFAULT_TITLE;
          }
          if (config.skin && !store.state.skin) changeSkin(config.skin);
          store.commit("updateSystemConfig", config);
          document.title =
            config.title + (route?.meta?.title ? ` - ${route.meta.title}` : "");
        }
      });
      window.addEventListener("storage", onStorage);
      window.addEventListener("focus", onWindowFocus);
    });
    onUnmounted(() => {
      window.removeEventListener("storage", onStorage);
      window.removeEventListener("focus", onWindowFocus);
    });
    store.watch(
      (state) => state.skin,
      (newVal) => changeSkin(newVal)
    );
    store.watch(
      (state) => state.language,
      (newVal) => {
        locale.value = LOCALE_MAP[newVal];
      }
    );
    return { locale };
  },
};
</script>

<style scoped></style>
