<template>
  <popover-view placement="bottomLeft" v-model:visible="visible">
    <template #content>
      <div class="icon-picker-pop rmx-scrollbar">
        <template v-for="icon in icons" :key="icon">
          <div
            class="icon-picker-item"
            :class="{ 'icon-picker-item-active': activeIcon === icon }"
            @click="onChange(icon)"
          >
            <component :is="iconComp" :icon="icon"></component>
          </div>
        </template>
      </div>
    </template>
    <div class="icon-picker" :class="{ 'icon-picker-active': visible }">
      <div class="icon-picker-box">
        <component :is="iconComp" :icon="activeIcon" />
      </div>
      <div v-if="nameValid" class="icon-picker-name">{{ activeIcon }}</div>
      <div v-else class="icon-picker-hint">{{ $t("hint.no_data") }}</div>
      <div
        v-if="clearable && nameValid"
        class="icon-picker-clear"
        :title="$t('actions.clear')"
        @click="onChange('')"
      >
        <close-outlined />
      </div>
    </div>
  </popover-view>
</template>

<script>
import { computed, ref, toRefs } from "vue";
import { useCachedProps } from "@/components/common/shared/compInternal";
import { PROPS_INPUT_BASE } from "@/components/common/shared/compAttrs";
import {
  ICON_NAMES_MENU,
  ICON_NAMES_META_DATA,
} from "@/components/common/shared/iconNames";
import { CloseOutlined } from "@ant-design/icons-vue";
import PopoverView from "@/components/common/action/PopoverView";
import BackMenuIcon from "@/components/back/BackMenuIcon";
import MetaDataIcon from "@/components/icon/MetaDataIcon";

const MAP = {
  metaData: ICON_NAMES_META_DATA,
  menu: ICON_NAMES_MENU,
};

const ICON_MAP = {
  metaData: MetaDataIcon,
  menu: BackMenuIcon,
};

export default {
  name: "IconPicker",
  components: { BackMenuIcon, PopoverView, CloseOutlined },
  emits: ["change"],
  props: {
    ...PROPS_INPUT_BASE,
  },
  setup(props, { emit }) {
    const propRefs = toRefs(props);
    const visible = ref(false);
    const icons = computed(() => MAP[propRefs.mode.value]);
    const { makeValProp } = useCachedProps(propRefs, { emit });
    const activeIcon = makeValProp("value", "", true);
    const iconComp = computed(() => ICON_MAP[propRefs.mode.value]);
    const nameValid = computed(
      () => activeIcon.value && icons.value?.includes?.(activeIcon.value)
    );
    const onChange = (v) => {
      activeIcon.value = v;
      emit("change", v);
    };
    return { visible, icons, iconComp, nameValid, activeIcon, onChange };
  },
};
</script>

<style scoped>
.icon-picker {
  display: inline-flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  transition: color ease 0.3s;
}
.icon-picker-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 32px;
  height: 32px;
  border: 1px solid var(--rmx-border);
  border-radius: 2px;
  color: var(--rmx-text-1);
  transition: color ease 0.3s, border ease 0.3s;
}
.icon-picker-active,
.icon-picker:hover {
  color: var(--rmx-primary-color);
}
.icon-picker-active .icon-picker-box,
.icon-picker:hover .icon-picker-box {
  border: 1px solid var(--rmx-primary-color);
  color: var(--rmx-primary-color);
}
.icon-picker-pop {
  display: flex;
  gap: 16px;
  width: 450px;
  max-height: 480px;
  overflow: auto;
  flex-wrap: wrap;
}
.icon-picker-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  width: 48px;
  height: 48px;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  transition: color ease 0.3s, border ease 0.3s;
}
.icon-picker-item-active,
.icon-picker-item:hover {
  border: 1px solid var(--rmx-primary-color);
  color: var(--rmx-primary-color);
}
.icon-picker-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 50%;
  background: #dddddd;
  font-size: 12px;
  color: white;
  transition: background ease 0.3s;
}
.icon-picker-clear:hover {
  background: #bbbbbb;
}
.icon-picker-hint {
  color: var(--rmx-text-4);
}
</style>
