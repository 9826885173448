<template>
  <div class="option-checkbox">
    <a-checkbox
      :name="name"
      :checked="checked"
      :disabled="disabled"
      @change="onChange"
    >
      <span class="options-checkbox-label">{{ text ?? label }}</span>
      <span v-if="description" class="options-checkbox-desc">
        {{ description }}
      </span>
      <tips-icon v-if="tips" :tips="tips"></tips-icon>
    </a-checkbox>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import TipsIcon from "@/components/common/display/TipsIcon";

export default {
  name: "OptionCheckbox",
  components: { TipsIcon },
  emits: ["change", "update:value"],
  props: {
    name: { type: String },
    value: { type: [String, Boolean, Number] },
    compat: { type: Boolean },
    readonly: { type: Boolean },
    disabled: { type: Boolean },
    text: { type: String },
    label: { type: String },
    description: { type: String },
    tips: { type: String },
  },
  setup(props, { emit }) {
    const propRefs = toRefs(props);
    const { compat, readonly, value } = propRefs;
    const checked = computed(() =>
      compat.value ? +value.value === 1 : value.value
    );
    const setVal = (val) => {
      if (!readonly.value) {
        emit("update:value", val);
        emit("change", val);
      }
    };
    const onChange = (e) => {
      const c = e.target.checked;
      if (compat.value) {
        setVal(c ? 1 : 0);
      } else {
        setVal(c);
      }
    };
    return { checked, onChange };
  },
};
</script>

<style scoped>
.option-checkbox {
  display: flex;
  align-items: center;
  min-height: 32px;
}
.options-checkbox-label {
}
.options-checkbox-desc {
  margin-left: 4px;
  color: var(--rmx-text-3);
}
</style>
